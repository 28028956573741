/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { StoreDbService, OBJECTNAME, AdnUser, } from '../godigital-lib';
import { UtilsService, dayInMilliseconds } from '../godigital-lib';
import { MailerService } from '../godigital-lib';
import { PaymentGwService, PAYMENTGW } from '../godigital-lib';
import {
  SmbService,
  AdnCorporateI,
  AdnStoreILight,
  AdnStoreI,
  AdnStoreIKamli
} from '../godigital-lib';
import { UsersService } from '../godigital-lib';
import { AdnDocument, LeadHistory, LeadContact, AdnAssistant } from '../godigital-lib';

export enum PUBASSETTYPE {
  PUBSTORE = 'pubStore',
  PUBIMAGE = 'pubImages',
  PUBVIDEO = 'pubVideos',
  PUBSLIDE = 'pubSlides'
}

export enum PUBMODERATIONSTATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}

export enum PUBPAYMENTSTATUS {
  NOTPAID = 'NOTPAID',
  PARTIALLYPAID = 'PARTIALLYPAID',
  PAID = 'PAID',
  OFFERED = 'OFFERED'
}

export enum PUBSTARTSTATUS {
  NOTSTARTED = 'NOTSTARTED',
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
  PASSED = 'PASSED',
}

export enum CREATEPUBSTATUS {
  SUCCES = 1,
  CARTDELETIONERROR = 2,
  ORDEROBJECTCREATIONERROR = 3,
  STRIPECHARGEERROR = 4,
  STRIPESUBCRIPTIONERROR = 5,
  BUSINESSWITHOUTSTRIPE = 6,
  ORDERTOTAL0 = 7,
  PAYMENTMODENOTSUPPORTED = 8,
  UNKNOWNERROR = 9
}

export enum SORTPUBDIR {
  BusinessUp = 0,
  BusinessDown = 1,
  CategoryUp = 2,
  CategoryDown = 3,
  SubcategoryUp = 4,
  SubcategoryDown = 5,
  CampaignUp = 6,
  CampaignDown = 7
}

export interface PublishCampaign {
  adnCustomerUserId: string;
  adnBusinessUserId: string;
  campaignId: string;
  campaignName: string;
  storeAsset: StoreAsset;
  spots: string[];
  createdTS: any;
  modifiedTS: any;
  durationType: string;
  numberDuration: number;
  startDates: any[];
  broadcast: boolean;
  adType: number;
  estimatedPrice: number;
  TVA: number;
  advertiserName: string;
  webSite: string;
  phone: string;
  email: string;
  moderationStatus: PUBMODERATIONSTATUS;
  runningStatus: PUBSTARTSTATUS;
  paymentStatus: PUBPAYMENTSTATUS;

  businessType: string;
  businessTypeIndex: number;
  businessVertical: string;
  businessVerticalIndex: number;

  totalEyeBalls: number;
  totalTVs: number;
  totalTablets: number;

  paymentGtwData: any;
  selectedPaymentMethod: PAYMENTGW;

  documents: AdnDocument[];
  quoteHistory: LeadHistory[];
  contacts: LeadContact[];
}

export interface PublishOrder {
  storeId: string;
  orderId: string;
  storeAsset: StoreAsset;
  adnBusinessUserId: string;
  adnCustomerUserId: string;
  grandTotal: number;
  grandTotalVAT: number;
  visible: boolean;
  shared: boolean;
  createdTS: number;
  modifiedTS: number;
  archived: boolean;
  totalCommission: number;
  moderationStatus: PUBMODERATIONSTATUS;
  runningStatus: PUBSTARTSTATUS;
  analytics: {
    PCPM: number;
    ICPM: number;
    CPC: number;
    CPA: number;
  };
}

export interface StoreAsset {
  adnUserId: string;
  storeId: string;
  fbObjectType: string;
  assetId: string;
  asset: any;
}

/*export interface PublishAsset {
  adnCustomerUserId: string;
  pubAssetId: string;
//  pubAssetType: string;
//  pubAssetSubType: string;
  businessType: string;
  businessTypeIndex: number;
  businessVertical: string;
  businessVerticalIndex: number;
//  assetLength: number;
//  title: string;
//  description: string;
//  thumbL: string;
//  thumbP: string;
//  localurlL: string;
//  localurlP: string;
//  localurlL2: string;
//  localurlP2: string;
  createdTS: any;
  modifiedTS: any;
  storeAsset: StoreAsset;
}*/


export interface CampaignAnalytics {
  campaignId: string;
  storeId: string;
  deviceId: string;
  deviceType: string;
  latitude: number;
  longitude: number;
  ADANALYTICSEVENT: string;
  day: string;
  time: any;
  PCPM: number;
  ICPM: number;
  CPC: number;
  CPA: number;
}

export enum ADANALYTICSEVENT {
  PCPM = 'PCPM',
  ICPM = 'ICPM',
  CPC = 'CPC',
  CPA = 'CPA'
}

export interface PublishOrderKamli extends PublishOrder {
  storeAsset: StoreAsset;
  publishCampaign: PublishCampaign;
  store: AdnStoreI;
  advertiserUser: AdnUser;
  businessUser: AdnUser;
}

export interface PublishCampaignKamli extends PublishCampaign {
  storeAsset: StoreAsset;
  publishOrders: PublishOrder[];
  spotsDetails: AdnStoreI[];
  adnUser: AdnUser;
  adnBusiness: AdnUser;
  assistant: AdnAssistant;
}

/*export interface PublishAssetKamli extends PublishAsset {
  spots: AdnStoreI[];
  publishCampaigns: PublishCampaign[];
  adnUser: AdnUser;
}*/

export interface QuoteHistory {
  date: number;
  comments: string;
  price: number;
  commission: number;
}

export interface PublishQuote {
  assistantId: string;
  businessTypeIndex: number;
  businessVerticalIndex: number;
  campaignName: string;
  contacts: LeadContact[];
  corporateId: string;
  created: number;
  days: number[];
  mediaNickName: string;
  mediaType: string;
  mediaUrl: string;
  quoteHistory: QuoteHistory[];
  quoteId: string;
  spots: string[];
  startDates: any[];
  status: string;
}

export interface PublishQuoteKamli extends PublishQuote {
  assistant: AdnUser;
  businessType: string;
  businessVertical: string;
  spotsDetails: AdnStoreI[];
  corporate: AdnCorporateI;
}



export const regexVideo = /\.(mp4|avi)/;

@Injectable({
  providedIn: 'root'
})
export class PublishingRequestService {
  public dateTime = new Date().getTime();
  public storeAdOrder: PublishOrder;
  //  public storeAdAsset: StoreAsset;
  public storeAdCampaign: PublishCampaign;

  constructor(
    public paymentGwSvc: PaymentGwService,
    public usersSvc: UsersService,
    public mailerSvc: MailerService,
    public storeDbSvc: StoreDbService,
    public utilsSvc: UtilsService,
    public smbSvc: SmbService,
    public http: HttpClient,
  ) { }

  // Publish Orders
  createPublishOrder(
    campaign: PublishCampaign,
    storeId: string,
    oneSpotAd?: boolean
  ) {
    let publishOrder: PublishOrder;
    return new Promise(async (resolve, reject) => {
      let store: AdnStoreILight;
      let corporate: AdnCorporateI;
      let adnCustomerUser: AdnUser;
      let adnBusinessUser: AdnUser;
      try {
        store = await this.smbSvc.getStore(this.utilsSvc.backendFBstoreId, storeId) as AdnStoreILight;
      } catch (e) {
      }
      if (store) {
        try {
          corporate = await this.smbSvc.getCorporate(this.utilsSvc.backendFBstoreId, store.adnCorporateId) as AdnCorporateI;
        } catch (e) {
        }
        if (corporate) {
          adnBusinessUser = await this.usersSvc.getUser(corporate.adnUserId) as AdnUser;
        }
        if (corporate) {
          adnCustomerUser = await this.usersSvc.getUser(campaign.adnCustomerUserId) as AdnUser;
        }
      }
      if (store && corporate && adnBusinessUser && adnCustomerUser) {
        publishOrder = {} as PublishOrder;
        publishOrder.orderId = campaign.campaignId;
        publishOrder.adnCustomerUserId = adnCustomerUser.adnUserId;

        publishOrder.storeAsset = campaign.storeAsset;
        publishOrder.storeAsset.asset = null;

        publishOrder.moderationStatus = campaign.paymentStatus === PUBPAYMENTSTATUS.OFFERED ? PUBMODERATIONSTATUS.ACCEPTED
          : PUBMODERATIONSTATUS.PENDING;

        publishOrder.runningStatus = campaign.runningStatus ? campaign.runningStatus : PUBSTARTSTATUS.NOTSTARTED;

        publishOrder.storeId = store.adnStoreId;

        publishOrder.adnBusinessUserId = corporate.adnUserId;

        const unitpricing = store.kamliadsDetails && store.kamliadsDetails.publishingPricing &&
          store.kamliadsDetails.publishingPricing && store.kamliadsDetails.publishingPricing['10'] ?
          store.kamliadsDetails.publishingPricing['10'].pricing : 0;
        publishOrder.grandTotal = unitpricing * campaign.numberDuration;
        publishOrder.grandTotalVAT = Math.round(unitpricing *
          campaign.numberDuration * (1 - 1 / 1.2) * 100) / 100;

        publishOrder.totalCommission =
          Math.round(publishOrder.grandTotal * 0.5 * 100) / 100;

        publishOrder.archived = false;

        publishOrder.visible = true;
        this.storeDbSvc.updateObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb, OBJECTNAME.publishingNewOrders,
          publishOrder, store.adnStoreId + '/' + publishOrder.orderId);

        this.mailerSvc.sendMailNewPublishRequest(
          corporate,
          store,
          adnBusinessUser,
          adnCustomerUser,
          publishOrder.orderId,
          publishOrder.grandTotal,
          oneSpotAd
        );
        resolve([corporate, adnBusinessUser, adnCustomerUser, campaign, publishOrder]);
      }
    });
  }

  async getCurrentPublishingOrders(storePubOrders: PublishOrderKamli[]): Promise<PublishOrderKamli[]> {
    const publishOrdersToday: PublishOrderKamli[] = [];
    if (storePubOrders) {
      const timeToday = new Date().getTime();
      const storePubOrdersLength = storePubOrders.length;
      for (let i = 0; i < storePubOrdersLength; i++) {
        const pubOrder = storePubOrders[i];
        let campaign: PublishCampaign;
        try {
          campaign = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb,
            OBJECTNAME.publishingCampaigns, pubOrder.adnCustomerUserId + '/' + pubOrder.orderId) as PublishCampaign;
        } catch (e) {
        }
        if (campaign && campaign.startDates) {
          const pubOrderStartDatesLength = campaign.startDates.length;
          for (let j = 0; j < pubOrderStartDatesLength; j++) {
            const time = campaign.startDates[j];
            if (time) {
              if (time[0] <= timeToday && time[1] >= timeToday) {
                if (pubOrder.moderationStatus === PUBMODERATIONSTATUS.ACCEPTED ||
                  pubOrder.moderationStatus === PUBMODERATIONSTATUS.PENDING) {
                  publishOrdersToday.push(pubOrder);
                }
              }
            }
          }
        }
      }
    }
    return publishOrdersToday;
  }

  checkExpiredOrders(publishOrders: PublishOrderKamli[]) {
    const promises = [];
    return new Promise(async (resolve, reject) => {
      if (publishOrders) {
        for (const pubOrder of publishOrders) {
          let campaign: PublishCampaign;
          try {
            campaign = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb,
              OBJECTNAME.publishingCampaigns, pubOrder.adnCustomerUserId + '/' + pubOrder.orderId) as PublishCampaign;
          } catch (e) {
          }
          if (campaign && campaign.startDates) {
            const startDatesLength = campaign.startDates.length - 1;
            if (!pubOrder.archived) {
              const orderTS = campaign.startDates[startDatesLength][1];

              const nowTS = new Date().getTime();
              if (nowTS > orderTS) {
                //                const smbStoreLight = this.smbSvc.copyStoreLight(smbStore);
                promises.push(
                  this.updateSpotCampaign(pubOrder, PUBMODERATIONSTATUS.CANCELLED, PUBSTARTSTATUS.STOPPED)
                );
              }
            }
          }
          Promise.all(promises).then(
            data => {
              resolve(data);
            },
            error => {
              reject(error);
            }
          );
        }
      }
    });
  }

  updatePublishOrder(publishOrder: PublishOrder, smbStore: AdnStoreIKamli): Promise<PublishOrderKamli> {
    return new Promise(async (resolve, reject) => {
      let publishOrderKamli: PublishOrderKamli = publishOrder as PublishOrderKamli;
      if (publishOrder.storeAsset && publishOrder.storeAsset.assetId) {
        let pubAssetId = publishOrder.storeAsset.assetId;
        let asset;
        try {
          asset = await this.storeDbSvc.getStoreObject(publishOrder.storeAsset.storeId, publishOrder.storeAsset.fbObjectType, pubAssetId);
        } catch (e) { }
        if (asset) {
          publishOrderKamli.storeAsset.asset = asset;
          publishOrderKamli.store = smbStore as any;
          try {
            publishOrderKamli.advertiserUser = await this.usersSvc.getUser(publishOrder.adnCustomerUserId) as AdnUser;
          } catch (e) { }
          try {
            publishOrderKamli.publishCampaign = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId,
              this.utilsSvc.mdb, OBJECTNAME.publishingCampaigns, publishOrder.adnCustomerUserId + '/' +
            publishOrder.orderId) as PublishCampaign;
            const selectedRateS = { day: 0, week: 1, month: 2 };
            const selectedRate = selectedRateS[publishOrderKamli.publishCampaign.durationType];
            const dateLength = publishOrderKamli.publishCampaign.startDates.length;
            const dateNow = new Date().getTime();
            publishOrderKamli.publishCampaign.runningStatus = PUBSTARTSTATUS.NOTSTARTED;
            let stopLoop = false;
            for (let dateIndex = 0; dateIndex < dateLength && !stopLoop; dateIndex++) {
              const startDate = publishOrderKamli.publishCampaign.startDates[dateIndex];
              if (startDate[1] < dateNow) {
                publishOrderKamli.publishCampaign.runningStatus = PUBSTARTSTATUS.PASSED;
              }
              if (startDate[0] < dateNow && startDate[1] > dateNow) {
                publishOrderKamli.publishCampaign.runningStatus = PUBSTARTSTATUS.STARTED;
                stopLoop = true;
              }
            }
            let estimatedUnitPrice = 0;
            const temp2 = Number(publishOrderKamli.publishCampaign.adType === 0 ? 10 : 70) + Number(selectedRate) * 10;
            estimatedUnitPrice += smbStore.kamliadsDetails.publishingPricing[temp2].pricing *
              publishOrderKamli.publishCampaign.numberDuration;
            publishOrderKamli.grandTotal = estimatedUnitPrice / 1.2;
            publishOrderKamli.grandTotalVAT = estimatedUnitPrice - publishOrderKamli.grandTotal;
          } catch (e) { }
        } else {
          publishOrderKamli = {} as PublishOrderKamli;
        }
        resolve(publishOrderKamli);
      }
    });
  }

  updatePublishAsset(publishAsset: StoreAsset): Promise<StoreAsset> {
    return new Promise(async (resolve, reject) => {
      const publishAssetKamli: StoreAsset = publishAsset as StoreAsset;

      let adnUser: AdnUser;
      try {
        adnUser = await this.usersSvc.getUser(publishAssetKamli.adnUserId) as AdnUser;
      } catch (e) { }
      // eslint-disable-next-line @typescript-eslint/dot-notation
      publishAssetKamli['adnUser'] = adnUser;

      let campaignsj;
      try {
        campaignsj = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId,
          this.utilsSvc.mdb, OBJECTNAME.publishingCampaigns, publishAssetKamli.adnUserId);
      } catch (e) { }

      const publishCampaigns = this.utilsSvc.objectToArray(campaignsj) as PublishCampaign[];

      const spotIds = [];
      const spotsDetails = [] as AdnStoreI[];
      for (const spotid of spotIds) {
        let store;
        try {
          store = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId,
            this.utilsSvc.mdb, OBJECTNAME.adnStores, spotid);
        } catch (e) { }
        if (!store) {
          spotsDetails.push(store);
        }
      }
      resolve(publishAssetKamli);
    });
  }


  // Publish Campaigns
  createCampaign(adnUser: AdnUser, campaign: PublishCampaign, paymentMethod) {
    return new Promise((resolve, reject) => {
      let promiseIntent;
      const promiseOrder = [];

      // start with payment
      switch (paymentMethod) {
        case PAYMENTGW.CARD:
          promiseIntent = new Promise(resolve1 => resolve1({ id: null }));
          campaign.paymentStatus = PUBPAYMENTSTATUS.NOTPAID;
          break;
        case PAYMENTGW.IBAN:
          promiseIntent = new Promise(resolve1 => resolve1({ id: null }));
          campaign.paymentStatus = PUBPAYMENTSTATUS.NOTPAID;
          break;
        case PAYMENTGW.FREE:
          promiseIntent = new Promise(resolve1 => resolve1({ id: null }));
          campaign.paymentStatus = PUBPAYMENTSTATUS.OFFERED;
          break;
        case PAYMENTGW.CASH:
          promiseIntent = new Promise(resolve1 => resolve1({ id: null }));
          campaign.paymentStatus = PUBPAYMENTSTATUS.PAID;
          break;
      }

      promiseIntent.then(
        intent => {
          this.createCampaignFirebase(adnUser, campaign, intent).then(
            data => resolve(data),
            error => reject(error)
          );
        }
      );
    });
  }

  createCampaignFirebase(adnUser: AdnUser, campaign: PublishCampaign, intentId) {
    return new Promise(async (resolve, reject) => {
      const promiseOrder = [];
      campaign.paymentStatus = PUBPAYMENTSTATUS.NOTPAID;

      campaign.paymentGtwData = intentId;
      const campaignSpotsLength = campaign.spots.length;
      for (let i = 0; i < campaignSpotsLength; i++) {
        const store = campaign.spots[i];
        promiseOrder.push(
          new Promise((resolve1, reject1) => {
            this.createPublishOrder(campaign, store, false).then(
              data20 => {
                resolve1(data20);
              },
              error20 => {
                reject1(error20);
              }
            );
          })
        );

        Promise.all(promiseOrder).then(
          data => {
            this.mailerSvc.sendMailNewCampaignRequest(adnUser, campaign, undefined);
            resolve(data);
          },
          error => reject(error)
        );
      }
      await this.editCampaign(campaign as PublishCampaignKamli);
    });
  }

  editCampaign(campaign: PublishCampaignKamli) {
    return new Promise(async (resolve, reject) => {
      if (campaign.adnCustomerUserId && campaign.campaignId && campaign.storeAsset) {
        campaign.storeAsset.asset = null;
        campaign.spotsDetails = null;
        campaign.adnUser = null;
        campaign.adnBusiness = null;
        campaign.assistant = null;
        let error;
        try {
          await this.storeDbSvc.updateObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb, OBJECTNAME.publishingCampaigns, campaign,
            campaign.adnCustomerUserId + '/' + campaign.campaignId);
        } catch (e) { error = e; }
        if (!error) {
          resolve(1);
        } else {
          reject(error);
        }
      }
    });
  }

  updateSpotCampaign(
    publishOrder1: PublishOrderKamli, moderationStatus: PUBMODERATIONSTATUS, runningStatus: PUBSTARTSTATUS) {
    return new Promise((resolve, reject) => {
      const publishOrder: PublishOrder = {
        storeId: '',
        orderId: '0',
        storeAsset: null,
        adnBusinessUserId: '',
        adnCustomerUserId: '',
        grandTotal: 0,
        grandTotalVAT: 0,
        visible: true,
        shared: true,
        createdTS: new Date().getTime(),
        modifiedTS: new Date().getTime(),
        archived: false,
        totalCommission: 0,
        moderationStatus: PUBMODERATIONSTATUS.PENDING,
        runningStatus: PUBSTARTSTATUS.NOTSTARTED,
        analytics: {
          PCPM: 0,
          ICPM: 0,
          CPC: 0,
          CPA: 0,
        }
      };
      for (const key in publishOrder1) {
        if (publishOrder.hasOwnProperty(key)) {
          publishOrder[key] = publishOrder1[key];
        }
      }

      if (moderationStatus) {
        publishOrder.moderationStatus = moderationStatus;
      }
      if (runningStatus) {
        publishOrder.runningStatus = runningStatus;
      }
      if (runningStatus === PUBSTARTSTATUS.PASSED) {
        publishOrder.visible = false;
        publishOrder.archived = true;
        publishOrder.runningStatus = runningStatus;
      }
      if (runningStatus === PUBSTARTSTATUS.STARTED) {
        publishOrder.visible = true;
        publishOrder.runningStatus = runningStatus;
      }
      if (runningStatus === PUBSTARTSTATUS.STOPPED) {
        publishOrder.visible = false;
        publishOrder.runningStatus = runningStatus;
      }
      this.storeDbSvc.updateObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb, OBJECTNAME.publishingNewOrders, publishOrder,
        publishOrder1.store.adnStoreId + '/' + publishOrder.orderId
      );
      resolve(publishOrder);
    });
  }

  cancelSpotFromCampaign(campaign: PublishCampaign, spot: AdnStoreILight) {
    return new Promise((resolve, reject) => {
      this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb, OBJECTNAME.publishingNewOrders,
        spot.adnStoreId + '/' + campaign.campaignId).then(
          data => {
            const publishOrder = data as PublishOrder;
            publishOrder.moderationStatus = PUBMODERATIONSTATUS.CANCELLED;
            publishOrder.visible = false;
            publishOrder.archived = true;
            this.storeDbSvc.updateObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb, OBJECTNAME.publishingNewOrders,
              publishOrder, spot.adnStoreId + '/' + campaign.campaignId);
            resolve(campaign);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  createFillerAd(store: AdnStoreI, businessVertical, adnAds?): Promise<PublishOrderKamli> {
    return new Promise(async (resolve, reject) => {
      const storeAsset = {} as StoreAsset;
      const publishCampaign = {} as PublishCampaignKamli;
      let publishOrder: PublishOrderKamli;
      if ((store && store.kamlifilleradsDetails && store.kamlifilleradsDetails.thumbL && store.kamlifilleradsDetails.thumbP) ||
        (store && adnAds)) {
        let corporate: AdnCorporateI;
        try {
          corporate = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb,
            OBJECTNAME.adnCorporates, store.adnCorporateId) as AdnCorporateI;
        } catch (e) {
          reject(e);
        }
        if (corporate) {
          storeAsset.asset = adnAds ? adnAds : null;
          storeAsset.adnUserId = corporate.adnUserId;
          storeAsset.storeId = store.adnStoreId;
          storeAsset.adnUserId = corporate.adnUserId;
          const tempi = this.utilsSvc.getMediaType(adnAds.thumbL);
          storeAsset.fbObjectType = tempi === 'video' ? OBJECTNAME.DsPVideo : OBJECTNAME.DsPImage;
        }
        if (storeAsset) {
          let adnCustomer: AdnUser;
          let adnBusiness: AdnUser;
          let assistant: AdnAssistant;
          try {
            adnCustomer = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb,
              OBJECTNAME.adnUsers, storeAsset.adnUserId) as AdnUser;
          } catch (e) {
            reject(e);
          }
          if (store.assistantId) {
            try {
              assistant = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb,
                OBJECTNAME.assistants, store.assistantId) as AdnAssistant;
            } catch (e) {
              reject(e);
            }
            try {
              adnBusiness = await this.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId, this.utilsSvc.mdb,
                OBJECTNAME.adnUsers, store.assistantId) as AdnUser;
            } catch (e) {
              reject(e);
            }
          }
          if (adnCustomer && corporate && adnBusiness && assistant) {
            publishCampaign.TVA = 0;
            publishCampaign.adType = 0;
            publishCampaign.adnCustomerUserId = storeAsset.adnUserId;
            publishCampaign.adnBusinessUserId = adnBusiness.adnUserId;
            publishCampaign.adnUser = adnCustomer;
            publishCampaign.adnBusiness = adnBusiness;
            publishCampaign.assistant = assistant;
            publishCampaign.advertiserName = adnAds && adnAds.filename ? adnAds.filename : store.storeName;
            publishCampaign.campaignId = adnAds.assetId ? adnAds.assetId : Number(store.adnStoreId);
            publishCampaign.campaignName = adnAds && adnAds.filename ? adnAds.filename : store.storeName;
            publishCampaign.createdTS = new Date().getTime();
            publishCampaign.modifiedTS = new Date().getTime();
            publishCampaign.durationType = 'month';
            publishCampaign.email = store.email;
            publishCampaign.estimatedPrice = 0;
            publishCampaign.moderationStatus = PUBMODERATIONSTATUS.ACCEPTED;
            publishCampaign.numberDuration = 12;
            publishCampaign.paymentStatus = PUBPAYMENTSTATUS.OFFERED;
            publishCampaign.phone = store.phone;
            publishCampaign.runningStatus = PUBSTARTSTATUS.STARTED;
            publishCampaign.spots = [store.adnStoreId];
            publishCampaign.startDates = [];
            publishCampaign.startDates[0] = [new Date().getTime() - dayInMilliseconds, new Date().getTime() + 354 * dayInMilliseconds];
            publishCampaign.totalEyeBalls = store.kamliadsDetails?.maxEyeBalls;
            publishCampaign.totalTVs = store.kamliadsDetails?.numberTV;
            publishCampaign.totalTablets = store.kamliadsDetails?.numberTablet;
            publishCampaign.webSite = store.website ? store.website : 'https://kamliads.com';

            if (!publishOrder) {
              publishOrder = {} as PublishOrderKamli;
            }
            publishOrder.storeAsset = storeAsset;
            publishOrder.adnBusinessUserId = corporate.adnUserId;
            publishOrder.adnCustomerUserId = storeAsset.adnUserId;
            publishOrder.analytics = {
              PCPM: 0,
              ICPM: 0,
              CPC: 0,
              CPA: 0,
            };
            publishOrder.archived = false;
            publishOrder.advertiserUser = adnCustomer;
            publishOrder.grandTotal = 0;
            publishOrder.grandTotalVAT = 0;
            publishOrder.moderationStatus = PUBMODERATIONSTATUS.ACCEPTED;
            publishOrder.runningStatus = PUBSTARTSTATUS.STARTED;
            publishOrder.store = store;
            publishOrder.storeId = store.adnStoreId;
            publishOrder.totalCommission = 0;
            publishOrder.visible = true;
            publishOrder.publishCampaign = publishCampaign;
            publishOrder.orderId = adnAds.assetId ? adnAds.assetId : Number(store.adnStoreId);

          }
        }
      }
      resolve(publishOrder);
    });
  }


}
