import { Injectable, NgZone } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { UtilsService, IMAGETYPE, SCHEDULETYPE } from './utils.service';

/*import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';*/
import * as express from 'express';

export enum OBJECTNAME {
  adnCorporates = 'backendCorporates',
  adnStores = 'backendStores',
  sponsored = 'backendSponsored',
  adnUsers = 'backendAdnUsers',
  adnOtp = 'backendAdnOtp',
  inventory = 'backendInventory',
  backgroundImages = 'backgroundImages',
  firebaseProjects = 'backendFirebaseProjects',
  templateCatalogue = 'backendTemplateCatalogue',
  templateCatalogue1 = 'backendTemplateCatalogue1',
  storeupdates = 'storeupdates',

  leads = 'backendLeads',
  assistants = 'backendAssistants',
  documents = 'backendDocuments',

  customers = 'customers',
  categories = 'categories',
  accessories = 'accessories',
  products = 'products',
  events = 'events',
  loyaltyPointOffer = 'loyaltyPointOffer',
  loyaltyPointValue = 'loyaltyPointValue',
  promotions = 'promotions',
  extras = 'extraOptions',
  preparation = 'preparation',
  technology = 'technology',
  productSliders = 'productSliders',
  orders = 'orders',
  tables = 'tables',
  payments = 'payments',
  cart = 'cart',
  payDineDetails = 'payDineDetails',
  payCollectDetails = 'payCollectDetails',
  payDeliverDetails = 'payDeliverDetails',
  deliveryDetails = 'deliveryDetails',
  staff = 'staff',
  subscriptions = 'subscriptions',
  generalDetails = 'generalDetails',
  stripePlans = 'stripePlans',
  integral = '/',
  quotes = 'quotes',
  pageSliders = 'pageSliders',
  DsSchedule = 'DsSchedule',
  DsPlaylist = 'DsPlaylist',
  DsSlide = 'DsSlide',
  DsPImage = 'DsPImage',
  DsPVideo = 'DsPVideo',
  ImageVisible = 'ImageVisible',

  AdnMedia = 'videoServices1/AdnMedias',
  AdnMediaKamli = 'videoServices1/AdnMediaKamli',
  ThematicVideos = 'videoServices1/ThematicVideos',
  ThematicVideosKamli = 'videoServices1/ThematicVideosKamli',
  DsRss = 'videoServices1/DsRss',
  DsRssKamli = 'videoServices1/DsRssKamli',

  PublishingPricing = 'publishingPricing',

  //  publishingNewAssets = 'backendPublishingNewAssets',
  publishingNewOrders = 'backendPublishingNewOrders',
  publishingCampaigns = 'backendPublishingCampaigns',
  publishingQuotes = 'backendpublishingQuotes',

  analytics = 'backendAnalytics',

  publishingProduct = 'publishingProduct',

  DsSlideTypes = 'slidetypes',
  kameleon = 'backendKameleon',
  calories = 'backendCalories',
  allergens = 'backendAllergens',
  kamCommands = 'backendKamCommands',
  specialities = 'backendSpecialities',
  filters = 'backendFilters',
  localities = 'backendLocalities',
  franchises = 'backendFranchises',

  cartlight = 'cartlight',
  orderlight = 'orderlight',
  pubOrderlight = 'pubOrderlight',

  backendFB = 'backendFB',
  storeFB = 'storeFB',
  userFB = 'userFB',

  catalogueImages = 'backendcatalogueImages',
  Tutos = 'Tutos',
  Tutoslides = 'Tutoslides',

  socialnetworks = 'socialnetworks',
  likes = 'likes',
  notifications = 'notifications',
  smslistener = 'smslistener',
}

export interface AdnkameleonBox {
  kameleonId: string;
  config: any;
  playerType: kamPlayerType;
  playerModel: string;
  storeId: string;
  scheduleId: string;
  playlistId: string;
  slideId: string;
  modeSchedule: number;
  nickName: string;
  logging: boolean;
  otp: string;
  lat: number;
  lng: number;
  monitored: boolean;
  since: number;
  version: string;
  status: string;
  class: string;
  classn: number;
}

export enum ASSISTANTTYPE {
  ALL = 1,
  ECOMMERCE = 2,
  ADS = 3,
}

export interface AdnInventory {
  itemId: string;
  type: string;
  model: string;
  softwareversion: string;
  name: string;
  description: string;
  location: string;
  documents: AdnInventoryDocument[];
  status: string;
}

export interface AdnInventoryDocument {
  documentId: string;
  type: string;
  comments: string;
  link: string;
  date: number;
}

export enum KAMCOMMAND {
  KIOSK_START = 'KIOSK_START',
  KIOSK_STOP = 'KIOSK_STOP',
  PLAYER_START = 'PLAYER_START',
  PLAYER_STOP = 'PLAYER_STOP',
  MANAGER_START = 'MANAGER_START',
  MANAGER_STOP = 'MANAGER_STOP',
  KAM_START = 'KAM_START',
  KAM_STOP = 'KAM_STOP',
  MEDIA_NEW = 'MEDIA_NEW'
}

export interface KamCommand {
  kamCommandId: string;
  kameleonId: string;
  command: KAMCOMMAND;
  params: string[];
}

export enum kamPlayerType {
  androidTablet = 'androidTablet',
  androidTV = 'androidTV',
  cloud = 'cloud',
}

export enum KamliUser {
  USER = 1,
  SMB = 2,
  SPONSOR = 3,
  ASSISTANT = 4,
  ADMIN = 5,
}

export const KamliUserText = [
  'UNKNOWN',
  'USER',
  'SMB',
  'SPONSOR',
  'ASSISTANT',
  'ADMIN',
];

export interface AdnOtp {
  adnOtpId: string;
  name: string;
  email: string;
  mobileNo: string;
  password: string;
  timestamp: number;
  app: KamliApp;
  otp: string;
  favoriteStoreId: string;
}

export interface AdnUser {
  adnUserId: string;
  name: string;
  email: string;
  mobileNo: string;
  fixedNo: string;
  profile: any;
  postcode: string;
  addresses: AdnUserAddress[];
  receivePromo: boolean;
  stripeCustomers: string[];
  stripeAccount: string;
  stripeCustomersProd: string[];
  stripeAccountProd: string;
  stripeCustomersTest: string[];
  stripeAccountTest: string;
  emailVerified: boolean;
  photoURL: string;
  disabled: boolean;
  since: number;
  type: KamliUser;
  assistedStoreIds: AssistedStore[];
  storevisited: string[];
  favoriteStoreId: string;
  token: string;
}

export interface AdnUserAddress {
  city: string;
  country: string;
  countryCode: string;
  latlng: { lat: number, lng: number };
  postcode: string;
  value: string;
  nickName: string;
  complementInfo: string;
  primary: boolean;
}

export interface AssistedStore {
  adnStoreId: string;
  since: number;
  type: ASSISTANTTYPE;
}

export interface SmsListener {
  timestamp: number;
  phonenumber: string;
  smstext: string;
}

export enum UPLOADDIRECTORIES {
  USERS = 'Users',
  SMBS = 'stores',
  BACKEND = 'backend',
  GROUPSMBS = 'GroupSMBs',
  ADN = 'adn'
}

export enum SLIDETYPE {
  CATEGORIES = 'CATEGORIES',
  INTRO = 'INTRO',
  EVENTS = 'EVENTS',
  PRODUCTS = 'PRODUCTS',
  ADNIMAGES = 'ADNIMAGES',
  ADNCHANNEL = 'ADNCHANNEL',
  ADNRSS = 'ADNRSS',
  STOREIMAGE = 'StoreImages',
  STOREVIDEO = 'StoreVideos',
  STOREMEDIA = 'StoreMedias',
  MYSOCIAL = 'MYSOCIAL',
  SOCIALFRIENDS = 'SOCIALFRIENDS',
  PROMOTION = 'PROMOTION'
}

export enum KamliApp {
  KAMLIFOOD = 'KAMLIFOOD',
  KAMLISTORE = 'KAMLISTORE',
  KAMLIPUBLISH = 'KAMLIPUBLISH',
  KAMLIEAT = 'KAMLIEAT',
  KAMLIADS = 'KAMLIADS',
  KAMLIPLAYER = 'KAMLIPLAYER',
  KAMLINET = 'KAMLINET',
  KAMLIADMIN = 'KAMLIADMIN',
  KAMLICONSOLE = 'KAMLICONSOLE',
  KAMLIWEB = 'KAMLIWEB',
  KAMLIADN = 'KAMLIADN',
}

/*export enum RssFeedStatus {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
}*/

export enum LoyaltyType {
  UNITVALUE = 'UNITVALUE',
  RANGEVALUE = 'RANGEVALUE'
}

export interface DsSchedule {
  visible: boolean;
  name: string;
  description: string;
  scheduleId: string;
  playlists: any[];
  version: number;
}

export interface DsPlaylist {
  visible: boolean;
  name: string;
  description: string;
  playlistId: string;
  slides: DsSlide[];
  metadata;
  scheduleType;
  recommended: boolean;
  type: string;
  version: number;
}

export interface DsSlide {
  visible: boolean;
  name: string;
  description: string;
  slideId: string;
  assets: any[];
  assetNames: string[];
  duration: number;
  type: string;
  renderModel: string;
  thumbL: string;
  thumbP: string;
  localurlL: string;
  localurlP: string;
  localurlL2: string;
  localurlP2: string;
  properties: { refreshDuration: number };
  recommended: boolean;
  version: number;
}

export interface LoyaltyPointOffer {
  loyaltyPointOfferId: string;
  offer: string;
  active: boolean;
  points: number;
}

export interface LoyaltyPointValue {
  loyaltyPointValueId: string;
  rangeValue: number;
  active: boolean;
  points: number;
  type: LoyaltyType;
  unitValue: number;
}

export interface StoreUpdates {
  storeId: string;
  timestamp: number;
}

export interface KamliMedia {
  assetId: string;
  mediaType: string;
  dir: string;
  filename: string;
  title: string;
  nickName: string;
  name: string;
  size: number;
  thumbL: string;
  thumbP: string;
  localurlL: string;
  localurlP: string;
  localurlL2: string;
  localurlP2: string;
  muted: boolean;
  mainCategory: string;
  subCategory: string;
  startdate: string;
  duration: number;
  createdTS: number;
  modifiedTS: number;

  visible: boolean;
  publish: boolean;
  publishvisible: boolean;
  store: boolean;
  storevisible: boolean;
  likes: string[];
}

export interface EventI {
  eventId: string;
  backgroundL: string;
  localbackgroundL: string;
  localbackgroundL2: string;
  backgroundP: string;
  localbackgroundP: string;
  localbackgroundP2: string;
  description: string;
  endDate: number;
  thumbL: string;
  thumbP: string;
  localurlL: string;
  localurlP: string;
  localurlL2: string;
  localurlP2: string;
  imageDir: string;
  startDate: number;
  title: string;
  createdTS: number;
  modifiedTS: number;

  publish: boolean;
  publishvisible: boolean;
  store: boolean;
  storevisible: boolean;
  likes: string[];
}

export interface PromotionI {
  promotionId: string;
  title: string;
  mainText: string;
  subText: string;
  createdTS: number;
  modifiedTS: number;
  thumbL: string;
  thumbP: string;
  localurlL: string;
  localurlP: string;
  localurlL2: string;
  localurlP2: string;

  publish: boolean;
  publishvisible: boolean;
  store: boolean;
  storevisible: boolean;
  likes: string[];
}

export interface RssFeedI {
  rssId: string;
  feedTitle: string;
  smbcategoryId: string;
  feedCategory: string;
  url: string;
  feedUrl: string;
  status: RssFeedStatus;
  requester: string;
  requesterId: string;
  since: number;
  gpid: string;
  createdTS: number;
  modifiedTS: number;

  publish: boolean;
  publishvisible: boolean;
  store: boolean;
  storevisible: boolean;
}

export interface RssFeedIKamli extends RssFeedI {
  lastBuildDate: string;
  feedItems: RssfeeditemI[];
  feedDescription: string;
  image: string;
  localimage: string;
}


export enum AUTHSTATUS {
  SUCCESS = 1,
  EMAILNOTVERIFIED = -1,
  UPDATETOKENFAILED = -2,
  UNKNOWNERROR = -100
}

export interface AdnAssistant {
  adnUserId: string;
  title: string;
  description: string;
  storeids: string[];
  documents: any[];
  disabled: boolean;
  since: number;
  logo: string;
  currentTypeIndex: number;
  currentVerticalIndex: number;
}

export enum LEADSTATUS {
  NOTCONTACTED = 0,
  INTERESTED = 1,
  UNKNOWN = 2,
  NOTINTERESTED = 3
}

export const LEADSTATUSTEXT = ['NOTCONTACTED', 'INTERESTED', 'UNKNOWN', 'NOTINTERESTED'];

export interface AdnLead {
  leadId: string;
  siret: string;
  businessName: string;
  address: string;
  email: string;
  phone: string;
  type: string;
  website: string;
  facebook: string;
  instagram: string;
  assistantId: string;
  status: LEADSTATUS;
  since: number;
  documents: AdnDocument[];
  leadHistory: LeadHistory[];
  contacts: LeadContact[];
}

export interface LeadContact {
  name: string;
  fixedNo: string;
  mobileNo: string;
  email: string;
  comments: string;
  since: number;
}

export interface LeadHistory {
  date: number;
  comments: string;
}

export interface AdnDocument {
  documentId: string;
  name: string;
  type: string;
  comments: string;
  date: number;
  thumb: string;
}

export enum RssFeedStatus {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
}

export interface RssfeeditemI {
  content: any;
  contentSnippet: string;
  creator: string;
  enclosure: any;
  guid: string;
  isoDate: string;
  link: string;
  localurl: string;
  pubDate: string;
  thumb: string;
  title: string;
  createdTS: number;
  modifiedTS: number;

  publish: boolean;
  publishvisible: boolean;
  store: boolean;
  storevisible: boolean;
}

export enum SponsoredStatus {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
}

export interface SponsoredI {
  sponsoredId: string;
  adnStoreId: string;
  businessName: string;
  address: any;
  vertical: string;
  verticalIndex: number;
  categoryId: string;
  type: string;
  typeIndex: number;
  contact: LeadContact;
  since: number;
  status: SponsoredStatus;
  sponsoreeStoreId: string;
  sponsorStoreId: string;
  comments: string;
  facebook: string;
  instagram: string;
  gpid: string;
  storeImages: KamliMedia[];
  storeVideos: KamliMedia[];
}

export enum PAYMENTMODE {
  PRODUCT = 'PRODUCT',
  ADREQUEST = 'ADREQUEST'
}

export interface ImageVisibleI {
  id: string;
  type: string;
  visible: boolean;
  timestamp: number;
}

export interface LikedI {
  id: string;
  timestamp: number;
  adnUserId: string;
  objectType: string;
  objectId: string;
  comments: string;
  contactable: boolean;
  dataId: string;
}

export interface NotificationI {
  id: string;
  timestamp: number;
  thumb: string;
  localurl: string;
  title: string;
  description: string;
  tag: string;
  sent: NotificationSentI[];
}

export interface NotificationSentI {
  timestamp: number;
  store: boolean;
  publish: boolean;
}

export interface LikedIKamli extends LikedI {
  adnUser: AdnUser;
  type: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class StoreDbService {
  public firebaseApp = {};

  public adb: AngularFireDatabase;
  public bdb: AngularFireDatabase;
  public baf: AngularFireAuth;

  public storageAdn: Storage = null;

  public envPlatform: any;

  firebaseBSS = [];
  firebaseBSSdata = [];
  firebaseRefOn = [];
  firebaseData = [];

  public backendFbRef = {};
  public storeFbRef = [];

  public uploadProgress$: Observable<number>;

  public firebaseauth;

  constructor(
    public http: HttpClient,
    public ngZone: NgZone,
    public utilSvc: UtilsService
  ) { }

  initFBlistener(storeId, fbObject) {
    this.firebaseBSS[storeId][fbObject] = new BehaviorSubject<any>([]);
    this.firebaseBSSdata[storeId][fbObject] = this.firebaseBSS[storeId][fbObject].asObservable();
    this.firebaseData[storeId][fbObject] = [];
  }

  closeFBlistener(storeId, fbObject) {
    if (this.firebaseBSS[storeId]) {
      this.firebaseBSS[storeId][fbObject] = undefined;
    }
    if (this.firebaseBSSdata[storeId]) {
      this.firebaseBSSdata[storeId][fbObject] = undefined;
    }
    if (this.firebaseData[storeId]) {
      this.firebaseData[storeId][fbObject] = undefined;
    }
  }

  initFB(
    storeId: string,
    config: any,
    appName: string,
    storage: boolean,
    auth: boolean,
    firebaseObjects: Array<string>,
    fbRef: any
  ) {
    return new Promise((resolve, reject) => {
      const promises = [];

      this.firebaseData[storeId] = this.firebaseData[storeId] ? this.firebaseData[storeId] : [];
      this.firebaseBSS[storeId] = this.firebaseBSS[storeId] ? this.firebaseBSS[storeId] : [];
      this.firebaseBSSdata[storeId] = this.firebaseBSSdata[storeId] ? this.firebaseBSSdata[storeId] : [];
      this.firebaseRefOn[storeId] = this.firebaseRefOn[storeId] ? this.firebaseRefOn[storeId] : [];

      firebaseObjects.forEach(fbObject => {
        this.initFBlistener(storeId, fbObject);
      });

      let data: any;
      data = this.initFirebaseDatabase(config, appName);
      const databaseString = 'database';
      const authString = 'auth';
      const storageString = 'storage';
      fbRef[databaseString] = data;
      if (auth) {
        let data1: any;
        data1 = this.initFirebaseAuth(config, appName);
        data1.languageCode = 'fr';
        fbRef[authString] = data1;
      }
      if (storage) {
        let data2: any;
        data2 = this.initFirebaseStorage(config, appName);
        fbRef[storageString] = data2;
      }
      resolve(fbRef);
    });
  }

  closeFB(storeId: string, firebaseObjects: Array<string>, fbRef: any) {
    return new Promise(resolve => {
      const promises = [];

      firebaseObjects.forEach(fbObject => {
        this.closeFBlistener(storeId, fbObject);
      });

      this.firebaseData[storeId] = [];
      this.firebaseBSS[storeId] = [];
      this.firebaseBSSdata[storeId] = [];
      this.firebaseRefOn[storeId] = [];
      fbRef = [];

      Promise.all(promises).then(() => resolve(1));
    });
  }

  initStoreFb(
    backendStoreId,
    storeId: string,
    fbObjects: Array<string>,
    storeFbRef: any
  ) {
    return new Promise((resolve, reject) => {
      const databaseString = 'database';
      const storageString = 'storage';
      if (backendStoreId && storeId !== undefined) {
        this.getObject(backendStoreId, this.utilSvc.mdb, OBJECTNAME.adnStores, storeId).then(
          data => {
            if (data != null) {
              const firebaseConfigString = 'firebaseConfig';
              const firebaseConfigId = data[firebaseConfigString];
              this.getObject(backendStoreId, this.utilSvc.mdb, OBJECTNAME.firebaseProjects, firebaseConfigId).then(data1 => {
                if (data1) {
                  const fbConfig = data1;
                  const fbprojectName = 'store' + storeId;
                  storeFbRef = [];
                  if (!fbConfig) {
                    reject('storeId with null fbconfig ' + storeId);
                  } else {
                    this.initFB(storeId, fbConfig[firebaseConfigString], fbprojectName, true, false, fbObjects, storeFbRef).then(() => {
                      this.utilSvc.sdb[storeId] = storeFbRef[databaseString];
                      this.utilSvc.sst[storeId] = storeFbRef[storageString];
                      fbObjects.forEach(fo => {
                        this.subscribeObject(storeId, this.utilSvc.sdb[storeId], fo);
                      });
                      resolve(storeFbRef);
                    });
                  }
                } else {
                  reject('no store identified 1');
                }
              });
            } else {
              reject('no store identified 2');
            }
          });
      } else {
        reject('missing parameters, storeId, backendStoreId or sdb/mdb');
      }
    });
  }

  closeStoreFb(
    backendStoreId,
    storeId: string,
    fbObjects: Array<string>,
    storeFbRef: Array<any>
  ) {
    return new Promise((resolve, reject) => {
      if (backendStoreId && storeId !== undefined) {
        const promises = [];

        fbObjects.forEach(fo => {
          promises.push(
            this.unsubscribeObject(storeId, this.utilSvc.sdb[storeId], fo)
          );
        });
        Promise.all(promises).then(
          data => {
            this.closeFB(storeId, fbObjects, storeFbRef).then(() => {
              this.utilSvc.sdb[storeId] = [];
              this.utilSvc.sst[storeId] = [];
              storeFbRef = [];
              resolve(1);
            });
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }


  // method for initialisation of FB
  public initFirebaseDatabase(config, appname: string) {
    if (!this.firebaseApp[appname]) {
      this.firebaseApp[appname] = firebase.initializeApp(config, appname);
    }
    const database = firebase.database(this.firebaseApp[appname]);
    return database;
  }

  public initFirebaseStorage(config, appname: string) {
    if (!this.firebaseApp[appname]) {
      this.firebaseApp[appname] = firebase.initializeApp(config, appname);
    }
    const storage = this.firebaseApp[appname].storage();
    return storage;
  }

  public initFirebaseAuth(config, appname: string) {
    if (!this.firebaseApp[appname]) {
      this.firebaseApp[appname] = firebase.initializeApp(config, appname);
    }
    const auth = firebase.auth(this.firebaseApp[appname]);
    this.firebaseauth = firebase.auth;
    /*    this.firebaseauth = new firebase.auth.RecaptchaVerifier('sign-in-button', {
          size: 'visible',
          callback: (response) => {
    
          },
          'expired-callback': () => {
          }
        });*/

    return auth;
  }

  // CRUD methods for a given object
  subscribeObject(storeId: string, fbDbRef, fbObject, refId?) {
    let tempObject = fbObject;
    if (fbObject.indexOf('backend') !== 0) {
      tempObject = storeId + '/' + fbObject;
    }
    if (refId && refId !== -1) {
      tempObject = tempObject + '/' + refId;
    }
    this.firebaseRefOn[storeId][fbObject] = fbDbRef.ref(tempObject)
      .on('value', data => {
        let temp = data.val();
        if (temp == null) {
          temp = undefined;
        }
        if (refId !== undefined) {
          if (refId !== -1) {
            this.firebaseData[storeId][fbObject][refId] = temp;
          } else {
            this.firebaseData[storeId][fbObject] = temp;
          }
        } else {
          if (temp !== undefined) {
            this.firebaseData[storeId][fbObject] = this.utilSvc.objectToArray(
              data.val()
            );
          }
        }
        if (this.firebaseBSS[storeId][fbObject]) {
          this.firebaseBSS[storeId][fbObject].next([temp]);
        }
      });
  }

  unsubscribeObject(storeId, fbDbRef, fbObject, refId?) {
    if (this.firebaseRefOn[storeId]) {
      if (this.firebaseRefOn[storeId][fbObject]) {
        let tempObject = fbObject;
        if (fbObject.indexOf('backend') !== 0) {
          tempObject = storeId + '/' + fbObject;
        }
        if (refId && refId !== -1) {
          tempObject = tempObject + '/' + refId + '/';
        }
        fbDbRef.ref(tempObject).off();
        this.firebaseRefOn[storeId][fbObject] = undefined;
      }
    }
  }

  getObject(storeId, fbDbRef, fbObject, refId?) {
    return new Promise((resolve, reject) => {
      let tempObject = fbObject;
      if (fbObject.indexOf('backend') !== 0) {
        tempObject = storeId + '/' + fbObject;
      }
      if (refId !== undefined && refId !== -1) {
        tempObject = tempObject + '/' + refId;
      }

      if (fbDbRef) {
        fbDbRef.ref(tempObject)
          .once('value')
          .then(
            data => {
              resolve(data.val());
            },
            error => {
              reject(error);
            }
          );
      } else {
        resolve(null);
      }
    });
  }

  removeObject(storeId, fbDbRef, fbObject, refId) {
    return new Promise((resolve, reject) => {
      let tempObject;
      if (refId !== undefined) {
        if (fbObject.indexOf('backend') !== 0) {
          tempObject = storeId + '/' + fbObject + '/' + refId;
        } else {
          tempObject = fbObject + '/' + refId;
        }
        fbDbRef.ref(tempObject).remove().then(
          () => {
            resolve(String(refId));
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve(undefined);
      }
    });
  }

  updateObject(storeId, fbDbRef, fbObject, objectData, refId?) {
    return new Promise((resolve, reject) => {
      let update = true;
      let tempObject = fbObject;
      if (fbObject.indexOf('backend') !== 0) {
        tempObject = storeId + '/' + fbObject;
      } else {
        if (fbObject !== OBJECTNAME.publishingNewOrders) {
          update = false;
        }
      }
      const tod = new Date().getTime();
      if (refId) {
        tempObject = tempObject + '/' + refId;
        objectData.modifiedTS = tod;
      }
      fbDbRef
        .ref(tempObject)
        .set(objectData)
        .then(
          async data => {
            if ((fbObject === OBJECTNAME.DsPImage || fbObject === OBJECTNAME.DsPVideo || fbObject === OBJECTNAME.products ||
              fbObject === OBJECTNAME.promotions || fbObject === OBJECTNAME.events) && refId) {
              const su = {} as StoreUpdates;
              su.timestamp = tod;
              su.storeId = storeId;
              await this.utilSvc.sdb[this.utilSvc.adnStoreId].ref(this.utilSvc.adnStoreId + '/' + OBJECTNAME.storeupdates + '/' + storeId).set(su);
            }
            resolve(objectData);
          },
          error => reject(error)
        );
    });
  }

  getAvailableObjectId(BEStoreId, fbObject, idName) {
    return new Promise((resolve, reject) => {
      this.getObject(BEStoreId, this.utilSvc.mdb, fbObject).then(
        data => {
          const temp = data as any[];
          const objectId = data[temp.length - 1][idName] + 1;
          resolve(objectId);
        },
        error => reject(error)
      );
    });
  }

  deleteObject(storeId, objectToDelete) {
    let ref;
    const regexasset = /(\/?assets\/)(.+)/;
    const temp = regexasset.exec(objectToDelete);
    if (temp && temp[2]) {
      if (storeId === this.utilSvc.backendFBstoreId) {
        // Create a reference to the file to delete
        ref = this.utilSvc.mst.ref(temp[2]);
      } else {
        ref = this.utilSvc.sst[storeId].ref(temp[2]);
      }
      // Delete the file
      ref.delete();
    }
  }

  deleteObjectFromUrl(storeId, url) {
    let ref;
    let error;
    if (storeId === this.utilSvc.backendFBstoreId) {
      // Create a reference to the file to delete
      try {
        ref = this.utilSvc.mst.refFromURL(url);
      }
      catch (e) { error = e; }
    } else {
      try {
        ref = this.utilSvc.sst[storeId].refFromURL(url);
      }
      catch (e) { error = e; }
    }
    // Delete the file
    if (ref) {
      try {
        ref.delete();
      } catch (e) { }
    }
  }

  uploadObjects(storeId, event, directory, read?) {
    return new Promise((resolve, reject) => {
      let ref;
      const fileName = event.target.files[0].name;

      if (storeId !== this.utilSvc.backendFBstoreId) {
        ref = this.utilSvc.sst[storeId].ref(directory + '/' + fileName);
      } else {
        ref = this.utilSvc.mst.ref(directory + '/' + fileName);
      }

      const task = ref.put(event.target.files[0]).then(
        async (snapshot) => {
          try {
            const downloadURL = await ref.getDownloadURL();
            resolve(downloadURL);
          } catch (e) {
            reject(e);
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  uploadMedia(storeId, event, directory) {
    return new Promise(async (resolve, reject) => {
      let thumb;
      if (!directory) {
        directory = '';
      }

      if (event) {
        try {
          thumb = await this.uploadObjects(storeId, event, directory, false);
        } catch (e) { }
      }
      resolve(thumb);
    });
  }

  deletePImage(storeId, pImage, objectName) {
    return new Promise((resolve, reject) => {
      if (pImage.fburl) {
        this.deleteObject(storeId, pImage.backendurl);
      }
      this.removeObject(storeId, this.utilSvc.sdb[storeId], objectName, String(pImage.assetId)).then(
        data => {
          resolve(pImage);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deletePVideo(storeId, pVideo, objectName) {
    return new Promise((resolve, reject) => {
      if (pVideo.fburl) {
        this.deleteObject(storeId, pVideo.backendurl);
      }
      this.removeObject(
        storeId,
        this.utilSvc.sdb[storeId],
        objectName,
        String(pVideo.assetId)
      ).then(
        data => {
          resolve(pVideo);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  createPImage(storeId, pImageName, objectName, type1) {
    return new Promise((resolve, reject) => {
      const pImage = {
        nickName: pImageName,
        assetId: Math.floor(Math.random() * 100000),
        type: type1
      };
      this.updateObject(
        storeId,
        this.utilSvc.sdb[storeId],
        objectName,
        pImage,
        pImage.assetId
      ).then(
        data => {
          resolve(pImage);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updatePImage(storeId, pImage, pImageName, objectName) {
    return new Promise((resolve, reject) => {
      pImage.nickName = pImageName;
      this.updateObject(
        storeId,
        this.utilSvc.sdb[storeId],
        objectName,
        pImage,
        pImage.assetId
      ).then(
        data => {
          resolve(pImage);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  createPVideo(storeId, pVideoName, objectName, type1) {
    return new Promise((resolve, reject) => {
      const pVideo = {
        nickName: pVideoName,
        assetId: Math.floor(Math.random() * 100000),
        type: type1
      };
      this.updateObject(
        storeId,
        this.utilSvc.sdb[storeId],
        objectName,
        pVideo,
        pVideo.assetId
      ).then(
        data => {
          resolve(pVideo);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updatePVideo(storeId, pVideo, pVideoName, objectName) {
    return new Promise((resolve, reject) => {
      pVideo.nickName = pVideoName;
      this.updateObject(
        storeId,
        this.utilSvc.sdb[storeId],
        objectName,
        pVideo,
        pVideo.assetId
      ).then(
        data => {
          resolve(pVideo);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  validateVideoFile(file) {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
    };

    video.srcObject = file;
  }

  getStoreObject(smbStoreId: string, fbObjectType, refId?): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let error;
      let result;
      if (!this.utilSvc.sdb[smbStoreId]) {
        try {
          await this.initStoreFb(this.utilSvc.backendFBstoreId, smbStoreId, [],
            this.storeFbRef[smbStoreId]);
        } catch (e) {
          error = e;
          reject(e);
        }
      }
      if (!error) {
        try {
          result = await this.getObject(smbStoreId, this.utilSvc.sdb[smbStoreId], fbObjectType, refId);
        } catch (e) {
          error = e;
          reject(e);
        }
        if (!error) {
          resolve(result);
        } else {
          reject(error);
        }
      }
    });
  }

  updateStoreObject(smbStoreId: string, fbObjectType, fbObject, refId?) {
    return new Promise(async (resolve, reject) => {
      let error;
      if (!this.utilSvc.sdb[smbStoreId]) {
        try {
          await this.initStoreFb(this.utilSvc.backendFBstoreId, smbStoreId, [],
            this.storeFbRef[smbStoreId]);
        } catch (e) {
          error = e;
          reject(e);
        }
      }
      if (!error) {
        try {
          await this.updateObject(smbStoreId, this.utilSvc.sdb[smbStoreId], fbObjectType, fbObject, refId);
        } catch (e) {
          error = e;
          reject(e);
        }
        if (!error) {
          resolve(1);
        } else {
          reject(error);
        }
      }
    });
  }


}
