/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import {
    StoreDbService, OBJECTNAME, AdnkameleonBox, KamliApp, LoyaltyPointOffer, LoyaltyPointValue,
    KamliMedia, PromotionI, EventI, DsSchedule, DsSlide, DsPlaylist, AdnInventory,
    SponsoredI, AdnUser, AdnLead, AdnAssistant, KamliUser, AdnUserOrders, AdnUserCart, AdnDocument,
    NotificationI, RssFeedIKamli, ImageVisibleI, StoreUpdates,
} from '../godigital-lib';
import { UtilsService, dayInMilliseconds } from '../godigital-lib';
import { UsersService, } from '../godigital-lib';
import { CustomerService, CustomerI, } from '../godigital-lib';
import {
    SmbService, AdnCorporateI, AdnStoreI, SCREENSTATUS, AdnStoreIKamli,
    SponsoredIKamli, AdnCorporateIKamli, AdnkameleonBoxKamli
} from '../godigital-lib';
import { regexsnurl } from '../godigital-lib';
import { SocialNetworkService } from '../godigital-lib';
import {
    PublishingRequestService, PUBSTARTSTATUS, PublishOrderKamli, PublishCampaignKamli, PublishCampaign,
    PublishOrder, PublishQuoteKamli
} from '../godigital-lib';
import { PaymentGwService } from '../godigital-lib';
import { CART, } from '../godigital-lib';
import { OrderService, OrderI, } from '../godigital-lib';
import { NgxSpinnerService } from 'ngx-spinner';
import { NGXLogger } from 'ngx-logger';
import { ScriptLoadingService } from '../godigital-lib';

import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import { saveAs } from 'file-saver';

export const externalUrlProvider = new InjectionToken(
    'externalUrlRedirectResolver'
);
import { DELIVERYMETHOD } from '../godigital-lib';
import { PRODUCTI, CATEGORYI, EXTRAOPTIONI, PREPARATIONI, PRODUCTIKamli, PRODUCTPreparationI, PRODUCTExtraoptionsI, SmsListener } from '../godigital-lib';
import { FormGroup } from '@angular/forms';
import { PublishQuote } from '../godigital-lib';
import { ORDERSTATUS } from './order.service';
import { AdnUserKamli } from '../godigital-lib';

export enum EDITSLIDE {
    CREATIONSLIDE = 0,
    EDITIONSLIDE = 1
}

export const regexUrl = /https?:\/\//;
export const regexUrlImage = /(https?:\/\/.*\.(?:png|jpg|jpeg))/;
export const regexUrlVideo = /(https?:\/\/.*\.(?:mp4|avi))/;
export const regexUrlMedia = /(^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$)|((https?:\/\/.*\.(?:mp4|avi|png|jpg|jpeg)))/;
export const regexMobileNo = /(^(0033|\+33|0)(6|7)(\d{8})$)|(^\+44\d{10}$)|(^\+31\d{8,10}$)|(^\+34(\d{8,10})$)|(^\+41(\d{8,10})$)|(^\+39(\d{8,10})$)/;
export const regexFixedNo = /(^(0033|\+33|0)(1|2|3|4|5|8|9)(\d{8})$)|(^\+44\d{10}$)|(^\+31\d{8,10}$)|(^\+34(\d{8,10})$)|(^\+41(\d{8,10})$)|(^\+39(\d{8,10})$)/;
export const regexEmail = /^[^@]+@[a-z0-9.-]+\.[a-z]{2,3}$/;


interface TC {
    categoryId: string;
    type: string;
    vertical: string;
    fiebaseConfig: number;
    KAMLIPUBLISH: boolean;
    KAMLIADS: boolean;
    KAMLISTORE: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ServicesService {
    public smbStore: AdnStoreIKamli;
    public smbStoreO: BehaviorSubject<AdnStoreIKamli> = new BehaviorSubject(null);
    public smbCorporate: AdnCorporateI;

    public config;

    public backendFbObjects = [
        OBJECTNAME.adnCorporates,
        OBJECTNAME.adnStores,
        OBJECTNAME.sponsored,
        OBJECTNAME.kameleon,
        OBJECTNAME.firebaseProjects,
        OBJECTNAME.adnUsers,
        OBJECTNAME.assistants,
    ];

    public backendFbObjectsConnected = [
        OBJECTNAME.publishingCampaigns,
        OBJECTNAME.publishingQuotes,
    ];

    public adnStoreFbObjects = [
        OBJECTNAME.DsRssKamli,
        OBJECTNAME.DsSlideTypes,
        OBJECTNAME.backgroundImages,
        OBJECTNAME.publishingProduct,
        OBJECTNAME.AdnMediaKamli,
        OBJECTNAME.ThematicVideosKamli,
        OBJECTNAME.accessories,
        OBJECTNAME.socialnetworks,
        OBJECTNAME.likes,
        OBJECTNAME.storeupdates,
        OBJECTNAME.leads,
        OBJECTNAME.documents,
        OBJECTNAME.inventory,
    ];

    public storeFbObjectsAdmin = [
        OBJECTNAME.categories,
        OBJECTNAME.products,
        OBJECTNAME.extras,
        OBJECTNAME.preparation,
        OBJECTNAME.events,
        OBJECTNAME.promotions,
        OBJECTNAME.ImageVisible,
        OBJECTNAME.loyaltyPointOffer,
        OBJECTNAME.loyaltyPointValue,
        OBJECTNAME.allergens,
        OBJECTNAME.calories,
        OBJECTNAME.DsSchedule,
        OBJECTNAME.DsPlaylist,
        OBJECTNAME.DsSlide,
        OBJECTNAME.orders,
        OBJECTNAME.notifications,
        OBJECTNAME.customers,
        OBJECTNAME.DsPImage,
        OBJECTNAME.DsPVideo,
    ];

    public storeFbObjectsUnconnected = [
        OBJECTNAME.categories,
        OBJECTNAME.products,
        OBJECTNAME.events,
        OBJECTNAME.promotions,
        OBJECTNAME.ImageVisible,
        OBJECTNAME.allergens,
        OBJECTNAME.calories,
        OBJECTNAME.loyaltyPointOffer,
        OBJECTNAME.loyaltyPointValue,
    ];

    public storeFbObjectsConnected = [
        OBJECTNAME.cart,
        OBJECTNAME.customers,
        OBJECTNAME.DsPImage,
        OBJECTNAME.DsPVideo,
    ];

    public adnStore: AdnStoreI;

    public adnCustomerUser: AdnUserKamli;
    public adnCustomerUserO: BehaviorSubject<AdnUserKamli> = new BehaviorSubject(null);

    public adnBusinessUser: AdnUser;
    public adnBusinessUserO: BehaviorSubject<AdnUser> = new BehaviorSubject(null);

    public allCorporates: AdnCorporateI[];
    public allCorporatesO: BehaviorSubject<AdnCorporateI[]> = new BehaviorSubject(null);

    public currentStores: AdnStoreI[];
    public currentStoresO: BehaviorSubject<AdnStoreI[]> = new BehaviorSubject(null);

    public currentCorporate: AdnCorporateI;
    public currentCorporateO: BehaviorSubject<AdnCorporateI> = new BehaviorSubject(null);

    public allStores: AdnStoreIKamli[];
    public allStoresO: BehaviorSubject<AdnStoreIKamli[]> = new BehaviorSubject(null);

    public allAdnMedias: KamliMedia[];
    public allAdnMediasO: BehaviorSubject<KamliMedia[]> = new BehaviorSubject(null);
    public adnMedias: KamliMedia[];
    public adnMediasO: BehaviorSubject<KamliMedia[]> = new BehaviorSubject(null);

    public storeCart: CART;
    public storeCartO: BehaviorSubject<CART> = new BehaviorSubject(null);

    public storeOrders: OrderI[];
    public storeOrdersO: BehaviorSubject<OrderI[]> = new BehaviorSubject(null);

    public storeCustomers: CustomerI[];
    public storeCustomersO: BehaviorSubject<CustomerI[]> = new BehaviorSubject(null);

    public storeImages: KamliMedia[];
    public storeImagesO: BehaviorSubject<KamliMedia[]> = new BehaviorSubject(null);

    public storeVideos: KamliMedia[];
    public storeVideosO: BehaviorSubject<KamliMedia[]> = new BehaviorSubject(null);

    public storeCategories: CATEGORYI[];
    public storeCategoriesO: BehaviorSubject<CATEGORYI[]> = new BehaviorSubject(null);

    public notifications: NotificationI[];
    public notificationsO: BehaviorSubject<NotificationI[]> = new BehaviorSubject(null);

    public storeProducts: PRODUCTIKamli[];
    public storeProductsO: BehaviorSubject<PRODUCTIKamli[]> = new BehaviorSubject(null);

    public storeExtras: EXTRAOPTIONI[];
    public storeExtrasO: BehaviorSubject<EXTRAOPTIONI[]> = new BehaviorSubject(null);

    public storePreparation: PREPARATIONI[];
    public storePreparationO: BehaviorSubject<PREPARATIONI[]> = new BehaviorSubject(null);

    public storeAllergens;
    public storeAllergensO: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public storeCalories;
    public storeCaloriesO: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public storeEvents: EventI[];
    public storeEventsO: BehaviorSubject<EventI[]> = new BehaviorSubject(null);

    public storePromotions: PromotionI[];
    public storePromotionsO: BehaviorSubject<PromotionI[]> = new BehaviorSubject(null);

    public storeLoyaltyPointOffer: LoyaltyPointOffer[];
    public storeLoyaltyPointOfferO: BehaviorSubject<LoyaltyPointOffer[]> = new BehaviorSubject(null);

    public storeLoyaltyPointValue: LoyaltyPointValue[];
    public storeLoyaltyPointValueO: BehaviorSubject<LoyaltyPointValue[]> = new BehaviorSubject(null);

    public storeImageVisible: any[];
    public storeImageVisibleO: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public storeDsSlides: DsSlide[];
    public storeDsSlidesO: BehaviorSubject<DsSlide[]> = new BehaviorSubject(null);

    public storeDsVideo: KamliMedia[];
    public storeDsVideoO: BehaviorSubject<KamliMedia[]> = new BehaviorSubject(null);

    public storeDsSchedules: DsSchedule[];
    public storeDsSchedulesO: BehaviorSubject<DsSchedule[]> = new BehaviorSubject(null);

    public storeDsPlaylists: DsPlaylist[];
    public storeDsPlaylistsO: BehaviorSubject<DsPlaylist[]> = new BehaviorSubject(null);

    public adnDsRss;
    public adnDsRssKamliO: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public adnStoreUpdates: StoreUpdates[];
    public adnStoreUpdatesO: BehaviorSubject<StoreUpdates[]> = new BehaviorSubject(null);

    public adnDsMySocialNetwork;
    public adnDsMySocialNetworkO: BehaviorSubject<any> = new BehaviorSubject(null);

    public adnDsAllVideos: KamliMedia[];
    public adnDsAllVideosO: BehaviorSubject<KamliMedia[]> = new BehaviorSubject(null);
    public adnDsVideos: KamliMedia[];
    public adnDsVideosO: BehaviorSubject<KamliMedia[]> = new BehaviorSubject(null);
    public adnDsVideoChannels: any[];

    public adnBackgroundImages;
    public adnBackgroundImagesO: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public adnpublishingProducts;
    public adnpublishingProductsO: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public adnDsSlideTypes;
    public adnDsSlideTypesO: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public accessories;
    public accessoriesO: BehaviorSubject<any> = new BehaviorSubject(null);

    public tutoslides;
    public tutoslidesO: BehaviorSubject<any> = new BehaviorSubject(null);

    public storePubCampaigns: PublishCampaignKamli[];
    public storePubCampaignsO: BehaviorSubject<PublishCampaignKamli[]> = new BehaviorSubject(null);

    public storePubQuotes: PublishQuoteKamli[];
    public storePubQuotesClients: any[];
    public storePubQuotesO: BehaviorSubject<PublishQuoteKamli[]> = new BehaviorSubject(null);

    public cloudPlayerUrl = '';
    public templateCatalogue;
    //    public templateCatalogue2;

    public version;

    public businessVertical;
    public catalogueImagesProductsDir;
    public catalogueImagesEventsDir;
    public catalogueImages: any[];

    public languageO: BehaviorSubject<string> = new BehaviorSubject(null);

    public publishingPricing;

    public publishingSmbOrdersO: BehaviorSubject<PublishOrderKamli[]> = new BehaviorSubject(null);
    public publishingSmbOrders: PublishOrderKamli[];

    public publishingAdnOrdersO: BehaviorSubject<PublishOrderKamli[]> = new BehaviorSubject(null);
    public publishingAdnOrders: PublishOrderKamli[];

    public publishingFreeOrdersO: BehaviorSubject<PublishOrderKamli[]> = new BehaviorSubject(null);
    public publishingFreeOrders: PublishOrderKamli[];

    public storeUrl: AdnStoreI;

    public allinventory: AdnInventory[];
    public allinventoryO: BehaviorSubject<AdnInventory[]> = new BehaviorSubject(null);

    public leads: AdnLead[];
    public leadsO: BehaviorSubject<AdnLead[]> = new BehaviorSubject(null);

    public assistants: AdnAssistant[];
    public assistantsO: BehaviorSubject<AdnAssistant[]> = new BehaviorSubject(null);

    public documents: AdnDocument[];
    public documentsO: BehaviorSubject<AdnDocument[]> = new BehaviorSubject(null);


    public firebaseBSSdata = {};

    public playNewOrderAudio = false;

    public polledPlayers = {};
    public polledPlayersO: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public pollInterval;

    public storekameleons: AdnkameleonBox[];
    public storekameleonsO: BehaviorSubject<AdnkameleonBox[]> = new BehaviorSubject(null);

    public corporatekameleons: AdnkameleonBox[];
    public corporatekameleonsO: BehaviorSubject<AdnkameleonBox[]> = new BehaviorSubject(null);

    public assistedkameleons: AdnkameleonBox[];
    public assistedkameleonsO: BehaviorSubject<AdnkameleonBox[]> = new BehaviorSubject(null);

    public allkameleons: AdnkameleonBox[];
    public allkameleonsO: BehaviorSubject<AdnkameleonBox[]> = new BehaviorSubject(null);

    public sponsored: SponsoredIKamli[];
    public sponsoredO: BehaviorSubject<SponsoredIKamli[]> = new BehaviorSubject(null);
    public sponsorMedia: KamliMedia;

    public editSlideMode = EDITSLIDE.CREATIONSLIDE;

    public deliveryType = DELIVERYMETHOD.PayCollect;

    public tutoCategories;
    public tutos;
    public tutosO: BehaviorSubject<any> = new BehaviorSubject(null);

    public timerSocialFriends;
    public timerMySocialNetwork;

    public subscriptions = new Subscription();

    public progress = 0;

    public localities = [
        { localityId: 10, localityName: 'Sophia-Antipolis' },
        { localityId: 20, localityName: 'Nice Arenas' },
        { localityId: 30, localityName: 'Polygone' },
        { localityId: 40, localityName: 'Cap 3000' },
        { localityId: 50, localityName: 'Environs Grasse' },
        { localityId: 60, localityName: 'Environs Nice' },
        { localityId: 70, localityName: 'Environs Cannes' },
        { localityId: 80, localityName: 'Environs Antibes' },
        { localityId: 90, localityName: 'Autres' },
    ];

    public errorMessage = {
        title: '',
        description: '',
        details: ''
    };
    public currentPosition = {
        lat: 0,
        lng: 0
    };

    constructor(
        public http: HttpClient,
        public router: Router,
        public storeDbSvc: StoreDbService,
        public utilSvc: UtilsService,
        public customerSvc: CustomerService,
        public usersSvc: UsersService,
        public smbSvc: SmbService,
        public orderSvc: OrderService,
        public publishingRequestSvc: PublishingRequestService,
        public spinner: NgxSpinnerService,
        public scriptLoadingSvc: ScriptLoadingService,
        public logger: NGXLogger,
        public paymentGwSvc: PaymentGwService,
        public socialnetworkSvc: SocialNetworkService,
    ) {
    }

    logDS(...args: any[]) {
        let logText = '';
        for (let i = 1; i < args.length; i++) {
            logText = logText + args[i] + ',';
        }
        const userId =
            this.adnBusinessUser
                ? this.adnBusinessUser.adnUserId
                : 'Guest';

        logText =
            args[0] +
            ',' +
            this.utilSvc.appName +
            ',' +
            userId +
            ',' +
            this.smbStore.adnStoreId +
            ',' +
            this.currentPosition.lat +
            ',' +
            this.currentPosition.lng +
            ',' +
            logText;
        this.logger.info(logText);
    }

    public readConfigFile(env) {
        return new Promise((resolve, reject) => {
            this.utilSvc.readConfig('./assets/config/adf.json').then(
                data => {
                    this.config = data;
                    const backendURLString = 'backendURL';
                    if (!this.utilSvc.language) {
                        this.utilSvc.language = 'fr';
                    }

                    if (!env || !env.platform) {
                        this.utilSvc.platform = this.config.application?.platform;
                        env = {};
                        env.platform = this.utilSvc.platform;
                    } else {
                        this.utilSvc.platform = env.platform;
                    }
                    this.utilSvc.backendURL = data[env.platform][backendURLString];
                    if (this.config.network) {
                        if (this.config.network.cloudPlayerUrl) {
                            this.cloudPlayerUrl = this.config.network.cloudPlayerUrl;
                        }
                    }
                    if (this.config.application && this.config.application.stripeplatform) {
                        this.utilSvc.stripeplatform = this.config.application.stripeplatform;
                    } else {
                        this.utilSvc.stripeplatform = 'test';
                    }
                    if (this.config.application) {
                        if (this.config.application.release) {
                            this.version =
                                env.platform + '/' + this.config.application.release;
                        }
                    }
                    if (this.config[this.utilSvc.platform].backendWSUrl) {
                        this.utilSvc.backendWSURL = this.config[this.utilSvc.platform].backendWSUrl;
                    }
                    this.utilSvc.webappUrl = this.config[env.platform].webappUrl;
                    this.utilSvc.webappUrlPort = this.config[env.platform].webappUrlPort;
                    this.utilSvc.goadminUrl = this.config[env.platform].goadminUrl;
                    this.utilSvc.goadminUrlPort = this.config[env.platform].goadminUrlPort;
                    this.utilSvc.kamliadminUrl = this.config[env.platform].kamliadminUrl;
                    this.utilSvc.signageUrl = this.config[env.platform].signageUrl;
                    this.utilSvc.signageUrlPort = this.config[env.platform].signageUrlPort;
                    this.utilSvc.kamlistoreUrl = this.config[env.platform].kamlistoreUrl;
                    this.utilSvc.kamliadsUrl = this.config[env.platform].kamliadsUrl;
                    this.utilSvc.analyticsUrl = this.config[env.platform].analyticsUrl;
                    this.utilSvc.backendURL = this.config[env.platform].backendURL;
                    this.utilSvc.appName = this.utilSvc.appName;
                    resolve(this.config);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    public initBEService(env) {
        return new Promise((resolve, reject) => {
            const backendFbConfig = this.config[env.platform].firebaseMasterConfig;
            this.storeDbSvc.initFB(this.utilSvc.backendFBstoreId, backendFbConfig, 'goDigitalBE', true, true,
                this.backendFbObjects, this.storeDbSvc.backendFbRef).then(
                    async () => {
                        const databaseString = 'database';
                        const storageString = 'storage';
                        const authString = 'auth';
                        this.utilSvc.mdb = this.storeDbSvc.backendFbRef[databaseString];
                        this.utilSvc.mst = this.storeDbSvc.backendFbRef[storageString];
                        this.utilSvc.mauth = this.storeDbSvc.backendFbRef[authString];
                        this.backendFbObjects.forEach(fo => {
                            this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, fo);
                        });
                        this.subscribePollPlayer(KamliApp.KAMLIPLAYER);
                        try {
                            await this.getBusinessVertical();
                        } catch (e) { }
                        this.subscribeKameleons();
                        this.subscribeCorporates();
                        this.subscribeStores();
                        this.subscribeSponsored();
                        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                            this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.adnUsers);
                        }
                        this.subscribeUsers();
                        this.subscribeAssistants();
                        resolve(1);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    public closeBEService() {
        return new Promise((resolve, _reject) => {
            this.unsubscribeKameleons();
            this.unsubscribeCorporates();
            this.unsubscribeStores();
            this.unsubscribeSponsored();
            this.unsubscribeUsers();
            this.unsubscribeAssistants();

            this.backendFbObjects.forEach(fo => {
                this.storeDbSvc.unsubscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, fo);
            });
            this.utilSvc.mdb = undefined;
            this.utilSvc.mst = undefined;
            this.utilSvc.mauth = undefined;

            this.storeDbSvc.closeFB(
                this.utilSvc.backendFBstoreId,
                this.backendFbObjects,
                this.storeDbSvc.backendFbRef
            );
            resolve(1);
        });
    }

    public initAdnStoreService(adnStoreId) {
        return new Promise((resolve, reject) => {
            this.storeDbSvc
                .initStoreFb(this.utilSvc.backendFBstoreId, adnStoreId, this.adnStoreFbObjects,
                    this.storeDbSvc.storeFbRef[adnStoreId]).then(
                        data => {
                            this.subscribeDsRss(adnStoreId);
                            this.subscribeStoreupdates(adnStoreId);
                            this.subscribeBackdroundImages(adnStoreId);
                            this.subscribePublishingProduct(adnStoreId);
                            this.subscribeDsSlideTypes(adnStoreId);
                            this.subscribeAccessories(adnStoreId);
                            this.subscribeAdnAds(adnStoreId);
                            this.subscribeAdnMedias(adnStoreId);
                            this.subscribeThematicVideos(adnStoreId);
                            this.subscribeleads(adnStoreId);
                            this.subscribeDocuments(adnStoreId);
                            this.subscribeInventory(adnStoreId);
                            this.socialnetworkSvc.subscribeSocialnetworks(adnStoreId);
                            this.socialnetworkSvc.subscribeLikes(adnStoreId);
                            resolve(data);
                        },
                        error => {
                            reject(error);
                        }
                    );
        });
    }

    public closeAdnStoreService(adnStoreId) {
        return new Promise((resolve, reject) => {
            this.unsubscribeDsRss(adnStoreId);
            this.unsubscribeStoreupdates(adnStoreId);
            this.unsubscribeBackdroundImages(adnStoreId);
            this.unsubscribePublishingProduct(adnStoreId);
            this.unsubscribeDsSlideTypes(adnStoreId);
            this.unsubscribeAccessories(adnStoreId);
            this.unsubscribeAdnAds(adnStoreId);
            this.socialnetworkSvc.unsubscribeSocialnetworks(adnStoreId);
            this.socialnetworkSvc.unsubscribeLikes(adnStoreId);
            this.unsubscribeThematicVideos(adnStoreId);
            this.unsubscribeAdnMedias(adnStoreId);
            this.unsubscribeleads(adnStoreId);
            this.unsubscribeDocuments(adnStoreId);
            this.unsubscribeInventory(adnStoreId);
            this.storeDbSvc.closeStoreFb(this.utilSvc.backendFBstoreId, adnStoreId, this.adnStoreFbObjects,
                this.storeDbSvc.storeFbRef[adnStoreId]).then(
                    _data => {
                        resolve(adnStoreId);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    public initStoreService(smbStoreId) {
        if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH || this.utilSvc.appName === KamliApp.KAMLIADS ||
            this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLICONSOLE ||
            this.utilSvc.appName === KamliApp.KAMLINET || this.utilSvc.appName === KamliApp.KAMLIADN) {
            return this.initStoreServiceAdmin(smbStoreId);
        } else {
            return this.initStoreServiceUser(smbStoreId);
        }
    }

    public initStoreServiceUser(smbStoreId) {
        return new Promise((resolve, reject) => {
            this.storeDbSvc.initStoreFb(this.utilSvc.backendFBstoreId, smbStoreId, this.storeFbObjectsUnconnected,
                this.storeDbSvc.storeFbRef[smbStoreId]).then(
                    _data => {
                        this.smbSvc.getStore(this.utilSvc.backendFBstoreId, smbStoreId).then(async data1 => {
                            let errorCorporate;
                            const storeData = data1 as AdnStoreI;
                            this.smbStore = storeData as AdnStoreIKamli;
                            await this.subscribeStores2(storeData);
                            this.setCurrentStore(this.smbStore);
                            try {
                                this.smbCorporate = await this.smbSvc.getCorporate(this.utilSvc.backendFBstoreId, storeData.adnCorporateId);
                            } catch (e) { errorCorporate = e; }
                            if (!errorCorporate) {
                                this.setCurrentCorporate(this.smbCorporate);
                                this.getBusinessUser(storeData).then(
                                    async data3 => {
                                        const adnBusinessUser = data3 as AdnUser;
                                        this.setBusinessUserO(data3 as AdnUser);
                                        //                                    let userId;
                                        if ((this.utilSvc.appName === KamliApp.KAMLIEAT || this.utilSvc.appName === KamliApp.KAMLIWEB ||
                                            this.utilSvc.appName === KamliApp.KAMLIFOOD || this.utilSvc.appName === KamliApp.KAMLIADS)
                                            && adnBusinessUser.stripeAccount) {
                                            this.paymentGwSvc.setStripeEnv(adnBusinessUser.stripeAccount);
                                        }
                                        this.storeDbSvc.initFBlistener(this.utilSvc.backendFBstoreId, OBJECTNAME.publishingNewOrders);
                                        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                                            this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                OBJECTNAME.publishingNewOrders);
                                        } else {
                                            this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                OBJECTNAME.publishingNewOrders, smbStoreId);
                                        }
                                        //                                    this.socialnetworkSvc.subscribeSocialPosts(smbStoreId);
                                        this.subscribeEvents(smbStoreId);
                                        this.subscribePromotions(smbStoreId);
                                        this.subscribestoreImageVisible(smbStoreId);
                                        this.subscribestoreLoyaltyPointOffer(smbStoreId);
                                        this.subscribestoreLoyaltyPointValue(smbStoreId);
                                        this.getpublishingPricing();
                                        try {
                                            await this.getCatalogueImages();
                                        } catch (e) { }
                                        let proceed = true;
                                        let temp;
                                        try {
                                            temp = await this.storeDbSvc.getObject(smbStoreId, this.utilSvc.sdb[smbStoreId],
                                                OBJECTNAME.categories);
                                        } catch (e) {
                                            proceed = false;
                                        }
                                        if (proceed) {
                                            this.storeCategories = this.utilSvc.objectToArray(temp) as CATEGORYI[];
                                        }
                                        proceed = true;
                                        try {
                                            temp = await this.storeDbSvc.getObject(smbStoreId, this.utilSvc.sdb[smbStoreId],
                                                OBJECTNAME.preparation);
                                        } catch (e) {
                                            proceed = false;
                                        }
                                        if (proceed) {
                                            this.storePreparation = this.utilSvc.objectToArray(temp) as PREPARATIONI[];
                                        }
                                        proceed = true;
                                        try {
                                            temp = await this.storeDbSvc.getObject(smbStoreId, this.utilSvc.sdb[smbStoreId],
                                                OBJECTNAME.extras);
                                        } catch (e) {
                                            proceed = false;
                                        }
                                        if (proceed) {
                                            this.storeExtras = this.utilSvc.objectToArray(temp) as EXTRAOPTIONI[];
                                        }
                                        this.subscribeAllergens(smbStoreId);
                                        this.subscribeCalories(smbStoreId);
                                        this.subscribeCategories(smbStoreId);
                                        this.subscribeProducts(smbStoreId);
                                        this.orderSvc.checkExpiredOrders(
                                            this.storeOrders,
                                            this.smbCorporate,
                                            storeData,
                                            this.adnCustomerUser
                                        );
                                        this.subscribeStoreUserObjects(this.adnCustomerUser?.adnUserId);
                                        this.subscribepublishingSmbOrders(smbStoreId);
                                        this.subscribePublishCampaigns();
                                        this.subscribePublishQuotes();
                                        resolve(storeData);
                                    },
                                    error3 => reject(error3)
                                );
                            } else {
                                reject(errorCorporate);
                            }
                        });
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    public initStoreServiceAdmin(smbStoreId) {
        return new Promise((resolve, reject) => {
            this.storeDbSvc.initStoreFb(this.utilSvc.backendFBstoreId, smbStoreId, this.storeFbObjectsAdmin,
                this.storeDbSvc.storeFbRef[smbStoreId]).then(
                    _data => {
                        this.smbSvc.getStore(this.utilSvc.backendFBstoreId, smbStoreId).then(async data1 => {
                            let errorCorporate;
                            const storeData = data1 as AdnStoreI;
                            this.smbStore = storeData as AdnStoreIKamli;
                            await this.subscribeStores2(storeData);

                            if (!storeData.sponsorStoreId) {
                                let tempsponsored;
                                try {
                                    tempsponsored = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                                        this.utilSvc.mdb, OBJECTNAME.sponsored) as SponsoredI[];
                                } catch (e) { }
                                const sponsored = this.utilSvc.objectToArray(tempsponsored) as SponsoredI[];
                                const tempfb = storeData.googlePlaceId &&
                                    sponsored && sponsored.find(s => s.gpid === storeData.googlePlaceId);
                                if (tempfb) {
                                    storeData.sponsorId = tempfb.sponsoredId;
                                    storeData.sponsorStoreId = tempfb.sponsorStoreId;
                                    if (storeData.kamliwebDetails) {
                                        if (!storeData.kamliwebDetails.facebook) {
                                            storeData.kamliwebDetails.facebook = tempfb.facebook;
                                        }
                                        if (!storeData.kamliwebDetails.instagram && tempfb.instagram) {
                                            storeData.kamliwebDetails.instagram = tempfb.instagram;
                                        }
                                    }
                                    // add the sponsor to the friend list and vice versa
                                    const sponsorStore = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                        OBJECTNAME.adnStores, tempfb.sponsorStoreId) as AdnStoreI;
                                    if (sponsorStore) {
                                        this.socialnetworkSvc.selectSn(sponsorStore, storeData, true);
                                        this.socialnetworkSvc.selectSn(storeData, sponsorStore, true);
                                    }
                                    // add the sponsor of the sponsor to the friend list
                                    if (sponsorStore.sponsorStoreId) {
                                        const sponsorStore2 = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                                            this.utilSvc.mdb, OBJECTNAME.adnStores, sponsorStore.sponsorStoreId) as AdnStoreI;
                                        if (sponsorStore2) {
                                            this.socialnetworkSvc.selectSn(sponsorStore2, storeData, true);
                                        }
                                    }
                                    this.smbSvc.editStore(this.utilSvc.backendFBstoreId, storeData);
                                }
                            } else if (storeData.kamliwebDetails && !storeData.kamliwebDetails.facebook) {
                                let socialnetworks: RssFeedIKamli[];
                                try {
                                    const temp0 = await this.storeDbSvc.getObject(this.utilSvc.adnStoreId,
                                        this.utilSvc.sdb[this.utilSvc.adnStoreId], OBJECTNAME.socialnetworks);
                                    if (temp0 && temp0 != null) {
                                        socialnetworks = this.utilSvc.objectToArray(temp0) as RssFeedIKamli[];
                                    }
                                } catch (e) { }
                                const tempsn = socialnetworks && socialnetworks.find(s => s.gpid === storeData.googlePlaceId);
                                if (tempsn) {
                                    const tempname = regexsnurl.exec(tempsn.url);
                                    if (tempname && tempname[1]) {
                                        storeData.kamliwebDetails.facebook = tempsn.url;
                                        this.socialnetworkSvc.createSN(socialnetworks, tempsn.feedTitle, tempsn.feedCategory, tempname[1],
                                            tempsn.gpid, tempsn, storeData, true);

                                        // add the sponsor to the friend list and vice versa
                                        const friendStore = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                            OBJECTNAME.adnStores, tempsn.requesterId) as AdnStoreI;
                                        if (friendStore) {
                                            this.socialnetworkSvc.selectSn(friendStore, storeData, true);
                                            this.socialnetworkSvc.selectSn(storeData, friendStore, true);
                                        }
                                        // add the sponsor of the sponsor to the friend list
                                        if (friendStore.sponsorStoreId) {
                                            const sponsorStore2 = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                                                this.utilSvc.mdb, OBJECTNAME.adnStores, friendStore.sponsorStoreId) as AdnStoreI;
                                            if (sponsorStore2) {
                                                this.socialnetworkSvc.selectSn(sponsorStore2, storeData, true);
                                            }
                                        }
                                        this.smbSvc.editStore(this.utilSvc.backendFBstoreId, storeData);
                                    }
                                }

                            }
                            this.setCurrentStore(this.smbStore);
                            try {
                                this.smbCorporate = await this.smbSvc.getCorporate(this.utilSvc.backendFBstoreId, storeData.adnCorporateId);
                            } catch (e) { errorCorporate = e; }
                            if (!errorCorporate) {
                                this.setCurrentCorporate(this.smbCorporate);
                                this.getBusinessUser(storeData).then(
                                    async data3 => {
                                        this.setBusinessUserO(data3 as AdnUser);
                                        if (String(smbStoreId) !== String(this.utilSvc.unprovisionedKamStoreId)) {
                                            let userId;
                                            this.storeDbSvc.initFBlistener(this.utilSvc.backendFBstoreId, OBJECTNAME.publishingNewOrders);
                                            if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                                                this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                    OBJECTNAME.publishingNewOrders);
                                            } else {
                                                this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                    OBJECTNAME.publishingNewOrders, smbStoreId);
                                            }
                                            if (this.utilSvc.appName === KamliApp.KAMLIADS) {
                                                userId = this.adnBusinessUser?.adnUserId;
                                            } else {
                                                userId = this.adnBusinessUser?.adnUserId;
                                            }
                                            this.storeDbSvc.initFBlistener(this.utilSvc.backendFBstoreId, OBJECTNAME.publishingCampaigns);
                                            if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                                                this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                    OBJECTNAME.publishingCampaigns);
                                            } else {
                                                this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                    OBJECTNAME.publishingCampaigns, userId);
                                            }
                                            this.storeDbSvc.initFBlistener(this.utilSvc.backendFBstoreId, OBJECTNAME.publishingQuotes);
                                            if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                                                this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                    OBJECTNAME.publishingQuotes);
                                            } else {
                                                this.storeDbSvc.subscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                    OBJECTNAME.publishingQuotes, userId);
                                            }
                                            this.subscribepublishingSmbOrders(smbStoreId);
                                            this.subscribePublishCampaigns();
                                            this.subscribePublishQuotes();
                                        }
                                        this.setCurrentKameleons(smbStoreId);

                                        let proceed = true;
                                        let temp;
                                        if (this.utilSvc.appName === KamliApp.KAMLIADMIN &&
                                            this.utilSvc.appName === KamliApp.KAMLICONSOLE &&
                                            this.utilSvc.appName === KamliApp.KAMLIEAT || this.utilSvc.appName !== KamliApp.KAMLIWEB) {
                                            this.subscribestoreLoyaltyPointOffer(smbStoreId);
                                            this.subscribestoreLoyaltyPointValue(smbStoreId);
                                            this.subscribeAllergens(smbStoreId);
                                            this.subscribeCalories(smbStoreId);
                                            try {
                                                await this.getCatalogueImages();
                                            } catch (e) { }
                                            try {
                                                temp = await this.storeDbSvc.getObject(smbStoreId, this.utilSvc.sdb[smbStoreId],
                                                    OBJECTNAME.categories);
                                            } catch (e) {
                                                proceed = false;
                                            }
                                            if (proceed) {
                                                this.storeCategories = this.utilSvc.objectToArray(temp) as CATEGORYI[];
                                            }
                                            proceed = true;
                                            try {
                                                temp = await this.storeDbSvc.getObject(smbStoreId, this.utilSvc.sdb[smbStoreId],
                                                    OBJECTNAME.preparation);
                                            } catch (e) {
                                                proceed = false;
                                            }
                                            if (proceed) {
                                                this.storePreparation = this.utilSvc.objectToArray(temp) as PREPARATIONI[];
                                            }
                                            proceed = true;
                                            try {
                                                temp = await this.storeDbSvc.getObject(smbStoreId, this.utilSvc.sdb[smbStoreId],
                                                    OBJECTNAME.extras);
                                            } catch (e) {
                                                proceed = false;
                                            }
                                            if (proceed) {
                                                this.storeExtras = this.utilSvc.objectToArray(temp) as EXTRAOPTIONI[];
                                            }
                                        }

                                        this.subscribePreparations(smbStoreId);
                                        this.subscribeExtras(smbStoreId);
                                        //                                    this.socialnetworkSvc.subscribeSocialPosts(smbStoreId);
                                        this.subscribeEvents(smbStoreId);
                                        this.subscribePromotions(smbStoreId);
                                        this.subscribestoreImageVisible(smbStoreId);

                                        this.subscribeDsSchedules(smbStoreId);
                                        this.subscribeDsPlaylists(smbStoreId);
                                        this.subscribeDsSlides(smbStoreId);

                                        this.subscribeOrders(smbStoreId);
                                        this.subscribePImage(smbStoreId);
                                        this.subscribePVideo(smbStoreId);

                                        this.subscribeNotifications(smbStoreId);


                                        this.getpublishingPricing();

                                        this.orderSvc.checkExpiredOrders(
                                            this.storeOrders,
                                            this.smbCorporate,
                                            storeData,
                                            this.adnBusinessUser
                                        );
                                        this.subscribeCategories(smbStoreId);
                                        this.subscribeProducts(smbStoreId);
                                        this.utilSvc.setStoreId(smbStoreId);
                                        resolve(storeData);
                                    },
                                    error3 => {
                                        console.log('error3=', error3);
                                        reject(error3);
                                    }
                                );
                            } else {
                                reject(errorCorporate);
                            }
                        });
                    },
                    error => {
                        console.log('error=', error);
                        reject(error);
                    }
                );
        });
    }

    public closeStoreService(smbStoreId) {
        return new Promise((resolve, _reject) => {
            this.unsubscribeKameleons();

            this.storeDbSvc.unsubscribeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.publishingNewOrders, smbStoreId);
            this.storeDbSvc.closeFBlistener(this.utilSvc.backendFBstoreId, OBJECTNAME.publishingNewOrders);
            this.unsubscribeCategories(smbStoreId);
            this.unsubscribeProducts(smbStoreId);
            this.unsubscribePreparations(smbStoreId);
            this.unsubscribeExtras(smbStoreId);
            this.unsubscribeAllergens(smbStoreId);
            this.unsubscribeCalories(smbStoreId);
            this.unsubscribestoreLoyaltyPointOffer(smbStoreId);
            this.unsubscribestoreLoyaltyPointValue(smbStoreId);
            this.unsubscribeCart(smbStoreId);

            //            this.socialnetworkSvc.unsubscribeSocialPosts(smbStoreId);
            this.unsubscribeEvents(smbStoreId);
            this.unsubscribePromotions(smbStoreId);
            this.unsubscribestoreImageVisible(smbStoreId);

            this.unsubscribeDsSchedules(smbStoreId);
            this.unsubscribeDsSlides(smbStoreId);
            this.unsubscribeDsPlaylists(smbStoreId);

            this.unsubscribeOrders(smbStoreId);
            this.unsubscribePImage(smbStoreId);
            this.unsubscribePVideo(smbStoreId);
            this.unsubscribepublishingSmbOrders();
            this.unsubscribePublishCampaigns();
            this.unsubscribePublishQuotes();
            this.unsubscribeNotifications(smbStoreId);
            this.unsubscribeStoreUserObjects(this.adnCustomerUser?.adnUserId);

            this.resetVariables();
            this.utilSvc.setStoreId(null);
            resolve(1);
        });
    }

    public getCurrentStore(): Observable<AdnStoreIKamli> {
        return this.smbStoreO.asObservable();
    }

    public async setCurrentStore(value: AdnStoreIKamli) {
        this.smbStore = await this.updateStoreKamli(value) as AdnStoreIKamli;
        this.smbStoreO.next(value);
    }

    updateStoreKamli(value: AdnStoreIKamli) {
        return new Promise(async (resolve, reject) => {
            this.smbStore = value;
            if (this.smbStore) {
                if (this.businessVertical && this.businessVertical[this.smbStore.currentVerticalIndex] &&
                    this.businessVertical[this.smbStore.currentVerticalIndex][this.smbStore.currentTypeIndex]) {
                    const vertical = this.businessVertical[this.smbStore.currentVerticalIndex][this.smbStore.currentTypeIndex];
                    this.smbStore.currentVertical = vertical.vertical;
                    this.smbStore.currentType = vertical.type;
                }
                let tempCorporate;
                try {
                    tempCorporate = await this.smbSvc.getCorporate(this.utilSvc.backendFBstoreId, this.smbStore.adnCorporateId);
                } catch (e) { }
                if (tempCorporate) {
                    this.smbStore.corporate = tempCorporate;
                    let user;
                    try {
                        user = await this.usersSvc.getUser(tempCorporate.adnUserId);
                    } catch (e) { }
                    if (user) {
                        this.smbStore.user = user;
                    }
                }
                if (this.smbStore.sponsorStoreId) {
                    let sponsorStore;
                    try {
                        sponsorStore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, this.smbStore.sponsorStoreId));
                    } catch (e) { }
                    if (sponsorStore) {
                        this.smbStore.sponsorStore = sponsorStore;
                    }
                }
                if (this.smbStore.sponsoredMediaIds && this.smbStore.sponsoredMediaIds != null) {
                    for (const smi of this.smbStore.sponsoredMediaIds) {
                        const tempImage = await this.storeDbSvc.getObject(this.smbStore.adnStoreId,
                            this.utilSvc.sdb[this.smbStore.adnStoreId], OBJECTNAME.DsPImage, smi) as KamliMedia;
                        if (tempImage && tempImage != null) {
                            if (!this.smbStore.sponsoredMedias) {
                                this.smbStore.sponsoredMedias = [];
                            }
                            this.smbStore.sponsoredMedias.push(tempImage);
                        }
                        const tempVideo = await this.storeDbSvc.getObject(this.smbStore.adnStoreId,
                            this.utilSvc.sdb[this.smbStore.adnStoreId], OBJECTNAME.DsPVideo, smi) as KamliMedia;
                        if (tempVideo && tempVideo != null) {
                            if (!this.smbStore.sponsoredMedias) {
                                this.smbStore.sponsoredMedias = [];
                            }
                            this.smbStore.sponsoredMedias.push(tempVideo);
                        }
                    }
                }
                let sponsored;
                let tempsponsored;
                if (!this.sponsored) {
                    try {
                        tempsponsored = (await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                            this.utilSvc.mdb, OBJECTNAME.sponsored));
                    } catch (e) { }
                    sponsored = this.utilSvc.objectToArray(tempsponsored);
                } else {
                    sponsored = this.sponsored;
                }
                if (!sponsored) {
                    sponsored = [];
                }
                if (this.smbStore.sponsoredId && this.smbStore.sponsoredId != null &&
                    this.smbStore.sponsoredId.length > 0) {
                    for (const sid of this.smbStore.sponsoredId) {
                        const sponsor = sponsored && sponsored.find(s => String(s.sponsoredId) === String(sid));
                        if (sponsor) {
                            if (!this.smbStore.sponsored) {
                                this.smbStore.sponsored = [];
                            }
                            this.smbStore.sponsored.push(sponsor);
                        }
                    }
                }
            }
            resolve(value);
        });
    }

    public getBusinessUserO(): Observable<AdnUser> {
        return this.adnBusinessUserO.asObservable();
    }
    public setBusinessUserO(value: AdnUser) {
        this.adnBusinessUser = value;
        this.adnBusinessUserO.next(value);
    }


    public resetVariables() {
        this.setCurrentStore(undefined);

        this.storeDbSvc.storeFbRef = [];
        this.setBusinessUserO(undefined);
        this.storeOrders = undefined;

        this.storeCategories = undefined;
        this.storeProducts = undefined;
        this.storeAllergens = undefined;
        this.storeCalories = undefined;
        this.storeEvents = undefined;

        this.storeDsSlides = undefined;
        this.storeDsVideo = undefined;
        this.storeDsSchedules = undefined;
        this.storeDsPlaylists = undefined;
    }

    public getBusinessUser(store: AdnStoreI) {
        return new Promise(async (resolve, reject) => {
            this.smbCorporate = await this.smbSvc.getCorporate(this.utilSvc.backendFBstoreId, store.adnCorporateId);
            this.usersSvc.getUser(this.smbCorporate.adnUserId).then(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    public getBusinessVertical() {
        let timeOfDay = new Date().getTime();
        return new Promise((resolve, _reject) => {
            this.storeDbSvc
                .getObject(
                    this.utilSvc.backendFBstoreId,
                    this.utilSvc.mdb,
                    OBJECTNAME.templateCatalogue1
                )
                .then(
                    data => {
                        if (data) {
                            timeOfDay = new Date().getTime();
                            const templateCatalogue = [];
                            this.templateCatalogue = this.utilSvc.objectToArray(data) as TC[];
                            const catVerticals = this.templateCatalogue.map(item =>
                                item.vertical).filter((value, index, self) => self.indexOf(value) === index);
                            for (const cv of catVerticals) {
                                const temp = this.templateCatalogue.filter(tc => tc.vertical === cv);
                                const tcs = {};
                                for (const tct of temp) {
                                    tcs[tct.type] = tct;
                                }
                                templateCatalogue.push(tcs);
                            }
                            const result = [];
                            for (const tc of templateCatalogue) {
                                const typeString = 'type';
                                for (const key in tc) {
                                    if (tc.hasOwnProperty[key]) {
                                        tc[key][typeString] = key;
                                    }
                                }
                                const temp2 = this.utilSvc.objectToArray(
                                    tc
                                );
                                result.push(temp2);
                            }
                            this.businessVertical = result;
                            resolve(result);
                        }
                    },
                    _error => { }
                );
        });
    }

    getCatalogueImages(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this.storeDbSvc
                .getObject(
                    this.utilSvc.backendFBstoreId,
                    this.utilSvc.mdb,
                    OBJECTNAME.catalogueImages
                )
                .then(
                    data => {
                        const temp = this.utilSvc.objectToArray(data);
                        this.catalogueImagesProductsDir = [];
                        this.catalogueImagesEventsDir = [];
                        this.catalogueImages = temp.filter(i => {
                            if (i.fullPath) {
                                return (
                                    (i.fullPath.includes('ADN/Products/Eats/') ||
                                        i.fullPath.includes('ADN/Events')) &&
                                    i.filename !== '.DS_Store'
                                );
                            } else {
                                return false;
                            }
                        });
                        let temp2;
                        const regExProductsDir = /(ADN\/Products\/Eats\/)(.+$)/;
                        const regExEventsDir = /(ADN\/Events\/)(.+$)/;
                        for (const ci of this.catalogueImages) {
                            if (temp2 !== ci.directory) {
                                temp2 = ci.directory;
                                const temp3 = regExProductsDir.exec(temp2);
                                const temp33 = regExEventsDir.exec(temp2);
                                if (temp3 && temp3 != null && temp3[2]) {
                                    const catImaDir = {
                                        fullDirectory: temp2,
                                        shortDirectory: temp3[2],
                                    };
                                    this.catalogueImagesProductsDir.push(catImaDir);
                                }
                                if (temp33 && temp33 != null && temp33[2]) {
                                    const catImaDir = {
                                        fullDirectory: temp2,
                                        shortDirectory: temp33[2],
                                    };
                                    this.catalogueImagesEventsDir.push(catImaDir);
                                }
                            }
                        }
                        resolve(this.catalogueImages);
                    },
                    error => reject(error)
                );
        });
    }

    subscribeKameleons() {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLINET ||
            this.utilSvc.appName === KamliApp.KAMLIPLAYER || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.kameleon].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setKameleons(temp);
                    if (this.smbStore && this.smbStore.adnStoreId) {
                        this.setCurrentKameleons(this.smbStore.adnStoreId);
                    }
                },
                error => console.log(error)
            );
        }
    }

    public unsubscribeKameleons() {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLINET ||
            this.utilSvc.appName === KamliApp.KAMLIPLAYER || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.unsubscribeObject(
                beStoreId,
                this.utilSvc.mdb,
                OBJECTNAME.kameleon
            );
            this.setKameleons(undefined);
        }
    }

    public getKameleons(): Observable<AdnkameleonBox[]> {
        return this.allkameleonsO.asObservable();
    }
    public setKameleons(value: AdnkameleonBox[]) {
        this.allkameleons = value;
        this.allkameleonsO.next(value);
    }

    public getStoreKameleons(): Observable<any> {
        return this.storekameleonsO.asObservable();
    }
    public getCorporateKameleons(): Observable<any> {
        return this.corporatekameleonsO.asObservable();
    }
    public getAssistedKameleons(): Observable<any> {
        return this.assistedkameleonsO.asObservable();
    }
    public async setCurrentKameleons(storeId) {
        if (!this.allkameleons) {
            let tempK;
            try {
                tempK = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.kameleon);
            } catch (e) { }
            this.allkameleons = this.utilSvc.objectToArray(tempK);
        }
        if (this.allkameleons) {
            this.storekameleons = this.allkameleons.filter(k => String(k.storeId) === String(storeId));
            if (!this.storekameleons) {
                this.storekameleons = [];
            }
            this.corporatekameleons = this.allkameleons.filter(k => String(k.storeId).substring(0, 9) === String(storeId).substring(0, 9));
            if (!this.corporatekameleons) {
                this.corporatekameleons = [];
            }
            this.assistedkameleons = [];
            if (this.adnBusinessUser && this.adnBusinessUser.assistedStoreIds) {
                for (const assistedStoreId of this.adnBusinessUser.assistedStoreIds) {
                    const temp = this.allkameleons.filter(k => String(k.storeId) === String(assistedStoreId));
                    if (temp) {
                        this.assistedkameleons = this.assistedkameleons.concat(temp);
                    }
                }
            } else {
                this.assistedkameleons = this.corporatekameleons;
            }
        } else {
            this.storekameleons = [];
            this.corporatekameleons = [];
            this.assistedkameleons = [];
        }
        this.storekameleonsO.next(this.storekameleons);
        this.corporatekameleonsO.next(this.corporatekameleons);
        this.assistedkameleonsO.next(this.assistedkameleons);
    }

    subscribeCorporates() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.adnCorporates].subscribe(
            data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null;
                this.setCorporates(temp);
            }
        );
    }

    unsubscribeCorporates() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.adnCorporates);
        this.setCorporates(undefined);
    }

    public getCorporates(): Observable<AdnCorporateI[]> {
        return this.allCorporatesO.asObservable();
    }

    async setCorporates(value: AdnCorporateI[]) {
        if (value != null && value) {
            this.allCorporates = [];
            if (value) {
                for (const corporate of value) {
                    let corporateKamli: AdnCorporateIKamli;
                    try {
                        corporateKamli = await this.updateCorporates(corporate);
                    } catch (e) { }
                    if (corporateKamli) {
                        this.allCorporates.push(corporateKamli);
                    }
                }
            }
            this.allCorporatesO.next(this.allCorporates);
        } else {
            this.allCorporates = undefined;
            this.allCorporatesO.next(this.allCorporates);
        }
    }

    updateCorporates(corporate: AdnCorporateI): Promise<AdnCorporateIKamli> {
        return new Promise(async (resolve, _reject) => {
            const corporateKamli: AdnCorporateIKamli = corporate as AdnCorporateIKamli;

            let adnUser: AdnUser;
            try {
                adnUser = await this.usersSvc.getUser(corporateKamli.adnUserId) as AdnUser;
            } catch (e) { }
            corporateKamli.adnUser = adnUser;

            const storesDetails = [] as AdnStoreI[];
            for (const storeId of corporateKamli.storeList) {
                let store;
                try {
                    store = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                        this.utilSvc.mdb, OBJECTNAME.adnStores, storeId);
                } catch (e) { }
                if (store) {
                    storesDetails.push(store);
                }
            }
            corporateKamli.storeListDetails = storesDetails;
            resolve(corporateKamli);
        });
    }



    subscribeSponsored() {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.sponsored].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null;
                    this.setSponsored(temp);
                }
            );
        }
    }

    unsubscribeSponsored() {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.sponsored);
            this.setSponsored(undefined);
        }
    }

    public getSponsored(): Observable<SponsoredIKamli[]> {
        return this.sponsoredO.asObservable();
    }
    public async setSponsored(value: SponsoredI[]) {
        if (value != null) {
            this.sponsored = [];
            if (!this.allStores) {
                const tempStores = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                    OBJECTNAME.adnStores);
                this.allStores = this.utilSvc.objectToArray(tempStores);
            }

            if (this.allStores && value) {
                for (const sponsor of value) {
                    const sponsorKamli = sponsor as SponsoredIKamli;
                    const temp1 = this.allStores && this.allStores.find(s => s.adnStoreId === sponsor.sponsorStoreId);
                    if (temp1) {
                        sponsorKamli.sponsorStore = temp1 as any;
                    }
                    const temp2 = this.allStores && this.allStores.find(s => s.adnStoreId === sponsor.sponsoreeStoreId);
                    if (temp2) {
                        sponsorKamli.sponsoreeStore = temp2 as any;
                    }
                    this.sponsored.push(sponsorKamli);
                }

            }
        } else {
            this.sponsored = undefined;
        }
        this.sponsoredO.next(this.sponsored);
    }

    subscribeStores() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.adnStores].subscribe(
            async data => {
                const allStores = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) as AdnStoreIKamli[] : null;
                if (allStores) {
                    let allkamaleons = [] as AdnkameleonBox[];
                    if (!this.allkameleons) {
                        try {
                            const tempK = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                                this.utilSvc.mdb, OBJECTNAME.kameleon);
                            if (tempK) {
                                allkamaleons = this.utilSvc.objectToArray(tempK) as AdnkameleonBoxKamli[];
                            }
                        } catch (e) { }
                    } else {
                        allkamaleons = this.allkameleons;
                    }
                    for (const store of allStores) {
                        if (!store.sponsored) {
                            store.sponsored = [];
                        }
                        const temp = this.templateCatalogue.find(c => Number(c.categoryId) === Number(store.categoryId));
                        if (temp) {
                            store.businessVertical = temp;
                        }
                        const tempK = allkamaleons.filter(k => String(k.storeId) === String(store.adnStoreId));
                        if (tempK && tempK.length > 1) {
                            store.screenStatus = SCREENSTATUS.INSTALLED;
                        } else {
                            store.screenStatus = SCREENSTATUS.NOTINSTALLED;
                        }
                    }
                    this.setStores(allStores);
                    if (this.smbStore) {
                        const temp = allStores && allStores.find(s => String(s.adnStoreId) === String(this.smbStore.adnStoreId));
                        if (temp) {
                            this.setCurrentStore(temp);
                        }
                    }
                    this.adnStore = this.allStores && this.allStores.find(s => String(s.adnStoreId) ===
                        String(this.utilSvc.adnStoreId)) as any;
                }
            }
        );
    }

    subscribeStores2(smbStore: AdnStoreI) {
        return new Promise(async (resolve) => {
            const dir = 'assets/backend/';
            const backendurlString = 'backendurl';
            if (this.allStores) {
                const publishingFreeOrders = [];
                for (const store of this.allStores) {
                    if (store.kamlifilleradsDetails && store.kamlifilleradsDetails.thumbL &&
                        store.kamlifilleradsDetails.thumbL.length > 0) {
                        let result;
                        try {
                            const url = store.kamlifilleradsDetails.thumbL;
                            result = await this.utilSvc.downloadThumb2(url, dir, store.kamlifilleradsDetails.localurlL,
                                false).catch(_e => {
                                    console.log('error with store %s thumb=', store.adnStoreId, store.kamlifilleradsDetails.thumbL);
                                });
                        } catch (e) {
                            console.log('error with store %s thumb=', store.adnStoreId, store.kamlifilleradsDetails.thumbL);
                        }
                        if (result) {
                            store.kamlifilleradsDetails.localurlL = result[backendurlString];
                            store.kamlifilleradsDetails.localurlL2 =
                                this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                    try {
                                        store.kamlifilleradsDetails.localurlL2 = await this.utilSvc.getLocalUrl(
                                            store.kamlifilleradsDetails.localurlL,
                                            store.kamlifilleradsDetails.thumbL);
                                    } catch (e2) { }
                                }
                            }
                        }
                    }
                    if (store.kamlifilleradsDetails && store.kamlifilleradsDetails.thumbP &&
                        store.kamlifilleradsDetails.thumbP.length > 0) {
                        let result;
                        try {
                            const url = store.kamlifilleradsDetails.thumbP;
                            result = await this.utilSvc.downloadThumb2(url, dir, store.kamlifilleradsDetails.localurlP,
                                false).catch(_e => {
                                    console.log('error with store %s thumb=', store.adnStoreId, store.kamlifilleradsDetails.thumbL);
                                });
                        } catch (e) {
                            console.log('error with store thumb=', store.kamlifilleradsDetails.thumbL);
                        }
                        if (result) {
                            store.kamlifilleradsDetails.localurlP = result[backendurlString];
                            store.kamlifilleradsDetails.localurlP2 =
                                this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                    try {
                                        store.kamlifilleradsDetails.localurlP2 = await this.utilSvc.getLocalUrl(
                                            store.kamlifilleradsDetails.localurlP,
                                            store.kamlifilleradsDetails.thumbP);
                                    } catch (e2) { }
                                }
                            }
                        }
                    }
                    const storeString = 'store';
                    if ((store.kamlifilleradsDetails && store.kamlifilleradsDetails.localurlL) ||
                        (store.kamlifilleradsDetails && store.kamlifilleradsDetails.localurlP)) {
                        store.kamlifilleradsDetails[storeString] = store;
                        publishingFreeOrders.push(store.kamlifilleradsDetails);
                    }
                }
                this.setPublishingFreeOrders(publishingFreeOrders);
                if (smbStore.kamliwebDetails && smbStore.kamliwebDetails.logo && smbStore.kamliwebDetails.logo.length > 0) {
                    const url = smbStore.kamliwebDetails.logo;
                    let result;
                    try {
                        result = await this.utilSvc.downloadThumb2(url, dir, smbStore.kamliwebDetails.locallogo,
                            false).catch(_e => {
                                console.log('error downloading url=', url);
                            }).catch(_e => {
                                console.log('error with store thumb=', smbStore.kamlifilleradsDetails.thumbL);
                            });
                    } catch (e) {
                        console.log('error with store thumb=', smbStore.kamlifilleradsDetails.thumbL);
                    }
                    if (result) {
                        smbStore.kamliwebDetails.locallogo = result[backendurlString];
                        smbStore.kamliwebDetails.locallogo2 = this.utilSvc.backendURL + result[backendurlString];
                        if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                            if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                try {
                                    smbStore.kamliwebDetails.locallogo2 = await this.utilSvc.getLocalUrl(
                                        smbStore.kamliwebDetails.locallogo,
                                        smbStore.kamliwebDetails.logo);
                                } catch (e2) { }
                            }
                        }
                    }
                }
            }
            resolve(1);
        });
    }

    unsubscribeStores() {
        const beStoreId = this.utilSvc.backendFBstoreId;
        this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.adnStores);
        this.setStores(undefined);
    }

    public getStores(): Observable<AdnStoreIKamli[]> {
        return this.allStoresO.asObservable();
    }
    public setStores(value: AdnStoreIKamli[]) {
        this.allStores = value;
        this.allStoresO.next(value);
    }

    public async getSponsorMedia(sponsorStore: AdnStoreI, sponsorMediaId: string): Promise<KamliMedia> {
        let sponsorMedia: KamliMedia;
        const backendurlString = 'backendurl';
        if (sponsorStore) {
            sponsorMedia = await this.storeDbSvc.getStoreObject(sponsorStore.adnStoreId,
                OBJECTNAME.DsPImage, sponsorMediaId) as KamliMedia;
            if (!sponsorMedia) {
                sponsorMedia = await this.storeDbSvc.getStoreObject(sponsorStore.adnStoreId,
                    OBJECTNAME.DsPVideo, sponsorMediaId) as KamliMedia;
            }
            if (sponsorMedia) {
                let result;
                const dir = 'assets/stores/' + sponsorStore.adnStoreId + '/DsPImage/';
                try {
                    result = await this.utilSvc.downloadThumb2(sponsorMedia.thumbL,
                        dir, sponsorMedia.localurlL, false).catch(_e => {
                            console.log('error with sponsor media thumb=', sponsorMedia.thumbL);
                        });
                } catch (e) {
                    console.log('error with sponsor media thumb=', sponsorMedia.thumbL);
                }
                if (result) {
                    sponsorMedia.localurlL = result[backendurlString];
                    sponsorMedia.localurlL2 = this.utilSvc.backendURL + result[backendurlString];
                    if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                        sponsorMedia.localurlL2 = await this.utilSvc.getLocalUrl(sponsorMedia.localurlL,
                            sponsorMedia.thumbL);
                    }
                }
                result = undefined;
                try {
                    result = await this.utilSvc.downloadThumb2(sponsorMedia.thumbP,
                        dir, sponsorMedia.localurlP, false).catch(_e => {
                            console.log('error with sponsor media thumb=', sponsorMedia.thumbP);
                        });
                } catch (e) {
                    console.log('error with sponsor media thumb=', sponsorMedia.thumbP);
                }
                if (result) {
                    sponsorMedia.localurlP = result[backendurlString];
                    sponsorMedia.localurlP2 = this.utilSvc.backendURL + result[backendurlString];
                    if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                        sponsorMedia.localurlP2 = await this.utilSvc.getLocalUrl(sponsorMedia.localurlP,
                            sponsorMedia.thumbP);
                    }
                }
            }
        }
        return sponsorMedia;
    }

    public subscribeOrders(smbStoreId: string, adnUserId?: string) {
        const timeofday = new Date().getTime();
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.firebaseBSSdata[smbStoreId][OBJECTNAME.orders].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) as OrderI[] : undefined as OrderI[];
                    if (temp) {
                        for (const o of temp) {
                            if (!o.deliveryDetails.createdTS) {
                                o.archived = true;
                                //                                o.status = ORDERSTATUS.CANCELLED;
                            } else if ((o.deliveryDetails.createdTS && o.deliveryDetails.createdTS < timeofday)) {
                                o.archived = true;
                                //                                o.status = ORDERSTATUS.CANCELLED;
                            }
                        }
                    }
                    let temp1;
                    if (adnUserId) {
                        temp1 = temp && temp.filter(t => t.adnCustomerUserId === adnUserId);
                    } else {
                        temp1 = temp;
                    }

                    this.setStoreOrders(temp1);
                },
                error => console.log(error)
            );
        }
    }

    public unsubscribeOrders(smbStoreId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.unsubscribeObject(
                smbStoreId,
                this.utilSvc.sdb[smbStoreId],
                OBJECTNAME.orders
            );
            this.setStoreOrders(undefined);
        }
    }

    public getStoreOrders(): Observable<OrderI[]> {
        return this.storeOrdersO.asObservable();
    }
    setStoreOrders(value: OrderI[]) {
        this.storeOrders = value;
        this.storeOrdersO.next(value);
        if (this.storeOrders && this.storeOrders.length > 0 && this.utilSvc.appName !== KamliApp.KAMLISTORE) {
            this.playAudio();
        }
    }

    subscribeCart(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            let storeCart;
            this.firebaseBSSdata[OBJECTNAME.cart] = this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.cart].subscribe(
                async data => {
                    //                    if (this.adnCustomerUser) {
                    if (data && data[0]) {
                        storeCart = data[0];
                        if (storeCart) {
                            storeCart.deliveryFee = 0;
                            if (storeCart.deliveryType) {
                                this.deliveryType = storeCart.deliveryType;
                                if (this.deliveryType === DELIVERYMETHOD.PayDeliver) {
                                    if (this.smbStore && this.smbStore.kamliwebDetails && this.smbStore.kamliwebDetails.clickAndDeliver &&
                                        this.smbStore.kamliwebDetails.clickAndDeliver.deliveryFee) {
                                        storeCart.deliveryFee = this.smbStore.kamliwebDetails.clickAndDeliver.deliveryFee;
                                    } else {
                                        storeCart.deliveryFee = 0;
                                    }
                                }
                            } else {
                                this.deliveryType = this.deliveryType ? this.deliveryType : DELIVERYMETHOD.PayDine;
                            }
                        } else {
                            this.deliveryType = this.deliveryType ? this.deliveryType : DELIVERYMETHOD.PayDine;
                        }
                        /*                            try {
                                                        await this.usersSvc.addVisitedStore(this.adnCustomerUser.adnUserId, storeId);
                                                    } catch (e) { }*/
                        this.setCart(storeCart);
                    } else {
                        this.setCart(undefined);
                    }
                    //                    }
                },
                error => console.log(error)
            );
        }
    }

    unsubscribeCart(_storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.setCart(undefined);
            if (this.firebaseBSSdata[OBJECTNAME.cart]) {
                this.firebaseBSSdata[OBJECTNAME.cart].unsubscribe();
                this.deliveryType = DELIVERYMETHOD.PayDine;
            }
        }
    }

    public getCart(): Observable<CART> {
        return this.storeCartO.asObservable();
    }
    public setCart(value: CART) {
        this.storeCart = value;
        this.storeCartO.next(value);
    }

    subscribeNotifications(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT || this.utilSvc.appName === KamliApp.KAMLISTORE) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.notifications].subscribe(data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null;
                this.setNotifications(temp);
            });
        }
    }
    unsubscribeNotifications(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT || this.utilSvc.appName === KamliApp.KAMLISTORE) {
            this.storeDbSvc.unsubscribeObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.notifications, storeId);
            this.setNotifications(undefined);
        }
    }

    public getNotifications(): Observable<any[]> {
        return this.notificationsO.asObservable();
    }
    public setNotifications(value: NotificationI[]) {
        this.notifications = value;
        this.notificationsO.next(value);
    }


    public getCustomers(adnCustomerUser: AdnUser) {
        return new Promise((resolve, reject) => {
            let params1 = new HttpParams();
            if (adnCustomerUser && adnCustomerUser.stripeAccount) {
                params1 = params1.set('connectedAccountId', adnCustomerUser.stripeAccount);
                this.http.get(this.utilSvc.backendURL + 'stripe/customer/list', { params: params1, responseType: 'json' }).subscribe(
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
            } else {
                resolve({});
            }
        });
    }

    public subscribeCategories(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const backendurlString = 'backendurl';
            const dir = 'assets/stores/' + storeId + '/categories/';
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.categories].subscribe(
                async data => {
                    const storeCategories = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) as CATEGORYI[] : [];
                    for (const sc of storeCategories) {
                        if (sc.parentCategory !== '-1') {
                            const temp = storeCategories && storeCategories.find(
                                c => String(c.categoryId) === String(sc.parentCategory)
                            );
                            if (temp) {
                                temp.visible = false;
                            }
                        }
                    }
                    this.setCategories(storeCategories);
                    //                    if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                    let result;
                    for (const category of storeCategories) {
                        try {
                            result = await this.utilSvc.downloadThumb2(category.thumb,
                                dir, category.localurl, false);
                        } catch (e) {
                            console.log('error with category thumb=', category.thumb);
                        }
                        if (result) {
                            category.localurl = result[backendurlString];
                            category.localurl2 = this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                try {
                                    category.localurl2 = await this.utilSvc.getLocalUrl(category.localurl, category.thumb);
                                } catch (e2) { }
                            }
                        }
                    }
                    //                    }
                    this.setCategories(storeCategories);
                    this.cleanStoredAssets(storeId, OBJECTNAME.categories);
                }
            );
        }
    }
    public unsubscribeCategories(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.categories,
                storeId
            );
            this.setCategories(undefined);
        }
    }
    public getCategories(): Observable<any[]> {
        return this.storeCategoriesO.asObservable();
    }
    public setCategories(value: any[]) {
        this.storeCategories = value;
        this.storeCategoriesO.next(value);
    }

    public subscribePreparations(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.preparation].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setPreparations(temp);
                }
            );
        }
    }
    public unsubscribePreparations(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.preparation,
                storeId
            );
            this.setPreparations(undefined);
        }
    }
    public getPreparations(): Observable<PREPARATIONI[]> {
        return this.storePreparationO.asObservable();
    }
    public setPreparations(value: PREPARATIONI[]) {
        this.storePreparation = value;
        this.storePreparationO.next(value);
    }

    public subscribeExtras(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.extras].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setExtras(temp);
                }
            );
        }
    }
    public unsubscribeExtras(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.extras,
                storeId
            );
            this.setExtras(undefined);
        }
    }
    public getExtras(): Observable<EXTRAOPTIONI[]> {
        return this.storeExtrasO.asObservable();
    }
    public setExtras(value: EXTRAOPTIONI[]) {
        this.storeExtras = value;
        this.storeExtrasO.next(value);
    }

    public subscribeEvents(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const backendurlString = 'backendurl';
            const dir = 'assets/stores/' + storeId + '/events/';
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.events].subscribe(
                async data => {
                    const backgroundString = 'background';
                    const thumbString = 'thumb';
                    const localurlString = 'backendurl';
                    const storeEvents = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) as EventI[] : [];
                    for (const event of storeEvents) {
                        event.backgroundL = event.backgroundL ?
                            event.backgroundL : event[backgroundString] ? event[backgroundString] : '';
                        event.backgroundP = event.backgroundP ? event.backgroundP : event.backgroundL;
                        event.thumbL = event.thumbL ? event.thumbL : event[thumbString] ? event[thumbString] : '';
                        event.thumbP = event.thumbP ? event.thumbP : event.thumbL;
                        event.localurlL = event.localurlL ? event.localurlL : null;
                        event.localurlP = event.localurlP ? event.localurlP : null;
                    }
                    this.setEvents(storeEvents);
                    if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                        for (const storeEvent of storeEvents) {
                            let result;
                            try {
                                result = await this.utilSvc.downloadThumb2(storeEvent.thumbL,
                                    dir, storeEvent.localurlL, true).catch(e => {
                                        console.log('error with events thumb=', storeEvent.thumbL, ', error=', e);
                                    });
                            } catch (e) {
                                console.log('error with events thumb=', storeEvent.thumbL, ', error=', e);
                            }
                            if (result) {
                                storeEvent.localurlL = result[backendurlString];
                                storeEvent.localurlL2 = this.utilSvc.backendURL + result[backendurlString];
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                    try {
                                        storeEvent.localurlL2 = await this.utilSvc.getLocalUrl(storeEvent.localurlL, storeEvent.thumbL);
                                    } catch (e1) { }
                                }
                            }
                            result = undefined;
                            try {
                                result = await this.utilSvc.downloadThumb2(storeEvent.thumbP,
                                    dir, storeEvent.localurlP, false).catch(e => {
                                        console.log('error with events thumb=', storeEvent.thumbL, ', error=', e);
                                    });
                            } catch (e) {
                                console.log('error with events thumb=', storeEvent.thumbL);
                            }
                            if (result) {
                                storeEvent.localurlP = result[backendurlString];
                                storeEvent.localurlP2 = this.utilSvc.backendURL + result[backendurlString];
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                                    storeEvent.localurlP2 = await this.utilSvc.getLocalUrl(storeEvent.localurlP, storeEvent.thumbP);
                                }
                            }
                            result = undefined;
                            try {
                                result = await this.utilSvc.downloadThumb2(storeEvent.backgroundL, dir,
                                    false).catch(e => {
                                        console.log('error with events thumb=', storeEvent.thumbL, ', error=', e);
                                    });
                            } catch (e) {
                                // console.log('error with events background=', storeEvent[backendurlString]);
                            }
                            if (result) {
                                storeEvent.localbackgroundL = result[backendurlString];
                                storeEvent.localbackgroundL2 = this.utilSvc.backendURL + storeEvent.localbackgroundL;
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                    storeEvent.localbackgroundL2 = await this.utilSvc.getLocalUrl(storeEvent.localbackgroundL,
                                        storeEvent.backgroundL);
                                }
                                result = undefined;
                                try {
                                    result = await this.utilSvc.downloadThumb2(storeEvent.backgroundL, dir, storeEvent.localbackgroundL,
                                        false);
                                } catch (e) {
                                    // console.log('error with events background=', storeEvent[backendurlString]);
                                }
                                if (result) {
                                    storeEvent.localbackgroundL = result[backendurlString];
                                    storeEvent.localbackgroundL2 = this.utilSvc.backendURL + storeEvent.localbackgroundL;
                                    if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                                        storeEvent.localbackgroundL2 = await this.utilSvc.getLocalUrl(storeEvent.localbackgroundL,
                                            storeEvent.backgroundL);
                                    }
                                }
                            }
                        }
                        this.setEvents(storeEvents);
                        this.cleanStoredAssets(storeId, OBJECTNAME.events);
                    }
                }
            );
        }
    }
    public unsubscribeEvents(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.events,
                storeId
            );
            this.setEvents(undefined);
        }
    }
    public getEvents(): Observable<EventI[]> {
        return this.storeEventsO.asObservable();
    }
    public setEvents(value: EventI[]) {
        this.storeEvents = value;
        this.storeEventsO.next(value);
    }

    public subscribestoreLoyaltyPointOffer(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.loyaltyPointOffer].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setloyaltyPointOffer(temp);
                }
            );
        }
    }
    public unsubscribestoreLoyaltyPointOffer(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.loyaltyPointOffer,
                storeId
            );
            this.setloyaltyPointOffer(undefined);
        }
    }
    public getloyaltyPointOffer(): Observable<LoyaltyPointOffer[]> {
        return this.storeLoyaltyPointOfferO.asObservable();
    }
    public setloyaltyPointOffer(value: LoyaltyPointOffer[]) {
        this.storeLoyaltyPointOffer = value;
        this.storeLoyaltyPointOfferO.next(value);
    }

    public subscribestoreLoyaltyPointValue(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.loyaltyPointValue].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setloyaltyPointValue(temp);
                }
            );
        }
    }
    public unsubscribestoreLoyaltyPointValue(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.loyaltyPointValue,
                storeId
            );
            this.setloyaltyPointOffer(undefined);
        }
    }
    public getloyaltyPointValue(): Observable<LoyaltyPointValue[]> {
        return this.storeLoyaltyPointValueO.asObservable();
    }
    public setloyaltyPointValue(value: LoyaltyPointValue[]) {
        this.storeLoyaltyPointValue = value;
        this.storeLoyaltyPointValueO.next(value);
    }

    public subscribestoreImageVisible(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.ImageVisible].subscribe(
                data => {
                    const temp = data && data[0] != null ? data[0] : undefined;
                    this.setImageVisible(temp);
                }
            );
        }
    }
    public unsubscribestoreImageVisible(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.ImageVisible,
                storeId
            );
            this.setImageVisible(undefined);
        }
    }
    public getImageVisible(): Observable<ImageVisibleI[]> {
        return this.storeImageVisibleO.asObservable();
    }
    public setImageVisible(value: ImageVisibleI[]) {
        this.storeImageVisible = value;
        this.storeImageVisibleO.next(value);
    }


    public subscribePromotions(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const backendurlString = 'backendurl';
            const thumbString = 'thumb';
            const dir = 'assets/stores/' + storeId + '/promotions/';
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.promotions].subscribe(
                async data => {
                    const storePromotions = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) as PromotionI[] : [];
                    for (const promotion of storePromotions) {
                        promotion.thumbL = promotion.thumbL ? promotion.thumbL : promotion[thumbString];
                        promotion.thumbP = promotion.thumbP ? promotion.thumbP : promotion[thumbString];
                    }
                    this.setPromotions(storePromotions);
                    for (const promotion of storePromotions) {
                        let result;
                        try {
                            result = await this.utilSvc.downloadThumb2(promotion.thumbL,
                                dir, promotion.localurlL, false).catch(_e => {
                                    console.log('error with promotion thumb=', promotion.thumbL);
                                });
                        } catch (e) {
                            console.log('error with promotion thumb=', promotion.thumbL);
                        }
                        if (result) {
                            promotion.localurlL = result[backendurlString];
                            promotion.localurlL2 = this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                                try {
                                    promotion.localurlL2 = await this.utilSvc.getLocalUrl(promotion.localurlL, promotion.thumbL);
                                } catch (e2) { }
                            }
                        }
                        result = undefined;
                        try {
                            result = await this.utilSvc.downloadThumb2(promotion.thumbP,
                                dir, promotion.localurlP, false).catch(_e => {
                                    console.log('error with promotion thumb=', promotion.thumbL);
                                });
                        } catch (e) {
                            console.log('error with promotion thumb=', promotion.thumbP);
                        }
                        if (result) {
                            promotion.localurlP = result[backendurlString];
                            promotion.localurlP2 = this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                                promotion.localurlP2 = await this.utilSvc.getLocalUrl(promotion.localurlP, promotion.thumbP);
                            }
                        }
                    }
                    this.setPromotions(storePromotions);
                    this.cleanStoredAssets(storeId, OBJECTNAME.promotions);
                }
            );
        }
    }
    public unsubscribePromotions(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.promotions,
                storeId
            );
            this.setPromotions(undefined);
        }
    }
    public getPromotions(): Observable<PromotionI[]> {
        return this.storePromotionsO.asObservable();
    }
    public setPromotions(value: PromotionI[]) {
        this.storePromotions = value;
        this.storePromotionsO.next(value);
    }

    public subscribeProducts(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const backendurlString = 'backendurl';
            const dir = 'assets/stores/' + storeId + '/products/';
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.products].subscribe(
                async data => {
                    if (!this.storeCategories || this.storeCategories == null) {
                        let temp;
                        try {
                            temp = await this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.products);
                        } catch (e) { }
                        if (temp) {
                            this.storeCategories = this.utilSvc.objectToArray(temp);
                        }
                    }
                    const storeProducts = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) as PRODUCTIKamli[] : [];
                    this.setProducts(storeId, storeProducts);
                    for (const product of storeProducts) {
                        let result;
                        try {
                            result = await this.utilSvc.downloadThumb2(product.thumb,
                                dir, product.localurl, true).catch(_e => {
                                    console.log('error with product thumb=', product);
                                });
                        } catch (e) {
                            console.log('error with product thumb=', product);
                        }
                        if (result) {
                            product.localurl = result[backendurlString];
                            product.localurl2 = this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                    product.localurl2 = await this.utilSvc.getLocalUrl(product.localurl, product.thumb);
                                }
                            }
                        }
                    }
                    this.setProducts(storeId, storeProducts);
                    //                    this.cleanStoredAssets(storeId, OBJECTNAME.products);
                }
            );
        }
    }
    public unsubscribeProducts(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.products,
                storeId
            );
            this.setProducts(storeId, undefined);
        }
    }
    public getProducts(): Observable<PRODUCTIKamli[]> {
        return this.storeProductsO.asObservable();
    }

    /*    public async setProducts(storeId, value: PRODUCTI[]) {
            let storeProductskamli = [];
            let storeProductskamli2 = [];
            if (value) {
                for (const product of value) {
                    let productkamli: PRODUCTIKamli;
                    productkamli = await this.setProductKamli(storeId, product, value, 1);
                    storeProductskamli.push(productkamli);
                }
                for (const product of storeProductskamli) {
                    let productkamli: PRODUCTIKamli;
                    productkamli = await this.setProductKamli(storeId, product, storeProductskamli, 2);
                    storeProductskamli2.push(productkamli);
                }
            } else {
                storeProductskamli2 = undefined;
            }
            this.storeProducts = storeProductskamli2;
            this.storeProductsO.next(storeProductskamli2);
        }*/

    public async setProducts(storeId, value: PRODUCTIKamli[]) {
        const storeProductskamli = [];
        let storeProductskamli2 = [];
        if (value) {
            const daytime = new Date().getTime();
            for (const product of value) {
                const productkamli = await this.setProductKamli(storeId, product, value, 1) as PRODUCTIKamli;
                if (productkamli.modifiedTS + 2 * dayInMilliseconds < daytime) {
                    productkamli.store = false;
                    productkamli.publish = false;
                }
                storeProductskamli.push(productkamli);
            }
            for (const product of storeProductskamli) {
                const productkamli = await this.setProductKamli(storeId, product, storeProductskamli, 2);
                storeProductskamli2.push(productkamli);
            }
        } else {
            storeProductskamli2 = undefined;
        }
        this.storeProducts = storeProductskamli2;
        this.storeProductsO.next(storeProductskamli2);
    }


    public async setProductKamli(storeId: string, product: PRODUCTI, products: PRODUCTI[], step: number) {
        let productkamli: PRODUCTIKamli;
        if (step === 1) {
            productkamli = product as PRODUCTIKamli;
            if (!product.categoryId) {
                product.categoryId = product.category;
            }
            const category = this.storeCategories && this.storeCategories.find(c => String(c.categoryId) === String(product.categoryId));
            if (category) {
                productkamli.fullcategory = category;
                productkamli.categoryName = category.title;
                productkamli.category = category.categoryId;

                // preparations
                let tempp;
                const preparations = [] as PREPARATIONI[];
                if (!this.storePreparation) {
                    tempp = await this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.preparation);
                    this.storePreparation = this.utilSvc.objectToArray(tempp);
                }

                if (this.storePreparation && productkamli.preparationI) {
                    for (const preparationId of productkamli.preparationI) {
                        const preparation = this.storePreparation && this.storePreparation.find(p => String(p.preparationId) === String(preparationId));
                        if (preparation) {
                            preparations.push(preparation);
                        }
                    }
                }
                const preparationsCategories = preparations.map(item => item.category).filter((value1, index, self) =>
                    self.indexOf(value1) === index);
                productkamli.preparation = [] as PRODUCTPreparationI[];
                for (const pc of preparationsCategories) {
                    const temp = preparations.filter(e => e.category === pc);
                    if (temp) {
                        const prep = {
                            type: pc,
                            value: temp
                        };
                        productkamli.preparation.push(prep);
                    }
                }

                // etras
                const extraoptions = [] as EXTRAOPTIONI[];
                if (!this.storeExtras) {
                    tempp = await this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.extras);
                    const storeExtras = this.utilSvc.objectToArray(tempp);
                }
                if (this.storeExtras && productkamli.extraOptionsI) {
                    for (const extraId of productkamli.extraOptionsI) {
                        const extra = this.storeExtras && this.storeExtras.find(p => String(p.extraOptionId) === String(extraId));
                        if (extra) {
                            extraoptions.push(extra);
                        }
                    }
                }
                const extraoptionsCategories = extraoptions.map(item => item.category).filter((value1, index, self) =>
                    self.indexOf(value1) === index);
                productkamli.extraOptions = [] as any;
                for (const ec of extraoptionsCategories) {
                    const temp = extraoptions.filter(e => e.category === ec);
                    if (temp) {
                        const extra = {
                            type: ec,
                            value: temp,
                        };
                        productkamli.extraOptions.push(extra);
                    }
                }

                // preparations composed products
                const preparationsMenu = [] as PREPARATIONI[];
                if (this.storePreparation && productkamli.preparationMenuI) {
                    for (const preparationId of productkamli.preparationMenuI) {
                        const preparation = this.storePreparation && this.storePreparation.find(p => String(p.preparationId) === String(preparationId));
                        if (preparation) {
                            preparationsMenu.push(preparation);
                        }
                    }
                }
                const preparationsMenuCategories = preparationsMenu.map(item => item.category).filter((value1, index, self) =>
                    self.indexOf(value1) === index);
                productkamli.preparationMenu = [] as any;
                for (const pc of preparationsMenuCategories) {
                    const temp = preparationsMenu.filter(e => e.category === pc);
                    const preparation = {
                        type: pc,
                        value: temp
                    };
                    productkamli.preparationMenu.push(preparation);
                }

                // extras composed products
                const extrassMenu = [] as EXTRAOPTIONI[];
                if (this.storeExtras && productkamli.extraOptionsMenuI) {
                    for (const extraId of productkamli.extraOptionsMenuI) {
                        const extra = this.storeExtras && this.storeExtras.find(e => String(e.extraOptionId) === String(extraId));
                        if (extra) {
                            extrassMenu.push(extra);
                        }
                    }
                }
                const extrasMenuCategories = extrassMenu.map(item => item.category).filter((value1, index, self) =>
                    self.indexOf(value1) === index);
                productkamli.extraOptionsMenu = [] as any;
                for (const ec of extrasMenuCategories) {
                    const temp = extrassMenu.filter(e => e.category === ec);
                    const extra = {
                        type: ec,
                        value: temp,
                    } as PRODUCTExtraoptionsI;
                    productkamli.extraOptionsMenu.push(extra);
                }
            }
        } else if (step === 2) {
            productkamli = product as PRODUCTIKamli;
            // composed products
            productkamli.childrenProducts = [] as any;
            if (productkamli.childrenProductsI) {
                for (const childProductI of productkamli.childrenProductsI) {
                    const childProduct = {
                        section: childProductI.section,
                        options: [] as PRODUCTIKamli[],
                        extraPrice: 0
                    };
                    let j = 0;
                    if (!childProductI.extraprices) {
                        childProductI.extraprices = [];
                    }
                    for (const option of childProductI.options) {
                        if (childProductI.extraprices[j]) {
                            childProductI.extraprices[j] = 0;
                        }
                        const cp = products && products.find(p => String(p.productId) === String(option)) as PRODUCTIKamli;
                        if (cp) {
                            cp.extraPrice = childProductI.extraprices[j] ? childProductI.extraprices[j] : 0;
                            childProduct.options.push(cp);
                        }
                    }
                    productkamli.childrenProducts.push(childProduct);
                    j++;
                }
            }
        }
        return (productkamli);
    }

    public subscribeAllergens(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.allergens].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setAllergens(temp);
                }
            );
        }
    }
    public unsubscribeAllergens(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.allergens,
                storeId
            );
            this.setAllergens(undefined);
        }
    }
    public getAllergens(): Observable<any[]> {
        return this.storeAllergensO.asObservable();
    }
    public setAllergens(value: any[]) {
        this.storeAllergens = value;
        this.storeAllergensO.next(value);
    }

    public subscribeCalories(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.calories].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setCalories(temp);
                }
            );
        }
    }
    public unsubscribeCalories(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT ||
            this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIWEB) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.calories,
                storeId
            );
            this.setCalories(undefined);
        }
    }
    public getCalories(): Observable<any[]> {
        return this.storeCaloriesO.asObservable();
    }
    public setCalories(value: any[]) {
        this.storeCalories = value;
        this.storeCaloriesO.next(value);
    }

    public getCurrentCorporate(): Observable<AdnCorporateI> {
        return this.currentCorporateO.asObservable();
    }
    public setCurrentCorporate(value: AdnCorporateI) {
        this.currentCorporate = value;
        this.currentCorporateO.next(value);
    }

    public getCurrentStores(): Observable<AdnStoreI[]> {
        return this.currentStoresO.asObservable();
    }
    public async setCurrentStores(adnCorporateId, value: AdnStoreIKamli[]) {
        this.allStores = value;
        if (this.allStores && this.allStores != null) {
            this.currentStores = this.allStores.filter(
                s => s.adnCorporateId === adnCorporateId
            ) as any;
            const assistedstoreidsString = 'assistedstoreids';
            if (this.adnBusinessUser && this.adnBusinessUser[assistedstoreidsString]) {
                for (const astoreId of this.adnBusinessUser[assistedstoreidsString]) {
                    const temp = this.currentStores && this.currentStores.find(s => String(s.adnStoreId) === String(astoreId));
                    if (!temp) {
                        const astore = this.allStores && this.allStores.find(s => String(s.adnStoreId) === String(astoreId)) as any;
                        if (astore) {
                            this.currentStores.push(astore);
                        }
                    }
                }
            }
        }
        this.currentStoresO.next(this.currentStores);
    }

    public exportObjects(objects, objectName) {
        const json = JSON.stringify(objects);
        const blob = new Blob([json], { type: 'application/json' });
        saveAs(blob, objectName + '.json');
    }

    public exportString(strings, objectName) {
        const blob = new Blob([strings], { type: 'application/json' });
        saveAs(blob, objectName + '.csv');
    }

    public subscribeDsSlides(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.DsSlide].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setDsSlides(temp);
                }
            );
        }
    }
    public unsubscribeDsSlides(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.DsSlide,
                storeId
            );
            this.setDsSlides(undefined);
        }
    }
    public getDsSlides(): Observable<DsSlide[]> {
        return this.storeDsSlidesO.asObservable();
    }
    public setDsSlides(value: DsSlide[]) {
        const invisibleString = 'invisible';
        if (value && value != null) {
            for (const slide of value) {
                slide.visible = slide.visible ? slide.visible : slide[invisibleString] ? !slide[invisibleString] : true;
            }
        }
        this.storeDsSlides = value;
        this.storeDsSlidesO.next(value);
    }

    public subscribeDsSchedules(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.DsSchedule].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setDsSchedules(temp);
                }
            );
        }
    }
    public unsubscribeDsSchedules(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.DsSchedule,
                storeId
            );
            this.setDsSchedules(undefined);
        }
    }
    public getDsSchedules(): Observable<DsSchedule[]> {
        return this.storeDsSchedulesO.asObservable();
    }
    public setDsSchedules(value: DsSchedule[]) {
        const invisibleString = 'invisible';
        if (value && value != null) {
            for (const schedule of value) {
                schedule.visible = schedule.visible ? schedule.visible :
                    schedule[invisibleString] ? !schedule[invisibleString] : true;
            }
        }
        this.storeDsSchedules = value;
        this.storeDsSchedulesO.next(value);
    }

    public subscribeDsPlaylists(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.DsPlaylist].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setDsPlaylists(temp);
                }
            );
        }
    }
    public unsubscribeDsPlaylists(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.DsPlaylist,
                storeId
            );
            this.setDsPlaylists(undefined);
        }
    }
    public getDsPlaylists(): Observable<DsPlaylist[]> {
        return this.storeDsPlaylistsO.asObservable();
    }
    public setDsPlaylists(value: DsPlaylist[]) {
        const invisibleString = 'invisible';
        if (value && value != null) {
            for (const playlist of value) {
                playlist.visible = playlist.visible ? playlist.visible :
                    playlist[invisibleString] ? !playlist[invisibleString] : true;
            }
        }
        this.storeDsPlaylists = value;
        this.storeDsPlaylistsO.next(value);
    }

    public subscribeStoreupdates(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLISTORE || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.storeupdates].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setStoreupdates(temp);
                }
            );
        }
    }
    public unsubscribeStoreupdates(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.storeupdates,
                storeId
            );
            this.setStoreupdates(undefined);
        }
    }
    public getStoreupdates(): Observable<StoreUpdates[]> {
        return this.adnStoreUpdatesO.asObservable();
    }
    public setStoreupdates(value: StoreUpdates[]) {
        this.adnStoreUpdates = value;
        this.adnStoreUpdatesO.next(value);
    }
    public createStoreupdates(storeId: string) {
        const timeofday = new Date().getTime();
        const u = {} as StoreUpdates;
        u.timestamp = timeofday;
        u.storeId = storeId;
        this.storeDbSvc.updateObject(this.utilSvc.adnStoreId, this.utilSvc.sdb[this.utilSvc.adnStoreId], OBJECTNAME.storeupdates, u, storeId);
    }

    public subscribeDsRss(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.DsRssKamli].subscribe(
                data => {
                    const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setDsRss(temp);
                }
            );
        }
    }
    public unsubscribeDsRss(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.DsRssKamli,
                storeId
            );
            this.setDsRss(undefined);
        }
    }
    public getDsRss(): Observable<RssFeedIKamli[]> {
        return this.adnDsRssKamliO.asObservable();
    }
    public setDsRss(value: RssFeedIKamli[]) {
        this.adnDsRss = value;
        this.adnDsRssKamliO.next(value);
    }

    subscribeAdnMedias(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.AdnMediaKamli].subscribe(
                async data => {
                    const temp = data ? this.utilSvc.objectToArray(data[0]) as KamliMedia[] : undefined;
                    this.setAllAdnMedias(temp);
                }
            );
        }
    }

    unsubscribeAdnMedias(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.AdnMediaKamli, storeId);
            this.setAllAdnMedias(undefined);
        }
    }

    public async filterAdnMedias(allAdnMedias: KamliMedia[]) {
        const dir = 'assets/adn/imagechannels/';
        const dateToday = new Date().getTime();
        const adnMedias = [] as KamliMedia[];
        const adnTodayMedias = [] as KamliMedia[];
        const startdateString = 'startdate';
        const durationString = 'duration';
        const subCategoryString = 'subCategory';
        if (allAdnMedias) {
            for (const image of allAdnMedias) {
                let imageTimeStart = this.utilSvc.stringToDate(image.startdate);
                if (imageTimeStart === 0) {
                    imageTimeStart = dateToday;
                }
                if (!image[durationString]) {
                    image[durationString] = 1;
                }
                const imageTimeEnd = this.utilSvc.stringToDate(image[startdateString]) +
                    Number(image[durationString]) * dayInMilliseconds;
                if (dateToday > imageTimeStart && dateToday < imageTimeEnd) {
                    adnTodayMedias.push(image);
                    if (image[subCategoryString] !== 'adnads') {
                        adnMedias.push(image);
                    }
                }
            }
            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                for (const image of adnMedias) {
                    image.localurlL2 = this.utilSvc.backendURL + image.localurlL;
                    image.localurlP2 = this.utilSvc.backendURL + image.localurlP;
                }
            }
            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH &&
                this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                for (const image of adnMedias) {
                    let result;
                    let url = image.thumbL;
                    try {
                        result = await this.utilSvc.downloadThumb2(
                            url, dir, image.localurlL, false).catch(e => {
                                this.logger.error('error with DsPImage =' + e);
                            });
                    } catch (e) {
                        this.logger.error('error with DsPImage =' + e);
                    }
                    if (result) {
                        image.localurlL = result.backendurl;
                        image.localurlL2 = this.utilSvc.backendURL + image.localurlL;
                        try {
                            image.localurlL2 = await this.utilSvc.getLocalUrl(image.localurlL, image.thumbL);
                        } catch (e) {
                            this.logger.error('error with getLocalUrl =' + e);
                        }
                    }
                    url = image.thumbP;
                    result = undefined;
                    try {
                        result = await this.utilSvc.downloadThumb2(url, dir, image.localurlP, false).catch(e => {
                            this.logger.error('error with DsPImage =' + e);
                        });
                    } catch (e) {
                        this.logger.error('error with DsPImage =' + e);
                    }
                    if (result) {
                        image.localurlP = result.backendurl;
                        image.localurlP2 = this.utilSvc.backendURL + image.localurlP;
                        try {
                            image.localurlP2 = await this.utilSvc.getLocalUrl(image.localurlP, image.thumbP);
                        } catch (e) {
                            this.logger.error('error with getLocalUrl =' + e);
                        }
                    }
                }
                const newImages = this.utilSvc.arrayToObject(adnMedias, 'assetId');
                try {
                    this.utilSvc.arrayToFile(newImages, dir, 'DsImage.json');
                } catch (e) {
                    console.log('error arrayToFile DsImage =', e);
                }
                this.setAdnMedias(adnMedias);
                if (adnTodayMedias && adnTodayMedias.length > 0) {
                    this.cleanAdnAssets(OBJECTNAME.AdnMediaKamli, adnTodayMedias);
                }
            } else {
                this.setAdnMedias(adnMedias);
            }
        } else {
            this.setAdnMedias(undefined);
        }
    }

    public getAllAdnMedias(): Observable<KamliMedia[]> {
        return this.allAdnMediasO.asObservable();
    }
    public async setAllAdnMedias(value: KamliMedia[]) {
        this.allAdnMedias = value;
        this.filterAdnMedias(this.allAdnMedias);
        this.allAdnMediasO.next(this.allAdnMedias);
    }

    public getAdnMedias(): Observable<KamliMedia[]> {
        return this.adnMediasO.asObservable();
    }
    public async setAdnMedias(value: KamliMedia[]) {
        this.adnMedias = value;
        this.adnMediasO.next(this.adnMedias);
    }

    subscribeThematicVideos(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.ThematicVideosKamli].subscribe(
                async data => {
                    const temp = data ? this.utilSvc.objectToArray(data[0]) as KamliMedia[] : undefined;
                    this.setAllThematicVideos(temp);
                }
            );
        }
    }

    unsubscribeThematicVideos(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.ThematicVideosKamli, storeId);
            this.setAllThematicVideos(undefined);
        }
    }

    filterThematicVideos(adnDsAllVideos: KamliMedia[]): Promise<KamliMedia[]> {
        return new Promise(async resolve => {
            const dateToday = new Date().getTime();
            const dir = 'assets/adn/videochannels/';
            const urlString = 'thumb';
            const backendurlString = 'backendurl';
            const startdateString = 'startdate';
            const durationString = 'duration';

            const storeVideos = [] as KamliMedia[];
            for (const video of adnDsAllVideos) {
                let videoTimeStart = this.utilSvc.stringToDate(video[startdateString]);
                if (videoTimeStart === 0) {
                    videoTimeStart = dateToday;
                }
                if (!video[durationString]) {
                    video[durationString] = 1;
                }
                const videoTimeEnd = this.utilSvc.stringToDate(video[startdateString]) +
                    Number(video[durationString]) * dayInMilliseconds;
                if (dateToday > videoTimeStart && dateToday < videoTimeEnd) {
                    storeVideos.push(video);
                }
            }
            for (const video of storeVideos) {
                if (video[urlString]) {
                    video.thumbL = video[urlString];
                    video.thumbP = video[urlString];
                }
                video.localurlL = video.localurlL ? video.localurlL : dir + this.utilSvc.parseMediaFile(video.thumbL);
                video.localurlP = video.localurlL;
                if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                    video.localurlL2 = this.utilSvc.backendURL + video.localurlL;
                    video.localurlP2 = this.utilSvc.backendURL + video.localurlP;
                }
            }
            this.setThematicVideos(storeVideos);
            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH &&
                this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                for (const video of storeVideos) {
                    let result;
                    try {
                        result = await this.utilSvc.downloadThumb2(
                            video.thumbL, dir, video.localurlL, false).catch(e => {
                                this.logger.error('error with ThematicVideos =' + e);
                            });
                    } catch (e) {
                        this.logger.error('error with ThematicVideos =' + e);
                    }
                    if (result) {
                        video.localurlL = result[backendurlString];
                        video.localurlL2 = this.utilSvc.backendURL + video.localurlL;
                        try {
                            video.localurlL2 = await this.utilSvc.getLocalUrl(video.localurlL,
                                this.utilSvc.backendURL + video[backendurlString]);
                            video.localurlP2 = video.localurlL2;
                        } catch (e) {
                            this.logger.error('error with getLocalUrl =' + e);
                        }
                    }
                }
            }
            this.cleanAdnAssets(OBJECTNAME.ThematicVideosKamli, storeVideos);
            this.setThematicVideos(storeVideos);
            resolve(storeVideos);
        });
    }

    public getAllThematicVideos(): Observable<KamliMedia[]> {
        return this.adnDsAllVideosO.asObservable();
    }
    public async setAllThematicVideos(value: KamliMedia[]) {
        const channelString = 'channel';
        this.adnDsAllVideos = value;
        if (this.adnDsAllVideos != null) {
            this.adnDsVideoChannels = this.adnDsAllVideos.map(item => item[channelString]).filter((value1, index, self) =>
                self.indexOf(value1) === index);
            this.filterThematicVideos(this.adnDsAllVideos);
            this.adnDsAllVideosO.next(this.adnDsAllVideos);
        }
    }

    public getThematicVideos(): Observable<KamliMedia[]> {
        return this.adnDsVideosO.asObservable();
    }
    public async setThematicVideos(value: KamliMedia[]) {
        this.adnDsVideos = value;
        this.adnDsVideosO.next(this.adnDsVideos);
    }

    public subscribeAdnAds(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            if (this.storeDbSvc.firebaseBSSdata[storeId]) {
                this.firebaseBSSdata[OBJECTNAME.AdnMediaKamli] =
                    this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.AdnMediaKamli].subscribe(data => {
                        const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : undefined;
                        this.setAdnAds(temp);
                    });
            }
        }
    }

    public unsubscribeAdnAds(_storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            if (this.firebaseBSSdata[OBJECTNAME.AdnMediaKamli]) {
                this.firebaseBSSdata[OBJECTNAME.AdnMediaKamli].unsubscribe();
                this.setAdnAds(undefined);
            }
        }
    }
    public getAdnAds(): Observable<any> {
        return this.publishingAdnOrdersO.asObservable();
    }

    public stringToDate(stringDate: string) {
        const regexDate = /([0-9]{2})([0-9]{2})([0-9]{4})/;
        const dateTemp1 = regexDate.exec(stringDate);
        if (dateTemp1 && dateTemp1 != null && dateTemp1[3]) {
            return new Date(dateTemp1[3] + '-' + dateTemp1[2] + '-' + dateTemp1[1]).getTime();
        } else {
            return 0;
        }
    }

    async setAdnAds(value: any) {
        const dateToday = new Date().getTime();
        const adnAdsArray = [];
        const adnTodayMedia = [];
        const backendurlString = 'backendurl';
        const dir = 'assets/adn/imagechannels/';
        if (value) {
            for (const image of value) {
                let imageTimeStart = this.stringToDate(image.startdate);
                if (imageTimeStart === 0) {
                    imageTimeStart = dateToday;
                }
                if (!image.duration) {
                    image.duration = 1;
                }
                const imageTimeEnd = this.stringToDate(image.startdate) + Number(image.duration) * dayInMilliseconds;
                if (dateToday > imageTimeStart && dateToday < imageTimeEnd) {
                    adnTodayMedia.push(image);
                    if (image.subCategory === 'adnads') {
                        adnAdsArray.push(image);
                    }
                }
            }
        }
        if (adnAdsArray && adnAdsArray.length > 0) {
            const adnFillerPubishOrders = [];
            for (const adnAds of adnAdsArray) {
                if (adnAds.subCategory.toLowerCase() === 'adnads') {
                    let adnFillerPubishOrder: PublishOrderKamli;
                    try {
                        adnFillerPubishOrder = await this.publishingRequestSvc.createFillerAd(this.adnStore, this.businessVertical, adnAds);
                    } catch (e) { }
                    //                    const asset = adnFillerPubishOrder.publishAssets[0];
                    if (adnFillerPubishOrder) {
                        adnFillerPubishOrders.push(adnFillerPubishOrder);
                    }
                }
            }
            this.setPublishingAdnOrders(adnFillerPubishOrders);
            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH &&
                this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                for (const publishingOrder of adnFillerPubishOrders) {
                    const asset = publishingOrder.publishAssets[0];
                    let result;
                    try {
                        result = await this.utilSvc.downloadThumb2(asset.thumbL, dir, asset.localurlL, false).catch(_e => {
                            console.log('error with pub asset thumb=', asset.thumbL);
                        });
                    } catch (e) {
                        console.log('error with pub asset thumb=', asset.thumbL);
                    }
                    if (result) {
                        asset.localurlL = result[backendurlString];
                        if (asset.localurlL) {
                            asset.localurlL2 = this.utilSvc.backendURL + asset.localurlL;
                        }
                        if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                            try {
                                asset.localurlL2 = await this.utilSvc.getLocalUrl(asset.localurlL, asset.thumbL);
                            } catch (e2) { }
                        }
                    }
                    result = undefined;
                    try {
                        result = await this.utilSvc.downloadThumb2(asset.thumbP, dir, asset.localurlP, false).catch(_e => {
                            console.log('error with pub asset thumb=', asset.thumbP);
                        });
                    } catch (e) {
                        console.log('error with pub asset thumb=', asset.thumbP);
                    }
                    if (result) {
                        asset.localurlP = result[backendurlString];
                        if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                            if (asset.localurlP) {
                                asset.localurlP2 = this.utilSvc.backendURL + asset.localurlP;
                            }
                            if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                try {
                                    asset.localurlP2 = await this.utilSvc.getLocalUrl(asset.localurlP, asset.thumbP);
                                } catch (e2) { }
                            }
                        }
                    }
                }
                this.setPublishingAdnOrders(adnFillerPubishOrders);
                this.cleanAdnAssets(OBJECTNAME.AdnMediaKamli, adnTodayMedia);
            }
        }
    }

    subscribeInventory(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.inventory].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setInventory(temp);
                },
                error => console.log(error)
            );
        }
    }

    unsubscribeInventory(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.mdb,
                OBJECTNAME.inventory
            );
            this.setInventory(undefined);
        }
    }

    public getInventory(): Observable<AdnInventory[]> {
        return this.allinventoryO.asObservable();
    }
    public setInventory(value: AdnInventory[]) {
        this.allinventory = value;
        this.allinventoryO.next(value);
    }

    subscribeUsers() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.adnUsers].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setUsers(temp);
                }
            );
        }
    }

    unsubscribeUsers() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.adnUsers);
            this.setUsers(undefined);
        }
    }

    public getUsers(): Observable<AdnUser[]> {
        return this.usersSvc.allUsersO.asObservable();
    }
    public setUsers(value: AdnUser[]) {
        this.usersSvc.allUsers = value;
        this.usersSvc.allUsersO.next(value);
    }


    subscribeleads(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.leads].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setleads(temp);
                }
            );
        }
    }

    unsubscribeleads(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.unsubscribeObject(storeId, this.utilSvc.mdb, OBJECTNAME.adnUsers);
            this.setleads(undefined);
        }
    }

    public getleads(): Observable<AdnLead[]> {
        return this.leadsO.asObservable();
    }
    public setleads(value: AdnLead[]) {
        this.leads = value;
        this.leadsO.next(value);
    }

    subscribeAssistants() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADS) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.firebaseBSSdata[beStoreId][OBJECTNAME.assistants].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setAssistants(temp);
                }
            );
        }
    }

    unsubscribeAssistants() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADS) {
            const beStoreId = this.utilSvc.backendFBstoreId;
            this.storeDbSvc.unsubscribeObject(beStoreId, this.utilSvc.mdb, OBJECTNAME.assistants);
            this.setAssistants(undefined);
        }
    }

    public getAssistants(): Observable<AdnAssistant[]> {
        return this.assistantsO.asObservable();
    }
    public setAssistants(value: AdnAssistant[]) {
        this.assistants = value;
        if (this.assistants) {
            for (let assistant of this.assistants) {
                const temp = this.allStores && this.allStores.find(s => s['user'] && s['user']['adnUserId'] === assistant.adnUserId);
                if (temp) {
                    assistant['store'] = temp;
                }
            }
        }
        this.assistantsO.next(value);
    }

    subscribeDocuments(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.documents].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setDocuments(temp);
                }
            );
        }
    }

    unsubscribeDocuments(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.unsubscribeObject(storeId, this.utilSvc.mdb, OBJECTNAME.documents);
            this.setDocuments(undefined);
        }
    }

    public getDocuments(): Observable<AdnDocument[]> {
        return this.documentsO.asObservable();
    }
    public setDocuments(value: AdnDocument[]) {
        this.documents = value;
        this.documentsO.next(value);
    }


    public subscribeBackdroundImages(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE) {
            this.storeDbSvc.firebaseBSSdata[storeId][
                OBJECTNAME.backgroundImages
            ].subscribe(data => {
                const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                this.setBackdroundImages(temp);
            });
        }
    }
    public unsubscribeBackdroundImages(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.backgroundImages,
                storeId
            );
            this.setBackdroundImages(undefined);
        }
    }
    public getBackdroundImages(): Observable<any[]> {
        return this.adnBackgroundImagesO.asObservable();
    }
    public setBackdroundImages(value: any[]) {
        this.adnBackgroundImages = value;
        this.adnBackgroundImagesO.next(value);
    }

    public subscribeDsSlideTypes(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.DsSlideTypes].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setDsSlideTypes(temp);
                }
            );
        }
    }
    public unsubscribeDsSlideTypes(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.DsRssKamli,
                storeId
            );
            this.setDsSlideTypes(undefined);
        }
    }
    public getDsSlideTypes(): Observable<any[]> {
        return this.adnDsSlideTypesO.asObservable();
    }
    public setDsSlideTypes(value: any[]) {
        this.adnDsSlideTypes = value;
        this.adnDsSlideTypesO.next(value);
    }

    public subscribePublishingProduct(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIADS) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.publishingProduct].subscribe(data => {
                const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                this.setPublishingProduct(temp);
            });
        }
    }
    public unsubscribePublishingProduct(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIADS) {
            this.storeDbSvc.unsubscribeObject(
                storeId,
                this.utilSvc.sdb[storeId],
                OBJECTNAME.publishingProduct,
                storeId
            );
            this.setPublishingProduct(undefined);
        }
    }
    public getPublishingProduct(): Observable<any[]> {
        return this.adnpublishingProductsO.asObservable();
    }
    public setPublishingProduct(value: any[]) {
        this.adnpublishingProducts = value;
        this.adnpublishingProductsO.next(value);
    }
    public subscribePImage(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLIADS ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const backendurlString = 'backendurl';
            const dir = 'assets/stores/' + storeId + '/DsPImage/';
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.DsPImage].subscribe(
                async data => {
                    const storeImages = data && data[0] ? this.utilSvc.objectToArray(data[0]) as KamliMedia[] : [];
                    const thumbString = 'thumb';
                    const localurlString = 'backendurl';
                    for (const image of storeImages) {
                        image.thumbL = image.thumbL ? image.thumbL : image[thumbString];
                        image.thumbP = image.thumbP ? image.thumbP : image[thumbString];
                        image.localurlL = image.localurlL ? image.localurlL : image[localurlString];
                        image.localurlP = image.localurlP ? image.localurlP : image[localurlString];
                        image.visible = image.visible ? image.visible : true;
                    }
                    for (const image of storeImages) {
                        let result;
                        try {
                            result = await this.utilSvc.downloadThumb2(image.thumbL, dir, image.localurlL, true).catch(_e => {
                                console.log('error with image thumb=', image.thumbL);
                            });
                        } catch (e) {
                            console.log('error with image thumb=', image.thumbL, ' localurl=', image.localurlL, ', error=', e);
                        }
                        if (result) {
                            image.localurlL = result[backendurlString];
                            image.localurlL2 = this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                    try {
                                        image.localurlL2 = await this.utilSvc.getLocalUrl(image.localurlL, image.thumbL);
                                    } catch (e2) { }
                                }
                            }
                            image.dir = result.dir ? result.dir : null;
                            image.filename = result.filename ? result.filename : null;
                            image.size = result.size ? result.size : null;
                        }
                        result = undefined;
                        try {
                            result = await this.utilSvc.downloadThumb2(image.thumbP, dir, image.localurlP, false).catch(_e => {
                                console.log('error with image thumb=', image.thumbP);
                            });
                        } catch (e) {
                            console.log('error with image thumb=', image.thumbP, ' localurl=', image.localurlP, ', error=', e);
                        }
                        if (result) {
                            image.localurlP = result[backendurlString];
                            image.localurlP2 = this.utilSvc.backendURL + result[backendurlString];
                            if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                                    image.localurlP2 = await this.utilSvc.getLocalUrl(image.localurlP, image.thumbP);
                                }
                            }
                        }
                    }
                    this.setPImages(storeImages);
                    this.cleanStoredAssets(storeId, OBJECTNAME.DsPImage);
                },
                error => console.log(error)
            );
        }
    }
    public unsubscribePImage(smbStoreId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLIADS ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                smbStoreId,
                this.utilSvc.sdb[smbStoreId],
                OBJECTNAME.DsPImage
            );
            this.setPImages(undefined);
        }
    }
    public getPImages(): Observable<KamliMedia[]> {
        return this.storeImagesO.asObservable();
    }
    public setPImages(value: KamliMedia[]) {
        this.storeImages = value;
        this.storeImagesO.next(value);
    }

    public subscribePVideo(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLIADS ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            const backendurlString = 'backendurl';
            const dir = 'assets/stores/' + storeId + '/DsPVideo/';
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.DsPVideo].subscribe(
                async data => {
                    const storeVideos = data && data[0] ? this.utilSvc.objectToArray(data[0]) as KamliMedia[] : [];
                    const thumbString = 'thumb';
                    const localurlString = 'backendurl';
                    for (const video of storeVideos) {
                        video.thumbL = video.thumbL ? video.thumbL : video[thumbString];
                        video.thumbP = video.thumbP ? video.thumbP : video[thumbString];
                        video.localurlL = video.localurlL ? video.localurlL : video[localurlString];
                        video.localurlP = video.localurlP ? video.localurlP : video[localurlString];
                        video.visible = video.visible ? video.visible : true;
                    }
                    this.setPVideos(storeVideos);
                    if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                        for (const video of storeVideos) {
                            let result;
                            try {
                                result = await this.utilSvc.downloadThumb2(video.thumbL, dir, video.localurlL, false).catch(_e => {
                                    console.log('error with video thumb=', video.thumbL);
                                });
                            } catch (e) {
                                console.log('error with video thumb=', video.thumbL);
                            }
                            if (result) {
                                video.localurlL = result[backendurlString];
                                video.localurlL2 = this.utilSvc.backendURL + result[backendurlString];
                                if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                                    try {
                                        video.localurlL2 = await this.utilSvc.getLocalUrl(video.localurlL, video.thumbL);
                                    } catch (e2) { }
                                }
                            }
                        }
                        this.setPVideos(storeVideos);
                        this.cleanStoredAssets(storeId, OBJECTNAME.DsPVideo);
                    }
                },
                error => console.log(error)
            );
        }
    }
    public unsubscribePVideo(smbStoreId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(
                smbStoreId,
                this.utilSvc.sdb[smbStoreId],
                OBJECTNAME.DsPVideo
            );
            this.setPVideos(undefined);
        }
    }
    public getPVideos(): Observable<KamliMedia[]> {
        return this.storeVideosO.asObservable();
    }
    public setPVideos(value: KamliMedia[]) {
        this.storeVideos = value;
        this.storeVideosO.next(value);
    }

    public subscribepublishingSmbOrders(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLISTORE ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH || this.utilSvc.appName === KamliApp.KAMLIADS) {
            const dir = 'assets/stores/' + storeId + '/backendPublishingNewOrders/';
            const backendurlString = 'backendurl';
            this.firebaseBSSdata[OBJECTNAME.publishingNewOrders] =
                this.storeDbSvc.firebaseBSSdata[this.utilSvc.backendFBstoreId][OBJECTNAME.publishingNewOrders].subscribe(async data => {
                    const temp1 = [] as PublishOrderKamli[];
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    if (temp) {
                        for (const pubOrder of temp) {
                            let pubOrderKamli: PublishOrderKamli;
                            try {
                                pubOrderKamli = await this.publishingRequestSvc.updatePublishOrder(pubOrder, this.smbStore);
                            } catch (e) { }
                            if (pubOrderKamli.storeAsset) {
                                temp1.push(pubOrderKamli);
                            }
                        }
                        const publishingSmbOrders = temp1;
                        this.setPublishingSmbOrders(publishingSmbOrders);
                        for (const publishingOrder of publishingSmbOrders) {
                            if (publishingOrder.storeAsset && publishingOrder.storeAsset.asset) {
                                const asset = publishingOrder.storeAsset.asset;
                                let result;
                                try {
                                    result = await this.utilSvc.downloadThumb2(asset.thumbL, dir, asset.localurlL, false).catch(_e => {
                                        console.log('error with pub asset thumb=', asset.thumbL);
                                    });
                                } catch (e) {
                                    console.log('error with pub asset thumb=', asset.thumbL);
                                }
                                if (result) {
                                    asset.localurlL = result[backendurlString];
                                    asset.localurlL2 = this.utilSvc.backendURL + result[backendurlString];
                                    if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                                        if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                                            try {
                                                asset.localurlL2 = await this.utilSvc.getLocalUrl(asset.localurlL, asset.thumbL, false);
                                            } catch (e2) { }
                                        }
                                    }
                                }
                                result = undefined;
                                try {
                                    result = await this.utilSvc.downloadThumb2(asset.thumbP, dir, asset.localurlP, false).catch(_e => {
                                        console.log('error with pub asset thumb=', asset.thumbP);
                                    });
                                } catch (e) {
                                    console.log('error with pub asset thumb=', asset.thumbP);
                                }
                                if (result) {
                                    asset.localurlP = result[backendurlString];
                                    asset.localurlP2 = this.utilSvc.backendURL + result[backendurlString];
                                    if (this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
                                        if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {

                                            asset.localurlP2 = await this.utilSvc.getLocalUrl(asset.localurlP, asset.thumbL);
                                        }
                                    }
                                }
                            }
                        }
                        this.setPublishingSmbOrders(publishingSmbOrders);
                        this.cleanStoredAssets(storeId, OBJECTNAME.publishingNewOrders);
                    } else {
                        this.setPublishingSmbOrders(undefined);
                    }
                });
        }
    }

    public unsubscribepublishingSmbOrders() {
        if (this.utilSvc.appName === KamliApp.KAMLIADMIN || this.utilSvc.appName === KamliApp.KAMLIADN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLISTORE ||
            this.utilSvc.appName === KamliApp.KAMLIPUBLISH || this.utilSvc.appName === KamliApp.KAMLIADS) {
            if (this.firebaseBSSdata[OBJECTNAME.publishingNewOrders]) {
                this.firebaseBSSdata[OBJECTNAME.publishingNewOrders].unsubscribe();
                this.setPublishingSmbOrders(undefined);
            }
        }
    }

    public getPublishingSmbOrders(): Observable<PublishOrderKamli[]> {
        return this.publishingSmbOrdersO.asObservable();
    }
    public async setPublishingSmbOrders(value: PublishOrderKamli[]) {
        this.publishingSmbOrders = value;
        this.publishingSmbOrdersO.next(value);
    }

    public getPublishingAdnOrders(): Observable<PublishOrderKamli[]> {
        return this.publishingAdnOrdersO.asObservable();
    }
    public async setPublishingAdnOrders(value: PublishOrderKamli[]) {
        this.publishingAdnOrders = value;
        this.publishingAdnOrdersO.next(value);
    }

    public getPublishingFreeOrders(): Observable<PublishOrderKamli[]> {
        return this.publishingFreeOrdersO.asObservable();
    }
    public async setPublishingFreeOrders(value: PublishOrderKamli[]) {
        this.publishingFreeOrders = [];
        if (value && value != null) {
            for (const fillerAd of value) {
                let publishingFreeOrder: PublishOrderKamli;
                try {
                    publishingFreeOrder = await this.publishingRequestSvc.createFillerAd(fillerAd.store, this.businessVertical, fillerAd);
                } catch (e) { }
                if (publishingFreeOrder) {
                    this.publishingFreeOrders.push(publishingFreeOrder);
                }
            }
            if (this.publishingFreeOrders.length > 0) {
                this.publishingFreeOrdersO.next(this.publishingFreeOrders);
            }
        }
    }

    filterPublishOrder(payingPRs: PublishOrderKamli[], imageVisible?: any) {
        const dateTime = new Date().getTime();
        return payingPRs.filter(po => {
            const startDatesLength = po.publishCampaign.startDates.length;
            if (po.publishCampaign.startDates[0][0] < dateTime &&
                po.publishCampaign.startDates[startDatesLength - 1][1] > dateTime) {
                let visible = true;
                if (imageVisible) {
                    const thumbRegEx = /([^.]+)/;
                    let thumb1;
                    if (po.storeAsset && po.storeAsset.asset) {
                        thumb1 = this.utilSvc.getFilename('', po.storeAsset.asset.localurlL);
                    }
                    if (thumb1) {
                        const thumb2 = thumbRegEx.exec(thumb1);
                        if (thumb2[1]) {
                            const thumb = thumb2[1];
                            visible = imageVisible[thumb] ? imageVisible[thumb] : true;
                        }
                    }
                }
                po.runningStatus = PUBSTARTSTATUS.STARTED;
                return visible;
            } else {
                return false;
            }
        });
    }

    public getpublishingPricing() {
        const adnpublishingProducts = this.adnpublishingProducts;
        return new Promise((resolve, _reject) => {
            if (this.smbStore && this.smbStore.kamliadsDetails && adnpublishingProducts) {
                if (this.smbStore.kamliadsDetails.publishingPricing) {
                    this.publishingPricing = this.utilSvc.objectToArray(this.smbStore.kamliadsDetails.publishingPricing);
                    for (const app of adnpublishingProducts) {
                        if (this.publishingPricing) {
                            const pricings = this.publishingPricing.filter(
                                pp => pp.category === app.category
                            );
                            if (pricings) {
                                if (pricings[0]) {
                                    app.available = pricings[0].available;
                                }
                                app.pricing = pricings;
                            }
                        }
                    }
                }
                resolve(adnpublishingProducts);
            } else {
                resolve(1);
            }
        });
    }

    subscribeStoreUserObjects(adnUserId) {
        if (adnUserId) {
            if (this.smbStore) {
                this.storeFbObjectsConnected.forEach(fo => {
                    this.storeDbSvc.initFBlistener(this.smbStore.adnStoreId, fo);
                    this.storeDbSvc.subscribeObject(
                        this.smbStore.adnStoreId,
                        this.utilSvc.sdb[this.smbStore.adnStoreId],
                        fo,
                        adnUserId
                    );
                });
                this.storeDbSvc.initFBlistener(this.smbStore.adnStoreId, OBJECTNAME.orders);
                this.storeDbSvc.subscribeObject(
                    this.smbStore.adnStoreId,
                    this.utilSvc.sdb[this.smbStore.adnStoreId],
                    OBJECTNAME.orders, adnUserId);
                this.subscribeCart(this.smbStore.adnStoreId);
                this.subscribePImage(this.smbStore.adnStoreId);
                this.subscribePVideo(this.smbStore.adnStoreId);
                this.subscribeOrders(this.smbStore.adnStoreId, adnUserId);
            }
            this.backendFbObjectsConnected.forEach(fo => {
                this.storeDbSvc.initFBlistener(this.utilSvc.backendFBstoreId, fo);
                this.storeDbSvc.subscribeObject(
                    this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                    fo,
                    adnUserId
                );
            });
            this.subscribePublishCampaigns();
            //            this.subscribePublishAssets();
            this.subscribePublishQuotes();
        }
    }

    unsubscribeStoreUserObjects(adnUserId) {
        if (this.smbStore) {
            this.unsubscribeCart(this.smbStore.adnStoreId);
            this.unsubscribeOrders(this.smbStore.adnStoreId);
            this.unsubscribePImage(this.smbStore.adnStoreId);
            this.unsubscribePVideo(this.smbStore.adnStoreId);
            this.storeFbObjectsConnected.forEach(fo => {
                this.storeDbSvc.unsubscribeObject(
                    this.smbStore.adnStoreId,
                    this.utilSvc.sdb[this.smbStore.adnStoreId],
                    fo,
                    adnUserId
                );
            });
        }
    }

    connectingUser(adnUserId, token: string) {
        return new Promise(resolve => {
            if (adnUserId) {
                let temp: AdnUser;
                this.usersSvc.getUser(adnUserId).then(data => {
                    if (data) {
                        temp = data as AdnUser;
                        if (!this.adnCustomerUser) {
                            this.adnCustomerUser = data as unknown as AdnUserKamli;
                            this.subscribeStoreUserObjects(this.adnCustomerUser.adnUserId);
                        } else if (this.adnCustomerUser.adnUserId !== temp.adnUserId) {
                            this.unsubscribeStoreUserObjects(this.adnCustomerUser.adnUserId);
                            this.adnCustomerUser = data as unknown as AdnUserKamli;
                            this.subscribeStoreUserObjects(this.adnCustomerUser.adnUserId);
                        }
                        if (this.adnCustomerUser) {
                            this.storeDbSvc.initFBlistener(this.utilSvc.backendFBstoreId, OBJECTNAME.adnUsers);
                            this.storeDbSvc.subscribeObject(
                                this.utilSvc.backendFBstoreId,
                                this.utilSvc.mdb,
                                OBJECTNAME.adnUsers,
                                adnUserId
                            );
                            this.subscribeUser(adnUserId);
                            this.setLoggedUser(this.adnCustomerUser, token);
                        } else {
                            this.setLoggedUser(undefined, undefined);
                        }
                        resolve(temp);
                    } else {
                        this.setLoggedUser(undefined, undefined);
                        resolve(undefined);
                    }
                });
            } else {
                this.setLoggedUser(undefined, undefined);
                resolve(undefined);
            }
        });
    }

    disconnectingUser(adnUserId) {
        if (adnUserId) {
            this.unsubscribeStoreUserObjects(this.adnCustomerUser?.adnUserId);
            this.unsubscribeUser(adnUserId);
            this.setLoggedUser(undefined, undefined);
            this.utilSvc.clearUid();
            this.usersSvc.logout();
        }
    }

    public loginUser(identif: string, password: string, adnUserId: string, token: string) {
        const uidString = 'uid';
        let uid;
        return new Promise(async (resolve, reject) => {
            let temp;
            if (!adnUserId) {
                if (identif && password) {
                    try {
                        temp = await this.usersSvc.authUserU(identif, password, true);
                    } catch (error) {
                        this.errorMessage = {
                            title: 'Authentication error',
                            description:
                                'The password is invalid or the user does not have a kamli account',
                            details: error[1]
                        };
                        this.setLoggedUser(undefined, undefined);
                        reject(error);
                    }
                    if (temp && temp[1]) {
                        uid = temp[1][uidString];
                    }
                } else {
                    this.setLoggedUser(undefined, undefined);
                    reject([-100, 'missing id and/or password']);
                }
            } else {
                uid = adnUserId;
            }
            if (uid) {
                this.usersSvc.getUser(uid).then(
                    async userDetails => {
                        this.adnCustomerUser = userDetails as unknown as AdnUserKamli;
                        try {
                            await this.connectingUser(this.adnCustomerUser.adnUserId, token);
                        } catch (e) { }
                        resolve(this.adnCustomerUser);
                    },
                    error => {
                        this.errorMessage = {
                            title: 'error getting user information',
                            description: 'please try again later',
                            details: error[1]
                        };
                        reject([-100, error]);
                    }
                );
            } else {
                this.errorMessage = {
                    title: 'Authentication error',
                    description:
                        'The password is invalid or the user does not have a kamli account',
                    details: ''
                };
                this.setLoggedUser(undefined, undefined);
                reject([-100, 'The password is invalid or the user does not have a kamli account']);
            }
        });
    }

    public loginAdmin1(identif: string, password: string, adnUserId: string, storeId1: string, token: string) {
        return new Promise(async (resolvef, rejectf) => {
            let adnUser1: AdnUserKamli;
            let corporate1: AdnCorporateI;
            let store1: AdnStoreI;
            let errorString = '';
            if (identif && password) {
                let temp;
                try {
                    temp = await this.usersSvc.authUserU(identif, password).catch(_e => { });
                } catch (e) { }
                if (temp) {
                    const temp2 = temp;
                    const dataAuth = temp2[1];
                    const uidString = 'uid';
                    adnUserId = dataAuth[uidString];
                    try {
                        corporate1 = await this.smbSvc.getCorporateByUser(this.utilSvc.backendFBstoreId,
                            adnUserId).catch(_e => { }).catch(_e => { }) as AdnCorporateI;
                    } catch (e) { }
                    if (corporate1 && corporate1.storeList) {
                        storeId1 = corporate1.storeList[0];
                    } else {
                        errorString = 'Authentication error. user is not a corporate client 1/2.';
                        rejectf(errorString);
                    }
                } else {
                    errorString = 'Authentication error. please check your login user and password';
                    rejectf(errorString);
                }
            }

            if (storeId1 && adnUserId) {
                try {
                    adnUser1 = await this.usersSvc.getUser(adnUserId).catch(_e => { }) as unknown as AdnUserKamli;
                } catch (e) { }
                if (adnUser1) {
                    this.adnBusinessUser = adnUser1 as unknown as AdnUserKamli;
                    try {
                        store1 = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId1).catch(_e => { }) as AdnStoreI;
                    } catch (e) { }
                } else {
                    errorString = 'Authentication error. user is not a corporate client 2/2';
                    rejectf(errorString);
                }
            } else {
                errorString = 'Authentication error. user unknown on the kamli network';
                rejectf(errorString);
            }
            if (adnUser1 && store1) {
                corporate1 = await this.smbSvc.getCorporate(this.utilSvc.backendFBstoreId,
                    store1.adnCorporateId).catch(_e => { }) as AdnCorporateI;
            } else {
                errorString = 'Authentication error. store unknown on the kamli network';
                rejectf(errorString);
            }
            if (this.utilSvc.appName !== KamliApp.KAMLIADN && (corporate1 && corporate1.adnUserId === adnUserId ||
                    (adnUser1.type === KamliUser.ADMIN || adnUser1.type === KamliUser.ASSISTANT)) ||
                    (this.utilSvc.appName === KamliApp.KAMLIADN && (adnUser1.type === KamliUser.ADMIN || adnUser1.type === KamliUser.ASSISTANT))){
                this.spinner.show();
                this.currentCorporate = corporate1;
                this.setCurrentStores(this.currentCorporate.adnCorporateId, this.allStores);
                this.initStoreService(storeId1).then(
                    _data100 => {
                        this.setLoggedUser(adnUser1, token);
                        this.setCurrentCorporate(corporate1);
                        resolvef(1);
                        this.spinner.hide();
                    },
                    error100 => {
                        this.spinner.hide();
                        console.log('store loading error =', error100);
                        rejectf(error100);
                    });
            } else {
                errorString = 'Authentication error. this user cannot have access to this store account';
                rejectf(errorString);
            }
        });
    }

    public playAudio() {
        if (this.playNewOrderAudio) {
            const audio = new Audio();
            audio.src = '/assets/img/DoorBell.mp3';
            audio.load();
            audio.play();
        } else {
            this.playNewOrderAudio = true;
        }
    }

    public readInitialUrl(initialUrl) {
        const regexwindow = /(http:\/\/|https:\/\/)[^\/]+\/(.+)/;
        if (initialUrl && initialUrl.href) {
            const temp = initialUrl.href.match(regexwindow);
            if (temp && temp[2]) {
                return temp[2]
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public pollPlayer(url: string, queryParams?: any) {
        return new Promise((resolve, reject) => {
            let params1;
            if (queryParams) {
                for (const key in queryParams) {
                    if (queryParams[key]) {
                        if (!params1) {
                            params1 = new HttpParams();
                        }
                        params1 = params1.set(key, queryParams[key]);
                    }
                }
            }
            if (params1) {
                // tslint:disable-next-line: deprecation
                this.http.get(this.utilSvc.backendURL + url, { params: params1, responseType: 'json' }).subscribe(
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
            } else {
                // tslint:disable-next-line: deprecation
                this.http.get(this.utilSvc.backendURL + url, { responseType: 'json' }).subscribe(
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    }

    public reboot(kameleon: AdnkameleonBox) {
        return new Promise((resolve, reject) => {
            const url = 'utils/sendWebMessage/';
            let params1;
            const queryParams = {
                msgContent: 'reboot',
                messageSourceType: KamliApp.KAMLIPLAYER,
                messageSourceId: kameleon.kameleonId
            };
            for (const key in queryParams) {
                if (queryParams[key]) {
                    if (!params1) {
                        params1 = new HttpParams();
                    }
                    params1 = params1.set(key, queryParams[key]);
                }
            }
            if (params1) {
                // tslint:disable-next-line: deprecation
                this.http.get(this.utilSvc.backendURL + url, { params: params1, responseType: 'json' }).subscribe(
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    }

    public subscribePollPlayer(appName) {
        if (!this.pollInterval) {
            this.pollInterval = setInterval(async () => {
                const timeNow = new Date().getTime();
                let result;
                const url = 'utils/sendWebMessage/';
                const queryParams = {
                    messageSourceType: appName
                };
                this.pollPlayer(url, queryParams).then(
                    data => {
                        let temps;
                        if (Array.isArray(data)) {
                            temps = data as any[];
                        } else {
                            temps = [];
                        }
                        const polledPlayers = {};
                        const polledDataRef = {
                            timestamp: 0,
                            deviceType: '',
                            deviceModel: '',
                            deviceUuid: '',
                            platformVersion: '',
                            lat: 0,
                            lng: 0,
                            storeId: '',
                            action: '',
                            route: '',
                            playlistId: 0,
                            slideId: 0,
                        };
                        for (const temp of temps) {
                            if (temp.client && temp.message) {
                                let i = 0;
                                let tempR = true;
                                let tempSplit;
                                try {
                                    tempSplit = temp.message.split('~');
                                } catch (e) {
                                    tempR = false;
                                }
                                if (tempR) {
                                    const polledData = Object.assign({}, polledDataRef);
                                    for (const key1 of Object.keys(polledData)) {
                                        if (tempSplit[i]) {
                                            polledData[key1] = tempSplit[i];
                                        }
                                        i++;
                                    }
                                    polledPlayers[temp.client] = polledData;
                                }
                            }
                        }
                        this.setPlayerPollResults(polledPlayers);
                    },
                    _error => {
                        result = undefined;
                    }
                );
            }, 10000);
        }
    }

    public getPlayerPollResults(): Observable<any> {
        return this.polledPlayersO.asObservable();
    }
    public setPlayerPollResults(value: any) {
        this.polledPlayers = value;
        this.polledPlayersO.next(value);
    }

    public subscribeUser(_adnUserId: string) {
        if (this.firebaseBSSdata[OBJECTNAME.adnUsers]) {
            this.firebaseBSSdata[OBJECTNAME.adnUsers].unsubscribe();
        }
        this.firebaseBSSdata[OBJECTNAME.adnUsers] =
            this.storeDbSvc.firebaseBSSdata[this.utilSvc.backendFBstoreId][OBJECTNAME.adnUsers].subscribe(
                data => {
                    const temp = data ? data[0] : undefined;
                    this.setLoggedUser(temp, undefined);
                },
                error => console.log(error)
            ) as Subscription;
    }

    public unsubscribeUser(adnUserId: string) {
        this.storeDbSvc.unsubscribeObject(
            this.utilSvc.backendFBstoreId,
            this.utilSvc.mdb,
            OBJECTNAME.adnUsers,
            adnUserId
        );
        if (this.firebaseBSSdata[OBJECTNAME.adnUsers]) {
            this.firebaseBSSdata[OBJECTNAME.adnUsers].unsubscribe();
        }
    }


    public getLoggedUser(): Observable<AdnUserKamli> {
        return this.adnCustomerUserO.asObservable();
    }
    public async setLoggedUser(value: AdnUserKamli, token: string) {
        if (value) {
            this.utilSvc.setUid(value.adnUserId);
            try {
                this.adnCustomerUser = await this.orderSvc.updateUser(value, token);
            } catch (e) { }
            this.adnCustomerUserO.next(this.adnCustomerUser);
        } else {
            this.utilSvc.clearUid();
            if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                if (this.utilSvc.fcm && this.adnCustomerUser && this.adnCustomerUser.referrals) {
                    for (const r of this.adnCustomerUser.referrals) {
                        if (this.utilSvc.appName === KamliApp.KAMLISTORE) {
                            this.utilSvc.fcm.unsubscribe(r.referralId + '-promos');
                        }
                        if (this.utilSvc.appName === KamliApp.KAMLIADMIN) {
                            this.utilSvc.fcm.unsubscribe(r.referralId + '-orders');
                        }
                    }
                }
            }
            this.adnCustomerUser = undefined;
            this.adnCustomerUserO.next(undefined);
        }
    }

    /*    public async updateUser(user: AdnUserKamli, token: string): Promise<AdnUserKamli> {
            return new Promise(async (resolve, reject) => {
                const proceeds = [];
                // register token for private notifications
                if (token && token !== user?.token) {
                    user.token = token;
                    await this.usersSvc.updateUser(user);
                }
    
                // subscribe to referrals promotiona
                if (user && user.referrals && this.utilSvc.fcm && this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                    for (const r of user.referrals) {
                        if (this.utilSvc.appName === KamliApp.KAMLISTORE) {
                            this.utilSvc.fcm.subscribe(r.referralId + '-promos');
                        }
                        if (this.utilSvc.appName === KamliApp.KAMLIADMIN) {
                            this.utilSvc.fcm.subscribe(r.referralId + '-orders');
                        }
                    }
                }
    
                if (user.storevisited) {
                    user.userorders = [] as AdnUserOrders[];
                    user.usercarts = [] as AdnUserCart[];
                    for (const storeId of user.storevisited) {
                        let store;
                        let proceed = true;
                        try {
                            store = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId);
                        } catch (e) {
                        }
                        if (store) {
                            let tempUserOrders: AdnUserOrders;
                            tempUserOrders = user.userorders && user.userorders.find(o => o.store ? o.store.adnStoreId === storeId : false) as AdnUserOrders;
                            if (!tempUserOrders) {
                                tempUserOrders = {} as AdnUserOrders;
                                tempUserOrders.store = store;
                                tempUserOrders.value = [] as OrderI[];
                            }
                            let tempp;
                            try {
                                tempp = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.orders);
                            } catch (e) {
                            }
                            if (tempp) {
                                proceed = false;
                                const temp2 = this.utilSvc.objectToArray(tempp) as OrderI[];
                                const temp3 = temp2 && temp2.filter(o => o.adnCustomerUserId === user.adnUserId) as OrderI[];
                                if (temp3) {
                                    if (!tempUserOrders.value) {
                                        tempUserOrders.value = [];
                                    }
                                    tempUserOrders.value = tempUserOrders.value.concat(temp3);
                                }
                            }
    
                            let tempUserCart: AdnUserCart;
                            tempUserCart = user.usercarts && user.usercarts.find(o => o.store ? o.store.adnStoreId === storeId : false) as AdnUserCart;
                            if (!tempUserCart) {
                                tempUserCart = {} as AdnUserCart;
                                tempUserCart.store = store;
                                tempUserCart.value = {} as CART;
                            } else if (user.usercarts) {
                                const tempi = user.usercarts.findIndex(o => o.store.adnStoreId === storeId);
                                if (tempi !== -1) {
                                    user.usercarts.splice(tempi, 1);
                                }
                            }
    
                            let tempc;
                            try {
                                tempc = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.cart);
                            } catch (e) {
                            }
                            if (tempc) {
                                proceed = false;
                                const temp21 = tempc[user.adnUserId];
                                tempUserCart.value = temp21;
                            }
                            if (tempUserCart && tempUserCart.value && tempUserCart.value.cartId) {
                                user.usercarts.push(tempUserCart);
                            }
                            if (tempUserOrders && tempUserOrders.value && tempUserOrders.value.length > 0) {
                                user.userorders.push(tempUserOrders);
                            }
                        }
                        if (proceed) {
                            proceeds.push(storeId);
                        }
                    }
                    for (const sid of proceeds) {
                        const t = user.storevisited.findIndex(sv => sv === sid);
                        if (t !== -1) {
                            user.storevisited.splice(t, 1);
                        }
                    }
                }
                resolve(user);
            });
        }*/

    public getLanguage(): Observable<string> {
        return this.languageO.asObservable();
    }
    public setLanguage(lang: string) {
        localStorage.setItem('language', lang);
        this.utilSvc.language = lang;
        if (lang != null) {
            this.languageO.next(lang);
        }
    }

    public checkValueObject(objectInput, parameterTitle, parameterValue) {
        let found = false;
        for (const key in objectInput) {
            if (objectInput[key]) {
                const valueInput = objectInput[key];
                if (valueInput[parameterTitle] && valueInput[parameterTitle] === parameterValue) {
                    found = true;
                    break;
                }
            }
        }
        return found;
    }

    registerScript(loaded: () => void, url, name): void {
        this.scriptLoadingSvc.registerScript(url, name, loaded);
    }

    goToAnchorLink(anchorLink: string) {
        const x = document.querySelector('#' + anchorLink);
        if (x) {
            x.scrollIntoView();
        }
    }

    subscribeAccessories(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.accessories].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setAccessories(temp);
                }
            );
        }
    }
    unsubscribeAccessories(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
            this.storeDbSvc.unsubscribeObject(storeId, this.utilSvc.sdb[storeId],
                OBJECTNAME.accessories, storeId);
            this.setAccessories(undefined);
        }
    }
    public getAccessories(): Observable<any> {
        return this.accessoriesO.asObservable();
    }
    public setAccessories(value: any) {
        this.accessories = value;
        this.accessoriesO.next(value);
    }

    subscribeTutoslides(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLINET) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.Tutoslides].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setTutosSlides(temp);
                }
            );
        }
    }
    unsubscribeTutoslides(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLINET) {
            this.storeDbSvc.unsubscribeObject(storeId, this.utilSvc.sdb[storeId],
                OBJECTNAME.Tutoslides, storeId);
            this.setTutosSlides(undefined);
        }
    }


    public getTutosSlides(): Observable<any> {
        return this.tutoslidesO.asObservable();
    }
    public setTutosSlides(value: any) {
        this.tutoslides = value;
        this.tutoslidesO.next(value);
    }

    subscribeTutos(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLINET) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.Tutos].subscribe(
                data => {
                    const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                    this.setTutos(temp);
                }
            );
        }
    }
    unsubscribeTutos(storeId) {
        if (this.utilSvc.appName === KamliApp.KAMLINET) {
            this.storeDbSvc.unsubscribeObject(storeId,
                this.utilSvc.sdb[storeId], OBJECTNAME.Tutos, storeId);
        }
    }
    public getTutos(): Observable<any> {
        return this.tutosO.asObservable();
    }
    public setTutos(value: any) {
        this.tutos = value;
        this.tutosO.next(value);
        this.tutoCategories = this.tutos.map(item => item.application + '/' + item.category)
            .filter((value1, index, self) => self.indexOf(value1) === index);
    }

    updateCategoryStore(currentStores: AdnStoreI[], templateCatalogue: any[]) {
        if (currentStores && templateCatalogue) {
            for (const store of currentStores) {
                const categoryId = store.categoryId;
                if (templateCatalogue) {
                    const tempV = templateCatalogue && templateCatalogue.find(tc => String(tc.categoryId) === String(categoryId));
                    if (tempV) {
                        const verticalString = 'vertical';
                        const typeString = 'type';
                        store[verticalString] = tempV.vertical;
                        store[typeString] = tempV.type;
                    }
                }
            }
            return currentStores;
        }
    }

    subscribePublishCampaigns() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH ||
            this.utilSvc.appName === KamliApp.KAMLIADS) {
            this.firebaseBSSdata[OBJECTNAME.publishingCampaigns] =
                this.storeDbSvc.firebaseBSSdata[this.utilSvc.backendFBstoreId][OBJECTNAME.publishingCampaigns].subscribe(
                    (data) => {
                        const temp = data[0];
                        let temp1 = [];
                        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                            for (const index in temp) {
                                if (temp[index]) {
                                    temp1 = temp1.concat(this.utilSvc.objectToArray(temp[index]));
                                }
                            }
                        } else {
                            temp1 = this.utilSvc.objectToArray(temp);
                        }
                        if (temp1 != null) {
                            this.setPublishCampaigns(temp1);
                        }
                    },
                    (error) => console.log(error)
                );
        }
    }

    unsubscribePublishCampaigns() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH ||
            this.utilSvc.appName === KamliApp.KAMLIADS) {
            if (this.firebaseBSSdata[OBJECTNAME.publishingCampaigns]) {
                this.firebaseBSSdata[OBJECTNAME.publishingCampaigns].unsubscribe();
                this.setPublishCampaigns(undefined);
            }
        }
    }

    updatePublishCampaign(publishCampaign: PublishCampaign): Promise<PublishCampaignKamli> {
        return new Promise(async (resolve, _reject) => {
            const publishCampaignKamli: PublishCampaignKamli = publishCampaign as PublishCampaignKamli;

            let adnUser: AdnUser;
            let adnBusiness: AdnUser;
            try {
                adnUser = await this.usersSvc.getUser(publishCampaignKamli.adnCustomerUserId) as AdnUser;
            } catch (e) { }
            publishCampaignKamli.adnUser = adnUser;

            if (publishCampaignKamli.adnBusinessUserId) {
                try {
                    adnBusiness = await this.usersSvc.getUser(publishCampaignKamli.adnBusinessUserId) as AdnUser;
            } catch (e) { }
        }
            publishCampaignKamli.adnBusiness = adnBusiness;

            let adnAssistant: AdnAssistant;
            if (publishCampaignKamli.adnBusinessUserId) {
            try {
                adnAssistant = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                    this.utilSvc.mdb, OBJECTNAME.assistants, publishCampaignKamli.adnBusinessUserId) as AdnAssistant;
            } catch (e) { }
        }
            publishCampaignKamli.assistant = adnAssistant;

            const spotsDetails = [] as AdnStoreI[];
            const publishOrders = [] as PublishOrder[];
            for (const spotid of publishCampaignKamli.spots) {
                let store: AdnStoreI;
                try {
                    store = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                        this.utilSvc.mdb, OBJECTNAME.adnStores, spotid) as AdnStoreI;
                } catch (e) { }
                if (store) {
                    if (store.kamliadsDetails && store.kamliadsDetails.publishingPricing && store.kamliadsDetails.publishingPricing['10']) {
                        store.kamliadsDetails['unitpricing'] = store.kamliadsDetails.publishingPricing['10'].pricing;
                    }
                    spotsDetails.push(store);
                }
                let publishOrder: PublishOrder;
                try {
                    publishOrder = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                        this.utilSvc.mdb, OBJECTNAME.publishingNewOrders, spotid + '/' +
                    publishCampaignKamli.campaignName) as PublishOrder;
                } catch (e) { }
                if (publishOrder) {
                    publishOrders.push(publishOrder);
                }
            }
            publishCampaignKamli.spotsDetails = spotsDetails;
            publishCampaignKamli.publishOrders = publishOrders;

            if (publishCampaignKamli && publishCampaignKamli.storeAsset) {
                let asset;
                let mediaType = publishCampaignKamli.storeAsset.fbObjectType;
                try {
                    asset = await this.storeDbSvc.getStoreObject(publishCampaignKamli.storeAsset.storeId,
                        mediaType, publishCampaignKamli.storeAsset.assetId);
                } catch (e) { }
                if (asset) {
                    publishCampaignKamli.storeAsset.asset = asset;
                }

            }
            const todaytime = new Date().getTime();
            const startDatesLength = publishCampaignKamli.startDates ? publishCampaignKamli.startDates.length : 0;
            if (publishCampaignKamli.startDates[0][0] > todaytime) {
                publishCampaignKamli.runningStatus = PUBSTARTSTATUS.NOTSTARTED;
            }
            if (publishCampaignKamli.startDates[0][0] < todaytime && publishCampaignKamli.startDates[startDatesLength - 1][1] > todaytime) {
                publishCampaignKamli.runningStatus = PUBSTARTSTATUS.STARTED;
            }
            if (publishCampaignKamli.startDates[startDatesLength - 1][1] < todaytime) {
                publishCampaignKamli.runningStatus = PUBSTARTSTATUS.PASSED;
            }
            resolve(publishCampaignKamli);
        });
    }

    getPublishCampaigns(): Observable<PublishCampaignKamli[]> {
        return this.storePubCampaignsO.asObservable();
    }
    async setPublishCampaigns(value: PublishCampaignKamli[]) {
        if (value != null && value) {
            this.storePubCampaigns = [];
            for (const campaign of value) {
                let campaignKamli: PublishCampaignKamli;
                try {
                    campaignKamli = await this.updatePublishCampaign(campaign);
                } catch (e) { }
                if (campaignKamli) {
                    this.storePubCampaigns.push(campaignKamli);
                }
            }
            this.storePubCampaignsO.next(this.storePubCampaigns);
        } else {
            this.storePubCampaigns = undefined;
        }
    }

    /*    subscribePublishAssets() {
            if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
                this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH ||
                this.utilSvc.appName === KamliApp.KAMLIADS) {
                this.firebaseBSSdata[OBJECTNAME.publishingNewAssets] =
                    this.storeDbSvc.firebaseBSSdata[this.utilSvc.backendFBstoreId][OBJECTNAME.publishingNewAssets].subscribe(
                        (data) => {
                            const temp = data[0];
                            let temp1 = [];
                            if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                                for (const index in temp) {
                                    if (temp[index]) {
                                        temp1 = temp1.concat(this.utilSvc.objectToArray(temp[index]));
                                    }
                                }
                            } else {
                                temp1 = this.utilSvc.objectToArray(temp);
                            }
                            if (temp1 != null) {
                                this.setPublishAssets(temp1);
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
            }
        }
     
        unsubscribePublishAssets() {
            if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
                this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH ||
                this.utilSvc.appName === KamliApp.KAMLIADS) {
                if (this.firebaseBSSdata[OBJECTNAME.publishingNewAssets] ) {
                    this.firebaseBSSdata[OBJECTNAME.publishingNewAssets].unsubscribe();
                    this.setPublishAssets(undefined);
                }
            }
        }
     
        getPublishAssets(): Observable<PublishAssetKamli[]> {
            return this.storePubAssetsO.asObservable();
        }
     
        async setPublishAssets(value: PublishAssetKamli[]) {
            if (value ) {
                this.storePubAssets = [];
                for (const asset of value) {
                    let assetFull;
                    try {
                        assetFull = await this.updatePublishAsset(asset);
                    } catch (e) { }
                    if (assetFull) {
                        this.storePubAssets.push(assetFull);
                    }
                }
            } else {
                this.storePubAssets = undefined;
            }
            this.storePubAssetsO.next(this.storePubAssets);
        }
     
        updatePublishAsset(publishAsset: PublishAsset): Promise<PublishAssetKamli> {
            return new Promise(async (resolve, _reject) => {
                const publishAssetKamli: PublishAssetKamli = publishAsset as PublishAssetKamli;
     
                let adnUser: AdnUser;
                try {
                    adnUser = await this.usersSvc.getUser(publishAssetKamli.adnCustomerUserId) as AdnUser;
                } catch (e) { }
                publishAssetKamli.adnUser = adnUser;
     
                let campaignsj;
                let temp = [] as PublishCampaign[];
                let temp1 = [];
                if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                    try {
                        campaignsj = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                            this.utilSvc.mdb, OBJECTNAME.publishingCampaigns);
                    } catch (e) { }
                    for (const index in campaignsj) {
                        if (campaignsj[index]) {
                            temp = temp.concat(this.utilSvc.objectToArray(campaignsj[index]));
                        }
                    }
                    temp1 = temp.filter(c => c.pubAssetIds && c.pubAssetIds.find(id => String(id) === String(publishAsset.pubAssetId)));
                } else {
                    try {
                        campaignsj = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                            this.utilSvc.mdb, OBJECTNAME.publishingCampaigns, publishAssetKamli.adnCustomerUserId);
                    } catch (e) { }
                    temp = this.utilSvc.objectToArray(campaignsj) as PublishCampaign[];
                    temp1 = temp.filter(c => c.pubAssetIds && c.pubAssetIds.find(id => String(id) === String(publishAsset.pubAssetId)));
                }
                if (temp1 && temp1 != null) {
                    publishAssetKamli.publishCampaigns = temp1;
                }
     
                const spotIds = [];
                const spotsDetails = [] as AdnStoreI[];
                for (const campaign of publishAssetKamli.publishCampaigns) {
                    for (const spotid of campaign.spots) {
                        if (!spotIds.includes(spotid)) {
                            spotIds.push(spotid);
                        }
                    }
                }
                for (const spotid of spotIds) {
                    let store;
                    try {
                        store = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                            this.utilSvc.mdb, OBJECTNAME.adnStores, spotid);
                    } catch (e) { }
                    if (store) {
                        spotsDetails.push(store);
                    }
                }
                publishAssetKamli.spots = spotsDetails;
                resolve(publishAssetKamli);
            });
        }*/

    subscribePublishQuotes() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADS) {
            this.firebaseBSSdata[OBJECTNAME.publishingQuotes] =
                this.storeDbSvc.firebaseBSSdata[this.utilSvc.backendFBstoreId][OBJECTNAME.publishingQuotes].subscribe(
                    (data) => {
                        const temp = data[0];
                        let temp1 = [];
                        if (this.utilSvc.appName === KamliApp.KAMLIADN) {
                            for (const index in temp) {
                                if (temp[index]) {
                                    temp1 = temp1.concat(this.utilSvc.objectToArray(temp[index]));
                                }
                            }
                        } else {
                            temp1 = this.utilSvc.objectToArray(temp);
                        }
                        if (temp1 != null) {
                            this.setPublishQuotes(temp1);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
        }
    }

    unsubscribePublishQuotes() {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADS) {
            if (this.firebaseBSSdata[OBJECTNAME.publishingQuotes]) {
                this.firebaseBSSdata[OBJECTNAME.publishingQuotes].unsubscribe();
                this.setPublishQuotes(undefined);
            }
        }
    }

    getPublishQuotes(): Observable<PublishQuoteKamli[]> {
        return this.storePubQuotesO.asObservable();
    }

    async setPublishQuotes(value: PublishQuoteKamli[]) {
        if (value) {
            this.storePubQuotes = [];
            this.storePubQuotesClients = [];
            for (const quote of value) {
                let PubQuotesFull: PublishQuoteKamli;
                try {
                    PubQuotesFull = await this.updatePublishQuotes(quote);
                } catch (e) { }
                if (PubQuotesFull) {
                    this.storePubQuotes.push(PubQuotesFull);
                    const client = {
                        corporate: null,
                        contacts: [],
                        quotes: []
                    };
                    const temp = this.storePubQuotesClients && this.storePubQuotesClients.find(c => String(c.corporate.adnCorporateId) ===
                        String(PubQuotesFull.corporateId));
                    if (!temp) {
                        client.corporate = PubQuotesFull.corporate;
                        client.contacts = PubQuotesFull.contacts;
                        client.quotes = [quote];
                        this.storePubQuotesClients.push(client);
                    } else {
                        temp.quotes.push(quote);
                    }
                }
            }
        } else {
            this.storePubQuotes = undefined;
        }
        this.storePubQuotesO.next(this.storePubQuotes);
    }

    updatePublishQuotes(publishQuote: PublishQuote): Promise<PublishQuoteKamli> {
        return new Promise(async (resolve, _reject) => {
            const publishQuoteKamli = publishQuote as PublishQuoteKamli;

            let adnUser: AdnUser;
            try {
                adnUser = await this.usersSvc.getUser(publishQuoteKamli.assistantId) as AdnUser;
            } catch (e) { }
            publishQuoteKamli.assistant = adnUser;

            if (this.businessVertical && this.businessVertical[0]) {
                publishQuoteKamli.businessVertical = this.businessVertical[publishQuoteKamli.businessVerticalIndex]
                [publishQuoteKamli.businessTypeIndex].vertical;
                publishQuoteKamli.businessType = this.businessVertical[publishQuoteKamli.businessVerticalIndex]
                [publishQuoteKamli.businessTypeIndex].type;
            }

            let corporate: AdnCorporateI;
            try {
                corporate = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                    this.utilSvc.mdb, OBJECTNAME.adnCorporates, publishQuoteKamli.corporateId) as AdnCorporateI;
            } catch (e) { }
            if (corporate) {
                publishQuoteKamli.corporate = corporate;
            }

            publishQuoteKamli.spotsDetails = [] as AdnStoreI[];
            if (publishQuoteKamli.spots) {
                for (const spotid of publishQuoteKamli.spots) {
                    let store;
                    try {
                        store = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                            this.utilSvc.mdb, OBJECTNAME.adnStores, spotid);
                    } catch (e) { }
                    if (store) {
                        publishQuoteKamli.spotsDetails.push(store);
                    }
                }
            }
            resolve(publishQuoteKamli);
        });
    }

    async cleanAdnAssets(type: string, dataToDelete: any) {
        let files;
        switch (type) {
            case OBJECTNAME.AdnMediaKamli:
                try {
                    files = await this.utilSvc.listDir(
                        'dist2/assets/adn/',
                        'imagechannels'
                    );
                } catch (e) {
                    console.log('listDir imagechannels error ', e);
                }
                if (files) {
                    const dataToDelete2 = dataToDelete as KamliMedia[];
                    for (const file of files) {
                        if (file.isFile && !file.name.includes('.json')) {
                            const storedFilename = file.name;
                            if (dataToDelete2 && dataToDelete2 != null &&
                                dataToDelete2.length > 0 && !storedFilename.includes('.json')) {
                                const temp = dataToDelete2 && dataToDelete2.find(dsimage => {
                                    let tempr = false;
                                    if (!tempr && dsimage.localurlL) {
                                        tempr = dsimage.localurlL.includes(storedFilename);
                                    }
                                    if (!tempr && dsimage.localurlP) {
                                        tempr = dsimage.localurlP.includes(storedFilename);
                                    }
                                    return tempr;
                                });
                                if (!temp) {
                                    try {
                                        await this.utilSvc.removeFile('dist2/assets/adn/imagechannels', storedFilename);
                                    } catch (e) {
                                        console.log('error deleting file %s =', storedFilename, e);
                                    }
                                }
                            }
                        }
                    }
                }
                break;

            case OBJECTNAME.ThematicVideosKamli:
                try {
                    files = await this.utilSvc.listDir(
                        'dist2/assets/adn/',
                        'videochannels'
                    );
                } catch (e) {
                    console.log('listDir videochannels error ', e);
                }
                if (files) {
                    for (const file of files) {
                        if (file.isFile && !file.name.includes('.json')) {
                            const storedFilename = file.name;
                            const dataToDelete2 = dataToDelete as KamliMedia[];
                            if (dataToDelete2 && dataToDelete2 != null &&
                                dataToDelete2.length > 0 && !storedFilename.includes('.json')) {
                                const temp = dataToDelete2 && dataToDelete2.find(
                                    dsvideo => {
                                        if (dsvideo.localurlL || dsvideo.localurlP) {
                                            let result = false;
                                            if (dsvideo.localurlL && !result) {
                                                result = dsvideo.localurlL.includes(storedFilename);
                                            }
                                            if (dsvideo.localurlP && !result) {
                                                result = dsvideo.localurlP.includes(storedFilename);
                                            }
                                            return result;
                                        } else {
                                            return false;
                                        }
                                    }
                                );
                                if (!temp) {
                                    try {
                                        await this.utilSvc.removeFile('dist2/assets/adn/videochannels', storedFilename);
                                    } catch (e) {
                                        console.log('error deleting file %s =', storedFilename, e);
                                    }
                                }
                            }
                        }
                    }
                }
                break;

        }
    }

    async cleanStoredAssets(currentStoreId: string, type: string) {
        let historyUrls;
        const nameString = 'name';
        try {
            historyUrls = await this.utilSvc.listDir(
                'dist2/assets/',
                'stores'
            );
        } catch (e) {
            console.log('listDir stores error ', e);
        }
        let files;
        if (historyUrls) {
            for (const historyUrl of historyUrls) {
                const storeId = historyUrl[nameString];
                if (String(storeId) === String(currentStoreId)) {
                    switch (type) {
                        case OBJECTNAME.products:
                            try {
                                files = await this.utilSvc.listDir(
                                    'dist2/assets/stores/' + storeId + '/',
                                    OBJECTNAME.products
                                );
                            } catch (e) {
                                console.log('error listdir dist2/assets/stores/' + storeId + '/products' + ' error ', e);
                            }
                            if (files) {
                                for (const file of files) {
                                    if (file.isFile && !file.name.includes('.json')) {
                                        const fullPath = file.fullPath;
                                        if (fullPath && this.storeProducts) {
                                            const temp = this.storeProducts && this.storeProducts.find(product => {
                                                const temp123 = fullPath.includes(product.localurl);
                                                return temp123;
                                            });
                                            if (!temp) {
                                                console.log('deleting %s', file.name, ' ,fullPath=%s', fullPath);
                                                try {
                                                    await this.utilSvc.removeFile('dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.products, file.name);
                                                } catch (e) {
                                                    console.log('error deleting file %s =', 'dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.products + '/' + file.name, e);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;

                        case OBJECTNAME.categories:
                            try {
                                files = await this.utilSvc.listDir(
                                    'dist2/assets/stores/' + storeId + '/',
                                    OBJECTNAME.categories
                                );
                            } catch (e) {
                                console.log('error listdir dist2/assets/stores/' + storeId + '/categories' + ' error ', e);
                            }
                            if (files) {
                                for (const file of files) {
                                    if (file.isFile && !file.name.includes('.json')) {
                                        const fullPath = file.fullPath;
                                        if (fullPath && this.storeCategories) {
                                            const temp = this.storeCategories && this.storeCategories.find(category => {
                                                return fullPath.includes(category.localurl);
                                            });
                                            if (!temp) {
                                                console.log('deleting %s', file.name);
                                                try {
                                                    await this.utilSvc.removeFile('dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.categories, file.name);
                                                } catch (e) {
                                                    console.log('error deleting file %s =', 'dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.categories + '/' + file.name, e);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;

                        case OBJECTNAME.events:
                            try {
                                files = await this.utilSvc.listDir(
                                    'dist2/assets/stores/' + storeId,
                                    OBJECTNAME.events
                                );
                            } catch (e) {
                                console.log('error listdir dist2/assets/stores/' + storeId + '/events' + ' error ', e);
                            }

                            if (files) {
                                for (const file of files) {
                                    if (file.isFile && !file.name.includes('.json')) {
                                        const fullPath = file.fullPath;
                                        if (fullPath && this.storeEvents) {
                                            const temp = this.storeEvents && this.storeEvents.find(event => {
                                                return fullPath.includes(event.localurlL) || fullPath.includes(event.localurlP) ||
                                                    fullPath.includes(event.localbackgroundL) || fullPath.includes(event.localbackgroundP);
                                            });
                                            if (!temp) {
                                                try {
                                                    await this.utilSvc.removeFile('dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.events, file.name);
                                                } catch (e) {
                                                    console.log('error deleting file %s =', 'dist2/assets/stores/' + storeId +
                                                        '/' + OBJECTNAME.events + '/' + file.name, e);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;

                        case OBJECTNAME.promotions:
                            try {
                                files = await this.utilSvc.listDir(
                                    'dist2/assets/stores/' + storeId,
                                    OBJECTNAME.promotions
                                );
                            } catch (e) {
                                console.log('error listdir dist2/assets/stores/' + storeId + '/promotions' + ' error ', e);
                            }
                            if (files) {
                                for (const file of files) {
                                    if (file.isFile && !file.name.includes('.json')) {
                                        const fullPath = file.fullPath;
                                        if (fullPath && this.storePromotions) {
                                            const temp = this.storePromotions &&
                                                this.storePromotions.find(promotion => fullPath.includes(promotion.localurlL) ||
                                                    fullPath.includes(promotion.localurlP));
                                            if (!temp) {
                                                console.log('deleting %s', file.name);
                                                try {
                                                    await this.utilSvc.removeFile('dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.promotions, file.name);
                                                } catch (e) {
                                                    console.log('error deleting file %s =', 'dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.promotions + '/' + file.name, e);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;

                        case OBJECTNAME.DsPImage:
                            try {
                                files = await this.utilSvc.listDir(
                                    'dist2/assets/stores/' + storeId,
                                    OBJECTNAME.DsPImage
                                );
                            } catch (e) {
                                console.log('error listdir dist2/assets/stores/' + storeId + '/images' + ' error ', e);
                            }
                            if (files) {
                                for (const file of files) {
                                    if (file.isFile && !file.name.includes('.json')) {
                                        const fullPath = file.fullPath;
                                        if (fullPath && this.storeImages) {
                                            let temp = this.storeImages && this.storeImages.find(image => {
                                                return fullPath.includes(image.localurlL);
                                            });
                                            if (!temp && this.sponsorMedia) {
                                                if (fullPath.includes(this.sponsorMedia.localurlL)) {
                                                    temp = this.sponsorMedia;
                                                }
                                            }
                                            if (!temp) {
                                                console.log('deleting %s', file.name);
                                                try {
                                                    await this.utilSvc.removeFile('dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.DsPImage, file.name);
                                                } catch (e) {
                                                    console.log('error deleting file %s =', 'dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.DsPImage + '/' + file.name, e);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;

                        case OBJECTNAME.DsPVideo:
                            try {
                                files = await this.utilSvc.listDir(
                                    'dist2/assets/stores/' + storeId,
                                    OBJECTNAME.DsPVideo
                                );
                            } catch (e) {
                                console.log('error listdir dist2/assets/stores/' + storeId + '/videos' + ' error ', e);
                            }
                            if (files) {
                                for (const file of files) {
                                    if (file.isFile && !file.name.includes('.json')) {
                                        const fullPath = file.fullPath;
                                        if (fullPath && this.storeVideos) {
                                            let temp = this.storeVideos &&
                                                this.storeVideos.find(video => fullPath.includes(video.localurlL));
                                            if (!temp && this.sponsorMedia) {
                                                if (fullPath.includes(this.sponsorMedia.localurlP)) {
                                                    temp = this.sponsorMedia;
                                                }
                                            }
                                            if (!temp) {
                                                console.log('deleting %s', file.name);
                                                try {
                                                    await this.utilSvc.removeFile('dist2/assets/stores/' + storeId +
                                                        '/' + OBJECTNAME.DsPVideo, file.name);
                                                } catch (e) {
                                                    console.log('error deleting file %s =', 'dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.DsPVideo + '/' + file.name, e);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;

                        case OBJECTNAME.publishingNewOrders:
                            try {
                                files = await this.utilSvc.listDir(
                                    'dist2/assets/stores/' + storeId,
                                    OBJECTNAME.publishingNewOrders
                                );
                            } catch (e) {
                                console.log('error listdir dist2/assets/stores/' + storeId + '/publishingNewOrders' + ' error ', e);
                            }

                            if (files) {
                                for (const file of files) {
                                    if (file.isFile && !file.name.includes('.json')) {
                                        const fullPath = file.fullPath;
                                        if (fullPath && this.publishingSmbOrders) {
                                            const temp = this.publishingSmbOrders && this.publishingSmbOrders.find(publishOrder => {
                                                if (publishOrder.storeAsset && publishOrder.storeAsset.asset) {
                                                    let localUrlL;
                                                    let localUrlP;
                                                    localUrlL = publishOrder.storeAsset.asset.localurlL;
                                                    localUrlP = publishOrder.storeAsset.asset.localurlP;
                                                    return fullPath.includes(localUrlL) ||
                                                        fullPath.includes(localUrlP);
                                                } else {
                                                    return false;
                                                }
                                            });
                                            if (!temp) {
                                                console.log('deleting %s', file.name);
                                                try {
                                                    await this.utilSvc.removeFile('dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.publishingNewOrders, file.name);
                                                } catch (e) {
                                                    console.log('error deleting file %s =', 'dist2/assets/stores/' + storeId + '/' +
                                                        OBJECTNAME.publishingNewOrders + '/' + file.name, e);
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                            break;
                    }
                } /*else {
                    if (this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
                        if (storeId !== this.utilSvc.unprovisionedKamStoreId) {
                            //                            console.log('remove storeId=%s, with type=%s', storeId, type);
                            //                            console.log('current storeId=%s, with type=%s', currentStoreId, type);
                            this.utilSvc.fileIonic.removeRecursively(this.utilSvc.fileIonic.externalDataDirectory +
                                '/dist2/assets/stores', storeId);
                        }
                    }
                }*/
            }
        }
    }

    public async getSmbInfoWithName(name: string, alladnusers: AdnUser[], allcorporates: AdnCorporateI[], requestForm: FormGroup) {
        return new Promise(async (resolve, reject) => {
            let user;
            if (!alladnusers) {
                let temp;
                try {
                    temp = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.adnUsers);
                } catch (e) { }
                alladnusers = this.utilSvc.objectToArray(temp) as AdnUser[];
            }
            if (!allcorporates) {
                let tempc;
                try {
                    tempc = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.adnCorporates);
                } catch (e) { }
                allcorporates = this.utilSvc.objectToArray(tempc) as AdnCorporateI[];
            }

            if (alladnusers) {
                user = alladnusers && alladnusers.find(u => name && u.name && u.name.trim().toLowerCase() === name.trim().toLowerCase());
            }
            if (user && allcorporates) {
                const tempc = allcorporates && allcorporates.find(c => c.adnUserId === user.adnUserId);
                if (tempc) {
                    requestForm.patchValue({
                        siretNumber: tempc.siretNumber,
                        customerEmail: tempc.email
                    });
                    const temp = await this.getSmbInfoWithSiret(tempc.siretNumber, requestForm);
                    resolve(temp);
                } else {
                    requestForm.patchValue({
                        siretNumber: ''
                    });
                    reject('username unknown. please enter a new one or enter the siret of the business');
                }
            } else {
                reject('system error');
            }
        });
    }


    public getSmbInfoWithSiret(siret: string, requestForm: FormGroup) {
        return new Promise(async (resolve, reject) => {
            const businessNameString = 'businessName';
            const sirenNumberString = 'sirenNumber';
            const vatNumberString = 'vatNumber';
            const apeCodeString = 'apeCode';
            const addressString = 'address';
            let storeTemp: AdnStoreI;

            const corporateTemp = await this.getCorporate(siret) as AdnCorporateI;
            if (this.allStores && corporateTemp) {
                storeTemp = this.allStores && this.allStores.find(s => String(s.adnStoreId) === String(corporateTemp.storeList[0])) as any;
            }
            if (corporateTemp && storeTemp) {
                this.adnBusinessUser = await this.usersSvc.getUser(corporateTemp.adnUserId) as AdnUser;
                requestForm.patchValue({
                    siretNumber: siret,
                    businessName: corporateTemp.businessName,
                    sirenNumber: corporateTemp.sirenNumber,
                    vatNumber: corporateTemp.vatNumber,
                    apeCode: corporateTemp.apeCode,
                    address: corporateTemp.address,
                    name: this.adnBusinessUser.name,
                    email: corporateTemp.email,
                    currentVerticalIndex: storeTemp.currentVerticalIndex,
                    currentTypeIndex: storeTemp.currentTypeIndex,
                });
                resolve([requestForm, corporateTemp, storeTemp]);
            } else {
                this.getCorporateInfoHttp(siret).then(
                    adnCorporateInfo => {
                        const name1 = adnCorporateInfo[businessNameString].replace(/\s/g, '') + String(Math.floor(Math.random() * 10000));
                        requestForm.patchValue({
                            siretNumber: siret,
                            businessName: adnCorporateInfo[businessNameString],
                            sirenNumber: adnCorporateInfo[sirenNumberString],
                            vatNumber: adnCorporateInfo[vatNumberString],
                            apeCode: adnCorporateInfo[apeCodeString],
                            address: adnCorporateInfo[addressString],
                            name: name1,
                        });
                        resolve([requestForm, adnCorporateInfo, undefined]);
                    },
                    error => reject(error)
                );
            }
        });
    }

    private getCorporateInfoHttp(siret: string) {
        const backendURL = this.utilSvc.backendURL;
        return new Promise((resolve, reject) => {

            const paramsV = new HttpParams().set('siret', siret);


            // tslint:disable-next-line: deprecation
            this.spinner.show();
            this.http.get(backendURL + 'corporateInfo/siret', { params: paramsV }).subscribe(
                data => {
                    this.spinner.hide();
                    const bodyString = 'body';
                    const etablissementString = 'etablissement';
                    const adnCorporateIdString = 'adnCorporateId';
                    const businessNameString = 'businessName';
                    const siretNumberString = 'siretNumber';
                    const sirenNumberString = 'sirenNumber';
                    const vatNumberString = 'vatNumber';
                    const apeCodeString = 'apeCode';
                    const addressString = 'address';
                    const cityString = 'city';
                    const countryString = 'country';
                    const latString = 'lat';
                    const lngString = 'lng';
                    const postalCodeString = 'postalCode';
                    if (data) {
                        if (data[bodyString]) {
                            if (data[bodyString][etablissementString]) {
                                const corporateRawInfo = data[bodyString][etablissementString];
                                const adnCorporateInfo = {} as AdnCorporateI;
                                adnCorporateInfo[adnCorporateIdString] = corporateRawInfo.siren;
                                adnCorporateInfo[businessNameString] = corporateRawInfo.nom_raison_sociale;
                                adnCorporateInfo[siretNumberString] = corporateRawInfo.siret;
                                adnCorporateInfo[sirenNumberString] = corporateRawInfo.siren;
                                adnCorporateInfo[vatNumberString] = corporateRawInfo.vat;
                                adnCorporateInfo[apeCodeString] = corporateRawInfo.activite_principale;
                                adnCorporateInfo[addressString] = corporateRawInfo.geo_adresse ? corporateRawInfo.geo_adresse :
                                    corporateRawInfo.libelle_commune;
                                adnCorporateInfo[cityString] = corporateRawInfo.libelle_commune;
                                adnCorporateInfo[countryString] = corporateRawInfo.l7_normalisee;
                                adnCorporateInfo[latString] = corporateRawInfo.latitude;
                                adnCorporateInfo[lngString] = corporateRawInfo.longitude;
                                adnCorporateInfo[postalCodeString] = corporateRawInfo.code_postal;
                                resolve(adnCorporateInfo);
                            } else {
                                reject('cannot find etablissement');
                            }
                        } else {
                            reject('cannot find this SIRET. Please double check it and retry again');
                        }
                    } else {
                        reject('cannot find this SIRET. Please double check it and retry again');
                    }
                },
                _error => {
                    this.spinner.hide();
                    reject('error when trying to get this SIRET. . Please double check it and retry again');
                }
            );

        });
    }

    public async getCorporate(siret: string) {
        const corporate = await this.smbSvc.getCorporateBySiret(this.utilSvc.backendFBstoreId, siret) as AdnCorporateI;
        return corporate;
    }

    filterPlaylistsWithContent(storePlaylists: DsPlaylist[]) {
        const compiledPlaylist = [] as DsPlaylist[];
        if (storePlaylists) {
            for (const playlist of storePlaylists) {
                let include = true;
                switch (playlist.type) {
                    case 'StorePromotions':
                        if (!(this.storePromotions && this.storePromotions.length)) {
                            include = false;
                        }
                        break;
                    case 'StoreEvents':
                        if (!(this.storeEvents && this.storeEvents.length)) {
                            include = false;
                        }
                        break;
                    case 'StoreImages':
                        if (!(this.storeImages && this.storeImages.length)) {
                            include = false;
                        }
                        break;
                    case 'StoreVideos':
                        if (!(this.storeVideos && this.storeVideos.length)) {
                            include = false;
                        }
                        break;
                    case 'StoreMedias':
                        if (!(this.storeVideos && this.storeVideos.length &&
                            this.storeImages && this.storeImages.length)) {
                            include = false;
                        }
                        break;
                }
                if (include) {
                    compiledPlaylist.push(playlist);
                }
            }
        }
        return (compiledPlaylist);
    }

    uploadThumb(event1, source: string, url: string, directory: string) {
        return new Promise(async (resolve, reject) => {
            this.spinner.show();
            if (source === 'url') {
                if (url && url.length > 0) {
                    const params = new HttpParams()
                        .set('url', url)
                        .set('dir', 'assets/' + directory);
                    // tslint:disable-next-line: deprecation
                    this.http.get(this.utilSvc.backendURL + 'store/downloadUrl',
                        {
                            params,
                            reportProgress: true,
                            observe: 'events'
                        }).subscribe(
                            (data: HttpEvent<any>) => {
                                switch (data.type) {
                                    case HttpEventType.Sent:
                                        break;
                                    case HttpEventType.ResponseHeader:
                                        break;
                                    case HttpEventType.DownloadProgress:
                                        this.progress = Math.round(data.loaded / data.total * 100);
                                        break;
                                    case HttpEventType.Response:
                                        setTimeout(() => {
                                            this.progress = 0;
                                        }, 1500);
                                        this.spinner.hide();
                                        resolve(data.body);
                                        break;
                                }
                            },
                            error => {
                                this.spinner.hide();
                                console.log(error);
                                reject(error);
                            }
                        );
                }
            } else {
                if (event1) {
                    this.storeDbSvc.uploadMedia(this.smbStore.adnStoreId, event1, directory).then(
                        temp1 => {
                            const thumb = temp1 as string;
                            const params = new HttpParams()
                                .set('url', thumb)
                                .set('dir', 'assets/' + directory);
                            // tslint:disable-next-line: deprecation
                            this.http.get(this.utilSvc.backendURL + 'store/downloadUrl', {
                                params,
                                reportProgress: true,
                                observe: 'events'
                            }).subscribe(
                                (data: HttpEvent<any>) => {
                                    switch (data.type) {
                                        case HttpEventType.Sent:
                                            break;
                                        case HttpEventType.ResponseHeader:
                                            break;
                                        case HttpEventType.DownloadProgress:
                                            this.progress = Math.round(data.loaded / data.total * 100);
                                            break;
                                        case HttpEventType.Response:
                                            setTimeout(() => {
                                                this.progress = 0;
                                            }, 1500);
                                            this.spinner.hide();
                                            resolve(data.body);
                                            break;
                                    }
                                },
                                error => {
                                    this.spinner.hide();
                                    console.log(error);
                                    reject(error);
                                }
                            );
                        }, //
                        error => {
                            this.spinner.hide();
                            reject(error);
                        }
                    );
                }
            }
        });
    }
}
