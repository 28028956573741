import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';

import { NgModule } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';

import { HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieModule } from 'ngx-cookie';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { GodigitalbModule } from 'godigital-lib';

import { Page404Component } from './page404/page404.component';

import { LayoutModule } from './layout/layout.module';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, // (Required) for share counts
    BrowserAnimationsModule,
    routing,
    CookieModule.forRoot(),
    GodigitalbModule,
    NgxSpinnerModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.apiUrl,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: false
    }),
    LayoutModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
