import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs';
import { StoreDbService, OBJECTNAME, AdnkameleonBox, KamliMedia, SponsoredI, AdnUser, RssfeeditemI, AdnAssistant } from './firebase-service';
import { UtilsService } from '../godigital-lib';
import { CART } from './cart.service';
import { OrderService, OrderI, } from './order.service';

export interface AdnCorporateI {
  address: string;
  adnCorporateId: string;
  adnUserId: string;
  apeCode: string;
  businessName: string;
  businessType: number;
  businessVertical: number;
  city: string;
  country: string;
  email: string;
  lat: number;
  lng: number;
  phoneNumber: string;
  postalCode: string;
  storeIndex: number;
  storeList: any;
  siretNumber: string;
  sirenNumber: string;
  vatNumber: string;
  since: number;
}

export interface AdnCorporateIKamli extends AdnCorporateI {
  adnUser: AdnUser;
  storeListDetails: AdnStoreI[];
  businessTypeDetails: string;
  businessVerticalDetails: string;
}

export interface AdnUserKamli extends AdnUser {
  referrals: ReferralI[];
  usercarts: AdnUserCart[];
  userorders: AdnUserOrders[];
  imageVisible: any;
  likes: RssfeeditemI[];
}

export interface AdnUserOrders {
  value: OrderI[];
  store: AdnStoreI;
}

export interface AdnUserCart {
  value: CART;
  store: AdnStoreI;
}

export interface AdnStoreI {
  kamlieatsDetails: {
    confirmation: boolean;
    confirmationts: number;
    clickAndDine: {
      confirmation: boolean;
      area: string[];
      numberTables: number;
      conditions: string;
    };
  };
  kamliwebDetails: {
    confirmation: boolean,
    confirmationts: number,
    commission: number,
    deliveryTypes: any;
    onlinemode: number;
    specialityNames: string[];
    specialities: string[];
    promotionalText: string[];
    storeDescription: string[];
    paymentSolutions: any[];
    filters: string[];
    franchise: number;
    facebook: string;
    instagram: string;
    twitter: string;
    linkedin: string;
    locality: string[];
    orderWebsite: {
      name: string;
      url: string;
    };
    logo: string;
    locallogo: string;
    locallogo2: string;
    backgroundimage: string;
    clickAndCollect: {
      confirmation: boolean;
      conditions: string;
    };
    clickAndDeliver: {
      confirmation: boolean;
      maximumDeliveryRadius: number;
      minimumOrderAmount: number;
      freeDelivery: boolean;
      averageDeliveryTime: number;
      deliveryHours: string;
      conditions: string;
      deliveryFee: number;
    };
    catalogueUrl: string;
    catalogueDocuments: string[];
  };
  kamliadsDetails: {
    confirmation: boolean,
    confirmationts: number,
    dayPerWeek: number;
    duration: number;
    numberTV: number;
    numberTablet: number;
    numberEyeBalls: string;
    maxEyeBalls: number;
    eyeBallStays: number;
    unitpricing: number;
    adCut: boolean;
    timerMainPlaylist: number;
    timerAds: number;
    maxAds: number;
    adDisplayTime: number;
    publishingPricing: any;
  };
  kamlifilleradsDetails: {
    confirmation: boolean,
    confirmationts: number,
    title: string,
    thumbL: string,
    thumbP: string
    localurlL: string;
    localurlP: string;
    localurlL2: string;
    localurlP2: string;
  };
  advertisers: string[];
  adnCorporateId: string;
  adnStoreId: string;
  address_components: any[];
  city: string;
  country: string;
  currentTypeIndex: number;
  currentVerticalIndex: number;
  categoryId: string;
  distance: number;
  email: string;
  feedbackRate: number;
  firebaseConfig: number;
  formatted_address: string;
  geometry: any;
  googlePlaceId: string;
  googlesheetLink: string;
  lat: number;
  lng: number;
  mode: string;
  open: boolean;
  opening_hours: any;
  promotions: boolean;
  phone: string;
  postalCode: string;
  price_level: number;
  priceRate: number;
  rating: number;
  storeStatus: number;
  screenStatus: number;
  storeName: string;
  url: string;
  user_ratings_total: number;
  postalAddress: string;
  reviews: string[];
  website: string;
  referrals: ReferralI[];
  sponsoredId: string[];
  sponsoredMediaIds: string[];
  sponsorStoreId: string;
  sponsorId: string;
  since: number;
  followers: AdnStoreI[];
  activefollowers: AdnStoreI[];
  assistantId: string;
}

export interface AdnStoreILight {
  kamliwebDetails: {
    confirmation: boolean,
    confirmationts: number,
    deliveryTypes: any;
    onlinemode: number;
    specialities: string[];
    promotionalText: string[];
    storeDescription: string[];
    paymentSolutions: any[];
    filters: string[];
    franchise: number;
    facebook: string;
    instagram: string;
    twitter: string;
    linkedin: string;
    locality: string[];
    orderWebsite: {
      name: string;
      url: string;
    };
    logo: string;
    clickAndCollect: {
      confirmation: boolean;
      conditions: string;
    };
    clickAndDeliver: {
      confirmation: boolean;
      maximumDeliveryRadius: number;
      minimumOrderAmount: number;
      freeDelivery: boolean;
      averageDeliveryTime: number;
      deliveryHours: string;
      conditions: string;
      deliveryFee: number;
    };
  };
  kamliadsDetails: {
    confirmation: boolean,
    confirmationts: number,
    dayPerWeek: number;
    numberTV: number;
    numberTablet: number;
    numberEyeBalls: string;
    maxEyeBalls: number;
    eyeBallStays: number;
    unitpricing: number;
    adCut: boolean;
    timerMainPlaylist: number;
    timerAds: number;
    maxAds: number;
    adDisplayTime: number;
    publishingPricing: any;
  };
  kamlifilleradsDetails: {
    confirmation: boolean,
    confirmationts: number,
    title: string,
    thumbL: string,
    thumbP: string
    localurlL: string;
    localurlP: string;
  };
  adnCorporateId: string;
  adnStoreId: string;
  city: string;
  country: string;
  currentTypeIndex: number;
  currentVerticalIndex: number;
  email: string;
  formatted_address: string;
  googlePlaceId: string;
  lat: number;
  lng: number;
  opening_hours: any;
  phone: string;
  postalCode: string;
  storeStatus: number;
  storeName: string;
  url: string;
  website: string;
}

export enum SMBORDERS {
  onlineCatalogue = 10,
  emailOrders = 15,
  onlineOrders = 20,
  onlinePayment = 40
}

export enum SMBSTATUS {
  TARGETED = 0,
  MODERATION = 1,
  ACTIVE = 2,
  REFUSED = 3,
  SUSPENDED = 4,
  DELETED = 5
}

export const SMBTEXTSTATUS = [
  'TARGETED',
  'MODERATION',
  'ACTIVE',
  'REFUSED',
  'SUSPENDED',
  'DELETED',
];

export enum SCREENSTATUS {
  INSTALLED = 1,
  NOTINSTALLED = 2,
}

export interface AdnkameleonBoxKamli extends AdnkameleonBox {
  store: AdnStoreI;
  playlist: any;
  schedule: any;
}

export interface AdnStoreIKamli extends AdnStoreI {
  businessVertical: any;
  sponsored: SponsoredI[];
  sponsoredMedias: KamliMedia[];
  currentVertical: string;
  currentType: string;
  corporate: AdnCorporateI;
  user: AdnUser;
  sponsorStore: AdnStoreI;
  assistant: AdnAssistant
}

export interface SponsoredIKamli extends SponsoredI {
  sponsoreeStore: AdnStoreI;
  sponsorStore: AdnStoreI;
}

export interface ReferralI {
  id: string;
  name: string;
  type: string;
  referralId: string;
  publish: boolean;
  publishvisible: boolean;
  store: boolean;
  storevisible: boolean;
  urlname: string;
  feedCategory: string;
}

@Injectable({
  providedIn: 'root'
})
export class SmbService {
  constructor(
    public storeDbSvc: StoreDbService,
    public utilSvc: UtilsService,
  ) { }

  getStores(BEStoreId) {
    return new Promise((resolve, reject) => {
      this.storeDbSvc
        .getObject(BEStoreId, this.utilSvc.mdb, OBJECTNAME.adnStores)
        .then(
          data => resolve(this.utilSvc.objectToArray(data)),
          error => reject(error)
        );
    });
  }

  getStoreSpecialities(BEStoreId) {
    return new Promise((resolve, reject) => {
      this.storeDbSvc
        .getObject(BEStoreId, this.utilSvc.mdb, OBJECTNAME.specialities)
        .then(
          data => resolve(this.utilSvc.objectToArray(data)),
          error => reject(error)
        );
    });
  }

  getStore(BEStoreId, storeId: string) {
    return new Promise((resolve, reject) => {
      this.storeDbSvc
        .getObject(BEStoreId, this.utilSvc.mdb, OBJECTNAME.adnStores, storeId)
        .then(data => resolve(data), error => reject(error));
    });
  }

  editStore(BEStoreId, store: AdnStoreI) {
    return new Promise((resolve, reject) => {
      if (store) {
        const storeString = 'store';
        const sponsoredString = 'sponsored';
        const sponsoredMediasString = 'sponsoredMedias';
        store[sponsoredString] = null;
        store[sponsoredMediasString] = null;
        store.followers = null;
        store.activefollowers = null;
        store.kamlifilleradsDetails[storeString] = null;
        store['currentType'] = null;
        store['currentVertical'] = null;
        store['user'] = null;
        store['corporate'] = null;
        this.storeDbSvc
          .updateObject(
            BEStoreId,
            this.utilSvc.mdb,
            OBJECTNAME.adnStores,
            store,
            store.adnStoreId
          )
          .then(data => resolve(data), error => reject(error));
      } else {
        resolve(1);
      }
    });
  }

  deleteStore(BEstoreId, storeId: string) {
    return new Promise((resolve, reject) => {
      this.getStore(BEstoreId, storeId).then(
        store => {
          let update = true;
          const promises = [];
          promises.push(
            new Promise((resolve1, reject1) => {
              const storeListString = 'storeList';
              const adnCorporateIdString = 'adnCorporateId';
              this.getCorporate(BEstoreId, store[adnCorporateIdString]).then(
                data => {
                  const storeList = data[storeListString];
                  if (storeList ) {
                    const index = storeList.findIndex(s => s === storeId);
                    if (index !== -1) {
                      storeList.splice(index, 1);
                    } else {
                      update = false;
                    }
                  }
                  if (update) {
                    data[storeListString] = storeList;
                    this.updateCorporate(BEstoreId, data as AdnCorporateI);
                  }
                  resolve(data);
                },
                error1 => {
                  reject(error1);
                }
              );
            })
          );
          promises.push(
            new Promise((resolve1, reject1) => {
              this.storeDbSvc
                .removeObject(
                  BEstoreId,
                  this.utilSvc.mdb,
                  OBJECTNAME.adnStores,
                  storeId
                )
                .then(
                  data => {
                    resolve(data);
                  },
                  error1 => {
                    reject(error1);
                  }
                );
            })
          );
          Promise.all(promises).then(
            data => resolve(data),
            error => reject(error)
          );
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateCorporate(BEStoreId, corporate: AdnCorporateI) {
    return new Promise((resolve, reject) => {
      this.storeDbSvc
        .updateObject(
          BEStoreId,
          this.utilSvc.mdb,
          OBJECTNAME.adnCorporates + '/' + corporate.adnCorporateId,
          corporate
        )
        .then(data => resolve(data), error => reject(error));
    });
  }

  getCorporate(BEStoreId, corporateId: string): Promise<AdnCorporateI> {
    return new Promise((resolve, reject) => {
      this.storeDbSvc
        .getObject(
          BEStoreId,
          this.utilSvc.mdb,
          OBJECTNAME.adnCorporates,
          corporateId
        )
        .then(data => resolve(data as AdnCorporateI), error => reject(error));
    });
  }

  getCorporateByUser(BEStoreId, adnUserId: string): Promise<AdnCorporateI> {
    return new Promise((resolve, reject) => {
      let result: AdnCorporateI;
      this.storeDbSvc
        .getObject(BEStoreId, this.utilSvc.mdb, OBJECTNAME.adnCorporates)
        .then(
          data => {
            const corporates = this.utilSvc.objectToArray(data) as AdnCorporateI[];
            if (corporates  && corporates !== null) {
              result = corporates.find(c => c.adnUserId === adnUserId);
            }
            resolve(result);
          },
          error => reject(error)
        );
    });
  }

  getCorporateBySiret(BEStoreId, siret: string) {
    return new Promise((resolve, reject) => {
      let result: AdnCorporateI;
      this.storeDbSvc
        .getObject(BEStoreId, this.utilSvc.mdb, OBJECTNAME.adnCorporates)
        .then(
          data => {
            const corporates = this.utilSvc.objectToArray(data) as AdnCorporateI[];
            if (corporates  && corporates !== null) {
              result = corporates.find(c => String(c.siretNumber) === String(siret));
            }
            resolve(result);
          },
          error => reject(error)
        );
    });
  }

  getStoreByGoogleplaceid(BEStoreId, googleplaceid: string) {
    return this.storeDbSvc.firebaseData[BEStoreId][OBJECTNAME.adnStores].find(
      c => c.googlePlaceId === googleplaceid
    );
  }

  getStoreByUrl(BEStoreId, url: string) {
    return this.storeDbSvc.firebaseData[BEStoreId][OBJECTNAME.adnStores].find(
      c => c.url === url
    );
  }

  isStoreOpen(store: AdnStoreI, pday, hour, minute) {
    let result = false;
    const regExOpenDaysAllDay = /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday):\s+(\d+):(\d+)\s+AM\s–\s(\d+):(\d+)\s+PM/;
    const regExOpenDaysHalfDay = /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday):\s+(\d+):(\d+)\s+\s*–\s(\d+):(\d+)\s+(AM|PM)?/;
    const daysT = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    const ptime = Number(hour) * 60 + Number(minute);
    if (store.opening_hours ) {
      const weekdayTextString = 'weekday_text';
      const weekday = store ? store.opening_hours[weekdayTextString]: undefined;
      for (const day of weekday) {
        const temp = regExOpenDaysAllDay.exec(day);
        if (temp != null && temp ) {
          if (temp[1].includes(daysT[pday])) {
            const startTime = Number(temp[2]) * 60 + Number(temp[3]);
            const endTime = Number(temp[4] + 12) * 60 + Number(temp[5]);

            if (ptime >= startTime && ptime <= endTime) {
              result = true;
            }
          }
        } else {
          let offset = 0;
          const temp1 = regExOpenDaysHalfDay.exec(day);
          if (temp1 != null && temp1 ) {
            if (temp1[1].includes(daysT[pday])) {
              if (temp1[6] === 'PM') {
                offset = 12;
              }
              const startTime = (Number(temp1[2]) + offset) * 60 + Number(temp1[3]);
              const endTime = (Number(temp1[4]) + offset) * 60 + Number(temp1[5]);
              if (ptime >= startTime && ptime <= endTime) {
                result = true;
              }
            }
          }
        }
      }
    }
    return result;
  }

  isStoreOpen1(store: AdnStoreI, timestamp) {
    const pday = new Date(timestamp).getDay();
    const hour = new Date(timestamp).getHours();
    const minute = new Date(timestamp).getMinutes();
    let result = false;
    const regExOpenDaysAllDay = /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday):\s+(\d+):(\d+)\s+AM\s–\s(\d+):(\d+)\s+PM/;
    const regExOpenDaysHalfDay = /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday):\s+(\d+):(\d+)\s+\s*–\s(\d+):(\d+)\s+(AM|PM)?/;
    const daysT = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    const ptime = Number(hour) * 60 + Number(minute);
    if (store.opening_hours ) {
      const weekdayTextString = 'weekday_text';
      const weekday = store.opening_hours[weekdayTextString];
      if (weekday) {
        for (const day of weekday) {
          const temp = regExOpenDaysAllDay.exec(day);
          if (temp != null && temp ) {
            if (temp[1].includes(daysT[pday])) {
              const startTime = Number(temp[2]) * 60 + Number(temp[3]);
              const endTime = Number(temp[4] + 12) * 60 + Number(temp[5]);

              if (ptime >= startTime && ptime <= endTime) {
                result = true;
              }
            }
          } else {
            let offset = 0;
            const temp1 = regExOpenDaysHalfDay.exec(day);
            if (temp1 != null && temp1 ) {
              if (temp1[1].includes(daysT[pday])) {
                if (temp1[6] === 'PM') {
                  offset = 12;
                }
                const startTime = (Number(temp1[2]) + offset) * 60 + Number(temp1[3]);
                const endTime = (Number(temp1[4]) + offset) * 60 + Number(temp1[5]);
                if (ptime >= startTime && ptime <= endTime) {
                  result = true;
                }
              }
            }
          }
        }
      }
    }
    return result;
  }



  updateKameleon(value: AdnkameleonBoxKamli): Promise<AdnkameleonBoxKamli> {
    return new Promise(
      async (resolve) => {
        let cont = true;
        const currentKameleon = value;
        let store: AdnStoreI;
        if (cont) {
          try {
            store = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
              OBJECTNAME.adnStores, currentKameleon.storeId) as AdnStoreI;
          } catch (e) { cont = false; }
        }
        let schedule: any;
        if (cont) {
          currentKameleon.store = store;
          try {
            schedule = await this.storeDbSvc.getObject(currentKameleon.storeId, this.utilSvc.sdb[currentKameleon.storeId],
              OBJECTNAME.DsSchedule, currentKameleon.scheduleId);
          } catch (e) { cont = false; }
        }
        let playlist: any;
        if (cont) {
          currentKameleon.schedule = schedule;
          try {
            playlist = await this.storeDbSvc.getObject(currentKameleon.storeId, this.utilSvc.sdb[currentKameleon.storeId],
              OBJECTNAME.DsPlaylist, currentKameleon.playlistId);
          } catch (e) { cont = false; }
        }
        if (cont) {
          currentKameleon.playlist = playlist;
        }
        if (currentKameleon.store  && currentKameleon.store != null) {
          this.setPins(currentKameleon.store);
        }
        resolve(currentKameleon);
      });
  }

  setPins(store: AdnStoreI) {
    const iconString = 'icon';
    if (Number(store.storeStatus) === SMBSTATUS.ACTIVE) {
      store[iconString] = {
        url: '/assets/img/pinred.png',
        scaledSize: {
          width: 15,
          height: 20
        }
      };
    } else if (Number(store.storeStatus) === SMBSTATUS.MODERATION) {
      store[iconString] = {
        url: '/assets/img/pingreen.png',
        scaledSize: {
          width: 15,
          height: 20
        }
      };
    } else if (Number(store.storeStatus) === SMBSTATUS.TARGETED) {
      store[iconString] = {
        url: '/assets/img/pinblue.png',
        scaledSize: {
          width: 15,
          height: 20
        }
      };
    }

  }


}
