
import { Injectable, InjectionToken, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, } from '@angular/router';
import { HaversineService } from 'ng2-haversine';

import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import {
  AdnStoreIKamli, PRODUCTIKamli, AdnCorporateI, AdnUser, AdnStoreI, MailDetailsI,
  StoreDbService, UtilsService, UsersService, ProductsService, SmbService, CartService, MailerService,
  KamliApp
} from 'godigital-lib';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class LocalUtilsService {
  public allStores: AdnStoreIKamli[];
  public currentStores: AdnStoreIKamli[];
  public currentStore: AdnStoreIKamli;
  public currentKamliApp: KamliApp = KamliApp.KAMLIEAT;
  public businessVertical;
  public accessories;
  public platform;
  public backUrl = '';
  public webappUrl = '';
  public activeTab = 'tab-food';
  public error;
  public tutoCategories;
  public tutos;
  public tutoslides;
  public tutoMenu = 0;
  public adnBusinessUser: AdnUser;
  public verticals;
  //  public storeCategories: CATEGORYI[];
  //  public storeProducts: PRODUCTIKamli[];
  public currentCorporate: AdnCorporateI;
  public alladnUsers: AdnUser[];
  public showModaltwoButtonsO: BehaviorSubject<any> = new BehaviorSubject(null);
  public showModaltwoButtonsSubscribtion: Subscription;
  public currentPosition = {
    lat: 43.6280558,
    lng: 7.0358579
  };

  public errorMessage = {
    title: '',
    description: '',
    details: '',
    result: 0
  };


  constructor(
    private router: Router,
    private usersSvc: UsersService,
    private utilsSvc: UtilsService,
    private smbSvc: SmbService,
    private http: HttpClient,
    public haversineService: HaversineService,
  ) { }


  getBusinessUser(store: AdnStoreI, beStoreId: string) {
    return new Promise((resolve, reject) => {
      this.smbSvc.getCorporate(beStoreId, store.adnCorporateId).then(
        data => {
          const corporate = data as AdnCorporateI;
          this.usersSvc.getUser(corporate.adnUserId).then(
            data1 => {
              resolve(data1);
            },
            error => reject(error)
          );
        }
      );
    });
  }

  sendMail(mailDetails: MailDetailsI) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('fromName', encodeURIComponent(mailDetails.fromName))
        .set('fromEmail', encodeURIComponent(mailDetails.from))
        .set('fromPhone', encodeURIComponent(mailDetails.fromPhone))
        .set('toName', encodeURIComponent(mailDetails.toName))
        .set('toEmail', encodeURIComponent(mailDetails.to))
        .set('subject', 'Contact from the website kamlinet.com.')
        .set('comments', mailDetails.text)
        .set('time', '');

      this.http.get(this.utilsSvc.backendURL + 'mailer/contact', { params: params1 }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        });

    });
  }


  goToProduct(product) {
    let route;
    switch (product) {
      case 'kamliFood':
        route = '/kamli/products/kamlifood';
        break;
      case 'kamliPublish':
        route = '/kamli/products/kamlipublish';
        break;
      case 'kamliStore':
        route = '/kamli/products/kamlistore';
        break;
      case 'kamliStoreUser':
        route = '/kamli/services/kamlistore';
        break;
      case 'kamliEats':
        route = '/kamli/services/kamlieats';
        break;
      case 'kamliAds':
        route = '/kamli/services/kamliads';
        break;
    }
    if (route !== undefined) {
      this.router.navigate([route]);
    }
  }

  public getshowModaltwoButtons(): Observable<any> {
    return this.showModaltwoButtonsO.asObservable();
  }
  public setshowModaltwoButtons(value: number) {
    this.showModaltwoButtonsO.next(value);
    this.showModaltwoButtonsO.next(null);
  }

  showModaltwoButtons(title: string, description: string, details: string) {
    return new Promise(resolve => {
      this.errorMessage.title = title;
      this.errorMessage.description = description;
      this.errorMessage.details = details;
      $('#modal-two-buttons').modal('show');

      this.showModaltwoButtonsSubscribtion = this.getshowModaltwoButtons().subscribe(
        data => {
          if (this.showModaltwoButtonsSubscribtion !== undefined) {
            this.showModaltwoButtonsSubscribtion.unsubscribe();
          }
          if (data != null) {
            resolve(data);
          }
        }
      );
    });
  }


  showModalNoButton(title: string, description: string) {
    this.errorMessage.title = title;
    this.errorMessage.description = description;
    $('#modal-no-buttons').modal('show');
  }

  getStoreDistance(address, adnPos): Promise<number> {
    return new Promise(resolve => {
      let distance = 0;

      if (address) {
        const from = {
          latitude: adnPos.lat,
          longitude: adnPos.lng
        };
        const to = {
          latitude: address.lat,
          longitude: address.lng
        };
        distance = this.haversineService.getDistanceInKilometers(
          from,
          to
        );
      }
      resolve(distance);
    });
  }



}
