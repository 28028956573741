/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, NgZone, } from '@angular/core';
import { Router, } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { UtilsService, CartService, ServicesService, UsersService, AdnUser, AdnStoreIKamli, AdnCorporateI } from 'godigital-lib';
import { LocalUtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public userInfo;
  public storeMessages;
  public hasStores = false;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public mainSvc: ServicesService,
    public utilsSvc: UtilsService,
    public cartSvc: CartService,
    public usersSvc: UsersService,
    public localUtilsSvc: LocalUtilsService,
  ) { }

  logout() {
    this.mainSvc.disconnectingUser(this.adnBusinessUser.adnUserId);
    this.router.navigate(['/prehome']);
    this.usersSvc.logout();
  }

  get adnBusinessUser() {
    return this.localUtilsSvc.adnBusinessUser;
  }
  set adnBusinessUser(value: AdnUser) {
    this.localUtilsSvc.adnBusinessUser = value;
  }

  get platform() {
    return this.localUtilsSvc.platform;
  }
  set platform(value) {
    this.localUtilsSvc.platform = value;
  }

  get currentCorporate() {
    return this.localUtilsSvc.currentCorporate;
  }
  set currentCorporate(value: AdnCorporateI) {
    this.localUtilsSvc.currentCorporate = value;
  }

  get currentStore() {
    return this.localUtilsSvc.currentStore;
  }
  set currentStore(value: AdnStoreIKamli) {
    this.localUtilsSvc.currentStore = value;
  }

  get currentStores() {
    return this.localUtilsSvc.currentStores;
  }
  set currentStores(value: AdnStoreIKamli[]) {
    this.localUtilsSvc.currentStores = value;
  }


}
