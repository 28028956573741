import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { firebaseConfig } from '../firebase.config';

import { HaversineService } from 'ng2-haversine';



import {
  OrderStatus, ProductDeliveryType, OrderDeliveryInfo, FilterGenericN, CountGenericN, FilterGenericS,
  FilterGenericIS, CountGenericS, CountGenericPS, FilterGenericNS, FilterGenericB, ParentCategory, TranslateAuto,
  CountGenericB, FilterGenericA, OrderPaymentMode, AddComponent, FilterGenericPS, AdnDate, TotalCalc, StringRegex,
  CampaignDuration, CampaignAnalyticsTotal, SafePipe,
  FilterGenericPSA, FilterGenericPSO
} from './goPipe';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const regexComponent = /.*\.component\.((.|\n|\r)+)+/gi;
    const temp = regexComponent.exec(params.key);
    if (temp && temp != null) {
      return temp[1];
    }
  }
}

@NgModule({
  declarations: [
    OrderStatus, ProductDeliveryType, OrderDeliveryInfo, FilterGenericN, FilterGenericS, FilterGenericIS, CountGenericS, CountGenericPS,
    FilterGenericNS, FilterGenericPS, FilterGenericB, CountGenericB, FilterGenericA, ParentCategory, TranslateAuto,
    OrderPaymentMode, AddComponent, AdnDate, TotalCalc, CountGenericN, CampaignDuration, CampaignAnalyticsTotal, StringRegex, SafePipe,
    FilterGenericPSA, FilterGenericPSO,
  ],
  imports: [
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig, 'bootstrap'),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC6FX2XH6F713ugpHEcvS6UWbbi1VB3zi0',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      useDefaultLang: false
    }),
  ],
  providers: [HaversineService],
  exports: [
    OrderStatus, ProductDeliveryType, OrderDeliveryInfo, FilterGenericN, FilterGenericS,
    CountGenericS, CountGenericPS, FilterGenericIS, FilterGenericNS, FilterGenericPS,
    FilterGenericB, CountGenericB, FilterGenericA, AddComponent,
    ParentCategory, TranslateAuto, AgmCoreModule, OrderPaymentMode,
    TranslateModule, AdnDate, TotalCalc, CountGenericN, CampaignDuration, CampaignAnalyticsTotal, StringRegex, SafePipe,
    FilterGenericPSA, FilterGenericPSO,
  ],
})

export class GodigitalbModule { }

