/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs';
import { OBJECTNAME, AdnUser } from '../godigital-lib';
import { UtilsService } from '../godigital-lib';
import { CartService, CART, CARTITEM, CARTDELIVERY } from './cart.service';
import { CustomerService } from '../godigital-lib';
import { UsersService, } from '../godigital-lib';
import { SmbService, AdnStoreI, AdnCorporateI } from '../godigital-lib';
import { PaymentGwService, PAYMENTGW } from '../godigital-lib';
import { MailerService } from '../godigital-lib';
import { StoreDbService } from '../godigital-lib';
import { AdnUserKamli, KamliApp, AdnUserOrders, AdnUserCart } from '../godigital-lib';

export interface OrderI {
  deliveryDetails: CARTDELIVERY;
  orderId: string;
  createdTS: number;
  modifiedTS: number;
  cartItems: Array<CARTITEM>;
  grandTotal: number;
  grandTotalVAT: any;
  status: ORDERSTATUS;
  deliveryFee: number;
  totalCommission: number;

  smbEmail: string;
  smbName: string;
  smbStoreName: string;
  customerEmail: string;
  customerName: string;
  currency: string;
  capture: string;

  storeId: string;
  adnCustomerUserId: string;
  adnBusinessUserId: string;

  paymentGtwType: string;
  paymentGtwData: string;
  paymentGtwStatus: string;

  tableId: string;
  tabId: string;
  tabTimestamp: any;
  tableNumber: number;

  orderType: ORDERTYPE;
  parentOrders: string[];

  archived: boolean;
}

export enum ORDERSTATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED'
}

export enum ORDERACTION {
  ACCEPT = 'ACCEPT',
  CANCEL = 'CANCEL',
  MODIFY = 'MODIFY',
  DUPLICATE = 'DUPLICATE',
  REFUSE = 'REFUSE',
  DELETE = 'DELETE',
  ARCHIVE = 'ARCHIVE'
}

export enum ORDERTYPE {
  STANDARD = 'STANDARD',
  EXPRESS = 'EXPRESS'
}

export enum EXPRESSORDERMODE {
  DIVIDE,
  AMOUNT,
  TOTAL
}

export enum SORTORDERSDIR {
  OrderIdUp,
  OrderIdDown,
  CDateUp,
  CDateDown,
  DeliveryUp,
  DeliveryDown,
  ODateUp,
  ODateDown,
  CustomerNameUp,
  CustomerNameDown,
  AmountUp,
  AmountDown,
  StatusUp,
  StatusDown,
  PaymentTypeUp,
  PaymentTypeDown
}

export enum CREATEORDERSTATUS {
  SUCCES,
  SUCCESNOEMAIL,
  CARTDELETIONERROR,
  ORDEROBJECTCREATIONERROR,
  STRIPECHARGEERROR,
  STRIPESUBCRIPTIONERROR,
  BUSINESSWITHOUTSTRIPE,
  ORDERTOTAL0,
  PAYMENTMODENOTSUPPORTED,
  CUSTOMERUPDATEERROR,
  UNKNOWNERROR
}

export enum UPDATEORDERSTATUS {
  SUCCES,
  SUCCESNOEMAIL,
  UNKNOWNERROR
}

export enum EXPRESSOPERATION {
  ORDER = 'ORDER',
  CHECKOUT = 'CHECKOUT'
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(
    public storeDbSvc: StoreDbService,
    public utilSvc: UtilsService,
    public userSvc: UsersService,
    public smbSvc: SmbService,
    public customerSvc: CustomerService,
    public paymentGwSvc: PaymentGwService,
    public cartSvc: CartService,
    public mailerSvc: MailerService
  ) { }

  getOrder(storeId, orderId) {
    const objectName = OBJECTNAME.orders;
    return new Promise((resolve, reject) => {
      this.storeDbSvc
        .getObject(storeId, this.utilSvc.sdb[storeId], objectName, orderId)
        .then(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  getOrders(storeId, userId?) {
    const objectName = OBJECTNAME.orders;
    return new Promise((resolve, reject) => {
      this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
        data => {
          const temp = this.utilSvc.objectToArray(data);
          let orders;
          if (userId ) {
            orders = temp.filter(o => o.adnCustomerUserId === userId);
          } else {
            orders = temp;
          }
          resolve(orders);
        },
        error => {
          reject(error);
        }
      );
    });
  }

/*  updateOrderLight(storeId1: string, userId: string, order: OrderI) {
    let orderLight = {} as OrderLightI;
    this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId1).then(data => {
      const store = data as AdnStoreI;
      let logo = '';
      logo = store.kamliwebDetails.logo;
      orderLight = {
        storeId: storeId1,
        storeName: store.storeName,
        storeAddress: store.formatted_address,
        storeLogo: logo,
        deliveryDetails: order.deliveryDetails,
        orderId: order.orderId,
        qrCode: '',
        grandTotal: order.grandTotal,
        timeStamp: order.timeStamp,
        status: order.status,
        expired: order.expired  ? order.expired : false,
        archived: order.archived  ? order.archived : false
      };
      if (userId  && userId != null) {
        this.storeDbSvc.updateObject(
          this.utilSvc.backendFBstoreId,
          this.utilSvc.mdb,
          OBJECTNAME.adnUsers,
          orderLight,
          userId +
          '/' +
          OBJECTNAME.orderlight +
          '/' +
          order.orderId
        );
      }
    });
  }*/

  createOrder(
    adnCorporate: AdnCorporateI,
    adnStore: AdnStoreI,
    adnBusinessUser: AdnUser,
    adnCustomerUser: AdnUser,
    cart: CART,
    paymentGatewayType: string,
    stripintent
  ) {
    return new Promise((resolve, reject) => {
      const adnStoreId = adnStore.adnStoreId;
      const order = {} as OrderI;
      let chargeCreationStatus = false;

      order.orderId = String(Math.floor(Math.random() * 100000));
      order.deliveryDetails = cart.deliveryDetails;
      if (cart.deliveryDetails ) {
        order.deliveryFee =
          cart.deliveryFee  ? cart.deliveryFee : 0;
      } else {
        order.deliveryFee = 0;
      }
      order.totalCommission = cart.totalCommission;
      order.cartItems = cart.cartItems;
      order.grandTotal = cart.grandTotal;
      order.grandTotalVAT = cart.grandTotalVAT;

      order.createdTS = new Date().getTime();
      order.modifiedTS = new Date().getTime();
      order.status = ORDERSTATUS.PENDING;
      order.orderType = ORDERTYPE.STANDARD;

      order.adnBusinessUserId = adnBusinessUser.adnUserId;
      order.smbName = adnBusinessUser.name;
      order.smbEmail = adnBusinessUser.email;
      order.adnCustomerUserId = adnCustomerUser.adnUserId;
      order.customerName = adnCustomerUser.name;
      order.customerEmail = adnCustomerUser.email;

      order.storeId = adnStoreId;
      order.smbStoreName = adnStore.storeName;

      order.paymentGtwType = paymentGatewayType;
      order.paymentGtwData = stripintent;

      order.currency = 'EUR';
      order.capture = 'false';

      if (order.grandTotal + order.deliveryFee > 0) {
        this.updateOrderObject(adnStoreId, order).then(
          data => {
            this.cartSvc.deleteCart(adnStoreId, adnCustomerUser.adnUserId).then(
              data1 => {
                chargeCreationStatus = true;
                this.customerSvc
                  .updateCustomerOrders(
                    adnStore.adnStoreId,
                    order.adnCustomerUserId,
                    order as any
                  )
                  .then(
                    data2 => {
                      this.mailerSvc.sendMailNewOrder(
                        adnCorporate,
                        adnStore,
                        adnBusinessUser,
                        adnCustomerUser,
                        order as any,
                        order.grandTotal + order.deliveryFee
                      )
                        .then(
                          () => {
                            resolve([CREATEORDERSTATUS.SUCCES, order]);
                          },
                          () => {
                            resolve([CREATEORDERSTATUS.SUCCESNOEMAIL, order]);
                          }
                        );
                    },
                    error => {
                      reject([CREATEORDERSTATUS.CUSTOMERUPDATEERROR, error]);
                    }
                  );
              },
              error => {
                reject([CREATEORDERSTATUS.CARTDELETIONERROR, error]);
              }
            );
          },
          error => reject([CREATEORDERSTATUS.ORDEROBJECTCREATIONERROR, error])
        );
      } else {
        reject([CREATEORDERSTATUS.ORDERTOTAL0, null]);
      }
    });
  }

  updateOrderObject(storeId: string, orderDetails: OrderI) {
    return new Promise((resolve, reject) => {
      const objectName = OBJECTNAME.orders;
      if (!orderDetails.paymentGtwStatus) {
        orderDetails.paymentGtwStatus = 'unknown';
      }
      this.storeDbSvc
        .updateObject(
          storeId,
          this.utilSvc.sdb[storeId],
          objectName,
          orderDetails,
          orderDetails.orderId
        )
        .then(
          async data => {
            let user: AdnUserKamli;
            try {
              user = await this.userSvc.getUser(orderDetails.adnCustomerUserId) as AdnUserKamli;
            } catch(e) {

            }
            this.updateUser(user, this.utilSvc.currentToken);
            orderDetails['adnCustomerUser'] = user;
/*            this.updateOrderLight(
              storeId,
              orderDetails.adnCustomerUserId,
              orderDetails
            );*/
            resolve(data);
          },
          error => {
            console.log('error=', error);
            reject(error);
          }
        );
    });
  }

  updateOrder(
    adnCorporate: AdnCorporateI,
    adnStore: AdnStoreI,
    adnBusinessUser: AdnUser,
    adnCustomerUser: AdnUser,
    order: OrderI,
    update: ORDERSTATUS,
    comments?: string
  ) {
    return new Promise(async (resolve, reject) => {
      order.modifiedTS = new Date().getTime();
      try {
        adnCustomerUser = await this.userSvc.getUser(order.adnCustomerUserId) as AdnUser;
      } catch (e) { }
      switch (order.paymentGtwType) {
        case PAYMENTGW.CARD:
        case PAYMENTGW.IBAN:
          if (order.grandTotal + order.deliveryFee > 0) {
            let paymentIntentStatus;
            switch (update) {
              case ORDERSTATUS.ACCEPTED:
              case ORDERSTATUS.CANCELLED:
              case ORDERSTATUS.REFUSED:
                order.status = update;
                if (update === ORDERSTATUS.ACCEPTED) {
                  paymentIntentStatus = 'confirmPaymentIntent';
                } else {
                  paymentIntentStatus = 'cancelPaymentIntent';
                }
                order.status = update;
                this.updateOrderStripe(
                  order,
                  adnCorporate,
                  adnStore,
                  adnBusinessUser,
                  adnCustomerUser,
                  update,
                  paymentIntentStatus,
                  comments
                ).then(data => resolve(data), error => reject(error));
                break;
                case ORDERSTATUS.EXPIRED:
                  case ORDERSTATUS.ARCHIVED:
                order.archived = true;
                this.updateOrderObject(adnStore.adnStoreId, order).then(
                  data => {
                    resolve([UPDATEORDERSTATUS.SUCCES, data]);
                  },
                  error => {
                    reject(error);
                  }
                );
                break;
            }
          }
          break;
        case PAYMENTGW.TICKETRESTAURANT:
        case PAYMENTGW.CASH:
          switch (update) {
            case ORDERSTATUS.REFUSED:
            case ORDERSTATUS.ACCEPTED:
            case ORDERSTATUS.CANCELLED:
              order.status = update;
              this.updateOrderObject(adnStore.adnStoreId, order).then(data => {
                this.mailerSvc
                  .sendMailUpdatedOrder(
                    adnCorporate,
                    adnStore,
                    adnBusinessUser,
                    adnCustomerUser,
                    order as any,
                    order.grandTotal,
                    update,
                    comments
                  )
                  .then(
                    () => {
                      resolve([UPDATEORDERSTATUS.SUCCES, data]);
                    },
                    () => {
                      resolve([UPDATEORDERSTATUS.SUCCESNOEMAIL, data]);
                    }
                  );
              });
              break;
              case ORDERSTATUS.EXPIRED:
                case ORDERSTATUS.ARCHIVED:
              order.archived = true;
              this.updateOrderObject(adnStore.adnStoreId, order).then(
                data => {
                  resolve([UPDATEORDERSTATUS.SUCCES, data]);
                },
                error => {
                  reject(error);
                }
              );
              break;
          }
          break;

        default:
          break;
      }
    });
  }

  updateOrderStripe(
    order: OrderI,
    adnCorporate: AdnCorporateI,
    adnStore: AdnStoreI,
    adnBusinessUser: AdnUser,
    adnCustomerUser: AdnUser,
    orderStatus: ORDERSTATUS,
    stripeFunction,
    comments?: string
  ) {
    return new Promise((resolve, reject) => {
      this.paymentGwSvc[stripeFunction](order.paymentGtwData, adnBusinessUser.stripeAccount).then(
        data => {
          order.paymentGtwStatus = data.payment_intent  ? data.payment_intent.status  ? data.payment_intent.status
            : 'unknown' : 'unknown';
          this.updateOrderObject(adnStore.adnStoreId, order).then(
            data1 => {
              this.mailerSvc
                .sendMailUpdatedOrder(
                  adnCorporate,
                  adnStore,
                  adnBusinessUser,
                  adnCustomerUser,
                  order as any,
                  order.grandTotal,
                  orderStatus,
                  comments
                )
                .then(
                  () => {
                    resolve([UPDATEORDERSTATUS.SUCCES, data1]);
                  },
                  () => {
                    resolve([UPDATEORDERSTATUS.SUCCESNOEMAIL, data1]);
                  }
                );
            },
            error1 => reject(error1)
          );
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteOrderObject(storeId, orderDetails: OrderI) {
    return new Promise((resolve, reject) => {
      const objectName = OBJECTNAME.orders;
      this.storeDbSvc
        .removeObject(
          storeId,
          this.utilSvc.sdb[storeId],
          objectName,
          orderDetails.adnCustomerUserId + '/' + orderDetails.orderId
        )
        .then(
          data => {
            this.deleteOrderObjectLight(storeId, orderDetails);
            resolve(data);
          },
          error => reject(error)
        );
    });
  }

  deleteOrderObjectLight(storeId: string, orderDetails: OrderI) {
    return new Promise((resolve, reject) => {
      if (orderDetails.adnCustomerUserId  && orderDetails.adnCustomerUserId != null) {
        this.storeDbSvc
          .removeObject(
            this.utilSvc.backendFBstoreId,
            this.utilSvc.mdb,
            OBJECTNAME.adnUsers,
            orderDetails.adnCustomerUserId +
            '/' +
            OBJECTNAME.orderlight +
            '/' +
            storeId +
            '/' +
            orderDetails.orderId
          )
          .then(data => resolve(data), error => reject(error));
      } else {
        resolve(undefined);
      }
    });
  }

  sortOrders(storeOrders, sortType) {
    switch (sortType) {
      case SORTORDERSDIR.OrderIdUp:
        storeOrders.sort((a, b) => {
          return a.orderId > b.orderId ? 1 : b.orderId > a.orderId ? -1 : 0;
        });
        break;

      case SORTORDERSDIR.OrderIdDown:
        storeOrders.sort((a, b) => {
          return a.orderId > b.orderId ? -1 : b.orderId > a.orderId ? 1 : 0;
        });
        break;

      case SORTORDERSDIR.CDateUp:
        storeOrders.sort((a, b) => {
          return new Date(a.createdAt).getTime() >
            new Date(b.createdAt).getTime()
            ? 1
            : new Date(b.createdAt).getTime() > new Date(a.createdAt).getTime()
              ? -1
              : 0;
        });
        break;

      case SORTORDERSDIR.CDateDown:
        storeOrders.sort((a, b) => {
          return new Date(a.createdAt).getTime() >
            new Date(b.createdAt).getTime()
            ? -1
            : new Date(b.createdAt).getTime() > new Date(a.createdAt).getTime()
              ? 1
              : 0;
        });
        break;

      case SORTORDERSDIR.DeliveryUp:
        storeOrders.sort((a, b) => {
          return a.deliveryDetails.type > b.deliveryDetails.type
            ? 1
            : b.deliveryDetails.type > a.deliveryDetails.type
              ? -1
              : 0;
        });
        break;

      case SORTORDERSDIR.DeliveryDown:
        storeOrders.sort((a, b) => {
          return a.deliveryDetails.type > b.deliveryDetails.type
            ? -1
            : b.deliveryDetails.type > a.deliveryDetails.type
              ? 1
              : 0;
        });
        break;

      case SORTORDERSDIR.ODateUp:
        storeOrders.sort((a, b) => {
          return new Date(a.deliveryDetails.date).getTime() >
            new Date(b.deliveryDetails.date).getTime()
            ? 1
            : new Date(b.deliveryDetails.date).getTime() >
              new Date(a.deliveryDetails.date).getTime()
              ? -1
              : 0;
        });
        break;

      case SORTORDERSDIR.ODateDown:
        storeOrders.sort((a, b) => {
          return new Date(a.deliveryDetails.date).getTime() >
            new Date(b.deliveryDetails.date).getTime()
            ? -1
            : new Date(b.deliveryDetails.date).getTime() >
              new Date(a.deliveryDetails.date).getTime()
              ? 1
              : 0;
        });
        break;

      case SORTORDERSDIR.CustomerNameUp:
        storeOrders.sort((a, b) => {
          return a.customerName > b.customerName
            ? 1
            : b.customerName > a.customerName
              ? -1
              : 0;
        });
        break;

      case SORTORDERSDIR.CustomerNameDown:
        storeOrders.sort((a, b) => {
          return a.customerName > b.customerName
            ? -1
            : b.customerName > a.customerName
              ? 1
              : 0;
        });
        break;

      case SORTORDERSDIR.AmountUp:
        storeOrders.sort((a, b) => {
          return a.grandTotal > b.grandTotal
            ? 1
            : b.grandTotal > a.grandTotal
              ? -1
              : 0;
        });
        break;

      case SORTORDERSDIR.AmountDown:
        storeOrders.sort((a, b) => {
          return a.grandTotal > b.grandTotal
            ? -1
            : b.grandTotal > a.grandTotal
              ? 1
              : 0;
        });
        break;

      case SORTORDERSDIR.StatusUp:
        storeOrders.sort((a, b) => {
          return a.status > b.status ? 1 : b.status > a.status ? -1 : 0;
        });
        break;

      case SORTORDERSDIR.StatusDown:
        storeOrders.sort((a, b) => {
          return a.status > b.status ? -1 : b.status > a.status ? 1 : 0;
        });
        break;

      case SORTORDERSDIR.PaymentTypeUp:
        storeOrders.sort((a, b) => {
          return a.paymentMode.type > b.paymentMode.type
            ? 1
            : b.paymentMode.type > a.paymentMode.type
              ? -1
              : 0;
        });
        break;

      case SORTORDERSDIR.PaymentTypeDown:
        storeOrders.sort((a, b) => {
          return a.paymentMode.type > b.paymentMode.type
            ? -1
            : b.paymentMode.type > a.paymentMode.type
              ? 1
              : 0;
        });
        break;

      default:
        break;
    }
  }

  /*  getPayment(storeId, paymentId) {
      const objectName = OBJECTNAME.payments;
      return new Promise((resolve, reject) => {
        this.storeDbSvc
          .getObject(storeId, this.utilSvc.sdb[storeId], objectName, paymentId)
          .then(
            data => {
              resolve(data);
            },
            error => {
              reject(error);
            }
          );
      });
    }

    detachTableOrder(storeId, orderId) {
      return new Promise((resolve, reject) => {
        this.getOrder(storeId, orderId).then(data => {
          const order = data as OrderI;
          if (order ) {
            order.tabId = null;
            order.tabTimestamp = null;
            order.tableId = null;
            this.updateOrderObject(storeId, order).then(
              data1 => resolve(data1),
              error => reject(error)
            );
          }
        });
      });
    }*/

  checkExpiredOrders(
    storeOrders: OrderI[],
    smbCorporate,
    smbStore,
    adnBusinessUser
  ) {
    const promises = [];
    return new Promise((resolve, reject) => {
      if (storeOrders ) {
        for (const order of storeOrders) {
          if (!order.archived && order.status === ORDERSTATUS.PENDING) {
            let orderTS;
            const orderTSString = 'orderTS';
            if (order.deliveryDetails[orderTSString] ) {
              orderTS = order.deliveryDetails[orderTSString];
            } else {
              orderTS = Date.parse(
                order.deliveryDetails.date + ' ' + order.deliveryDetails.time
              );
            }
            const nowTS = new Date().getTime();
            if (nowTS > orderTS) {
              this.userSvc.getUser(order.adnCustomerUserId).then(data => {
                const adnCustomerUser = data as AdnUser;
                promises.push(
                  this.updateOrder(
                    smbCorporate,
                    smbStore,
                    adnBusinessUser,
                    adnCustomerUser,
                    order,
                    ORDERSTATUS.EXPIRED
                  )
                );
              });
            }
          }
        }
        Promise.all(promises).then(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

/*  createPaymentIntent(
    sourceType,
    cart: CART,
    adnCustomerUser: AdnUser,
    adnBusinessUser: AdnUser
  ) {
    let paymentIntent;
    return new Promise(async (resolve, reject) => {
      try {
        paymentIntent = await this.paymentGwSvc.createOrderPaymentIntent(
          sourceType,
          cart,
          adnCustomerUser,
          adnBusinessUser
        );
        resolve(paymentIntent);
      } catch (e) {
        reject(e);
      }
    });
  }

  submitPaymentIntent(
    sourceType: string,
    stripeElement: any,
    paymentIntent,
    adnCorporate: AdnCorporateI,
    adnStore: AdnStoreI,
    cart: CART,
    adnCustomerUser: AdnUser,
    adnBusinessUser: AdnUser
  ) {
    return new Promise(async (resolve, reject) => {
      if (sourceType === 'stripe') {

      } else {
        try {
          const idString = 'id';
          const result = await this.createOrder(
            adnCorporate,
            adnStore,
            adnBusinessUser,
            adnCustomerUser,
            cart,
            sourceType,
            paymentIntent[idString]
          );
          resolve(result[1]);
        } catch (e) {
          console.log('error createOrder=', e);
          reject(e);
        }
      }
    });
  }

  createSetupIntent(
    paymentMethod: string,
    adnCustomerUser: AdnUser,
    adnBusinessUser: AdnUser
  ) {
    return this.paymentGwSvc.createStripeSetupIntent(
      paymentMethod,
      adnCustomerUser,
      adnBusinessUser.stripeAccount
    );
  }

  submitSetupIntent(
    sourceType: PAYMENTGW,
    stripeElement: any,
    clientSecret: string,
    adnCorporate: AdnCorporateI,
    adnStore: AdnStoreI,
    cart,
    adnCustomerUser: AdnUser,
    adnBusinessUser: AdnUser
  ) {
    return new Promise((resolve, reject) => {
      if (sourceType === PAYMENTGW.CARD) {
        this.paymentGwSvc
          .confirmCardPayment(stripeElement, clientSecret, adnCustomerUser)
          .then(async data => {
            const idString = 'id';
            try {
              const result = await this.createOrder(
                adnCorporate,
                adnStore,
                adnBusinessUser,
                adnCustomerUser,
                cart,
                sourceType,
                data[idString]
              );
              resolve(result[1]);
            } catch (e) {
              reject(e);
            }
          });
      } else if (sourceType === PAYMENTGW.IBAN) {
        this.paymentGwSvc
          .confirmIbanPayment(stripeElement, clientSecret, adnCustomerUser)
          .then(async data => {
            const idString = 'id';
            try {
              const result = await this.createOrder(
                adnCorporate,
                adnStore,
                adnBusinessUser,
                adnCustomerUser,
                cart,
                sourceType,
                data[idString]
              );
              resolve(result[1]);
            } catch (e) {
              reject(e);
            }
          });
      }
    });
  }*/

  public async updateUser(user: AdnUserKamli, token: string): Promise<AdnUserKamli> {
    return new Promise(async (resolve, reject) => {
        const proceeds = [];
        // register token for private notifications
        if (token && token !== user?.token) {
            user.token = token;
            await this.userSvc.updateUser(user);
        }

        // subscribe to referrals promotiona
        if (user && user.referrals && this.utilSvc.fcm && this.utilSvc.platformDevice && this.utilSvc.platformDevice.is('cordova')) {
            for (const r of user.referrals) {
                if (this.utilSvc.appName === KamliApp.KAMLISTORE && r.referralId) {
                  console.log('subscribe to %s', r.referralId + '-promos');
                    this.utilSvc.fcm.subscribe(r.referralId + '-promos');
                    let referralstore: AdnStoreI;
                    try {
                      referralstore = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, r.referralId) as AdnStoreI;
                    } catch(e) {}
                    if (referralstore && referralstore.assistantId) {
                      console.log('subscribe to %s', referralstore.assistantId + '-store');
                      this.utilSvc.fcm.subscribe(referralstore.assistantId + '-store');
                    }
                }
                if (this.utilSvc.appName === KamliApp.KAMLIADMIN  && r.referralId) {
                    this.utilSvc.fcm.subscribe(r.referralId + '-orders');
                    let referralstore: AdnStoreI;
                    try {
                      referralstore = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, r.referralId) as AdnStoreI;
                    } catch(e) {}
                    if (referralstore && referralstore.assistantId) {
                      this.utilSvc.fcm.subscribe(referralstore.assistantId + '-publish');
                    }
                }
            }
        }

        if (user.storevisited) {
            user.userorders = [] as AdnUserOrders[];
            user.usercarts = [] as AdnUserCart[];
            for (const storeId of user.storevisited) {
                let store;
                let proceed = true;
                try {
                    store = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId);
                } catch (e) {
                }
                if (store) {
                    let tempUserOrders: AdnUserOrders;
                    tempUserOrders = user.userorders && user.userorders.find(o => o.store ? o.store.adnStoreId === storeId : false) as AdnUserOrders;
                    if (!tempUserOrders) {
                        tempUserOrders = {} as AdnUserOrders;
                        tempUserOrders.store = store;
                        tempUserOrders.value = [] as OrderI[];
                    }
                    let tempp;
                    try {
                        tempp = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.orders);
                    } catch (e) {
                    }
                    if (tempp) {
                        proceed = false;
                        const temp2 = this.utilSvc.objectToArray(tempp) as OrderI[];
                        const temp3 = temp2 && temp2.filter(o => o.adnCustomerUserId === user.adnUserId) as OrderI[];
                        if (temp3) {
                            if (!tempUserOrders.value) {
                                tempUserOrders.value = [];
                            }
                            tempUserOrders.value = tempUserOrders.value.concat(temp3);
                        }
                    }

                    let tempUserCart: AdnUserCart;
                    tempUserCart = user.usercarts && user.usercarts.find(o => o.store ? o.store.adnStoreId === storeId : false) as AdnUserCart;
                    if (!tempUserCart) {
                        tempUserCart = {} as AdnUserCart;
                        tempUserCart.store = store;
                        tempUserCart.value = {} as CART;
                    } else if (user.usercarts) {
                        const tempi = user.usercarts.findIndex(o => o.store.adnStoreId === storeId);
                        if (tempi !== -1) {
                            user.usercarts.splice(tempi, 1);
                        }
                    }

                    let tempc;
                    try {
                        tempc = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.cart);
                    } catch (e) {
                    }
                    if (tempc) {
                        proceed = false;
                        const temp21 = tempc[user.adnUserId];
                        tempUserCart.value = temp21;
                    }
                    if (tempUserCart && tempUserCart.value && tempUserCart.value.cartId) {
                        user.usercarts.push(tempUserCart);
                    }
                    if (tempUserOrders && tempUserOrders.value && tempUserOrders.value.length > 0) {
                        user.userorders.push(tempUserOrders);
                    }
                }
                if (proceed) {
                    proceeds.push(storeId);
                }
            }
            for (const sid of proceeds) {
                const t = user.storevisited.findIndex(sv => sv === sid);
                if (t !== -1) {
                    user.storevisited.splice(t, 1);
                }
            }
        }
        resolve(user);
    });
}


}
