/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs';
import { StoreDbService, OBJECTNAME, AdnUser, AdnUserAddress, AdnUserKamli } from '../godigital-lib';
import { UtilsService, } from '../godigital-lib';
import { UsersService } from '../godigital-lib';
import { SmbService, AdnStoreI, } from '../godigital-lib';
import { DeliveryService, DELIVERYMETHOD } from '../godigital-lib';
import { PRODUCTIKamli } from '../godigital-lib';
import { FormGroup } from '@angular/forms';
import { GeoCoord } from 'ng2-haversine';
import { DatePipe, } from '@angular/common';

export enum SORTCARTDIR {
  ProductNameUp,
  ProductNameDown,
  PriceUp,
  PriceDown,
}

export enum CARTSTATUS {
  REQUEST,
  RESPONSEOK,
  RESPONSEKO,
}

export interface CART {
  createdTS: number;
  modifiedTS: number;
  deliveryDetails: CARTDELIVERY;
  cartId: string;
  deliveryFee: number;
  timeStamp: any;
  cartItems: Array<CARTITEM>;
  grandTotal: number;
  grandTotalVAT: any;
  status: CARTSTATUS;
  adnUserId: string;
  totalCommission: number;
  deliveryType: DELIVERYMETHOD;
}

export interface CARTDELIVERY {
  type?: DELIVERYMETHOD;
  date?: any;
  time?: any;
  createdTS: number;
  modifiedTS: number;
  nbPeople?: number;
  preference?: string;
  name: string;
  email: string;
  mobileNo: string;
  addressFullText: string;
  addressLat: number;
  addressLng: number;
  complementInfo: string;
  nickName: string;
  duration: number;
  distance: number;
  street_number: number;
  route: number;
  postal_code: string;
  locality: string;
  country: string;
  contactName: string;
  inhousedining: boolean;
  comments: string;
  smsReminder: boolean;
  splitMethod: string;
  splitDivider: number;
  splitAmount: number;
}

enum dateTimeValidationError {
  passedDate,
  closedBusiness,
  OK
}


export interface CARTITEM extends PRODUCTIKamli {
  cartItemId: string;
  quantity: number;
  totalPrice: number;
  extraPrice: number;
  totalVAT: any;
  extraVAT: any;
  size: number;
}

export enum ADDCARTSTATUS {
  SIMPLEPRODUCT = 1,
  COMPLEXPRODUCT = 2,
  MENUPRODUCT = 3,
  USER_NOT_LOGGEDIN = 7,
}

export enum UPDATECARTSTATUS {
  UPDATEOK = 0,
  DATEINTHEPAST = 1,
  STORECLOSED = 2,
  INVALIDDATETIME = 3,
  UNKNOWNERROR = 4,
}


@Injectable({
  providedIn: 'root'
})

export class CartService {
  constructor(
    public storeDbSvc: StoreDbService,
    public utilSvc: UtilsService,
    public datepipe: DatePipe,
    public userSvc: UsersService,
    public smbSvc: SmbService,
    public deliverySvc: DeliveryService,
  ) { }

  getCart(storeId: string, userId: string): Promise<CART> {
    const objectName = OBJECTNAME.cart;
    return new Promise((resolve, reject) => {
      this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName, userId).then(
        data => {
          resolve(data as CART);
        },
        error => reject(error)
      );
    });
  }

  createCartLight(storeId: string, userId: string) {
    return new Promise(async (resolve, reject) => {
      let error;
      let user: AdnUser;
      try {
        user = await this.userSvc.addVisitedStore(userId, storeId);
      } catch (e) {
        error = e;
      }
    });
  }

  createCart(storeId: string, userId: string, deliveryType?: DELIVERYMETHOD): Promise<CART> {
    return new Promise((resolve, reject) => {
      const objectName = OBJECTNAME.cart;
      const cart: CART = {} as CART;
      if (!deliveryType) {
        deliveryType = DELIVERYMETHOD.PayCollect;
      }
      cart.deliveryType = deliveryType;
      cart.cartId = String(Math.floor(Math.random() * 100000));
      cart.status = CARTSTATUS.RESPONSEOK;
      cart.timeStamp = Date();
      cart.cartItems = [];
      cart.createdTS = new Date().getTime();
      cart.modifiedTS = new Date().getTime();
      this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, cart, userId).then(
        data => {
          let error;
          try {
            this.createCartLight(userId, storeId);
          } catch (e) {
            error = e;
          }
          if (error) {
            reject(error);
          } else {
            resolve(cart);
          }
        },
        error => reject(error)
      );
    });
  }

  deleteCart(storeId: string, userId: string) {
    return new Promise((resolve, reject) => {
      const objectName = OBJECTNAME.cart;

      this.storeDbSvc.removeObject(storeId, this.utilSvc.sdb[storeId], objectName, userId).then(
        async data => {
          resolve(data);
        },
        error => reject(error)
      );
    });
  }

  addItemCart(storeId: string, userId: string, cartItem: CARTITEM, commissionPercent?: number, deliveryType?: DELIVERYMETHOD,
    productType?) {
    return new Promise(async (resolve, reject) => {
      let promise;
      if (!commissionPercent) {
        commissionPercent = 0;
      }

      let cart: CART;
      let error;
      try {
        cart = await this.getCart(storeId, userId);
      } catch (e) {
        error = e;
      }

      if (error) {
        reject(error);
      } else {
        if (!cart) {
          try {
            cart = await this.createCart(storeId, userId, deliveryType);
          } catch (e) {
            error = e;
          }
          if (error || !cart) {
            reject(error);
          }
        }
        if (cart) {
          let proceed = true;
          if (!cartItem?.cartItemId || cartItem.cartItemId === '') {
            if (productType === ADDCARTSTATUS.SIMPLEPRODUCT) {
              const cartItemExistingI = cart.cartItems &&
                cart.cartItems.findIndex(ci => String(ci.productId) === String(cartItem.productId));
              if (cartItemExistingI !== -1) {
                const cartItemExisting = cart.cartItems[cartItemExistingI];
                cartItem = cartItemExisting;
                cartItem.quantity++;
                cart.cartItems.splice(cartItemExistingI, 1);
                proceed = false;
              }
            }
            if (proceed) {
              cartItem.cartItemId = String(Math.floor(Math.random() * 100000));
              if (!cart.cartItems) {
                cart.cartItems = [];
              }
            }
          } else {
            const cartItemIndex = cart.cartItems.findIndex(ci => ci.cartItemId === cartItem.cartItemId);
            if (cartItemIndex !== -1) {
              cart.cartItems.splice(cartItemIndex, 1);
            }
          }
          cart.cartItems.push(cartItem);
          this.cartGrandTotal(cart, commissionPercent);
          cart.modifiedTS = new Date().getTime();
          this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.cart, cart, userId).then(
            () => {
              try {
                this.userSvc.addVisitedStore(userId, storeId);
              } catch (e) {
                error = e;
              }
              if (error) {
                reject(error);
              } else {
                resolve(cart);
              }
            },
            error1 => reject(error1)
          );//
        } else {
          reject(undefined);
        }
      }
    });
  }

  updateCartDeliveryDetails(storeId: string, adnUserId: string, storeCart: CART, deliveryDetails: CARTDELIVERY) {
    return new Promise((resolve, reject) => {
      if (storeCart) {
        const objectName = OBJECTNAME.cart + '/' + adnUserId;
        if (!storeCart.deliveryDetails) {
          storeCart.deliveryDetails = {} as CARTDELIVERY;
        }
        storeCart.deliveryDetails = Object.assign(storeCart.deliveryDetails, deliveryDetails);

        this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, storeCart).then(data => {
          resolve(data);
        });
      } else {
        reject('storeCart is undefined');
      }
    });
  }

  removeItemCart(storeId: string, userId: string, cartItemId: string, commissionPercent?: number) {
    return new Promise((resolve, reject) => {
      if (!commissionPercent) {
        commissionPercent = 0;
      }

      const objectName = OBJECTNAME.cart + '/' + userId;
      this.getCart(storeId, userId).then(
        data => {
          const cart: CART = data as CART;
          const cartItemIndex = cart.cartItems.findIndex(ci => ci.cartItemId === cartItemId);
          if (cartItemIndex !== -1) {
            cart.cartItems.splice(cartItemIndex, 1);
            if (cart.cartItems.length > 0) {
              this.cartGrandTotal(cart, commissionPercent);
              this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, cart).then(
                data1 => {
                  resolve(data1);
                },
                error => reject(error)
              );
            } else {
              this.deleteCart(storeId, userId);
              resolve(1);
            }
          }
        }
      );
    });
  }

  IncrementItemQuantity(storeId: string, userId: string, cartItemId: string, commissionPercent?: number) {
    return new Promise((resolve, reject) => {
      if (!commissionPercent) {
        commissionPercent = 0;
      }

      const objectName = OBJECTNAME.cart + '/' + userId;
      this.getCart(storeId, userId).then(
        data => {
          const cart: CART = data as CART;
          const cartItem = cart.cartItems.find(ci => ci.cartItemId === cartItemId);
          if (cartItem.quantity < 10) {
            cartItem.quantity++;
            this.cartGrandTotal(cart, commissionPercent);
            this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, cart).then(
              data1 => {
                this.cartGrandTotal(cart, commissionPercent);
                resolve(data1);
              }
            );
          } else {
            resolve(data);
          }

        }
      );
    });
  }

  DecrementItemQuantity(storeId: string, userId: string, cartItemId: string, commissionPercent?: number) {
    return new Promise((resolve, reject) => {
      if (!commissionPercent) {
        commissionPercent = 0;
      }

      const objectName = OBJECTNAME.cart + '/' + userId;
      this.getCart(storeId, userId).then(
        data => {
          const cart: CART = data as CART;
          const cartItem = cart.cartItems.find(ci => ci.cartItemId === cartItemId);
          if (cartItem.quantity > 1) {
            cartItem.quantity--;
            this.cartGrandTotal(cart, commissionPercent);
            this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, cart).then(
              data1 => {
                resolve(data1);
              }
            );
          } else {
            resolve(data);
          }
        }
      );
    });
  }

  setCartItemQuantity(storeId: string, userId: string, cartItem1: CARTITEM, commissionPercent?: number) {
    return new Promise((resolve, reject) => {
      if (!commissionPercent) {
        commissionPercent = 0;
      }

      const objectName = OBJECTNAME.cart + '/' + userId;
      this.getCart(storeId, userId).then(
        data => {
          const cart: CART = data as CART;
          const cartItem = cart.cartItems.find(ci => ci.cartItemId === cartItem1.cartItemId);
          if (cartItem && cartItem.quantity > 0 && cartItem.quantity < 10) {
            this.cartGrandTotal(cart, commissionPercent);
            this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, cart).then(
              data1 => {
                resolve(data1);
              }
            );
          } else {
            resolve(data);
          }
        }
      );
    });
  }



  cartGrandTotal(storeCart: CART, commissionPercent?: number) {
    if (storeCart) {
      storeCart.deliveryFee = storeCart.deliveryFee ? storeCart.deliveryFee : 0;
      if (!commissionPercent) {
        commissionPercent = 0;
      }

      storeCart.grandTotal = 0;
      storeCart.grandTotalVAT = {};
      storeCart.cartItems.forEach(element => {
        storeCart.grandTotal += element.totalPrice * element.quantity;
        for (const key in element.totalVAT) {
          if (element.totalVAT[key]) {
            const key2 = String(key);
            if (!storeCart.grandTotalVAT[key2]) {
              storeCart.grandTotalVAT[key2] = 0;
            }
            storeCart.grandTotalVAT[key2] += element.totalVAT[key2] * element.quantity;
          }
        }
      });
      storeCart.grandTotal = Math.round(storeCart.grandTotal * 100) / 100;
      for (const key in storeCart.grandTotalVAT) {
        if (storeCart.grandTotalVAT[key]) {
          storeCart.grandTotalVAT[key] = Math.round(storeCart.grandTotalVAT[key] * 100) / 100;
        }
      }
      storeCart.totalCommission = Math.round(storeCart.grandTotal * commissionPercent * 100) / 100;
    }
  }

  public updateCart(adnCustomerUser: AdnUser, smbStore: AdnStoreI, storeCart: CART, deliveryDetails, errorMessage) {
    return new Promise((resolve, reject) => {
      const dateString = 'date';
      const timeString = 'time';
      const orderTSString = 'orderTS';
      const requestedDate = deliveryDetails[dateString];
      const requestedTime = deliveryDetails[timeString];
      const temp = this.deliverySvc.validateDateTime(smbStore, requestedDate, requestedTime);

      if (temp === dateTimeValidationError.passedDate) {
        const temp1 = {
          title: 'Date in the past',
          description: 'the selected date is in the past. please select a date in the future',
          details: ''
        };
        this.utilSvc.copyObject(errorMessage, temp1);
        reject(UPDATECARTSTATUS.DATEINTHEPAST);
      } else if (temp === dateTimeValidationError.closedBusiness) {
        const temp1 = {
          title: 'Store closed at this time',
          description: 'Our store is closed at this time. Please check our opening hours on our main page',
          details: ''
        };
        this.utilSvc.copyObject(errorMessage, temp1);
        reject(UPDATECARTSTATUS.STORECLOSED);
      } else if (temp === dateTimeValidationError.OK) {
        deliveryDetails[orderTSString] = new Date(requestedDate).getTime() + requestedTime * 3600000;
        this.updateCartDeliveryDetails(smbStore.adnStoreId, adnCustomerUser.adnUserId,
          storeCart, deliveryDetails).then(
            data => {
              storeCart = data as CART;
              resolve(UPDATECARTSTATUS.UPDATEOK);
            },
            error => {
              const temp1 = {
                title: 'System error',
                description: 'Please try again later',
                details: error
              };
              this.utilSvc.copyObject(errorMessage, temp1);
              reject(UPDATECARTSTATUS.UNKNOWNERROR);
            }
          );
      } else {
        const temp1 = {
          title: 'invalid date/time',
          description: 'invalid date/time',
          details: ''
        };
        this.utilSvc.copyObject(errorMessage, temp1);
        reject(UPDATECARTSTATUS.INVALIDDATETIME);
      }
    });
  }

  submitPayDeliverDetails(
    adnCustomerUser: AdnUser, payDeliverForm: FormGroup, selectedAddress: AdnUserAddress, fulladdressTextV: AdnUserAddress,
    currentStore: AdnStoreI, storeCart: CART, errorMessage) {
    return new Promise(async (resolve, reject) => {
      if (payDeliverForm.valid) {
        let proceed = true;
        if (payDeliverForm.value.typeAddress === 2) {
          selectedAddress = fulladdressTextV;
          try {
            await this.userSvc.addUserAddress(adnCustomerUser, fulladdressTextV, payDeliverForm.value.complementInfo);
          } catch (e) { proceed = false; }
        }
        if (proceed) {
          if (payDeliverForm.value.selectedAddressIndex === -1) {
            if (!fulladdressTextV) {
              proceed = false;
              const temp1 = {
                title: 'Address not recognised',
                description: 'Address not recognised. Please try to re-enter the delivery address',
                details: ''
              };
            }
          } else {
            if (adnCustomerUser.addresses) {
              if (!adnCustomerUser.addresses[payDeliverForm.value.selectedAddressIndex]) {
                proceed = false;
                const temp1 = {
                  title: 'Wrong pre-registered address',
                  description: 'Wrong pre-registered address. Please try to enter a new delivery address',
                  details: ''
                };
                this.utilSvc.copyObject(errorMessage, temp1);
              }
            } else {
              proceed = false;
              const temp1 = {
                title: 'You have no pre-registered address',
                description: 'You have no pre-registered address. Please try to enter a new delivery address',
                details: ''
              };
              this.utilSvc.copyObject(errorMessage, temp1);
            }
          }
          if (proceed) {
            const from = {
              latitude: currentStore.lat,
              longitude: currentStore.lng
            } as GeoCoord;
            const to = {
              latitude: selectedAddress.latlng.lat,
              longitude: selectedAddress.latlng.lng
            } as GeoCoord;
            if (from.latitude  && from.longitude  &&
              to.latitude  && to.longitude ) {
              const complementInfoString = 'complementInfo';
              const distanceString = 'distance';
              const durationString = 'duration';
              const distance = this.utilSvc.haversineService.getDistanceInKilometers(
                from,
                to
              );
              selectedAddress[complementInfoString] = payDeliverForm.value.complementInfo;
              selectedAddress[distanceString] = distance;
              selectedAddress[durationString] = distance;
              if (this.deliverySvc.validateAddress(selectedAddress, currentStore.kamliwebDetails.clickAndDeliver.maximumDeliveryRadius,
                errorMessage)) {
                let deliveryDetails = {} as CARTDELIVERY;
                deliveryDetails = Object.assign(deliveryDetails, selectedAddress);
                deliveryDetails.name = payDeliverForm.value.name;
                deliveryDetails.email = payDeliverForm.value.email;
                deliveryDetails.mobileNo = payDeliverForm.value.mobileNo;
                deliveryDetails.type = DELIVERYMETHOD.PayDeliver;
                deliveryDetails.date = payDeliverForm.value.date;
                deliveryDetails.time = payDeliverForm.value.time;
                deliveryDetails.mobileNo = payDeliverForm.value.mobileNo;
                deliveryDetails.comments = payDeliverForm.value.comments;
                if (payDeliverForm.value.napkins > 0) {
                  deliveryDetails.comments += ' Number of napkins=' + String(payDeliverForm.value.napkins);
                }
                deliveryDetails.inhousedining = false;
                this.updateCart(adnCustomerUser, currentStore, storeCart, deliveryDetails, errorMessage).then(
                  data => {
                    resolve(data);
                  },
                  error => {
                    console.log('error=', error);
                    reject(error);
                  }
                );
              } else {
                reject(1);
              }
            } else {
              reject(1);
            }
          }
        } else {
          const temp1 = {
            title: 'cannot register this address',
            description: 'cannot register this address. Please try to enter a new delivery address',
            details: ''
          };
          this.utilSvc.copyObject(errorMessage, temp1);
        }
      }

    });
  }

  public submitPayCollectDetails(
    adnCustomerUser: AdnUser, smbStore: AdnStoreI,
    storeCart: CART, payCollectForm: FormGroup, errorMessage) {
    return new Promise((resolve, reject) => {
      const deliveryDetails = {} as CARTDELIVERY;
      deliveryDetails.name = payCollectForm.value.name;
      deliveryDetails.email = payCollectForm.value.email;
      deliveryDetails.mobileNo = payCollectForm.value.mobileNo;
      deliveryDetails.type = DELIVERYMETHOD.PayCollect;
      deliveryDetails.date = payCollectForm.value.date;
      deliveryDetails.time = payCollectForm.value.time;
      deliveryDetails.comments = payCollectForm.value.comments;
      deliveryDetails.inhousedining = false;
      this.updateCart(adnCustomerUser, smbStore, storeCart, deliveryDetails, errorMessage).then(
        data => resolve(data),
        error => {
          console.log('error=', error);
          reject(error);
        }
      );
    });
  }

  public submitPayDineDetails(
    adnCustomerUser: AdnUser, smbStore: AdnStoreI,
    storeCart: CART, payDineForm: FormGroup, errorMessage) {
    return new Promise((resolve, reject) => {
      const deliveryDetails = {} as CARTDELIVERY;
      deliveryDetails.name = payDineForm.value.name;
      deliveryDetails.email = payDineForm.value.email;
      deliveryDetails.mobileNo = payDineForm.value.mobileNo;
      deliveryDetails.type = DELIVERYMETHOD.PayDine;
      deliveryDetails.date = payDineForm.value.date;
      deliveryDetails.time = payDineForm.value.time;
      deliveryDetails.nbPeople = payDineForm.value.nbPeople;
      deliveryDetails.preference = payDineForm.value.preference;
      deliveryDetails.comments = payDineForm.value.comments;
      deliveryDetails.inhousedining = false;
      this.updateCart(adnCustomerUser, smbStore, storeCart, deliveryDetails, errorMessage).then(
        data => resolve(data),
        error => reject(error)
      );
    });
  }

  public submitInHouseDetails(
    adnCustomerUser: AdnUser, smbStore: AdnStoreI,
    storeCart: CART, payDineForm: FormGroup, errorMessage) {
    return new Promise((resolve, reject) => {

      const dateDelivery = new Date();
      dateDelivery.setHours(dateDelivery.getHours(), dateDelivery.getMinutes() + 10);
      const today = this.datepipe.transform(dateDelivery, 'yyyy-MM-dd');
      const now = this.datepipe.transform(dateDelivery, 'HH:MM');

      const deliveryDetails = {} as CARTDELIVERY;
      deliveryDetails.type = DELIVERYMETHOD.PayDine;
      deliveryDetails.date = today;
      deliveryDetails.time = now;
      deliveryDetails.nbPeople = 2;
      deliveryDetails.preference = '';
      deliveryDetails.name = adnCustomerUser.name;
      deliveryDetails.email = adnCustomerUser.email;
      deliveryDetails.mobileNo = adnCustomerUser.mobileNo;
      deliveryDetails.inhousedining = true;
      this.updateCart(adnCustomerUser, smbStore, storeCart, deliveryDetails, errorMessage).then(
        data => resolve(data),
        error => reject(error)
      );
    });
  }


}


