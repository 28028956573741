import { PreloadAllModules, RouterModule, } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { Page404Component } from './page404/page404.component';
import { externalUrlProvider } from 'godigital-lib';

export const routes = [
  {
    path: '', component: LayoutComponent, children: [
      { 
        path: '',
        loadChildren: () => import('./home/home.module').then(x => x.HomeModule)
      },
      {
        path: '',
        loadChildren: () => import('./kamliaccount/kamliaccount.module').then(x => x.KamliaccountModule)
      },
      {
        path: '',
        loadChildren: () => import('./catalogue/catalogue.module').then(x => x.CatalogueModule)
      },
    ]
  },
  { path: '**', component: Page404Component },
];

export const routing = RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload'
});
