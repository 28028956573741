import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UtilsService, ServicesService, ORDERSTATUS, AdnUser, AdnStoreIKamli } from 'godigital-lib';
import { Subscription, } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../layout.service';
import { LocalUtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public loaded = 0;
  public adnBusinessUser: AdnUser;
  public adnUserAuth = false;
  public hasStores = false;
  public componentName = 'header.component';
  public subscriptions = new Subscription();

  constructor(
    public router: Router,
    public translateSvc: TranslateService,
    public utilSvc: UtilsService,
    public layoutSvc: LayoutService,
    public mainSvc: ServicesService,
    public localUtilsSvc: LocalUtilsService,
  ) {
  }

  ngOnInit() {
    this.localUtilsSvc.error = {};
    this.ngOnProceed();
  }

  ngOnProceed() {
    this.subscriptions.add(
      // tslint:disable-next-line: deprecation
      this.mainSvc.getLanguage().subscribe(language => {
        this.translateSvc.use(language);
      }));
    this.subscriptions.add(
      // tslint:disable-next-line: deprecation
      this.layoutSvc.mainSvc.getCurrentCorporate().subscribe(corporate => {
        this.layoutSvc.currentCorporate = corporate;
      }));
    this.subscriptions.add(
      // tslint:disable-next-line: deprecation
      this.layoutSvc.mainSvc.getCurrentStore().subscribe(store => {
        this.layoutSvc.currentStore = store;
      }));
    this.subscriptions.add(
      // tslint:disable-next-line: deprecation
      this.layoutSvc.mainSvc.getCurrentStores().subscribe(stores => {
        this.layoutSvc.currentStores = stores as AdnStoreIKamli[];
      }));

    this.subscriptions.add(
      // tslint:disable-next-line: deprecation
      this.mainSvc.getLoggedUser().subscribe(user => {
        this.adnBusinessUser = user;
        this.adnUserAuth = user !== undefined;
        if (this.mainSvc.currentStores !== undefined) {
          this.hasStores = this.mainSvc.currentStores.length > 0;
        }
      }));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.layoutSvc.logout();
  }

  setLanguage(lang) {
    this.mainSvc.setLanguage(lang);
  }

  manage() {
    if (this.layoutSvc.currentStores !== undefined && this.layoutSvc.currentStores != null) {
      if (this.layoutSvc.currentStores.length > 1) {
        this.router.navigate(['/complexaccount']);
      }
      else {
        this.router.navigate(['/simpleaccount']);
      }
    } else {
      this.router.navigate(['/signup']);
    }
  }
}
