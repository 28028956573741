import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService } from '../godigital-lib';
import { StripeScriptService } from '../godigital-lib';
import { UsersService,  } from '../godigital-lib';
import { AdnUser,  } from '../godigital-lib';
import { PRODUCTTYPE } from '../godigital-lib';
import {
  PublishCampaign,
  PublishOrder
} from '../godigital-lib';
// test
import { environment } from './environments/environment';
// prod
// import { environment } from './environments/environment.prod';
import { CART } from '../godigital-lib';

export interface StripePlanI {
  amount: number;
  interval: STRIPEPLANINTERVAL;
  product: {
    name: string;
  };
  type: PRODUCTTYPE;
  productId: string;
  stripeDetails;
}
export enum STRIPEPLANINTERVAL {
  month = 'month',
  year = 'year'
}

export interface PaymentGWDetailsI {
  paymentGatewayDetails: any;
  paymentGateway: PAYMENTGW;
}

export enum PAYMENTGW {
  CARD = 'card',
  IBAN = 'sepa_debit',
  CASH = 'cash',
  FREE = 'free',
  TICKETRESTAURANT = 'ticketrestaurant'
}

export interface StripeDetailsI {
  stripeUser: any;
  stripeAccount: any;
}

export enum STRIPEACCOUNTCREATION {
  ACCOUNTCREATED,
  ACCOUNTCONNECTED,
  ACCOUNTEXIST,
  ACCOUNTNOTCONNECTED
}

declare var Stripe: any;

@Injectable({
  providedIn: 'root'
})
export class PaymentGwService {
  public elementss = {};
  public cards = {};
  public stripes = {};
  public stripeLoaded = { 0: false };
  public currentElements;
  public currentCard;
  public currentStripe;
  public currentStripeId = 0;

  constructor(
    public http: HttpClient,
    public userSvc: UsersService,
    public utilSvc: UtilsService,
    public stripeScriptService: StripeScriptService
  ) {
    this.setStripeEnvInit();
  }

  setStripeEnvInit() {
    if (!this.stripeLoaded[0]) {
      this.stripeScriptService.registerScript(() => {
        this.setStripeEnv(0);
      });
    }
  }

  setStripeEnv(connectedAccountId) {
    if (connectedAccountId) {
      if (!this.stripeLoaded[connectedAccountId]) {
        this.stripeLoaded[connectedAccountId] = false;
      }
      const toto = {
        style: {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '40px',
            fontWeight: 300,
            fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
            fontSize: '18px',
            '::placeholder': {
              color: '#CFD7E0'
            }
          }
        }
      };

      if (!this.stripeLoaded[connectedAccountId]) {
        if (connectedAccountId !== 0) {
          this.stripes[connectedAccountId] = Stripe(
            environment.payment.stripe.publishableKey,
            { stripeAccount: connectedAccountId }
          );
          this.currentStripeId = connectedAccountId;
        } else {
          this.stripes[connectedAccountId] = Stripe(
            environment.payment.stripe.publishableKey
          );
        }
        this.elementss[connectedAccountId] = this.stripes[connectedAccountId].elements();
        this.cards[connectedAccountId] = this.elementss[connectedAccountId].create('card', toto);
        this.currentStripe = this.stripes[connectedAccountId];
        this.currentElements = this.elementss[connectedAccountId];
        this.currentCard = this.cards[connectedAccountId];
        this.stripeLoaded[connectedAccountId] = true;
      }
    }
  }

  //  updateUserPaymentDetailsStripe(adnUser: AdnUser, cardDetails) {
  updateStripeUserCard(adnUser: AdnUser, cardDetails, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      let promise1;
      const cardElement = cardDetails.cardElement;
      let stripeUser;

      this.createSourceCard(cardElement).then(
        data => {
          const idString = 'id';
          const sourceId = data[idString];
          if (sourceId) {
            if (!adnUser.stripeCustomers[stripeSmbAccount]) {
              promise1 = new Promise((resolve1, reject1) => {
                this.createStripeUser(adnUser, stripeSmbAccount).then(
                  data1 => {
                    stripeUser = data1;
                    resolve1(data1);
                  },
                  error1 => {
                    reject1(error1);
                  }
                );
              });
            } else {
              promise1 = new Promise((resolve1, reject1) =>
                this.retrieveStripeCustomer(adnUser, stripeSmbAccount).then(
                  data1 => {
                    stripeUser = data1;
                    resolve1(adnUser.stripeCustomers[stripeSmbAccount]);
                  },
                  error1 => {
                    this.createStripeUser(adnUser, stripeSmbAccount).then(
                      data2 => {
                        stripeUser = data2;
                        resolve1(data2);
                      },
                      error2 => {
                        reject1(error2);
                      }
                    ); //
                  }
                )
              );
            }

            promise1.then(
              data2 => {
                this.attachStripeSource(
                  adnUser,
                  sourceId,
                  stripeSmbAccount
                ).then(
                  data3 => {
                    resolve(data3);
                  },
                  error3 => {
                    reject(error3);
                  }
                );
              },
              error2 => {
                reject(error2);
              }
            );
          } else {
            reject('error card creation');
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  createCardFormElement(cardInfo) {
    return new Promise(resolve => {
      const cardElement = this.currentElements.getElement('card');
      cardElement.unmount();
      cardElement.mount(cardInfo);
      resolve(cardElement);
    });
  }

  createSourceCard(card) {
    return new Promise((resolve, reject) => {
      //      this.stripeService
      this.currentStripe.createSource(card).then(
        result => {
          if (result) {
            resolve(result.source);
          } else if (result.error) {
            reject(result.error.message);
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  retrieveSource(sourceId, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('sourceId', sourceId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/sources/retrieve', {
          params: params1,
          observe: 'body'
          // tslint:disable-next-line: deprecation
        }).subscribe(
          data => {
            const source = data;
            resolve(source);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  createStripeUser(adnUser: AdnUser, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      let params1;
      const idString = 'id';

      const user = {
        name: encodeURIComponent(adnUser.name),
        mobileNo: adnUser.mobileNo,
        email: encodeURIComponent(adnUser.email),
        adnUserId: adnUser.adnUserId
      };
      params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('adnUser', JSON.stringify(user));

      this.http.get(this.utilSvc.backendURL + 'stripe/customer/create', {
        params: params1,
        observe: 'body'
        // tslint:disable-next-line: deprecation
      }).subscribe(
        data => {
          const stripeUser = data;
          if (!adnUser.stripeCustomers) {
            adnUser.stripeCustomers = [];
          }
          if (stripeUser[idString]) {
            adnUser.stripeCustomers[stripeSmbAccount] = stripeUser[idString];
            this.userSvc.updateUser(adnUser).then(
              data1 => {
                resolve(stripeUser);
              },
              error1 => {
                reject(error1);
              }
            );
          } else {
            console.log('stripe/customer/create error1=', data);
            reject(data);
          }
        },
        error => {
          console.log('stripe/customer/create error2=', error);
          reject(error);
        }
      );
    });
  }

  attachStripeSource(adnUser: AdnUser, source: string, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('customerId', adnUser.stripeCustomers[stripeSmbAccount])
        .set('source', source);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/customer/createSource', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          data => {
            this.retrieveStripeCustomer(adnUser, stripeSmbAccount).then(
              data1 => {
                resolve(data1);
              },
              error1 => {
                reject(error1);
              }
            );
          },
          error => {
            reject(error);
          }
        );
    });
  }

  updateStripeUser(adnUser: AdnUser, token: string, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      const customer = adnUser.stripeCustomers[stripeSmbAccount];
      const params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('customerId', customer)
        .set('source', token);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/customer/update', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  retrieveStripeCustomer(adnUser: AdnUser, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      let createStripe = true;
      if (adnUser.stripeCustomers) {
        if (adnUser.stripeCustomers[stripeSmbAccount]) {
          createStripe = false;
        }
      }
      if (!createStripe) {
        const customerId = adnUser.stripeCustomers[stripeSmbAccount];
        const params1 = new HttpParams()
          .set('connectedAccountId', stripeSmbAccount)
          .set('customerId', customerId);
        this.http
          .get(this.utilSvc.backendURL + 'stripe/customer/retrieve', {
            params: params1,
            observe: 'body'
          })
          // tslint:disable-next-line: deprecation
          .subscribe(
            data => {
              resolve(data);
            },
            error => {
              console.log('error=', error);
              reject(error);
            }
          );
      } else {
        this.createStripeUser(adnUser, stripeSmbAccount).then(
          data => resolve(data),
          error => reject(error)
        );
      }
    });
  }

/*  createStripeSetupIntent(
    paymentMethod,
    adnUser: AdnUser,
    stripeSmbAccount: string
  ) {
    return new Promise((resolve, reject) => {
      let params1;
      params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('customerId', adnUser.stripeCustomers[stripeSmbAccount])
        .set('payment_method_types', paymentMethod)
        .set('metadata', JSON.stringify({}))
        .set('description', adnUser.adnUserId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/setupIntents/create', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          setupIntent => {
            resolve(setupIntent);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  confirmStripeSetupIntent(setupIntentId: string, stripeSmbAccount?: string) {
    return new Promise((resolve, reject) => {
      let params1;

      params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('setupIntentId', setupIntentId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/setupIntents/confirm', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          setupIntent => {
            resolve(setupIntent);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  cancelStripeSetupIntent(setupIntentId: string, stripeSmbAccount?: string) {
    return new Promise((resolve, reject) => {
      let params1;

      params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('setupIntentId', setupIntentId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/setupIntents/cancel', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          setupIntent => {
            resolve(setupIntent);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  createOrderPaymentIntent(
    sourceType: string,
    cart: CART,
    adnCustomerUser: AdnUser,
    adnBusinessUser: AdnUser
  ) {
    return new Promise((resolve, reject) => {
      const amount =
        (cart.grandTotal +
          (cart.deliveryFee ? cart.deliveryFee : 0)) *
        100;
      const commission = Math.round(cart.totalCommission * 100);
      const params1 = new HttpParams()
        .set('connectedAccountId', adnBusinessUser.stripeAccount)
        .set('amount', String(amount))
        .set(
          'customerId',
          adnCustomerUser.stripeCustomers[adnBusinessUser.stripeAccount]
        )
        .set('application_fee_amount', commission.toString())
        .set('email', encodeURIComponent(adnCustomerUser.email))
        .set(
          'description',
          adnBusinessUser.adnUserId + '/' + String(cart.cartId)
        );
      if (sourceType.toLowerCase() !== 'cash') {
        params1.set('payment_method_types', sourceType);
      }
      this.http
        .get(this.utilSvc.backendURL + 'stripe/paymentIntents/create', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          paymentIntent => {
            resolve(paymentIntent);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  createAdPaymentIntent(
    sourceType,
    campaign: PublishCampaign,
    adnCustomerUser: AdnUser,
    adnBusinessUser: AdnUser
  ) {
    return new Promise((resolve, reject) => {
      const amount = campaign.estimatedPrice * 100;
      const commission = 0;
      const params1 = new HttpParams()
        .set('connectedAccountId', adnBusinessUser.stripeAccount)
        .set('amount', String(amount))
        .set(
          'customerId',
          adnCustomerUser.stripeCustomers[adnBusinessUser.stripeAccount]
        )
        .set('application_fee_amount', commission.toString())
        .set('email', encodeURIComponent(adnCustomerUser.email))
        .set(
          'description',
          adnBusinessUser.adnUserId + '/' + String(campaign.campaignId)
        );
      if (sourceType.toLowerCase() !== 'cash') {
        params1.set('payment_method_types', sourceType);
      }
      this.http
        .get(this.utilSvc.backendURL + 'stripe/paymentIntents/create', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          paymentIntent => {
            resolve(paymentIntent);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  confirmPaymentIntent(paymentIntentId: string, stripeSmbAccount: string) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('paymentIntentId', paymentIntentId)
        .set('connectedAccountId', stripeSmbAccount);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/paymentIntents/confirm', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          paymentIntent => {
            resolve(paymentIntent);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  cancelPaymentIntent(paymentIntentId: string, stripeSmbAccount: string) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('paymentIntentId', paymentIntentId)
        .set('connectedAccountId', stripeSmbAccount);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/paymentIntents/cancel', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          paymentIntent => {
            resolve(paymentIntent);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  confirmCardPayment(
    paymentMethod,
    clientSecret,
    adnCustomerUser: AdnUser,
    metadata?
  ) {
    return new Promise((resolve, reject) => {
      const saveCardString = 'saveCard';
      this.currentStripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
        setup_future_usage:
          metadata && metadata[saveCardString]
            ? 'off_session'
            : 'on_session',
        receipt_email: adnCustomerUser.email
      })
        .then(
          result => {
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              reject(result.error);
            } else {
              // The payment has been processed!
              if (
                result.paymentIntent.status === 'succeeded' ||
                result.paymentIntent.status === 'requires_capture'
              ) {
                resolve(result.paymentIntent);
              } else {
                reject(result.paymentIntent);
              }
            }
          },
          error => {
            reject('confirmCardPayment error=' + error);
          }
        );
    });
  }

  confirmIbanPayment(ibanElement, clientSecret, adnUser: AdnUser) {
    return new Promise((resolve, reject) => {
      const paymentMethod = {
        sepa_debit: ibanElement,
        billing_details: {
          name: adnUser.name,
          email: adnUser.email
        }
      };
      this.currentStripe
        .confirmSepaDebitPayment(clientSecret, {
          payment_method: paymentMethod
        })
        .then(result => {
          if (result.error) {
            console.log('result.error1=', result.error);
            // Show error to your customer (e.g., insufficient funds)
            reject(result.error);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'processing') {
              resolve(result.paymentIntent);
            } else {
              reject(result.paymentIntent);
            }
          }
        });
    });
  }

  confirmPaymentIntentExpress(paymentIntentId: string) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams().set('paymentIntentId', paymentIntentId);
      this.http
        .get(
          this.utilSvc.backendURL + 'stripe/paymentIntents/express/confirm',
          { params: params1, observe: 'body' }
        )
        // tslint:disable-next-line: deprecation
        .subscribe(
          paymentIntent => {
            resolve(paymentIntent);
          },
          error => {
            console.log('error=', error);
            reject(error);
          }
        );
    });
  }

  cancelPaymentIntentExpress(paymentIntentId: string) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams().set('paymentIntentId', paymentIntentId);
      this.http
        .get(
          this.utilSvc.backendURL + 'stripe/paymentIntents/express/cancel',
          { params: params1, observe: 'body' }
        )
        // tslint:disable-next-line: deprecation
        .subscribe(
          paymentIntent => {
            resolve(paymentIntent);
          },
          error => {
            console.log('error=', error);
            reject(error);
          }
        );
    });
  }

  confirmCardSetup(paymentMethod, clientSecret, adnUser: AdnUser) {
    return new Promise((resolve, reject) => {
      this.currentStripe
        .confirmCardSetup(clientSecret, {
          payment_method: paymentMethod
        })
        .then(result => {
          if (result.error) {
            reject(result.error);
          } else {
            resolve(result.setupIntent);
          }
        });
    });
  }*/

  deleteStripeSource(adnUser: AdnUser, cardId, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('customerId', adnUser.stripeCustomers[stripeSmbAccount])
        .set('source', cardId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/customer/deleteSource', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          data => {
            this.retrieveStripeCustomer(adnUser, stripeSmbAccount).then(
              data1 => {
                resolve(data1);
              },
              error1 => {
                reject(error1);
              }
            );
          },
          error => {
            reject(error);
          }
        );
    });
  }

  setCustomerDefaultSource(customerId, cardId, stripeSmbAccount) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('connectedAccountId', stripeSmbAccount)
        .set('customerId', customerId)
        .set('cardId', cardId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/customer/setDefaultSource', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  createStripeIBANElement(
    ibanElementName: string,
    bankNameField: string,
    errorMessageField: string
  ) {
    return new Promise((resolve, reject) => {
      //      this.stripeService.elements()

      // Create an instance of Elements.
      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      const style1 = {
        base: {
          color: '#32325d',
          fontFamily:
            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a'
          }
        }
      };
      let iban = this.currentElements.getElement('iban');
      if (iban == null) {
        iban = this.currentElements.create('iban', {
          style: style1,
          supportedCountries: ['SEPA']
        });
      }
      // Add an instance of the iban Element into the `iban-element` <div>.
      iban.mount(ibanElementName);
      iban.clear();

      const errorMessage = document.getElementById(errorMessageField);
      const bankName = document.getElementById(bankNameField);
      iban.on('change', event => {
        // Handle real-time validation errors from the iban Element.
        if (event.error) {
          errorMessage.textContent = event.error.message;
          errorMessage.classList.add('visible');
          reject(event.error);
        } else {
          errorMessage.classList.remove('visible');
          resolve(iban);
        }
        // Display bank name corresponding to IBAN, if available.
        if (event.bankName) {
          bankName.textContent = event.bankName;
          bankName.classList.add('visible');
        } else {
          bankName.classList.remove('visible');
        }
      });
    });
  }

  createStripeIBAN(
    adnUser: AdnUser,
    ibanElement,
    errorMessageField: string,
    name1: string,
    email1: string,
    stripeSmbAccount
  ) {
    return new Promise((resolve, reject) => {
      const errorMessage = document.getElementById(errorMessageField);
      let stripeUser;

      // Handle form submission.
      const sourceData = {
        type: 'sepa_debit',
        currency: 'eur',
        owner: {
          name: name1,
          email: email1
        },
        mandate: {
          // Automatically send a mandate notification email to your customer
          // once the source is charged.
          notification_method: 'email'
        }
      };

      // Call `stripe.createSource` with the iban Element and additional options.
      //      this.stripeService.createSource(ibanElement, sourceData).subscribe(result => {
      this.currentStripe
        .createSource(ibanElement, sourceData)
        .subscribe(result => {
          if (result.error) {
            // Inform the customer that there was an error.
            errorMessage.textContent = result.error.message;
            errorMessage.classList.add('visible');
            reject(result.error);
          } else {
            // Send the Source to your server to create a charge.
            const ibanSourceId = result.source.id;
            errorMessage.classList.remove('visible');
            let promise1;
            if (!adnUser.stripeCustomers[stripeSmbAccount]) {
              promise1 = new Promise((resolve1, reject1) => {
                this.createStripeUser(adnUser, stripeSmbAccount).then(
                  data1 => {
                    stripeUser = data1;
                    resolve1(data1);
                  },
                  error1 => {
                    reject1(error1);
                  }
                );
              });
            } else {
              promise1 = new Promise((resolve1, reject1) =>
                this.retrieveStripeCustomer(adnUser, stripeSmbAccount).then(
                  data1 => {
                    stripeUser = data1;
                    resolve1(adnUser.stripeCustomers[stripeSmbAccount]);
                  },
                  error1 => {
                    this.createStripeUser(adnUser, stripeSmbAccount).then(
                      data2 => {
                        stripeUser = data2;
                        resolve1(data2);
                      },
                      error2 => {
                        reject1(error2);
                      }
                    );
                  }
                )
              );
            }
            promise1.then(
              data1 => {
                const idString = 'id';
                adnUser.stripeCustomers[stripeSmbAccount] = stripeUser[idString];
                this.attachStripeSource(
                  adnUser,
                  ibanSourceId,
                  stripeSmbAccount
                ).then(
                  data2 => {
                    resolve(data2);
                  },
                  error2 => {
                    reject(error2);
                  }
                );
              },
              error1 => reject(error1)
            );
          }
        });
    });
  }

  getPaymentMethod(paymentMethodId, stripeAccountId) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('connectedAccountId', stripeAccountId)
        .set('id', paymentMethodId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/paymentMethods/get', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  getPaymentIntent(paymentIntentId, stripeAccountId) {
    return new Promise((resolve, reject) => {
      const params1 = new HttpParams()
        .set('connectedAccountId', stripeAccountId)
        .set('id', paymentIntentId);
      this.http
        .get(this.utilSvc.backendURL + 'stripe/paymentIntents/retrieve', {
          params: params1,
          observe: 'body'
        })
        // tslint:disable-next-line: deprecation
        .subscribe(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
