import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GodigitalbModule, UtilsService } from 'godigital-lib';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { LayoutRoutingModule } from './layout.router.module';

import { NgxSpinnerModule } from 'ngx-spinner';



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LayoutComponent,
  ] ,
  imports: [
    CommonModule,
    GodigitalbModule,
    NgxSpinnerModule,
    LayoutRoutingModule,
  ],
  providers: [UtilsService]
})
export class LayoutModule { }
