import { Injectable, } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs';
import { StoreDbService, OBJECTNAME, AdnUser, PublishOrderKamli} from '../godigital-lib';
import { UtilsService } from '../godigital-lib';
import { DELIVERYMETHOD } from '../godigital-lib';
import { PublishOrder } from '../godigital-lib';
import { OrderI } from '../godigital-lib';

export enum SORTCUSTOMERSDIR {
    LastMonthSpendUp,
    LastMonthSpendDown,
    CustomerUp,
    CustomerDown,
    PendingOrderUp,
    PendingOrderDown,
}

export interface CustomerOrderI {
    orderId: string;
    type: DELIVERYMETHOD;
    forDate: any;
    forTime: any;
    amount: number;
}

export interface CustomerI {
    adnUserId: string;
    firstTime: any;
    adnUser: AdnUser;
    orders: OrderI[];
    publishOrders: PublishOrderKamli[];
    lastmonthVisit: number;
    lastmonthSpend: number;
}

@Injectable({
    providedIn: 'root'
})

export class CustomerService {

    public customers;

    constructor(
        public storeDbSvc: StoreDbService,
        public utilSvc: UtilsService,
    ) { }

    getCustomer(storeId, adnUserId) {
        return new Promise(async (resolve) => {
            let customer: CustomerI;
            try {
                customer = await this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.customers,
                    adnUserId) as CustomerI;
            } catch (e) {}
            if (customer) {
                let customerUser: AdnUser;
                try {
                    customerUser = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.adnUsers,
                    adnUserId) as AdnUser;
                } catch (e) {}
                if (customerUser) {
                    customer.adnUser = customerUser;
                }
                let ordersJ;
                let orders: OrderI[];
                try {
                    ordersJ = await this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.orders);
                } catch (e) {}
                if (ordersJ) {
                    orders = this.utilSvc.objectToArray(ordersJ);
                    customer.orders = orders.filter(o => o.adnCustomerUserId === adnUserId);
                }
                let adordersJ;
                let adorders: PublishOrderKamli[];
                try {
                    adordersJ = await this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], OBJECTNAME.publishingNewOrders);
                } catch (e) {}
                if (adordersJ) {
                    adorders = this.utilSvc.objectToArray(adordersJ);
                    customer.publishOrders = adorders.filter(o => o.adnCustomerUserId === adnUserId);
                }
                resolve(customer);
            } else {
                resolve(undefined);
            }
        });
    }

    createCustomer(storeId, adnUserId, customerInfo) {
        const objectName = OBJECTNAME.customers;
        return new Promise((resolve, reject) => {
            this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, customerInfo, adnUserId).then(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                });
        });
    }

    updateCustomerOrders(storeId, adnUserId1, orderDetails: OrderI) {
        return new Promise((resolve, reject) => {
            let customer: CustomerI;
            this.getCustomer(storeId, adnUserId1).then(
                data => {
                    const currentDate = Date();
                    customer = data as CustomerI;
                    if (data == null) {
                        customer = { } as CustomerI;
                        customer.adnUserId = adnUserId1;
                        customer.firstTime = currentDate;
                        this.createCustomer(storeId, adnUserId1, customer).then(
                            data1 => {
                                resolve(customer);
                            },
                            error => {
                                reject(error);
                            }
                        );
                    }
                    else {
                        resolve(customer);
                    }
                },
                error => {
                    reject(error);
                });
        });
    }

    updateCustomerAds(storeId, adnUserId1, pubDetails: PublishOrder) {
        return new Promise((resolve, reject) => {
            let customer;
            this.getCustomer(storeId, adnUserId1).then(data => {
                customer = data;
                const currentDate = new Date();
                let month = String(currentDate.getMonth() + 1);
                const year = String(currentDate.getFullYear());
                if (month.length === 1) {
                    month = '0' + month;
                }
                const monthyear = month + '-' + year;
                if (data == null) {
                    const ads1 = [];
                    ads1.push(pubDetails.orderId);
                    const customerInfo = { adnUserId: adnUserId1, firstTimeClient: currentDate, ads: ads1, adsHistory: {} };
                    customerInfo.adsHistory[monthyear] = Math.round(pubDetails.grandTotal * 100) / 100;
                    this.createCustomer(storeId, adnUserId1, customerInfo).then(
                        data1 => resolve(data1),
                        error => reject(error)
                    );
                }
                else {
                    customer.ads.push(pubDetails.orderId);
                    if (!customer.adsHistory[monthyear]) {
                        customer.adsHistory[monthyear] = Math.round(pubDetails.grandTotal * 100) / 100;
                    }
                    else {
                        customer.adsHistory[monthyear] += Math.round(pubDetails.grandTotal * 100) / 100;
                    }
                    this.createCustomer(storeId, adnUserId1, customer).then(
                        data1 => resolve(data1),
                        error1 => reject(error1)
                    );
                }
            });
        });
    }

    sortCustomers(storeCustomers, sortType) {
        const nameString = 'name';
        const pendingOrderString = 'pendingOrder';
        const lastmonthSpendString = 'lastmonthSpend';
        switch (sortType) {
            case SORTCUSTOMERSDIR.CustomerDown:
                storeCustomers.sort( (a: CustomerI, b: CustomerI) => {
                    return (a[nameString] > b[nameString]) ? 1 : ((b[nameString] > a[nameString]) ? -1 : 0);
                });
                break;

            case SORTCUSTOMERSDIR.CustomerUp:
                storeCustomers.sort((a: CustomerI, b: CustomerI) => {
                    return (a[nameString] > b[nameString]) ? -1 : ((b[nameString] > a[nameString]) ? 1 : 0);
                });
                break;

            case SORTCUSTOMERSDIR.PendingOrderDown:
                storeCustomers.sort((a: CustomerI, b: CustomerI) => {
                    if ((a[pendingOrderString] ) && (b[pendingOrderString] )) {
                        return (a[pendingOrderString][0].amount > b[pendingOrderString][0].amount) ? 1 :
                        ((a[pendingOrderString][0].amount > b[pendingOrderString][0].amount) ? -1 : 0);
                    }
                });
                break;

            case SORTCUSTOMERSDIR.PendingOrderUp:
                storeCustomers.sort((a: CustomerI, b: CustomerI) => {
                    if ((a[pendingOrderString] ) && (b[pendingOrderString] )) {
                        return (a[pendingOrderString][0].amount > b[pendingOrderString][0].amount) ? -1 :
                        ((a[pendingOrderString][0].amount > b[pendingOrderString][0].amount) ? 1 : 0);
                    }
                });
                break;

            case SORTCUSTOMERSDIR.LastMonthSpendDown:
                storeCustomers.sort((a: CustomerI, b: CustomerI) => {
                    return (a[lastmonthSpendString] > b[lastmonthSpendString]) ? 1 :
                    ((b[lastmonthSpendString] > a[lastmonthSpendString]) ? -1 : 0);
                });
                break;

            case SORTCUSTOMERSDIR.LastMonthSpendUp:
                storeCustomers.sort((a: CustomerI, b: CustomerI) => {
                    return (a[lastmonthSpendString] > b[lastmonthSpendString]) ? -1 :
                    ((b[lastmonthSpendString] > a[lastmonthSpendString]) ? 1 : 0);
                });
                break;

            default:
                break;
        }
    }


}
