/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, } from '@angular/core';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, } from '@angular/common/http';
import { StoreDbService, OBJECTNAME, KamliApp, AdnUser, AdnUserAddress, AUTHSTATUS } from '../godigital-lib';
import { UtilsService } from '../godigital-lib';
import { AdnUserKamli } from '../godigital-lib';
import { AlertController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { regexMobileNo } from './service-service';

export const firebaseConfig = {
    apiKey: 'AIzaSyAFIiBNkBda_tNdkppBmdzCzZhizmFOgKc',
    authDomain: 'backend-prod-e4d4e.firebaseapp.com',
    databaseURL: 'https://backend-prod-e4d4e.firebaseio.com',
    projectId: 'backend-prod-e4d4e',
    storageBucke: 'backend-prod-e4d4e.appspot.com',
    messagingSenderId: '981006637106'
};

@Injectable({
    providedIn: 'root'
})

export class UsersService {
    public userInfo: AdnUser
    public currentUser;
    public allUsers: AdnUser[];
    public allUsersO: BehaviorSubject<AdnUser[]> = new BehaviorSubject(null);
    public confirmationResult;
    public firebaseauth;
    public recaptchaVerifier;

    constructor(
        public http: HttpClient,
        public storeDbSvc: StoreDbService,
        public utilSvc: UtilsService,
        private alertController: AlertController,
    ) { }

    initSmsOtp() {
        firebase.initializeApp(firebaseConfig);
        this.firebaseauth = firebase.auth;
        this.recaptchaVerifier = new this.firebaseauth.RecaptchaVerifier('sign-in-button', {
            size: 'invisible',
            callback: (response) => {

            },
            'expired-callback': () => {
            }
        });
    }

    createSms(phoneNumber: string) {
        return new Promise(async (resolve, reject) => {
            if (this.recaptchaVerifier && phoneNumber) {
                this.firebaseauth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier).then(
                    async success => {
                        this.confirmationResult = success;
                        console.log('success=', success);
                        resolve(success);
                    },
                    error => {
                        console.log('error=', error);
                        reject(error);
                    }
                );
            } else {
                console.log('error phoneNumber=', phoneNumber);
                reject(false);
            }
        });
    }



    // Button event after the nmber is entered and button is clicked
    checkMobilePhone(phoneNumber: string) {
        return new Promise(async (resolve, reject) => {
            if (this.recaptchaVerifier && phoneNumber) {
                this.firebaseauth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier).then(
                    async success => {
                        this.confirmationResult = success;
                        const result = await this.OtpVerification1();
                        resolve(result);
                    },
                    error => {
                        reject(error);
                    }
                );
            } else {
                reject(false);
            }
        });
    }

    async showSuccess() {
        const alert = await this.alertController.create({
            header: 'Success',
            buttons: [
                {
                    text: 'Ok',
                    handler: (res) => {
                        alert.dismiss();
                    }
                }
            ]
        });
        alert.present();
    }

    OtpVerification(otp: string) {
        return new Promise(async (resolve, reject) => {
            if (this.confirmationResult) {
                this.confirmationResult.confirm(otp).then(
                    userData => {
                        resolve(true);
                    },
                    error => {
                        console.log('error=', error);
                        resolve(false);
                    }
                );
            } else {
                reject(1);
            }
        });
    }

    OtpVerification1() {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                header: 'Enter OTP',
                backdropDismiss: false,
                inputs: [
                    {
                        name: 'otp',
                        type: 'text',
                        placeholder: 'Enter your otp',
                    }
                ],
                buttons: [{
                    text: 'Enter',
                    handler: (res) => {
                        this.confirmationResult.confirm(res.otp).then(
                            userData => {
                                resolve(true);
                            },
                            error => {
                                resolve(false);
                            }
                        );
                    }
                }
                ]
            });
            await alert.present();

        });
    }

    createUser(otpUserId: string, app: KamliApp) {
        return new Promise((resolve, reject) => {
            const body = {
                otpUserId,
                app
            };
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                })
            };

            // tslint:disable-next-line: deprecation
            this.http.post(this.utilSvc.backendURL + 'user/createUserPre', body, httpOptions).subscribe(
                result => {
                    resolve(result);
                },
                error => {
                    reject(error);
                });
        });
    }

    deleteUser(adnUser: AdnUser) {
        return new Promise((resolve, reject) => {
            const promises = [];
            const body = {
                uid: adnUser.adnUserId,
            };
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                })
            };

            promises.push(new Promise((resolve1, reject1) => {
                // tslint:disable-next-line: deprecation
                this.http.post(this.utilSvc.backendURL + 'user/deleteUser', body, httpOptions).subscribe(
                    result => {
                        resolve1(result);
                    },
                    error => {
                        reject1(error);
                    });
            }));

            promises.push(new Promise((resolve1, reject1) => {
                this.storeDbSvc.removeObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.adnUsers, adnUser.adnUserId).then(
                    () => {
                        resolve1(adnUser);
                    },
                    (error) => reject1(error)
                );
            }));

            Promise.all(promises).then(
                data => resolve(data),
                error => reject(error)
            );
        });
    }

    authUserU(identif: string, password1: string, emailNotVerified?: boolean) {
        return new Promise(async (resolve, reject) => {
            const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;
            const phoneRegex = regexMobileNo;
            if (identif && password1) {
                const matche = identif.match(emailRegex);
                if (matche) {
                    this.authUser(identif, password1, emailNotVerified).then(
                        data1 => resolve(data1),
                        error1 => reject(error1)
                    );
                } else {
                    if (!this.allUsers) {
                        let temp;
                        try {
                            temp = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb, OBJECTNAME.adnUsers);
                        } catch (e) { }
                        if (temp) {
                            this.allUsers = this.utilSvc.objectToArray(temp);
                        }
                    }
                    const matchm = identif.match(phoneRegex);
                    if (matchm) {
                        const tempm = this.allUsers && this.allUsers.find(u => u.mobileNo === identif.toLowerCase().trim());
                        if (tempm) {
                            this.authUser(tempm.email, password1, emailNotVerified).then(
                                data => resolve(data),
                                error => reject(error)
                            );
                        } else {
                            reject([-100, 'unknown user']);
                        }
                    } else {
                        const tempm = this.allUsers && this.allUsers.find(u => u.name === identif.toLowerCase().trim());
                        if (tempm) {
                            this.authUser(tempm.email, password1, emailNotVerified).then(
                                data => resolve(data),
                                error => reject(error)
                            );
                        } else {
                            reject([-100, 'unknown user']);
                        }
                    }
                }
            } else {
                reject([-100, 'missing identif and password']);
            }
        });

    }


    authUser(email: string, password1: string, emailNotVerified?: boolean) {
        const password = password1;
        const maf = this.utilSvc.mauth;

        return new Promise((resolve, reject) => {
            maf.signInWithEmailAndPassword(email.toLowerCase(), password).then(
                (success) => {
                    const user = success.user;
                    if (user.emailVerified || emailNotVerified) {
                        resolve([AUTHSTATUS.SUCCESS, user]);
                    }
                    else {
                        reject([AUTHSTATUS.EMAILNOTVERIFIED, 'Login Failed! email not verified']);
                    }
                },
                error => {
                    reject([AUTHSTATUS.UNKNOWNERROR, error]);
                })
                .catch((error) => {
                    reject([AUTHSTATUS.UNKNOWNERROR, error]);
                });
        });
    }

    logout() {
        const maf = this.utilSvc.mauth;
        return new Promise((resolve, reject) => {
            maf.signOut().then(
                (success) => {
                    resolve(success);
                },
                error => {
                    reject(error);
                })
                .catch((error) => {
                    reject(error);
                });
        });

    }

    resetPwdUser(email: string) {
        const maf = this.utilSvc.mauth;
        return new Promise((resolve, reject) => {
            maf.sendPasswordResetEmail(email)
                .then(
                    () => {
                        resolve(1);
                    },
                    (error) => {
                        reject(error);
                    })
                .catch(
                    (error) => {
                        reject(error);
                    });
        });
    }

    getUser(adnUserId: string): Promise<AdnUser> {
        const storeId = this.utilSvc.backendFBstoreId;

        return new Promise((resolve, reject) => {
            if (!adnUserId) {
                resolve(undefined);
            }
            else {
                this.storeDbSvc.getObject(storeId, this.utilSvc.mdb, OBJECTNAME.adnUsers, adnUserId).then(
                    data => {
                        this.userInfo = data as AdnUser;
                        if (this.userInfo) {
                            if (this.utilSvc.stripeplatform === 'prod') {
                                this.userInfo.stripeCustomers = this.userInfo.stripeCustomersProd ? this.userInfo.stripeCustomersProd : this.userInfo.stripeCustomers ? this.userInfo.stripeCustomers : null;
                                this.userInfo.stripeAccount = this.userInfo.stripeAccountProd ? this.userInfo.stripeAccountProd : this.userInfo.stripeAccount ? this.userInfo.stripeAccount : null;
                            } else {
                                this.userInfo.stripeCustomers = this.userInfo.stripeCustomersTest ? this.userInfo.stripeCustomersTest : this.userInfo.stripeCustomers ? this.userInfo.stripeCustomers : null;
                                this.userInfo.stripeAccount = this.userInfo.stripeAccountTest ? this.userInfo.stripeAccountTest : this.userInfo.stripeAccount ? this.userInfo.stripeAccount : null;
                            }
                        }
                        resolve(this.userInfo);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    }

    checkUserEmail(email1: string) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('currentEmail', encodeURIComponent(email1));
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'user/checkEmail', { params: params1, observe: 'body' }).subscribe(
                account => {
                    resolve(account);
                },
                error => {
                    reject(error);
                });
        });
    }

    getUserByEmail(email: string) {
        const mdb = this.utilSvc.mdb;
        const objectName = OBJECTNAME.adnUsers;
        const storeId = this.utilSvc.backendFBstoreId;
        let users = [] as AdnUser[];

        return new Promise((resolve, reject) => {
            this.storeDbSvc.getObject(storeId, this.utilSvc.mdb, OBJECTNAME.adnUsers).then(
                data => {
                    users = this.utilSvc.objectToArray(data);
                    const temp = users.find(u => u.email === email);
                    resolve(temp);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    updateUser(adnUser: AdnUser) {
        const mdb = this.utilSvc.mdb;
        const objectName = OBJECTNAME.adnUsers;
        const storeId = this.utilSvc.backendFBstoreId;

        return new Promise((resolve, reject) => {
            this.userInfo = adnUser;
            const temp = adnUser as AdnUserKamli;
            temp.usercarts = null;
            temp.userorders = null;
            if (this.utilSvc.stripeplatform === 'prod') {
                this.userInfo.stripeCustomersProd = this.userInfo.stripeCustomers ? this.userInfo.stripeCustomers : this.userInfo.stripeCustomersProd ? this.userInfo.stripeCustomersProd : null;
                this.userInfo.stripeAccountProd = this.userInfo.stripeAccount ? this.userInfo.stripeAccount : this.userInfo.stripeAccountProd ? this.userInfo.stripeAccountProd : null;
            } else {
                this.userInfo.stripeCustomersTest = this.userInfo.stripeCustomers ? this.userInfo.stripeCustomers : this.userInfo.stripeCustomersTest ? this.userInfo.stripeCustomersTest : null;
                this.userInfo.stripeAccountTest = this.userInfo.stripeAccount ? this.userInfo.stripeAccount : this.userInfo.stripeAccountTest ? this.userInfo.stripeAccountTest : null;
            }
            this.storeDbSvc.updateObject(storeId, mdb, objectName, temp, adnUser.adnUserId).then(
                data => {
                    this.userInfo = data as AdnUser;
                    resolve(this.userInfo);
                },
                error => reject(error)
            );
        });
    }

    addUserAddress(adnCustomerUser: AdnUser, fulladdressTextV: AdnUserAddress, complementInfo) {
        const complementInfoString = 'complementInfo';
        const primaryString = 'primary';
        return new Promise((resolve, reject) => {
            let duplicate = false;
            if (fulladdressTextV ) {
                if (fulladdressTextV.value ) {
                    fulladdressTextV[complementInfoString] = complementInfo;
                    if (!adnCustomerUser.addresses) {
                        adnCustomerUser.addresses = [];
                        fulladdressTextV[primaryString] = true;
                    } else {
                        if (!adnCustomerUser.addresses[0]) {
                            adnCustomerUser.addresses = [];
                            fulladdressTextV[primaryString] = true;
                        } else {
                            const temp = adnCustomerUser.addresses.findIndex(a => a.value ===
                                fulladdressTextV.value);
                            if (temp !== -1) {
                                duplicate = true;
                            } else {
                                fulladdressTextV[primaryString] = false;
                            }
                        }
                    }
                }
                if (!duplicate) {
                    adnCustomerUser.addresses.push(fulladdressTextV);
                    this.updateUser(adnCustomerUser).then(
                        () => {
                            resolve(adnCustomerUser.addresses.length - 1);
                        },
                        () => reject(1)
                    );
                } else {
                    resolve(1);
                }
            } else {
                reject(1);
            }
        });
    }



    editUserAddress(adnUser: AdnUser, newAddress) {
        const mdb = this.utilSvc.mdb;
        const objectName = OBJECTNAME.adnUsers;
        const storeId = this.utilSvc.backendFBstoreId;
        const addressesString = 'addresses';

        return new Promise((resolve, reject) => {
            this.getUser(adnUser.adnUserId).then(
                (data) => {
                    this.userInfo = data;
                    const addressArray = this.userInfo[addressesString];
                    let addressArrayLength = 0;

                    if (this.userInfo[addressesString]) {
                        addressArrayLength = this.userInfo[addressesString].length;
                    } else {
                        this.userInfo[addressesString] = [];
                    }
                    this.updateUser(adnUser);
                    resolve(data);
                },
                error => reject(error)
            );
        });
    }

    deleteUserAddress(adnUser: AdnUser, index: number) {
        const mdb = this.utilSvc.mdb;
        const objectName = OBJECTNAME.adnUsers;
        const addressesString = 'addresses';

        return new Promise((resolve, reject) => {
            this.getUser(adnUser.adnUserId).then(
                (data) => {
                    this.userInfo = data;
                    const addressArray = this.userInfo[addressesString];
                    const addressArrayLength = 0;

                    if (this.userInfo[addressesString]) {
                        if (this.userInfo[addressesString][index] ) {
                            this.userInfo[addressesString].splice(index, 1);
                            this.updateUser(adnUser);
                            resolve(data);
                        }
                        else {
                            resolve(1);
                        }
                    }
                    else {
                        resolve(1);
                    }
                });
        });
    }

    sendVerificationEmail(adnUser: AdnUser) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('name', adnUser.name)
                .set('language', this.utilSvc.language)
                .set('email', encodeURIComponent(adnUser.email));
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/verificationEmail', { params: params1, observe: 'body' }).subscribe(
                account => {
                    resolve(account);
                },
                error => {
                    reject(error);
                });
        });
    }

    updatePwd(adnUser: AdnUser, oldPwd1: string, newPwd1: string): Promise<any> {
        return new Promise((resolve, reject) => {

            this.authUser(adnUser.email, oldPwd1).then(
                data => {
                    const body = {
                        email: adnUser.email,
                        newpassword: newPwd1,
                    };
                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json',
                        })
                    };
                    // tslint:disable-next-line: deprecation
                    this.http.post(this.utilSvc.backendURL + 'user/updatePwd', body, httpOptions).subscribe(
                        result => {
                            resolve(result);
                        },
                        error => {
                            reject(error);
                        });
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    /*  checkUserStatus() {
        return new Promise((resolve, reject) => {
          const maf = this.utilSvc.mauth;
          maf.authState.subscribe(user => {
            this.currentUser = user;
            resolve(this.currentUser);
          });
        });
      }*/

    addVisitedStore(adnUserId: string, storeId: string): Promise<AdnUser> {
        return new Promise(async (resolve, reject) => {
            let user: AdnUser;
            let error;
            try {
                user = await this.getUser(adnUserId);
            } catch (e) { error = e; }
            if (user) {
                const temp = user.storevisited && user.storevisited.find(s => s === storeId);
                if (!temp) {
                    if (!user.storevisited) {
                        user.storevisited = [];
                    }
                    user.storevisited.push(storeId);
                    if (!error && user && user.adnUserId) {
                        await this.updateUser(user);
                        resolve(user);
                    } else {
                        reject(error);
                    }
                }
            } else {
                reject('unknown user');
            }
        });
    }
}

