import {
  Component, OnInit, Inject, PLATFORM_ID,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { UtilsService, UsersService, ServicesService, KamliApp, OBJECTNAME, AdnUser } from 'godigital-lib';
import { LocalUtilsService } from './services/utils.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})

export class AppComponent implements OnInit {
  public env = {
    storeId: 0,
    platform: undefined
  };

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    public router: Router,
    public utilsSvc: UtilsService,
    public mainSvc: ServicesService,
    public usersSvc: UsersService,
    public localUtilsSvc: LocalUtilsService,
  ) {
  }

  ngOnInit() {
    AOS.init();
    this.env.platform = undefined;
    this.utilsSvc.appName = KamliApp.KAMLINET;

    const lang = this.utilsSvc.getLanguage() ? this.utilsSvc.getLanguage() : 'fr';
    this.mainSvc.setLanguage(lang);
    this.titleService.setTitle('KAMLI');
    this.metaTagService.updateTag({ name: 'description', content: 'COMMERCE NUMERIQUE - AFFICHAGE DIGITAL - PETITES ANNONCES' });

    this.mainSvc.readConfigFile(this.env).then(
      () => {
        this.env.platform = this.utilsSvc.platform;
        this.mainSvc.initBEService(this.env).then(
          async data => {
            let temp;
            try {
              temp = await this.mainSvc.storeDbSvc.getObject(this.utilsSvc.backendFBstoreId,
                this.utilsSvc.mdb, OBJECTNAME.adnUsers);
            } catch (e) { }
            this.localUtilsSvc.alladnUsers = this.utilsSvc.objectToArray(temp) as AdnUser[];
            this.mainSvc.initAdnStoreService(this.utilsSvc.adnStoreId).then(
              () => {
                if (this.utilsSvc.getUid()) {
                  const storeId = this.utilsSvc.getStoreId() ? this.utilsSvc.getStoreId() : undefined;
                  try {
                    this.mainSvc.loginAdmin1(undefined, undefined, this.utilsSvc.getUid(), storeId, undefined).catch(e => {
                      console.log('error 1 e=', e);
                    });
                  } catch (e) {
                    console.log('error 2 e=', e);
                  }
                }
              });
          });
      });
  }
}
