import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService, } from './utils.service';
import { AdnStoreI, AdnCorporateI, AdnStoreILight } from '../godigital-lib';
import { OrderI } from '../godigital-lib';
import { StoreDbService, OBJECTNAME, AdnUser } from '../godigital-lib';
import { PublishCampaign } from '../godigital-lib';

export enum MAILFORMAT {
    TEXT,
    HTML,
}

export interface MailDetailsI {
    from: string;
    fromName: string;
    fromPhone: string;
    to: string;
    toName: string;
    toPhone: string;
    text: string;
    subject: string;
    format: MAILFORMAT;
}

@Injectable({
    providedIn: 'root'
})

export class MailerService {
    constructor(
        public http: HttpClient,
        public storeDbSvc: StoreDbService,
        public utilSvc: UtilsService,
    ) {
    }

    sendContact(mail: MailDetailsI) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('fromName', encodeURIComponent(mail.fromName))
                .set('fromEmail', encodeURIComponent(mail.from))
                .set('fromPhone', encodeURIComponent(mail.fromPhone))
                .set('toName', encodeURIComponent(mail.toName))
                .set('toEmail', encodeURIComponent(mail.to))
                .set('subject', encodeURIComponent(mail.subject))
                .set('comments', encodeURIComponent(mail.text));
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/contact', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendMailNewOrder(
        corporate: AdnCorporateI,
        store: AdnStoreI,
        adnBusinessUser: AdnUser,
        adnCustomerUser: AdnUser,
        order: OrderI,
        orderTotal: number) {

        return new Promise((resolve, reject) => {
            const orderId = order.orderId;
            const products = [];
            if (order.cartItems ) {
                for (const cartItem of order.cartItems) {
                    const invoiceProduct = {
                        item: cartItem.title,
                        price: cartItem.totalPrice,
                        qty: cartItem.quantity,
                        preparation: cartItem.preparation  ? cartItem.preparation : null,
                        extraOptions: cartItem.extraOptions  ? cartItem.extraOptions : null,
                        total: Math.round(cartItem.totalPrice * cartItem.quantity * 100) / 100
                    };
                    const product = cartItem;
                    if (product.price ) {
                        if (product.price.length > 0) {
                            invoiceProduct.item = invoiceProduct.item + ':' + product.price[0].pname;
                        }
                    }
                    products.push(invoiceProduct);
                }
            }

            let customerAddress = '';
            if (adnCustomerUser.addresses ) {
                if (adnCustomerUser.addresses[0] ) {
                    customerAddress = adnCustomerUser.addresses[0].value;
                }
            }

            const orderDate = new Date(order.createdTS).toDateString();
            const comments = order.deliveryDetails.comments;

            const params1 = new HttpParams()
                .set('storeEmailAddress', encodeURIComponent(adnBusinessUser.email))
                .set('customerEmailAddress', encodeURIComponent(adnCustomerUser.email))
                .set('orderId', orderId.toString())
                .set('orderTotal', orderTotal.toString())
                .set('grandTotalVAT', JSON.stringify(order.grandTotalVAT))
                .set('smbName', store.storeName)
                .set('smbAddress', store.formatted_address)
                .set('smbPhone', store.phone)
                .set('smbEmail', store.email)
                .set('customerName', adnCustomerUser.name)
                .set('customerAddress', customerAddress)
                .set('customerEmail', adnCustomerUser.email)
                .set('customerPhone', adnCustomerUser.mobileNo)
                .set('products', JSON.stringify(products))
                .set('customerOrderTemplate', 'customerPendingOrder')
                .set('SmbOrderTemplate', 'smbPendingOrder')
                .set('orderDate', orderDate)
                .set('orderStatus', 'Sent')
                .set('qrCodeInfo', store.adnStoreId + '/' + OBJECTNAME.orders + '/' + order.orderId)
                .set('language', this.utilSvc.language)
                .set('smbSiren', corporate.sirenNumber)
                .set('smbApe', corporate.apeCode)
                .set('smbTVA', corporate.vatNumber)
                .set('comments', comments);
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailOrder', { params: params1 }).subscribe(
                data => {
                    console.log('data=', data);
                    resolve(data);
                },
                error => {
                    console.log('error=', error);
                    reject(error);
                }
            );
        });
    }

    sendMailExpressPayment(storeEmailAddress: string, customerEmailAddress: string, tabPinCode: string, expressPaymentAmount: number) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('storeEmailAddress', encodeURIComponent(storeEmailAddress))
                .set('customerEmailAddress', encodeURIComponent(customerEmailAddress))
                .set('tabPinCode', tabPinCode)
                .set('expressPaymentAmount', expressPaymentAmount.toString());
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailExpressPayment', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendMailUpdatedOrder(
        corporate: AdnCorporateI,
        store: AdnStoreI,
        adnBusinessUser: AdnUser,
        adnCustomerUser: AdnUser,
        order: OrderI,
        orderTotal: number,
        status: string,
        comments?: string,
    ) {

        const orderId = order.orderId;
        const products = [];
        const titleString = 'title';
        if (order.cartItems ) {
            for (const cartItem of order.cartItems) {
                const invoiceProduct = {
                    item: cartItem.title,
                    price: cartItem.totalPrice,
                    qty: cartItem.quantity,
                    preparation: cartItem.preparation  ? cartItem.preparation : null,
                    extraOptions: cartItem.extraOptions  ? cartItem.extraOptions : null,
                    total: Math.round(cartItem.totalPrice * cartItem.quantity * 100) / 100
                };
                const product = cartItem as any;
                if (product.price ) {
                    if (product.price.length > 0) {
                        invoiceProduct.item = invoiceProduct.item + ':' + product.price[0].pname;
                    }
                }
                products.push(invoiceProduct);
            }
        }
        let customerAddress = '';
        if (adnCustomerUser.addresses ) {
            if (adnCustomerUser.addresses[0] ) {
                customerAddress = adnCustomerUser.addresses[0].value;
            }
        }
        const orderDate = new Date(order.createdTS).toDateString();

        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('storeEmailAddress', encodeURIComponent(adnBusinessUser.email))
                .set('customerEmailAddress', encodeURIComponent(adnCustomerUser.email))
                .set('orderId', orderId.toString())
                .set('orderTotal', orderTotal.toString())
                .set('grandTotalVAT', JSON.stringify(order.grandTotalVAT))
                .set('smbName', store.storeName)
                .set('smbAddress', store.formatted_address)
                .set('smbPhone', store.phone)
                .set('smbEmail', store.email)
                .set('customerName', adnCustomerUser.name)
                .set('customerAddress', customerAddress)
                .set('customerEmail', adnCustomerUser.email)
                .set('customerPhone', adnCustomerUser.mobileNo)
                .set('products', JSON.stringify(products))
                .set('customerOrderTemplate', 'customerUpdatedOrder')
                .set('SmbOrderTemplate', 'smbUpdatedOrder')
                .set('orderDate', orderDate)
                .set('orderStatus', status)
                .set('qrCodeInfo', store.adnStoreId + '/' + OBJECTNAME.orders + '/' + order.orderId)
                .set('language', this.utilSvc.language)
                .set('smbSiren', corporate.sirenNumber)
                .set('smbApe', corporate.apeCode)
                .set('smbTVA', corporate.vatNumber)
                .set('comments', comments);
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailOrder', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendMailFreePublishRequest(
        corporate: AdnCorporateI,
        store: AdnStoreILight,
        adnCustomerUser: AdnUser,
    ) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('customerEmailAddress', encodeURIComponent(adnCustomerUser.email))
                .set('smbName', store.storeName)
                .set('customerName', adnCustomerUser.name)
                .set('pubLink', '')
                .set('smbSiren', corporate.sirenNumber)
                .set('smbApe', corporate.apeCode)
                .set('smbTVA', corporate.vatNumber)
                .set('smbAddress', store.formatted_address)
                .set('smbWebsite', store.website)
                .set('language', this.utilSvc.language);
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailFreePublish', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendMailNewPublishRequest(
        corporate: AdnCorporateI,
        store: AdnStoreILight,
        adnBusinessUser: AdnUser,
        adnCustomerUser: AdnUser,
        pubId: string,
        orderTotal: number,
        oneSpotAd?: boolean
    ) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('storeEmailAddress', encodeURIComponent(adnBusinessUser.email))
                .set('customerEmailAddress', encodeURIComponent(adnCustomerUser.email))
                .set('pubId', pubId.toString())
                .set('orderTotal', orderTotal.toString())
                .set('smbName', store.storeName)
                .set('customerName', adnCustomerUser.name)
                .set('pubLink', '')
                .set('customerPRTemplate', 'customerPendingAd')
                .set('SmbPRTemplate', 'smbPendingAd')
                .set('pubStatus', 'sent')
                .set('comments', '')
                .set('oneStopAd', oneSpotAd === undefined || oneSpotAd ? 'true' : 'false')
                .set('smbSiren', corporate.sirenNumber)
                .set('smbApe', corporate.apeCode)
                .set('smbTVA', corporate.vatNumber);
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailPublish', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendMailNewCampaignRequest(
        adnCustomerUser: AdnUser,
        campaignDetails: PublishCampaign,
        comments?: string,
    ) {
        return new Promise(async (resolve, reject) => {
            const smbs = [];
            if (campaignDetails.spots ) {
                for (const spot of campaignDetails.spots) {
                    let store: AdnStoreI;
                    try {
                        store = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                            this.utilSvc.mdb, OBJECTNAME.adnStores, spot) as AdnStoreI;
                    } catch (e) {
                    }
                    if (store) {
                        const smb = {
                            orderId: campaignDetails.campaignId,
                            smbName: store.storeName,
                            smbUnitPrice: store.kamliadsDetails.unitpricing,
                            smbQuantity: campaignDetails.numberDuration,
                            smbCost: store.kamliadsDetails.unitpricing * campaignDetails.numberDuration
                        };
                        smbs.push(smb);
                    }
                }
            }
            const campaignDetailsM = {
                campaignName: campaignDetails.campaignName,
                campaignId: campaignDetails.campaignId,
                advertiserName: campaignDetails.advertiserName,
                datetime: campaignDetails.createdTS,
                product: 'pubImages',
                rate: campaignDetails.durationType,
                quantity: campaignDetails.numberDuration,
                startdates: campaignDetails.startDates[0][0],
                enddates: campaignDetails.startDates[0][1],
                numberspots: campaignDetails.spots.length,
                orderTotal: campaignDetails.estimatedPrice,
                orderTotalHT: campaignDetails.estimatedPrice / 1.2,
                orderTotalVAT20: campaignDetails.estimatedPrice * (1 - 1 / 1.2),
                spots: smbs
            };

            const params1 = new HttpParams()
                .set('customerEmailAddress', encodeURIComponent(adnCustomerUser.email))
                .set('campaignDetails', encodeURIComponent((JSON.stringify(campaignDetailsM))))
                .set('language', this.utilSvc.language)
                .set('comments', comments || '');
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailCampaign', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendMailUpdatePublishRequest(
        corporate: AdnCorporateI,
        store: AdnStoreI,
        adnBusinessUser: AdnUser,
        adnCustomerUser: AdnUser,
        pubId: string,
        pubTotal: number,
        status: string,
        comments?: string,
        oneSpotAd?: boolean
    ) {
        return new Promise((resolve, reject) => {
            const params1 = new HttpParams()
                .set('storeEmailAddress', encodeURIComponent(adnBusinessUser.email))
                .set('customerEmailAddress', encodeURIComponent(adnCustomerUser.email))
                .set('pubId', pubId.toString())
                .set('orderTotal', pubTotal.toString())
                .set('smbName', store.storeName)
                .set('customerName', adnCustomerUser.name)
                .set('pubLink', '')
                .set('customerPRTemplate', 'customerUpdatedAd')
                .set('SmbPRTemplate', '')
                .set('pubStatus', status)
                .set('smbSiren', corporate.sirenNumber)
                .set('smbApe', corporate.apeCode)
                .set('smbTVA', corporate.vatNumber)
                .set('language', this.utilSvc.language)
                .set('oneStopAd', oneSpotAd === undefined || oneSpotAd ? 'true' : 'false')
                .set('comments', comments  ? comments : '');
            // tslint:disable-next-line: deprecation
            this.http.get(this.utilSvc.backendURL + 'mailer/sendMailPublish', { params: params1 }).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

}
