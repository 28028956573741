import {
    Injectable,
  } from '@angular/core';
  import 'rxjs/add/operator/map';
  import 'rxjs';
  import { HttpClient, } from '@angular/common/http';
  import { StoreDbService, OBJECTNAME, } from '../godigital-lib';
  import { UtilsService, } from './utils.service';
  import { PaymentGwService, StripePlanI, STRIPEPLANINTERVAL, } from '../godigital-lib';
  
  export enum SORTPRODUCTSDIR {
    NameUp,
    NameDown,
    CategoryNameUp,
    CategoryNameDown,
    AvailabilityUp,
    AvailabilityDown
  }
  
  export enum PRODUCTTYPE {
    PRODUCT = 'PRODUCT',
    EXTRA = 'EXTRA',
  }
  
  export interface CATEGORYI {
    categoryId: string;
    title: string;
    description: string;
    thumb: string;
    localurl: string;
    localurl2: string;
    parentCategory: string;
    createdTS: number;
    modifiedTS: number;
    icon: string;
    highlights: boolean;
    subCategory: boolean;
    visible: boolean;
    available: boolean;
    publish: boolean;
    publishvisible: boolean;
    store: boolean;
    storevisible: boolean;
  }
  
  export interface PRODUCTI {
    productId: string;
    title: string;
    description: string;
    thumb: string;
    localurl: string;
    localurl2: string;
    createdTS: number;
    modifiedTS: number;
    isComposed: boolean;
    categoryId: string;
    category: string;
    categoryName: string;
    price: [
      {
        pname: string;
        specialPrice: number;
        value: number;
      }
    ];
    VAT: number;
    deliveryMethod: {
      payDine: boolean;
      payCollect: boolean;
      payDeliver: boolean;
    };
    extraOptionsI: string[];
    preparationI: string[];
    childrenProductsI: [
      {
        section: string,
        options: string[];
        extraprices: number[];
      }
    ];
    extraOptionsMenuI: string[];
    preparationMenuI: string[];
  
    daily: boolean;
    available: boolean;

    publish: boolean;
    publishvisible: boolean;
    store: boolean;
    storevisible: boolean;
    likes: string[];
  }
  
  export interface EXTRAOPTIONI {
    extraOptionId: string;
    category: string;
    name: string;
    price: number;
    VAT: number;
    quantity: number;
  }
  
  export interface PREPARATIONI {
    preparationId: string;
    category: string;
    name: string;
  }
  
  export interface PRODUCTPreparationI {
    type: string;
    value: PREPARATIONI[];
  }
  
  export interface PRODUCTExtraoptionsI {
    type: string;
    value: EXTRAOPTIONI[];
  }
  
  export interface PRODUCTMenuItem {
    section: string;
    options: PRODUCTIKamli[];
    extraPrice: number;
  }
  
  export interface PRODUCTIKamli extends PRODUCTI {
    fullcategory: CATEGORYI;
    extraOptions: PRODUCTExtraoptionsI[];
    preparation: PRODUCTPreparationI[];
    childrenProducts: PRODUCTMenuItem[];
    preparationMenu: PRODUCTPreparationI[];
    extraOptionsMenu: PRODUCTExtraoptionsI[];
    extraPrice: number;
  }
  
  @Injectable({
    providedIn: 'root'
  })
  
  export class ProductsService {
    public categories;
    public products;
    public images;
    public videos;
    public extras;
    public preparation;
    public productSliders;
    public includedExtras;
    public currentCategory;
    public currentProduct;
    public stripeFBPlan;
  
    constructor(
      public http: HttpClient,
      public storeDbSvc: StoreDbService,
      public utilSvc: UtilsService,
      public paymentGwSvc: PaymentGwService,
    ) {
    }
  
    getCategories(storeId) {
      const objectName = OBJECTNAME.categories;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.categories = this.utilSvc.objectToArray(data);
            resolve(this.categories);
          },
          error => reject(error)
        );
      });
    }
  
    getCategory(storeId, categoryId) {
      const objectName = OBJECTNAME.categories;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.categories = this.utilSvc.objectToArray(data);
            resolve(this.categories.find(c => c.categoryId === categoryId));
          },
          error => reject(error)
        );
      });
    }
  
    getProducts(storeId) {
      const objectName = OBJECTNAME.products;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.products = this.utilSvc.objectToArray(data);
            resolve(this.products);
          },
          error => reject(error)
        );
      });
    }
  
    getExtras(storeId) {
      const objectName = OBJECTNAME.extras;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.extras = this.utilSvc.objectToArray(data);
            resolve(this.extras);
          },
          error => reject(error)
        );
      });
    }
  
    getPreparation(storeId) {
      const objectName = OBJECTNAME.preparation;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.preparation = this.utilSvc.objectToArray(data);
            resolve(this.preparation);
          },
          error => reject(error)
        );
      });
    }
  
    getCalories(storeId) {
      const objectName = OBJECTNAME.calories;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            resolve(this.utilSvc.objectToArray(data));
          },
          error => reject(error)
        );
      });
    }
  
    getEvents(storeId) {
      const objectName = OBJECTNAME.events;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            resolve(this.utilSvc.objectToArray(data));
          },
          error => reject(error)
        );
      });
    }
  
    getAllergens(storeId) {
      const objectName = OBJECTNAME.allergens;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            resolve(this.utilSvc.objectToArray(data));
          },
          error => reject(error)
        );
      });
    }
  
    addCategory(storeId, newCategory) {
      const objectName = OBJECTNAME.categories;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, newCategory).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    addProduct(storeId, newProduct) {
      const objectName = OBJECTNAME.products;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, newProduct).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    editCategory(storeId, updatedCategory, categoryId) {
      const objectName = OBJECTNAME.categories;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, updatedCategory, categoryId).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    deleteCategory(storeId, categoryId) {
      const objectName = OBJECTNAME.categories;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.removeObject(storeId, this.utilSvc.sdb[storeId], objectName, categoryId).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
  
    editProducts(storeId, updatedProducts) {
      const objectName = OBJECTNAME.products;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, updatedProducts).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    editProduct(storeId, updatedProduct, productId) {
      const objectName = OBJECTNAME.products;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, updatedProduct, productId).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    deleteProduct(storeId, productId) {
      const objectName = OBJECTNAME.products;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.removeObject(storeId, this.utilSvc.sdb[storeId], objectName, productId).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    /*  addProductPriceOption(storeId, productId, pname1, value1) {
        const objectName = OBJECTNAME.products;
        const product = this.storeDbSvc.firebaseData[storeId][objectName].find(p => p.productsId === productId);
        if (product ) {
          const priceOption = { pname: pname1, value: value1 };
          product.price.push(priceOption);
          this.editProduct(storeId, product, productId);
        }
      }
  
      delProductPriceOption(storeId, productId, index) {
        const objectName = OBJECTNAME.products;
        const product = this.storeDbSvc.firebaseData[storeId][objectName].find(p => p.productsId === productId);
        if (product ) {
          if (product.price[index] ) {
            product.price.splice(index, 1);
            this.editProduct(storeId, product, productId);
          }
        }
      }*/
  
  
    /*  setProductsInfo(storeId, products) {
        for (const product of products) {
          this.setProductInfo(storeId, product);
        }
      }
  
      getProductInfo2(products, storeProduct, calories?, foodAllergy?) {
        const childrenProductsIString = 'childrenProductsI';
        const childrenProductsString = 'childrenProducts';
        const suggestedProductsString = 'suggestedProducts';
        if (storeProduct[childrenProductsIString] && this.utilSvc.isObject(storeProduct[childrenProductsIString])) {
          const childrenObj = storeProduct[childrenProductsIString];
          for (const child of childrenObj) {
            const childOptions = [];
            if (child.options ) {
              for (let j = 0; j < child.options.length; j++) {
                const id = child.options[j];
                let extraCost1 = 0;
                if (child.extraCost ) {
                  if (child.extraCost[j] ) {
                    extraCost1 = child.extraCost[j];
                  }
                }
                const temp = products.find(p => Number(p.productId) === Number(id));
                if (temp ) {
                  const temp2 = { product: this.setProductComposed(temp), extraCost: extraCost1 };
                  childOptions.push(temp2);
                }
              }
            }
            child.options = childOptions;
          }
          storeProduct[childrenProductsString] = childrenObj;
        }
  
        if (storeProduct[suggestedProductsString]  && this.utilSvc.isObject(storeProduct[suggestedProductsString])) {
          const suggestedProductsArr = [];
          const suggestedProductsArrIndex = storeProduct[suggestedProductsString];
          for (const id of suggestedProductsArrIndex) {
            const temp = products.find(p => p.productId === id);
            if (temp ) {
              suggestedProductsArr.push(this.setProductComposed(temp));
            }
          }
          if (suggestedProductsArr.length > 0) {
            storeProduct[suggestedProductsString] = suggestedProductsArr;
          }
          else {
            storeProduct[suggestedProductsString] = [];
          }
        }
      }
  
      getProductsInfo(storeId, products, calories?, foodAllergy?) {
        const promises = [];
        promises.push(this.getExtras(storeId));
        promises.push(this.getPreparation(storeId));
  
        Promise.all(promises).then(
          data => {
            const extras = data[0];
            const preparations = data[1];
            for (const product of products) {
              this.getProductInfo1(products, product, preparations, extras, calories, foodAllergy);
            }
            for (const product of products) {
              this.getProductInfo2(products, product, calories, foodAllergy);
            }
          }
        );
      }*/
  
    setProductComposed(product) {
      let productItem;
      productItem = {};
      productItem.productId = product.productId;
      productItem.title = product.title;
      productItem.price = product.price;
      productItem.thumb = product.thumb;
      productItem.fburl = product.fburl  ? product.fburl : null;
      productItem.categoryName = product.categoryName;
      productItem.category = product.category;
      if (product.preparationMenu ) {
        productItem.preparationMenu = product.preparationMenu;
      }
      if (product.extraOptionsMenu ) {
        productItem.extraOptionsMenu = product.extraOptionsMenu;
      }
      return productItem;
    }
  
    /*    setProductInfo(storeId, storeProduct) {
          const preparationString = 'preparation';
          const extraOptionsString = 'extraOptions';
          const preparationMenuString = 'preparationMenu';
          const extraOptionsMenuString = 'extraOptionsMenu';
          const childrenProductsString = 'childrenProducts';
          let prepObj = {};
          let extraObj = {};
          return new Promise((resolve, reject) => {
            if (storeProduct[preparationString] ) {
              prepObj = {};
              for (const prep of storeProduct[preparationString]) {
                prepObj[prep.type] = prep.value;
              }
              storeProduct[preparationString] = prepObj;
            }
            else {
              storeProduct[preparationString] = {};
            }
  
            if (storeProduct[extraOptionsString] ) {
              extraObj = {};
              for (const extra of storeProduct[extraOptionsString]) {
                extraObj[extra.type] = extra.value;
              }
              storeProduct[extraOptionsString] = extraObj;
            }
            else {
              storeProduct[extraOptionsString] = {};
            }
  
            if (storeProduct[preparationMenuString] ) {
              prepObj = {};
              for (const prep of storeProduct[preparationMenuString]) {
                prepObj[prep.type] = prep.value;
              }
              storeProduct[preparationMenuString] = prepObj;
            }
            else {
              storeProduct[preparationMenuString] = {};
            }
  
            if (storeProduct[extraOptionsMenuString] ) {
              extraObj = {};
              for (const extra of storeProduct[extraOptionsMenuString]) {
                extraObj[extra.type] = extra.value;
              }
              storeProduct[extraOptionsMenuString] = extraObj;
            }
            else {
              storeProduct[extraOptionsMenuString] = {};
            }
  
            const childObj = [];
            if (storeProduct[childrenProductsString]) {
              for (const child of storeProduct[childrenProductsString]) {
                const temp2 = { section: child.section, options: [], extraCost: [] };
                for (const coption of child.options) {
                  temp2.options.push(coption.product.productId);
                  temp2.extraCost.push(coption.extraCost);
                }
                childObj.push(temp2);
              }
            }
            storeProduct[childrenProductsString] = childObj;
            resolve(storeProduct);
          });
        }*/
  
  
  
    editEvent(storeId, storeEvent) {
      const objectName = OBJECTNAME.events;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.updateObject(storeId, this.utilSvc.sdb[storeId], objectName, storeEvent, storeEvent.eventId).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    deleteEvent(storeId, storeEventId) {
      const objectName = OBJECTNAME.events;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.removeObject(storeId, this.utilSvc.sdb[storeId], objectName, storeEventId).then(
          data => resolve(1),
          error => reject(error)
        );
      });
    }
  
    sortProduct(sortType) {
      switch (sortType) {
        case SORTPRODUCTSDIR.NameUp:
          this.products.sort((a, b) => {
            return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
          });
          break;
  
        case SORTPRODUCTSDIR.NameDown:
          this.products.sort((a, b) => {
            return (a.title > b.title) ? -1 : ((b.title > a.title) ? 1 : 0);
          });
          break;
  
        case SORTPRODUCTSDIR.CategoryNameUp:
          this.products.sort((a, b) => {
            return (a.categoryName > b.categoryName) ? 1 : ((b.categoryName > a.categoryName) ? -1 : 0);
          });
          break;
  
        case SORTPRODUCTSDIR.CategoryNameDown:
          this.products.sort((a, b) => {
            return (a.categoryName > b.categoryName) ? -1 : ((b.categoryName > a.categoryName) ? 1 : 0);
          });
          break;
  
        case SORTPRODUCTSDIR.AvailabilityUp:
          this.products.sort((a, b) => {
            return (a.availability > b.availability) ? 1 : ((b.availability > a.availability) ? -1 : 0);
          });
          break;
  
        case SORTPRODUCTSDIR.AvailabilityDown:
          this.products.sort((a, b) => {
            return (a.availability > b.availability) ? -1 : ((b.availability > a.availability) ? 1 : 0);
          });
          break;
  
        default:
          break;
      }
    }
  
    /*  filterItem(storeId, objectName, field, value) {
        this.products = this.storeDbSvc.firebaseData[storeId][objectName].filter(p => p[field] === value);
      }
  
  
      getPrep(prepObjs: string[], preparations: any[]): any[] {
        const preparationIdString = 'preparationId';
        const categoryString = 'category';
        const nameString = 'name';
        const prepArr = [];
        if (prepObjs ) {
          for (const prepObj of prepObjs) {
            if (preparations ) {
              const temp = preparations.find(p => Number(p[preparationIdString]) === Number(prepObj));
              if (temp ) {
                let temp2 = prepArr.find(pa => pa.type === temp[categoryString]);
                if (temp2 === undefined) {
                  temp2 = {
                    type: temp[categoryString],
                    value: [{ name: temp[nameString] }]
                  };
                  prepArr.push(temp2);
                }
                else {
                  const temp3 = temp2.value.find(v => v.name === temp[nameString]);
                  if (temp3 === undefined) {
                    temp2.value.push({ name: temp[nameString] });
                  }
                }
              }
            }
          }
        }
  
        return prepArr;
      }
  
      getExtra(extraObjs: string[], extras: any[]): any[] {
        const extraOptionIdString = 'extraOptionId';
        const categoryString = 'category';
        const nameString = 'name';
        const priceString = 'price';
        const extraArr = [];
        if (extraObjs ) {
          for (const extraObj of extraObjs) {
            if (extras ) {
              const temp = extras.find(p => Number(p[extraOptionIdString]) === Number(extraObj));
              if (temp ) {
                let temp2 = extraArr.find(pa => pa.type === temp[categoryString]);
                if (temp2 === undefined) {
                  temp2 = {
                    type: temp[categoryString],
                    value: [{ name: temp[nameString], price: temp[priceString] }]
                  };
                  extraArr.push(temp2);
                }
                else {
                  const temp3 = temp2.value.find(v => v.name === temp[nameString]);
                  if (temp3 === undefined) {
                    temp2.value.push({ name: temp[nameString], price: temp[priceString] });
                  }
                }
              }
            }
          }
        }
  
        return extraArr;
      }
  
      getProductInfo1(products, storeProduct, preparations, extras, calories?, foodAllergy?) {
        const preparationIString = 'preparationI';
        const preparationString = 'preparation';
        const extraOptionsIString = 'extraOptionsI';
        const extraOptionsString = 'extraOptions';
        const preparationMenuIString = 'preparationMenuI';
        const preparationMenuString = 'preparationMenu';
        const extraOptionsMenuIString = 'extraOptionsMenuI';
        const extraOptionsMenuString = 'extraOptionsMenu';
        const caloriesString = 'calories';
        const foodAllergyString = 'foodAllergy';
        if (storeProduct[preparationIString]  && this.utilSvc.isObject(storeProduct[preparationIString])) {
          let prepArr = [];
          const prepObj = storeProduct[preparationIString];
          if (prepObj ) {
            prepArr = this.getPrep(prepObj, preparations);
          }
          storeProduct[preparationString] = prepArr;
        }
  
        if (storeProduct[extraOptionsIString]  && this.utilSvc.isObject(storeProduct[extraOptionsIString])) {
          let extraArr = [];
          const extraObj = storeProduct[extraOptionsIString];
          if (extraObj ) {
            extraArr = this.getExtra(extraObj, extras);
          }
          storeProduct[extraOptionsString] = extraArr;
        }
  
        if (storeProduct[preparationMenuIString]  && this.utilSvc.isObject(storeProduct[preparationMenuIString])) {
          let prepArr = [];
          const prepObj = storeProduct[preparationMenuIString];
          if (prepObj ) {
            prepArr = this.getPrep(prepObj, preparations);
          }
          storeProduct[preparationMenuString] = prepArr;
        }
  
        if (storeProduct[extraOptionsMenuIString]  && this.utilSvc.isObject(storeProduct[extraOptionsMenuIString])) {
          let extraArr = [];
          const extraObj = storeProduct[extraOptionsMenuIString];
          if (extraObj ) {
            extraArr = this.getExtra(extraObj, extras);
          }
          storeProduct[extraOptionsMenuString] = extraArr;
        }
  
        if (calories ) {
          if (storeProduct[caloriesString]) {
            const caloriesArr = [];
            const caloriesArrIndex = storeProduct[caloriesString];
            for (const id of caloriesArrIndex) {
              const temp = calories.find(p => p.caloriesId === id);
              if (temp ) {
                caloriesArr.push(temp);
              }
            }
            if (caloriesArr.length > 0) {
              storeProduct[caloriesString] = caloriesArr;
            }
          }
        }
  
        if (foodAllergy ) {
          if (storeProduct[foodAllergyString]) {
            const foodAlergiesArr = [];
            const foodAlergiesIndex = storeProduct[foodAllergyString];
            for (const id of foodAlergiesIndex) {
              const temp = foodAllergy.find(p => p.allergenId === id);
              if (temp ) {
                foodAlergiesArr.push(temp);
              }
            }
            if (foodAlergiesArr.length > 0) {
              storeProduct[foodAllergyString] = foodAlergiesArr;
            }
          }
        }
      }*/
  
    getImages(storeId) {
      const objectName = OBJECTNAME.DsPImage;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.images = this.utilSvc.objectToArray(data);
            resolve(this.images);
          },
          error => reject(error)
        );
      });
    }
  
    getImage(storeId, imageId) {
      const objectName = OBJECTNAME.DsPImage;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.images = this.utilSvc.objectToArray(data);
            let temp;
            if (this.images ) {
              temp = this.images.find(c => c.imageId === imageId);
            }
            resolve(temp);
          },
          error => reject(error)
        );
      });
    }
  
    getVideos(storeId) {
      const objectName = OBJECTNAME.DsPVideo;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.videos = this.utilSvc.objectToArray(data);
            resolve(this.videos);
          },
          error => reject(error)
        );
      });
    }
  
    getVideo(storeId, videoId) {
      const objectName = OBJECTNAME.DsPVideo;
      return new Promise((resolve, reject) => {
        this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(
          data => {
            this.videos = this.utilSvc.objectToArray(data);
            let temp;
            if (this.videos ) {
              temp = this.videos.find(c => c.videoId === videoId);
            }
            resolve(temp);
          },
          error => reject(error)
        );
      });
    }
  
  
  }
  
  