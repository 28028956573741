/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { SmbService, AdnStoreI, AdnStoreIKamli, ReferralI, AdnUser, AdnUserKamli } from '../godigital-lib';
import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    StoreDbService, OBJECTNAME, RssFeedIKamli, RssFeedI, KamliApp, SLIDETYPE, DsSlide,
    RssFeedStatus, RssfeeditemI, EventI, PromotionI, KamliMedia, PublishOrderKamli
} from '../godigital-lib';
import { PRODUCTI } from '../godigital-lib';
import { UtilsService, dayInMilliseconds } from '../godigital-lib';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PublishingRequestService, StoreUpdates } from '../godigital-lib';
import { PUBMODERATIONSTATUS } from '../godigital-lib';

export const regexsnurl = /.*\/([^?^\/]+)/;
const regexpost = /(\/stores\/.+)\?/;

export enum STARTWALL {
    WALLMYFEED = 'wallmyfeed',
    WALLKAMLIFRIENDSFEED = 'wallkamlifriendsFeed',
    WALLSOCIALFRIEND = 'wallsocialfriend',
    WALLADNFEED = 'walladnFeed',
}

@Injectable({
    providedIn: 'root'
})


export class SocialNetworkService {
    public subscriptions = new Subscription();
    public referralPostO: BehaviorSubject<RssFeedI[]> = new BehaviorSubject(null);
    public myPostO: BehaviorSubject<RssFeedI[]> = new BehaviorSubject(null);
    public socialnetworks: RssFeedI[];
    public socialnetworksO: BehaviorSubject<RssFeedI[]> = new BehaviorSubject(null);
    public friendsFeed: RssFeedIKamli[];
    public friendsFeedO: BehaviorSubject<RssFeedIKamli[]> = new BehaviorSubject(null);
    public wallFeed: RssFeedIKamli;
    public wallFeedO: BehaviorSubject<RssFeedIKamli> = new BehaviorSubject(null);
    public mysocialFeed: RssFeedIKamli[];
    public mysocialFeedO: BehaviorSubject<RssFeedIKamli[]> = new BehaviorSubject(null);
    public likes: any;
    public likesO: BehaviorSubject<any> = new BehaviorSubject(null);
    public timerWall;
    public timermysocial = {};
    public storeFeed = {};
    public storeFeedO = {};
    public feedCategory = '';
    public subscriptionStoreFeedItems = {};
    public wallPosts: RssFeedIKamli;
    public wallPostsO: BehaviorSubject<RssFeedIKamli> = new BehaviorSubject(null);
    public wallmyFeedItems = [] as RssfeeditemI[];
    public wallmyFeedItemsVisible = [] as RssfeeditemI[];
    public wallmyFeedItemsO: BehaviorSubject<RssfeeditemI[]> = new BehaviorSubject(null);
    public walladnFeedItems = [] as RssfeeditemI[];
    public walladnFeedItemsVisible = [] as RssfeeditemI[];
    public walladnFeedItemsO: BehaviorSubject<RssfeeditemI[]> = new BehaviorSubject(null);
    public wallkamlifriendsFeedItems = [] as RssfeeditemI[];
    public wallkamlifriendsFeedItemsVisible = [] as RssfeeditemI[];
    public wallkamlifriendsFeedItemsO: BehaviorSubject<RssfeeditemI[]> = new BehaviorSubject(null);
    public wallsocialfriendsFeedItems = [] as RssfeeditemI[];
    public wallsocialfriendsFeedItemsVisible = [] as RssfeeditemI[];
    public wallsocialfriendsFeedItemsO: BehaviorSubject<RssfeeditemI[]> = new BehaviorSubject(null);


    constructor(
        public http: HttpClient,
        public storeDbSvc: StoreDbService,
        public smbSvc: SmbService,
        public utilSvc: UtilsService,
        public datepipe: DatePipe,
        public publishingRequestSvc: PublishingRequestService,
    ) { }

    public getSocialFriendPosts(store: AdnStoreI) {
        return new Promise(async resolve => {
            let socialnetworksKamli;
            let referralPosts;
            if (store) {
                try {
                    const temp0 = await this.storeDbSvc.getObject(this.utilSvc.adnStoreId,
                        this.utilSvc.sdb[this.utilSvc.adnStoreId], OBJECTNAME.socialnetworks);
                    if (temp0 && temp0 != null) {
                        socialnetworksKamli = this.utilSvc.objectToArray(temp0);
                    }
                }
                catch (e) { }
                if (store.referrals && store.referrals.length > 0) {
                    referralPosts = [];
                    const referral1 = [];
                    let update = false;
                    for (const referral of store.referrals) {
                        if (referral.type !== 'kamli') {
                            if (socialnetworksKamli ) {
                                if (referral.type === OBJECTNAME.socialnetworks) {
                                    const temp0 = socialnetworksKamli && socialnetworksKamli.find(sn =>
                                        String(sn.rssId) === String(referral.referralId));
                                    if (temp0 ) {
                                        temp0.store = referral.store === false ? false : true;
                                        temp0.publish = referral.publish === false ? false : true;
                                        referralPosts.push(temp0);
                                        referral1.push(referral);
                                    }
                                    else {
                                        update = true;
                                    }
                                }
                            }
                        }
                        else {
                            let temps;
                            try {
                                temps = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, referral.referralId).catch(e => { }));
                            }
                            catch (e) { }
                            if (temps) {
                                const tempK = {} as RssFeedIKamli;
                                tempK.feedCategory = 'kamli';
                                tempK.feedDescription = temps.postalCode + ' ' + temps.city + ', ' + temps.country;
                                tempK.feedItems = [];
                                tempK.feedTitle = temps.storeName;
                                tempK.image = temps.kamliwebDetails.logo;
                                tempK.localimage = temps.kamliwebDetails.locallogo;
                                tempK.rssId = temps.adnStoreId;
                                tempK.store = referral.store === false ? false : true;
                                tempK.publish = referral.publish === false ? false : true;
                                referralPosts.push(tempK);
                                referral1.push(referral);
                            }
                            else {
                                update = true;
                            }
                        }
                    }
                    if (update) {
                        store.referrals = referral1;
                        this.smbSvc.editStore(this.utilSvc.backendFBstoreId, store);
                    }
                }
                resolve(referralPosts);
            } else {
                resolve(referralPosts);
            }
        });
    }
    public async getMySocialPosts(store: AdnStoreI) {
        const mySocialPosts = { instagram: undefined, facebook: undefined };
        let socialnetworksKamli;
        if (store && store.kamliwebDetails && (store.kamliwebDetails.facebook || store.kamliwebDetails.instagram)) {
            try {
                const temp0 = await this.storeDbSvc.getObject(this.utilSvc.adnStoreId,
                    this.utilSvc.sdb[this.utilSvc.adnStoreId], OBJECTNAME.socialnetworks);
                if (temp0 && temp0 != null) {
                    socialnetworksKamli = this.utilSvc.objectToArray(temp0);
                }
            }
            catch (e) { }
            if (socialnetworksKamli) {
                if (store.kamliwebDetails && store.kamliwebDetails.instagram) {
                    mySocialPosts.instagram = socialnetworksKamli && socialnetworksKamli.find(sn =>
                        sn.url === store.kamliwebDetails.instagram);
                }
                if (store.kamliwebDetails && store.kamliwebDetails.facebook) {
                    mySocialPosts.facebook = socialnetworksKamli && socialnetworksKamli.find(sn =>
                        sn.url === store.kamliwebDetails.facebook);
                }
            }
        }
        return mySocialPosts;
    }

    createSN(
        socialnetworks: RssFeedI[], snname: string, sntype: string, snurl: string, sngpid: string,
        currentPost: RssFeedI, currentStore: AdnStoreI, mine?: boolean) {
        return new Promise(async resolve => {
            let addReferral = false;
            const tempsnurl = regexsnurl.exec(snurl);
            const temp = socialnetworks && socialnetworks.find(r => {
                let result = false;
                const tempr = regexsnurl.exec(r.url);
                if (tempr && tempsnurl && tempr[1] && tempsnurl[1]) {
                    result = tempr[1].toLowerCase() === tempsnurl[1].toLowerCase() &&
                        r.feedCategory.toLowerCase() === sntype.toLowerCase();
                }
                return result;
            });
            if (!temp && sntype !== 'kamli') {
                currentPost.url = snurl;
                currentPost.feedCategory = sntype;
                currentPost.feedTitle = snname;
                currentPost.store = true;
                currentPost.publish = true;
                currentPost.gpid = sngpid;
                currentPost.smbcategoryId = '1400';
                currentPost.requester = currentStore.storeName;
                currentPost.requesterId = currentStore.adnStoreId;
                currentPost.since = new Date().getTime();
                currentPost.status = RssFeedStatus.REQUESTED;
                currentPost.rssId = String(Math.floor(Math.random() * 100000));
                if (!currentStore.referrals) {
                    currentStore.referrals = [];
                }
                if (currentStore ) {
                    const newReferral = {} as ReferralI;
                    newReferral.id = String(Math.floor(Math.random() * 100000));
                    newReferral.type = OBJECTNAME.socialnetworks;
                    newReferral.name = snname;
                    newReferral.referralId = currentPost.rssId;
                    newReferral.urlname = tempsnurl[1];
                    newReferral.feedCategory = sntype;
                    newReferral.store = true;
                    newReferral.publish = true;
                    if (!mine) {
                        currentStore.referrals.push(newReferral);
                    }
                    const storeString = 'store';
                    if (currentStore && currentStore.kamlifilleradsDetails) {
                        currentStore.kamlifilleradsDetails[storeString] = null;
                    }
                    if (sntype === 'facebook') {
                        const params1 = new HttpParams()
                            .set('url', encodeURI(snurl));
                        this.http.get(this.utilSvc.backendURL + 'rss/fetchrss', { params: params1 }).subscribe(
                            async data => {
                                const source = data as string;
                                currentPost.feedUrl = source;
                                currentPost.status = RssFeedStatus.ACCEPTED;
                                await this.storeDbSvc.updateObject(this.utilSvc.adnStoreId,
                                    this.utilSvc.sdb[this.utilSvc.adnStoreId], OBJECTNAME.socialnetworks, currentPost, currentPost.rssId);
                                if (!mine) {
                                    this.smbSvc.editStore(this.utilSvc.backendFBstoreId, currentStore);
                                }
                                resolve([1, 'facebook content add/update',
                                    'this facebook content is now imported and accessible on kamliadmin and kamlipublish']);
                            }, () => {
                                resolve([0, 'social network friend add/update',
                                    'this facebook account is unknown or not public']);
                            });
                    }
                    else {
                        await this.storeDbSvc.updateObject(this.utilSvc.adnStoreId,
                            this.utilSvc.sdb[this.utilSvc.adnStoreId], OBJECTNAME.socialnetworks, currentPost, currentPost.rssId);
                        if (!mine) {
                            this.smbSvc.editStore(this.utilSvc.backendFBstoreId, currentStore);
                        }
                        resolve([1, 'social network friend add/update', 'this social network account will be imported shortly']);
                    }
                }
                else {
                    resolve([0, 'social network friend new/update', 'unsupported social network or not recognised. Please try again']);
                }
            }
            else {
                addReferral = false;
                if (sntype !== 'kamli') {
                    currentPost = temp;
                }
                if (currentStore.referrals) {
                    const temp2 = currentStore && currentStore.referrals && currentStore.referrals.find(csr => String(csr.referralId) ===
                        String(currentPost.rssId));
                    if (!temp2) {
                        addReferral = true;
                    }
                }
                else {
                    currentStore.referrals = [];
                    addReferral = true;
                }
                const storeNameString = 'storeName';
                if (addReferral && currentStore) {
                    const newReferral = {} as ReferralI;
                    newReferral.id = String(Math.floor(Math.random() * 100000));
                    newReferral.type = sntype !== 'kamli' ? OBJECTNAME.socialnetworks : 'kamli';
                    newReferral.name = snname ? snname : currentPost && currentPost[storeNameString] ?
                        currentPost && currentPost[storeNameString] + ' ' + sntype : '';
                    newReferral.referralId = currentPost.rssId;
                    newReferral.urlname = tempsnurl && tempsnurl[1] ? tempsnurl[1] : currentPost ? currentPost.url : '';
                    newReferral.feedCategory = sntype;
                    newReferral.store = true;
                    newReferral.publish = true;
                    if (!mine) {
                        currentStore.referrals.push(newReferral);
                        let error;
                        try {
                            await this.smbSvc.editStore(this.utilSvc.backendFBstoreId, currentStore);
                        } catch (e) {
                            console.log('error=', e);
                            error = e;
                        }
                        if (!error) {
                            resolve([1, 'social network friend new/update', 'this social network content is now imported and accessible on kamliadmin and kamlipublish']);
                        } else {
                            resolve([0, 'social network friend new/update', 'this social network content cannot be imported now. please try again later']);
                        }
                    } else {
                        resolve([0, 'social network friend new/update', 'this social network content cannot be imported now. please try again later']);
                    }
                }
                else {
                    resolve([1, 'social network friend new/update',
                        'this social network content has already been imported and is accessible on kamliadmin and kamlipublish']);
                }
            }
        });
    }
    getUrlFromSocialNetwork(url: string) {
        let urlI;
        if (url ) {
            const temp = regexsnurl.exec(url);
            if (temp && temp != null && temp[1]) {
                urlI = temp[1];
            }
        }
        return urlI;
    }

    subscribeLikes(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT || this.utilSvc.appName === KamliApp.KAMLISTORE) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.likes].subscribe(data => {
                const temp = data && data[0] != null ? this.utilSvc.objectToArray(data[0]) : null;
                this.setLikes(temp);
            });
        }
    }
    unsubscribeLikes(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIEAT || this.utilSvc.appName === KamliApp.KAMLISTORE) {
            this.storeDbSvc.unsubscribeObject(this.utilSvc.adnStoreId, this.utilSvc.sdb[this.utilSvc.adnStoreId],
                OBJECTNAME.likes, storeId);
            this.setLikes(undefined);
        }
    }

    public getLikes(): Observable<any> {
        return this.likesO.asObservable();
    }
    public setLikes(value: any) {
        this.likes = value;
        this.likesO.next(value);
    }

    subscribeSocialnetworks(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.firebaseBSSdata[storeId][OBJECTNAME.socialnetworks].subscribe(data => {
                const temp = data && data[0] ? this.utilSvc.objectToArray(data[0]) : undefined;
                this.setSocialnetworks(temp);
            });
        }
    }
    unsubscribeSocialnetworks(storeId: string) {
        if (this.utilSvc.appName === KamliApp.KAMLIADN || this.utilSvc.appName === KamliApp.KAMLIADMIN ||
            this.utilSvc.appName === KamliApp.KAMLICONSOLE || this.utilSvc.appName === KamliApp.KAMLIPUBLISH) {
            this.storeDbSvc.unsubscribeObject(this.utilSvc.adnStoreId, this.utilSvc.sdb[this.utilSvc.adnStoreId],
                OBJECTNAME.socialnetworks, storeId);
            this.setSocialnetworks(undefined);
        }
    }

    getMySocialNetworkUrl(store: AdnStoreI) {
        let urlI;
        let urlF;
        if (store  && store != null) {
            if (store.kamliwebDetails?.instagram ) {
                urlI = this.getUrlFromSocialNetwork(store.kamliwebDetails?.instagram);
            }
            if (store.kamliwebDetails?.facebook ) {
                urlF = this.getUrlFromSocialNetwork(store.kamliwebDetails?.facebook);
            }
        }
        return [urlI, urlF];
    }

    selectSn(sn: any, currentStore: AdnStoreI, kamli?: boolean) {
        const storeNameString = 'storeName';
        const adnStoreIdString = 'adnStoreId';
        let proceed = true;
        return new Promise(async (resolve, reject) => {
            let type;
            const newReferral = {} as ReferralI;
            if (sn.url.includes('facebook')) {
                type = 'facebook';
            }
            if (sn.url.includes('instagram')) {
                type = 'instagram';
            }
            let tempsn = regexsnurl.exec(sn.url);
            if ((type === 'facebook' || type === 'instagram') && (!kamli)) {
                newReferral.type = 'socialnetworks';
                newReferral.name = sn.feedTitle;
                newReferral.referralId = sn.rssId;
                newReferral.feedCategory = type;
                newReferral.urlname = tempsn && tempsn[1] ? tempsn[1] : null;
                // checking that no referral kamli already has this social network
                if (currentStore.referrals) {
                    for (const sr of currentStore.referrals) {
                        if (sr.type === 'kamli') {
                            const fstore = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                OBJECTNAME.adnStores, sr.referralId) as AdnStoreI;
                            if (fstore && fstore.kamliwebDetails && fstore.kamliwebDetails[type]) {
                                const urlname = regexsnurl.exec(fstore.kamliwebDetails[type]);
                                if (urlname && urlname[1]) {
                                    if (urlname[1] === newReferral.urlname) {
                                        // social network already imported through that kamli account
                                        resolve(['social network friend new/update', 'your social network friend has been added']);
                                        proceed = false;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (kamli) {
                newReferral.type = 'kamli';
                type = 'kamli';
                newReferral.name = sn[storeNameString];
                newReferral.referralId = sn[adnStoreIdString];
                newReferral.feedCategory = type;
                newReferral.urlname = null;
                if (currentStore.referrals) {
                    // check is this business is not already a friend
                    const temp1 = currentStore && currentStore.referrals &&
                        currentStore.referrals.find(r => r.referralId === newReferral.referralId);
                    if (temp1) {
                        resolve(['social network friend new/update', 'this social network friend is alreay in your list of friends']);
                        proceed = false;
                    }
                    if (proceed) {
                        // checking to remove instagram/facebook referrals part of this kamli store
                        const fstore = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId,
                            this.utilSvc.mdb, OBJECTNAME.adnStores, newReferral.referralId) as AdnStoreI;
                        if (fstore.referrals && fstore.kamliwebDetails && fstore.kamliwebDetails[type]) {
                            tempsn = regexsnurl.exec(fstore.kamliwebDetails[type]);
                            if (tempsn && tempsn[1]) {
                                for (const sr of fstore.referrals) {
                                    const snreferral = fstore.referrals.findIndex(snr => snr.type === sr.type && snr.urlname === tempsn[1]);
                                    if (snreferral !== -1) {
                                        currentStore.referrals.splice(snreferral, 1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (proceed) {
                if (type ) {
                    newReferral.id = String(Math.floor(Math.random() * 100000));
                    if (!currentStore.referrals) {
                        currentStore.referrals = [];
                    }
                    newReferral.store = true;
                    newReferral.publish = true;
                    currentStore.referrals.push(newReferral);
                    const storeString = 'store';
                    if (currentStore && currentStore.kamlifilleradsDetails) {
                        currentStore.kamlifilleradsDetails[storeString] = null;
                    }
                    this.smbSvc.editStore(this.utilSvc.backendFBstoreId, currentStore);
                    resolve(['social network friend new/update', 'your social network friend has been added']);
                }
                else {
                    reject('unknown social network type');
                }
            }
        });
    }
    removefriendSn(referralPost: RssFeedIKamli, currentStore: AdnStoreI, storeDsSlides: DsSlide[]) {
        return new Promise((resolve) => {
            let foundSn = false;
            if (currentStore  && currentStore != null &&
                currentStore.referrals  && currentStore.referrals != null) {
                const tempIndex = currentStore.referrals.findIndex(r => String(r.referralId) === String(referralPost.rssId));
                if (tempIndex !== -1) {
                    currentStore.referrals.splice(tempIndex, 1);
                    if (currentStore.adnStoreId ) {
                        foundSn = true;
                        const storeString = 'store';
                        if (currentStore && currentStore.kamlifilleradsDetails) {
                            currentStore.kamlifilleradsDetails[storeString] = null;
                        }
                        this.smbSvc.editStore(this.utilSvc.backendFBstoreId, currentStore);
                        /*                        this.storeDbSvc.updateObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                                    OBJECTNAME.adnStores, currentStore, currentStore.adnStoreId);*/
                    }
                }
            }
            if (foundSn && storeDsSlides  && storeDsSlides != null) {
                const temp = storeDsSlides.filter(s => s.type === SLIDETYPE.SOCIALFRIENDS);
                for (const slide of temp) {
                    if (slide.assets  && slide.assets != null) {
                        const tempIndex = slide.assets.findIndex(a => String(a) === String(referralPost.rssId));
                        if (tempIndex !== -1) {
                            slide.assets.splice(tempIndex, 1);
                            if (slide.slideId ) {
                                this.storeDbSvc.updateObject(currentStore.adnStoreId, this.utilSvc.sdb[currentStore.adnStoreId],
                                    OBJECTNAME.DsSlide, slide, slide.slideId);
                            }
                        }
                    }
                }
            }
            if (foundSn) {
                resolve(['social network friend new/update',
                    'this social network account has been removed from your social network friend list']);
            }
        });
    }
    filterSocialNetworks(socialnetworks: RssFeedI[], currentStore: AdnStoreI) {
        let temp;
        let tempIndex = -1;
        const filteredSocialnetworks = [];
        const referrals = currentStore.referrals ? currentStore.referrals : [];
        const myfacebook = currentStore.kamliwebDetails.facebook;
        const myinstagram = currentStore.kamliwebDetails.instagram;
        if (socialnetworks) {
            for (const sn of socialnetworks) {
                temp = referrals && referrals.find(r => String(r.referralId) === String(sn.rssId));
                if (!temp) {
                    filteredSocialnetworks.push(sn);
                }
            }
        }
        if (myinstagram  && myinstagram != null) {
            tempIndex = filteredSocialnetworks.findIndex(s => this.getUrlFromSocialNetwork(s.url) ===
                this.getUrlFromSocialNetwork(myinstagram));
            if (tempIndex !== -1) {
                filteredSocialnetworks.splice(tempIndex, 1);
            }
        }
        if (myfacebook  && myfacebook != null) {
            tempIndex = filteredSocialnetworks.findIndex(s => this.getUrlFromSocialNetwork(s.url) ===
                this.getUrlFromSocialNetwork(myfacebook));
            if (tempIndex !== -1) {
                filteredSocialnetworks.splice(tempIndex, 1);
            }
        }
        return filteredSocialnetworks;
    }
    public getStoreEvent(storeId: string, walltype: string, numberdays?: number) {
        const creatorString = 'creator';
        const creatorlogoString = 'creatorlogo';
        const regexf = /([^\/]+$)|([^\/]+)\?.*$/;
        const thumbString = 'thumb';
        if (!numberdays) {
            numberdays = 2;
        }
        return new Promise(async (resolve, reject) => {
            const temp = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.events);
            const tempj = this.utilSvc.objectToArray(temp) as EventI[];
            const temprs = [] as RssfeeditemI[];
            const store = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId) as AdnStoreI;
            if (tempj) {
                for (const e of tempj) {
                    if (typeof e === 'object') {
                        const tempr = {} as RssfeeditemI;
                        const tempcontent = {
                            type: OBJECTNAME.events,
                            data: e
                        };
                        tempr.content = tempcontent;
                        tempr.guid = storeId + '-' + e.eventId;
                        if (e.modifiedTS && e.modifiedTS + numberdays * dayInMilliseconds > new Date().getTime() && e[walltype] === true) {
                            tempr.pubDate = this.datepipe.transform(e.modifiedTS, 'yyyy-MM-dd HH:mm');
                            tempr.thumb = e.thumbL ? e.thumbL : e[thumbString];
                            let localurl = tempcontent.data.localurlL;
                            if (!tempcontent.data?.localurlL) {
                                if (tempcontent.data?.thumbL && tempcontent.data?.thumbL.includes('firebasestorage')) {
                                    const thumb = decodeURIComponent(tempcontent.data?.thumbL);
                                    const tempthumb = regexpost.exec(thumb);
                                    if (tempthumb && tempthumb[1]) {
                                        localurl = 'assets/' + tempthumb[1];
                                    }
                                } else {
                                    localurl = null;
                                }
                            } else {
                                localurl = tempcontent.data?.localurlL;
                            }
                            tempr.localurl = localurl;
                            tempr.title = e.title;
                            tempr.store = e.store ? e.store : false;
                            tempr.storevisible = e.storevisible  ? e.storevisible : true;
                            tempr.publish = e.publish ? e.publish : false;
                            tempr.publishvisible = e.publishvisible  ? e.publishvisible : true;
                            tempr[creatorString] = store ? store.storeName : 'kamli';
                            tempr[creatorlogoString] = store ? store.kamliwebDetails.logo : null;
                            tempr.isoDate = tempr.pubDate;
                            tempr['storeId'] = storeId;
                            temprs.push(tempr);
                        }
                    }
                }
            }
            resolve(temprs);
        });
    }
    public getStorePromotion(storeId: string, walltype: string, numberdays?: number) {
        const regexf = /([^\/]+$)|([^\/]+)\?.*$/;
        const thumbString = 'thumb';
        const creatorString = 'creator';
        const creatorlogoString = 'creatorlogo';
        if (!numberdays) {
            numberdays = 2;
        }
        return new Promise(async (resolve, reject) => {
            const store = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId) as AdnStoreI;
            const temp = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.promotions);
            const tempp = this.utilSvc.objectToArray(temp) as PromotionI[];
            const temprs = [] as RssfeeditemI[];
            if (tempp) {
                for (const p of tempp) {
                    if (typeof p === 'object') {
                        const tempr = {} as RssfeeditemI;
                        const tempcontent = {
                            type: OBJECTNAME.promotions,
                            data: p
                        };
                        tempr.content = tempcontent;
                        tempr.guid = storeId + '-' + p.promotionId;
                        if (p.modifiedTS && p.modifiedTS + numberdays * dayInMilliseconds > new Date().getTime() && p[walltype] === true) {
                            tempr.pubDate = p.modifiedTS ? this.datepipe.transform(p.modifiedTS, 'yyyy-MM-dd HH:mm') :
                                this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd HH:mm');
                            tempr.thumb = p.thumbL ? p.thumbL : p[thumbString];
                            let localurl = tempcontent.data.localurlL;
                            if (!tempcontent.data?.localurlL) {
                                if (tempcontent.data?.thumbL && tempcontent.data?.thumbL.includes('firebasestorage')) {
                                    const thumb = decodeURIComponent(tempcontent.data?.thumbL);
                                    const tempthumb = regexpost.exec(thumb);
                                    if (tempthumb && tempthumb[1]) {
                                        localurl = 'assets/' + tempthumb[1];
                                    }
                                } else {
                                    localurl = null;
                                }
                            } else {
                                localurl = tempcontent.data?.localurlL;
                            }
                            tempr.localurl = localurl;
                            tempr.title = p.title;
                            tempr.store = p.store ? p.store : false;
                            tempr.storevisible = p.storevisible  ? p.storevisible : true;
                            tempr.publish = p.publish ? p.publish : false;
                            tempr.publishvisible = p.publishvisible  ? p.publishvisible : true;
                            tempr[creatorString] = store ? store.storeName : 'kamli';
                            tempr[creatorlogoString] = store ? store.kamliwebDetails.logo : null;
                            tempr.isoDate = tempr.pubDate;
                            tempr['storeId'] = storeId;
                            temprs.push(tempr);
                        }
                    }
                }
            }
            resolve(temprs);
        });
    }

    public getStoreImages(storeId: string, walltype: string, numberdays?: number) {
        const regexf = /([^\/]+$)|([^\/]+)\?.*$/;
        const creatorString = 'creator';
        const creatorlogoString = 'creatorlogo';
        if (!numberdays) {
            numberdays = 2;
        }

        return new Promise(async (resolve, reject) => {
            const store = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId) as AdnStoreI;
            const temp = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.DsPImage);
            const tempp = this.utilSvc.objectToArray(temp) as KamliMedia[];
            const temprs = [] as RssfeeditemI[];
            if (tempp) {
                for (const m of tempp) {
                    if (typeof m === 'object') {
                        if (m.modifiedTS && m.modifiedTS + numberdays * dayInMilliseconds > new Date().getTime() &&
                            (m.visible === undefined || m.visible === true) &&
                            (m[walltype] === true)) {
                            const tempr = {} as RssfeeditemI;
                            const tempcontent = {
                                type: OBJECTNAME.DsPImage,
                                data: m
                            };
                            tempr.content = tempcontent;
                            tempr.guid = storeId + '-' + m.assetId;
                            tempr.pubDate = m.modifiedTS ? this.datepipe.transform(m.modifiedTS, 'yyyy-MM-dd HH:mm') :
                                this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd HH:mm');
                            tempr.thumb = m.thumbL;
                            let localurl = tempcontent.data.localurlL;
                            if (!tempcontent.data?.localurlL) {
                                if (tempcontent.data?.thumbL && tempcontent.data?.thumbL.includes('firebasestorage')) {
                                    const thumb = decodeURIComponent(tempcontent.data?.thumbL);
                                    const tempthumb = regexpost.exec(thumb);
                                    if (tempthumb && tempthumb[1]) {
                                        localurl = 'assets/' + tempthumb[1];
                                    }
                                } else {
                                    localurl = null;
                                }
                            } else {
                                localurl = tempcontent.data?.localurlL;
                            }
                            tempr.localurl = localurl;
                            tempr.title = m.nickName ? m.nickName : m.title ? m.title : '';
                            tempr.storevisible = m.storevisible  ? m.storevisible : true;
                            tempr.publishvisible = m.publishvisible  ? m.publishvisible : true;
                            tempr.store = m.store ? m.store : false;
                            tempr.publish = m.publish ? m.publish : false;
                            tempr[creatorString] = store ? store.storeName : 'kamli';
                            tempr[creatorlogoString] = store ? store.kamliwebDetails.logo : null;
                            tempr.isoDate = tempr.pubDate;
                            tempr['storeId'] = storeId;
                            temprs.push(tempr);
                        }
                    }
                }
            }
            resolve(temprs);
        });
    }

    public getStoreVideos(storeId: string, walltype: string, numberdays?: number) {
        const regexf = /([^\/]+$)|([^\/]+)\?.*$/;
        const creatorString = 'creator';
        const creatorlogoString = 'creatorlogo';
        if (!numberdays) {
            numberdays = 2;
        }
        return new Promise(async (resolve, reject) => {
            const store = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId) as AdnStoreI;
            const temp = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.DsPVideo);
            const tempp = this.utilSvc.objectToArray(temp) as KamliMedia[];
            const temprs = [] as RssfeeditemI[];
            if (tempp) {
                for (const m of tempp) {
                    if (typeof m === 'object') {
                        if (m.modifiedTS && m.modifiedTS + numberdays * dayInMilliseconds > new Date().getTime() &&
                            (m.visible === undefined || m.visible === true) &&
                            ((m[walltype] === true))) {
                            const tempr = {} as RssfeeditemI;
                            const tempcontent = {
                                type: OBJECTNAME.DsPVideo,
                                data: m
                            };
                            tempr.content = tempcontent;
                            tempr.guid = storeId + '-' + m.assetId;
                            tempr.pubDate = m.modifiedTS ? this.datepipe.transform(m.modifiedTS, 'yyyy-MM-dd HH:mm') :
                                this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd HH:mm');
                            tempr.thumb = m.thumbL;
                            let localurl = tempcontent.data.localurlL;
                            if (!tempcontent.data?.localurlL) {
                                if (tempcontent.data?.thumbL && tempcontent.data?.thumbL.includes('firebasestorage')) {
                                    const thumb = decodeURIComponent(tempcontent.data?.thumbL);
                                    const tempthumb = regexpost.exec(thumb);
                                    if (tempthumb && tempthumb[1]) {
                                        localurl = 'assets/' + tempthumb[1];
                                    }
                                } else {
                                    localurl = null;
                                }
                            } else {
                                localurl = tempcontent.data?.localurlL;
                            }
                            tempr.localurl = localurl;
                            tempr.title = m.nickName ? m.nickName : m.title ? m.title : '';
                            tempr.storevisible = m.storevisible  ? m.storevisible : true;
                            tempr.publishvisible = m.publishvisible  ? m.publishvisible : true;
                            tempr.store = m.store ? m.store : false;
                            tempr.publish = m.publish ? m.publish : false;
                            tempr[creatorString] = store ? store.storeName : 'kamli';
                            tempr[creatorlogoString] = store ? store.kamliwebDetails.logo : null;
                            tempr.isoDate = tempr.pubDate;
                            tempr['storeId'] = storeId;
                            temprs.push(tempr);
                        }
                    }
                }
            }
            resolve(temprs);
        });
    }

    public getStoreProducts(storeId: string, walltype: string, numberdays?: number) {
        const creatorString = 'creator';
        const creatorlogoString = 'creatorlogo';
        if (!numberdays) {
            numberdays = 2;
        }
        return new Promise(async (resolve, reject) => {
            const store = await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, storeId) as AdnStoreI;
            const temp = await this.storeDbSvc.getStoreObject(storeId, OBJECTNAME.products);
            const tempp = this.utilSvc.objectToArray(temp) as PRODUCTI[];
            const temprs = [] as RssfeeditemI[];
            if (tempp) {
                for (const m of tempp) {
                    if (typeof m === 'object') {
                        if (m.modifiedTS && m.modifiedTS + numberdays * dayInMilliseconds > new Date().getTime() && m[walltype] === true) {
                            const tempr = {} as RssfeeditemI;
                            const tempcontent = {
                                type: OBJECTNAME.products,
                                data: m
                            };
                            tempr.content = tempcontent;
                            tempr.guid = storeId + '-' + m.productId;
                            tempr.pubDate = m.modifiedTS ? this.datepipe.transform(m.modifiedTS, 'yyyy-MM-dd HH:mm') :
                                this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd HH:mm');
                            tempr.thumb = m.thumb;
                            let localurl = tempcontent.data.localurl;
                            if (!tempcontent.data?.localurl) {
                                if (tempcontent.data?.thumb && tempcontent.data?.thumb.includes('firebasestorage')) {
                                    const thumb = decodeURIComponent(tempcontent.data?.thumb);
                                    const tempthumb = regexpost.exec(thumb);
                                    if (tempthumb && tempthumb[1]) {
                                        localurl = 'assets/' + tempthumb[1];
                                    }
                                } else {
                                    localurl = null;
                                }
                            } else {
                                localurl = tempcontent.data?.localurl;
                            }
                            tempr.localurl = localurl;
                            tempr.title = m.title ? m.title : '';
                            tempr.storevisible = m.storevisible  ? m.storevisible : true;
                            tempr.publishvisible = m.publishvisible  ? m.publishvisible : true;
                            tempr.store = m.store ? m.store : false;
                            tempr.publish = m.publish ? m.publish : false;
                            tempr[creatorString] = store ? store.storeName : 'kamli';
                            tempr[creatorlogoString] = store ? store.kamliwebDetails.logo : null;
                            tempr.isoDate = tempr.pubDate;
                            tempr['storeId'] = storeId;
                            temprs.push(tempr);
                        }
                    }
                }
            }
            resolve(temprs);
        });
    }

    getPublishOrders(publishOrders: PublishOrderKamli[]) {
        const creatorString = 'creator';
        const timetoday = new Date().getTime();
        return new Promise(async (resolve, reject) => {
            const tempp = publishOrders as PublishOrderKamli[];
            const temprs = [] as RssfeeditemI[];
            if (tempp) {
                for (const m of tempp) {
                    if (typeof m === 'object') {
                        if (m.visible && m.publishCampaign.startDates && m.publishCampaign.moderationStatus === PUBMODERATIONSTATUS.ACCEPTED &&
                            m.publishCampaign.startDates[0] &&
                            m.publishCampaign.startDates[0][0]) {
                            const startCampaign = m.publishCampaign.startDates[0][0];
                            const endCampaign = m.publishCampaign.startDates[m.publishCampaign.startDates.length - 1][1];
                            if (startCampaign < timetoday && endCampaign > timetoday) {
                                const tempr = {} as RssfeeditemI;
                                const tempcontent = {
                                    type: m.storeAsset && m.storeAsset.asset && m.storeAsset.asset.mediaType ?
                                        m.storeAsset.asset.mediaType === 'video' ? OBJECTNAME.DsPVideo : OBJECTNAME.DsPImage : null,
                                    data: m.storeAsset && m.storeAsset.asset ? m.storeAsset.asset : null
                                };
                                tempr.content = tempcontent;
                                tempr.guid = m.storeId + '-' + m.orderId;
                                tempr.pubDate = this.datepipe.transform(m.publishCampaign.startDates[0][0], 'yyyy-MM-dd HH:mm');
                                if (m.storeAsset &&
                                    m.storeAsset.asset && m.storeAsset.asset.thumbL &&
                                    m.storeAsset.asset.localurlL) {
                                    tempr.thumb = m.storeAsset.asset.thumbL;
                                    tempr.localurl = m.storeAsset.asset.localurlL;
                                }
                                tempr.title = m.publishCampaign.campaignName;
                                tempr.store = m.visible;
                                tempr.publish = m.visible;
                                tempr[creatorString] = m.publishCampaign.advertiserName;
                                tempr.isoDate = tempr.pubDate;
                                temprs.push(tempr);
                            }
                        }
                    }
                }
            }
            resolve(temprs);
        });
    }

    public getSocialnetworks(): Observable<RssFeedI[]> {
        return this.socialnetworksO.asObservable();
    }
    public setSocialnetworks(value: RssFeedI[]) {
        this.socialnetworks = value;
        this.socialnetworksO.next(value);
    }

    subscribeSocialFeedItems(socialnetworkInput: RssFeedI, numberdays?: number) {
        return new Promise((resolvef, rejectf) => {
            if (this.storeFeedO[socialnetworkInput.rssId] && socialnetworkInput) {
                this.unsubscribeFeedItems(socialnetworkInput.rssId);
            }
            let rssIds = [];
            let mysocialnetworkPosts = [];
            let promise1;
            const toto = () => {
                if (socialnetworkInput) {
                    rssIds = socialnetworkInput &&
                        ((socialnetworkInput.store === undefined || socialnetworkInput.store === true) ||
                            (socialnetworkInput.publish === undefined || socialnetworkInput.publish === true)) ?
                        [socialnetworkInput.rssId] : [];
                    promise1 = new Promise((resolve, reject) => {
                        const url = this.utilSvc.backendURL + 'rss/snFeedsO?rsslist=' + JSON.stringify(rssIds);
                        this.http.get(url).subscribe(data1 => {
                            const posts = this.utilSvc.objectToArray(data1);
                            if (posts  && posts != null) {
                                mysocialnetworkPosts = posts;
                                resolve(mysocialnetworkPosts);
                            }
                            else {
                                resolve(mysocialnetworkPosts);
                            }
                        }, error => {
                            resolve(mysocialnetworkPosts);
                        });
                    });
                }
                else {
                    promise1 = new Promise(resolve => resolve(1));
                }
                promise1.then(data => {
                    const posts = mysocialnetworkPosts ? mysocialnetworkPosts : [];
                    if (socialnetworkInput && socialnetworkInput.rssId) {
                        this.setStoreFeedItems(socialnetworkInput.rssId, posts);
                        if (this.timermysocial && this.timermysocial[socialnetworkInput.rssId]) {
                            clearTimeout(this.timermysocial[socialnetworkInput.rssId]);
                            this.timermysocial[socialnetworkInput.rssId] = undefined;
                        }
                        resolvef(posts);
                        this.timermysocial[socialnetworkInput.rssId] = setTimeout(toto, 120000);
                    }
                }, error => {
                    if (this.timermysocial && this.timermysocial[socialnetworkInput.rssId]) {
                        clearTimeout(this.timermysocial[socialnetworkInput.rssId]);
                        this.timermysocial[socialnetworkInput.rssId] = undefined;
                    }
                    this.timermysocial[socialnetworkInput.rssId] = setTimeout(toto, 120000);
                    rejectf(error);
                });
            };
            toto();
        });
    }
    subscribeStoreFeedItems(
        socialnetworkInput: RssFeedI[],
        publishSmbOrders: PublishOrderKamli[], store: AdnStoreI, walltype: string, numberdays?: number, addfriends?: boolean, addsocials?: boolean, addVideo?: boolean) {
        if (!addsocials) {
            addsocials = true;
        }
        if (!addVideo) {
            addVideo = true;
        }
        return new Promise((resolvef, rejectf) => {
            if (this.storeFeedO[store.adnStoreId] && store) {
                this.unsubscribeFeedItems(store.adnStoreId);
            }
            if (!(this.timermysocial && this.timermysocial[store.adnStoreId])) {
                const rssIds = [];
                const titleString = 'title';
                const descriptionString = 'description';
                if (store && store.kamliwebDetails) {
                    if (store.kamliwebDetails.facebook) {
                        const tempf = regexsnurl.exec(store.kamliwebDetails.facebook);
                        if (tempf && tempf[1]) {
                            const temp = socialnetworkInput && socialnetworkInput.find(sn => {
                                let result = false;
                                const temp1 = regexsnurl.exec(sn.url);
                                if (temp1 && temp1[1]) {
                                    result = tempf[1].toLowerCase() === temp1[1].toLowerCase();
                                }
                                return result;
                            });
                            if (temp && ((temp.store === undefined || temp.store === true) ||
                                (temp.publish === undefined || temp.publish === true))) {
                                rssIds.push(temp.rssId);
                            }
                        }
                    }
                    if (store.kamliwebDetails.instagram) {
                        const tempi = regexsnurl.exec(store.kamliwebDetails.instagram);
                        if (tempi && tempi[1]) {
                            const temp = socialnetworkInput && socialnetworkInput.find(sn => {
                                let result = false;
                                const temp1 = regexsnurl.exec(sn.url);
                                if (temp1 && temp1[1]) {
                                    result = tempi[1].toLowerCase() === temp1[1].toLowerCase();
                                }
                                return result;
                            });
                            if (temp && ((temp.store === undefined || temp.store === true) ||
                                (temp.publish === undefined || temp.publish === true))) {
                                rssIds.push(temp.rssId);
                            }
                        }
                    }
                }
                if (store.referrals && addfriends) {
                    for (const r of store.referrals) {
                        if (r) {
                            const temp = rssIds && rssIds.find(rr => String(rr) === String(r.referralId));
                            if (!temp) {
                                const temp2 = socialnetworkInput && socialnetworkInput.find(sn =>
                                    String(sn.rssId) === String(r.referralId));
                                if (temp2 && ((temp2.store === undefined || temp2.store === true) ||
                                    (temp2.publish === undefined || temp2.publish === true))) {
                                    rssIds.push(String(r.referralId));
                                }
                            }
                        }
                    }
                }
                let promise1;
                let promise2;
                const toto = () => {
                    let mysocialnetworkPosts = [] as RssFeedIKamli[];
                    const mystorePosts = {} as RssFeedIKamli;
                    if (addsocials && rssIds && rssIds.length > 0) {
                        promise1 = new Promise((resolve, reject) => {
                            const url = this.utilSvc.backendURL + 'rss/snFeedsO?rsslist=' + JSON.stringify(rssIds);
                            this.http.get(url).subscribe(data1 => {
                                const posts = this.utilSvc.objectToArray(data1);
                                if (posts  && posts != null) {
                                    mysocialnetworkPosts = posts;
                                    resolve(mysocialnetworkPosts);
                                }
                                else {
                                    resolve(mysocialnetworkPosts);
                                }
                            }, error => {
                                resolve(mysocialnetworkPosts);
                            });
                        });
                    }
                    else {
                        promise1 = new Promise(resolve => resolve(1));
                    }
                    promise2 = new Promise(async (resolve, reject) => {
                        mystorePosts.feedCategory = 'kamli';
                        mystorePosts.feedDescription = store.postalCode + ', ' + store.city;
                        mystorePosts.feedItems = [];
                        mystorePosts.feedTitle = store.storeName;
                        mystorePosts.feedUrl = store.url;
                        mystorePosts.image = store.kamliwebDetails.logo;
                        mystorePosts.store = true;
                        mystorePosts.publish = true;
                        mystorePosts.localimage = store.kamliwebDetails.locallogo;
                        mystorePosts.rssId = store.adnStoreId;
                        mystorePosts.feedItems = [] as RssfeeditemI[];
                        const feedItemse = await this.getStoreEvent(mystorePosts.rssId, walltype, numberdays) as RssfeeditemI[];
                        mystorePosts.feedItems = feedItemse ? mystorePosts.feedItems.concat(feedItemse) : mystorePosts.feedItems;
                        const feedItemspr = await this.getStorePromotion(mystorePosts.rssId, walltype, numberdays) as RssfeeditemI[];
                        mystorePosts.feedItems = feedItemspr ? mystorePosts.feedItems.concat(feedItemspr) : mystorePosts.feedItems;
                        const feedItemsei = await this.getStoreImages(mystorePosts.rssId, walltype, numberdays) as RssfeeditemI[];
                        mystorePosts.feedItems = feedItemsei ? mystorePosts.feedItems.concat(feedItemsei) : mystorePosts.feedItems;
                        let feedItemsv;
                        if (addVideo) {
                            feedItemsv = await this.getStoreVideos(mystorePosts.rssId, walltype, numberdays) as RssfeeditemI[];
                            mystorePosts.feedItems = feedItemsv ? mystorePosts.feedItems.concat(feedItemsv) : mystorePosts.feedItems;
                        }
                        const feedItemspp = await this.getStoreProducts(mystorePosts.rssId, walltype, numberdays) as RssfeeditemI[];
                        mystorePosts.feedItems = feedItemspp ? mystorePosts.feedItems.concat(feedItemspp) : mystorePosts.feedItems;
                        const feedItemspu = await this.getPublishOrders(publishSmbOrders) as RssfeeditemI[];
                        mystorePosts.feedItems = feedItemspu ? mystorePosts.feedItems.concat(feedItemspu) : mystorePosts.feedItems;
                        mystorePosts[titleString] = mystorePosts.feedTitle;
                        mystorePosts[descriptionString] = mystorePosts.feedDescription;
                        resolve(mystorePosts);
                    });
                    Promise.all([promise1, promise2]).then(data => {
                        const posts = mysocialnetworkPosts ? mysocialnetworkPosts : [];
                        for (const post of mysocialnetworkPosts) {
                            if (post.feedItems) {
                                for (const feed of post.feedItems) {
                                    feed['storeId'] = store.adnStoreId;
                                }
                            }
                        }
                        if (mystorePosts) {
                            posts.push(mystorePosts);
                        }
                        this.setStoreFeedItems(store.adnStoreId, posts);
                        if (this.timermysocial && this.timermysocial[store.adnStoreId]) {
                            clearTimeout(this.timermysocial[store.adnStoreId]);
                            this.timermysocial[store.adnStoreId] = undefined;
                        }
                        this.timermysocial[store.adnStoreId] = setTimeout(toto, 120000);
                        resolvef(posts);
                    }, error => {
                        this.setStoreFeedItems(store.adnStoreId, []);
                        if (this.timermysocial && this.timermysocial[store.adnStoreId]) {
                            clearTimeout(this.timermysocial[store.adnStoreId]);
                            this.timermysocial[store.adnStoreId] = undefined;
                        }
                        this.timermysocial[store.adnStoreId] = setTimeout(toto, 120000);
                        rejectf(error);
                    });
                };
                toto();
            }
        });
    }
    unsubscribeFeedItems(storeId: string) {
        if (this.timermysocial && this.timermysocial[storeId]) {
            clearTimeout(this.timermysocial[storeId]);
            this.timermysocial[storeId] = undefined;
            if (this.storeFeedO[storeId]) {
                this.storeFeedO[storeId].unsubscribe();
                this.storeFeedO[storeId] = undefined;
            }
        }
    }

    public getStoreFeedItems(storeId: string): Observable<RssFeedIKamli[]> {
        if (!this.storeFeedO[storeId]) {
            this.storeFeedO[storeId] = new BehaviorSubject(null);
        }
        return this.storeFeedO[storeId].asObservable();
    }
    public setStoreFeedItems(storeId: string, value: RssFeedIKamli[]) {
        this.storeFeed[storeId] = value;
        if (!this.storeFeedO[storeId]) {
            this.storeFeedO[storeId] = new BehaviorSubject(null);
        }
        this.storeFeedO[storeId].next(value);
    }

    subscribekamlistoreWallFeedItems(
        socialnetworkInput: RssFeedI[],
        allstores: AdnStoreI[],
        storeupdates: StoreUpdates[],
        publishAdnOrders: PublishOrderKamli[],
        currentUser: AdnUserKamli,
        numberdays?: number) {
        return new Promise(async (resolve, reject) => {
            const timeofday = new Date().getTime();
            const friends = [] as RssFeedIKamli[];
            const otherfriends = [] as RssFeedIKamli[];
            if (currentUser) {
                const myfeedItemsString = 'myfeedItems';
                const adnfeedItemsString = 'adnfeedItems';
                const socialfriendsfeedItemsString = 'socialfriendsfeedItems';
                const kamlifriendsfeedItemsString = 'kamlifriendsfeedItems';
                const wallPosts = {} as RssFeedIKamli;
                wallPosts.feedCategory = 'wall';
                wallPosts.feedDescription = 'kamli wall';
                wallPosts.feedItems = [];
                wallPosts.feedTitle = 'kamli wall';
                wallPosts.feedUrl = '';
                wallPosts.image = this.utilSvc.backendURL + 'assets/backend/Kamli-store-app-icon.png';
                wallPosts.store = true;
                wallPosts.publish = true;
                wallPosts.lastBuildDate = this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd HH:mm');
                wallPosts.localimage = 'assets/backend/Kamli-store-app-icon.png';
                wallPosts.rssId = '9999';
                wallPosts.smbcategoryId = '1400';
                wallPosts.status = RssFeedStatus.ACCEPTED;
                wallPosts.url = '';

                // followed stores ids
                if (currentUser.referrals) {
                    for (const r of currentUser.referrals) {
                        if ((r.store || r.store === undefined) && r.type === 'kamli') {
                            const temp1 = allstores && allstores.find(s => String(s.adnStoreId) === String(r.referralId));
                            const temp2 = storeupdates && storeupdates.find(s => String(s.storeId) === String(r.referralId));
                            if (temp1 && temp2 && temp2.timestamp + numberdays * dayInMilliseconds > timeofday) {
                                const temp = {} as RssFeedIKamli;
                                temp.feedCategory = 'kamli';
                                temp.feedTitle = r.urlname;
                                temp.feedUrl = temp1.url;
                                temp.gpid = temp1.googlePlaceId;
                                temp.rssId = temp1.adnStoreId;
                                temp.store = true;
                                temp.publish = false;
                                friends.push(temp);
                            }
                        }
                    }
                }

                // followed store assistants ids
                for (const friend of friends) {
                    let folllowedassistantstore: AdnStoreIKamli;
                    try {
                        folllowedassistantstore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, friend.rssId)) as AdnStoreIKamli;
                    }
                    catch (e) { }
                    if (folllowedassistantstore) {
                        const tempf = friends && friends.find(s => s.rssId === folllowedassistantstore.adnStoreId);
                        if (!tempf) {
                            const temp = {} as RssFeedIKamli;
                            temp.feedCategory = 'kamli';
                            temp.feedTitle = folllowedassistantstore.storeName;
                            temp.feedUrl = folllowedassistantstore.url;
                            temp.gpid = folllowedassistantstore.googlePlaceId;
                            temp.rssId = folllowedassistantstore.adnStoreId;
                            temp.store = true;
                            temp.publish = false;
                            friends.push(temp);
                        }
                    }
                }

                // my followed store content
                wallPosts[myfeedItemsString] = [];
                const snst = {};
                for (const friend of friends) {
                    let friendstore: AdnStoreIKamli;
                    if (friend.feedCategory === 'kamli') {
                        try {
                            friendstore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, friend.rssId)) as AdnStoreIKamli;
                        }
                        catch (e) { }
                        if (friendstore) {
                            // followed stores
                            let errorp;
                            let friendstorepublishorderst;
                            let friendstorepublishordersa: PublishOrderKamli[];
                            const friendstorepublishorders = [] as PublishOrderKamli[];
                            try {
                                friendstorepublishorderst = await this.storeDbSvc.getObject(this.utilSvc.backendFBstoreId, this.utilSvc.mdb,
                                    OBJECTNAME.publishingNewOrders, friendstore.adnStoreId);
                            } catch (e) {
                                errorp = e;
                            }
                            if (!errorp) {
                                friendstorepublishordersa = this.utilSvc.objectToArray(friendstorepublishorderst) as PublishOrderKamli[];
                            }
                            if (friendstorepublishordersa) {
                                for (const pubOrder of friendstorepublishordersa) {
                                    let pubOrderKamli: PublishOrderKamli;
                                    try {
                                        pubOrderKamli = await this.publishingRequestSvc.updatePublishOrder(pubOrder, friendstore);
                                    } catch (e) { }
                                    if (pubOrderKamli.storeAsset) {
                                        friendstorepublishorders.push(pubOrderKamli);
                                    }
                                }
                            }
                            await this.subscribeStoreFeedItems(socialnetworkInput, friendstorepublishorders, friendstore, 'store', numberdays, false, false, false);
                            if (this.subscriptionStoreFeedItems[friendstore.adnStoreId]) {
                                this.subscriptionStoreFeedItems[friendstore.adnStoreId].unsubscribe();
                            }
                            this.subscriptionStoreFeedItems[friendstore.adnStoreId] =
                                this.getStoreFeedItems(friendstore.adnStoreId).subscribe(sns => {
                                    if (sns) {
                                        for (const sn of sns) {
                                            if (!snst[sn.rssId]) {
                                                snst[sn.rssId] = true;
                                                if (sn && sn.feedItems && sn.feedItems.length > 0) {
                                                    wallPosts[myfeedItemsString] =
                                                    wallPosts[myfeedItemsString].concat(sn.feedItems);
                                                }
                                            }
                                        }
                                        if (wallPosts && wallPosts[myfeedItemsString]) {
                                            wallPosts[myfeedItemsString].sort((a: RssfeeditemI, b) => {
                                                const aTime = new Date(a.isoDate).getTime();
                                                const bTime = new Date(b.isoDate).getTime();
                                                return aTime > bTime ? -1 : bTime > aTime ? 1 : 0;
                                            });
                                        }
                                    }
                                });
                        } //
                    }
                }
                if (!wallPosts[myfeedItemsString]) {
                    wallPosts[myfeedItemsString] = [];
                }
                this.wallmyFeedItemsO.next(wallPosts[myfeedItemsString]);


                // ADN store content
                let adnstore: AdnStoreI;
                try {
                    adnstore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, this.utilSvc.adnStoreId)) as AdnStoreI;
                }
                catch (e) { }
                wallPosts[adnfeedItemsString] = [];
                if (adnstore) {
                    if (adnstore.referrals) {
                        const adnreferrals = [];
                        for (const r of adnstore.referrals) {
                            if (r && (r.store === true || r.store === undefined)) {
                                const temp = friends && friends.find(sr => sr && String(sr.rssId) === String(r.referralId));
                                if (!temp) {
                                    const temp1 = otherfriends && otherfriends.find(sr => sr && String(sr.rssId) === String(r.referralId));
                                    if (!temp1) {
                                        adnreferrals.push(r);
                                    }
                                }
                            }
                        }
                        adnstore.referrals = adnreferrals;
                    }
                    await this.subscribeStoreFeedItems(socialnetworkInput, publishAdnOrders, adnstore, 'store', numberdays, true, true, false);
                    if (this.subscriptionStoreFeedItems[adnstore.adnStoreId]) {
                        this.subscriptionStoreFeedItems[adnstore.adnStoreId].unsubscribe();
                    }
                    this.subscriptionStoreFeedItems[adnstore.adnStoreId] =
                        this.getStoreFeedItems(adnstore.adnStoreId).subscribe(sns => {
                            if (sns) {
                                wallPosts[adnfeedItemsString] = [];
                                for (const sn of sns) {
                                    if (sn.feedItems) {
                                        wallPosts[adnfeedItemsString] = wallPosts[adnfeedItemsString].concat(sn.feedItems);
                                    }
                                }
                                if (wallPosts && wallPosts[adnfeedItemsString]) {
                                    wallPosts[adnfeedItemsString].sort((a, b) => {
                                        const aTime = new Date(a.isoDate).getTime();
                                        const bTime = new Date(b.isoDate).getTime();
                                        return aTime > bTime ? -1 : bTime > aTime ? 1 : 0;
                                    });
                                }
                                this.walladnFeedItemsO.next(wallPosts[adnfeedItemsString]);
                            }
                        });
                }

                // my other friends content
                wallPosts[kamlifriendsfeedItemsString] = [];
                for (const friend of otherfriends) {
                    let friendstore;
                    if (friend.feedCategory === 'kamli') {
                        try {
                            friendstore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, friend.rssId));
                        }
                        catch (e) { }
                        if (friendstore) {
                            await this.subscribeStoreFeedItems(socialnetworkInput, undefined, friendstore, 'store', numberdays, false, false, false);
                            if (this.subscriptionStoreFeedItems[friendstore.adnStoreId]) {
                                this.subscriptionStoreFeedItems[friendstore.adnStoreId].unsubscribe();
                            }
                            this.subscriptionStoreFeedItems[friendstore.adnStoreId] =
                                this.getStoreFeedItems(friendstore.adnStoreId).subscribe(sns => {
                                    if (sns) {
                                        for (const sn of sns) {
                                            if (sn && sn.feedItems && sn.feedItems.length > 0) {
                                                let temp = wallPosts[kamlifriendsfeedItemsString] &&
                                                    wallPosts[kamlifriendsfeedItemsString].filter(posti =>
                                                        posti.creator !== sn.feedItems[0].creator);
                                                if (!temp) {
                                                    temp = [];
                                                }
                                                wallPosts[kamlifriendsfeedItemsString] =
                                                    temp.concat(sn.feedItems);
                                            }
                                        }
                                        if (wallPosts && wallPosts[kamlifriendsfeedItemsString]) {
                                            wallPosts[kamlifriendsfeedItemsString].sort((a, b) => {
                                                const aTime = new Date(a.isoDate).getTime();
                                                const bTime = new Date(b.isoDate).getTime();
                                                return aTime > bTime ? -1 : bTime > aTime ? 1 : 0;
                                            });
                                        }
                                    }
                                });
                        }
                    }
                }
                if (!wallPosts[kamlifriendsfeedItemsString]) {
                    wallPosts[kamlifriendsfeedItemsString] = [];
                }
                this.wallkamlifriendsFeedItemsO.next(wallPosts[kamlifriendsfeedItemsString]);
                resolve(1);
            } else {
                reject('lo logged user');
            }
        });
    }

    unsubscribekamlistoreWallFeedItems(allstores: AdnStoreI[]) {
        this.unsubscribeFeedItems(this.utilSvc.adnStoreId);
        for (const store of allstores) {
            this.unsubscribeFeedItems(store.adnStoreId);
            if (this.subscriptionStoreFeedItems[store.adnStoreId]) {
                this.subscriptionStoreFeedItems[store.adnStoreId].unsubscribe();
            }
        }
    }

    async subscribePublishWallFeedItems(
        socialnetworkInput: RssFeedI[],
        allstores: AdnStoreI[],
        publishSmbOrders: PublishOrderKamli[],
        publishAdnOrders: PublishOrderKamli[],
        currentStore: AdnStoreI,
        walltype: string,
        numberdays?: number,
    ) {
        if (currentStore) {
            const friends = [];
            const myfeedItemsString = 'myfeedItems';
            const adnfeedItemsString = 'adnfeedItems';
            const socialfriendsfeedItemsString = 'socialfriendsfeedItems';
            const kamlifriendsfeedItemsString = 'kamlifriendsfeedItems';
            const wallPosts = {} as RssFeedIKamli;
            wallPosts.feedCategory = 'wall';
            wallPosts.feedDescription = 'kamli wall';
            wallPosts.feedItems = [];
            wallPosts.feedTitle = 'kamli wall';
            wallPosts.feedUrl = '';
            wallPosts.image = this.utilSvc.backendURL + 'assets/backend/Kamli-store-app-icon.png';
            wallPosts.store = true;
            wallPosts.publish = true;
            wallPosts.lastBuildDate = this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd HH:mm');
            wallPosts.localimage = 'assets/backend/Kamli-store-app-icon.png';
            wallPosts.rssId = '9999';
            wallPosts.smbcategoryId = '1400';
            wallPosts.status = RssFeedStatus.ACCEPTED;
            wallPosts.url = '';
            if (currentStore.referrals) {
                for (const r of currentStore.referrals) {
                    if (r.publish || r.publish === undefined && r.type === 'socialnetworks') {
                        const temp = socialnetworkInput && socialnetworkInput.find(s => String(s.rssId) === String(r.referralId) &&
                            ((s.store === true || s.store === undefined) || (s.publish === true || s.publish === undefined)));
                        if (temp) {
                            friends.push(temp);
                        }
                    }
                    if (r.publish || r.publish === undefined && r.type === 'kamli') {
                        const temp1 = allstores && allstores.find(s => String(s.adnStoreId) === String(r.referralId));
                        if (temp1) {
                            const temp = {} as RssFeedIKamli;
                            temp.feedCategory = 'kamli';
                            temp.feedTitle = r.urlname;
                            temp.feedUrl = temp1.url;
                            temp.gpid = temp1.googlePlaceId;
                            temp.rssId = temp1.adnStoreId;
                            temp.store = true;
                            temp.publish = true;
                            friends.push(temp);
                        }
                    }
                }
            }

            for (const friend of friends) {
                let folllowedassistantstore: AdnStoreIKamli;
                try {
                    folllowedassistantstore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, friend.rssId)) as AdnStoreIKamli;
                }
                catch (e) { }
                if (folllowedassistantstore) {
                    const tempf = friends && friends.find(s => s.rssId === folllowedassistantstore.adnStoreId);
                    if (!tempf) {
                        const temp = {} as RssFeedIKamli;
                        temp.feedCategory = 'kamli';
                        temp.feedTitle = folllowedassistantstore.storeName;
                        temp.feedUrl = folllowedassistantstore.url;
                        temp.gpid = folllowedassistantstore.googlePlaceId;
                        temp.rssId = folllowedassistantstore.adnStoreId;
                        temp.store = true;
                        temp.publish = false;
                        friends.push(temp);
                    }
                }
            }

            // my store content
            await this.subscribeStoreFeedItems(socialnetworkInput, publishSmbOrders, currentStore, walltype, numberdays, false);
            if (this.subscriptionStoreFeedItems[currentStore.adnStoreId]) {
                this.subscriptionStoreFeedItems[currentStore.adnStoreId].unsubscribe();
            }
            this.subscriptionStoreFeedItems[currentStore.adnStoreId] =
                this.getStoreFeedItems(currentStore.adnStoreId).subscribe(sns => {
                    if (sns) {
                        wallPosts[myfeedItemsString] = [];
                        for (const sn of sns) {
                            if (sn.feedItems) {
                                wallPosts[myfeedItemsString] = wallPosts[myfeedItemsString].concat(sn.feedItems);
                            }
                        }
                        if (wallPosts && wallPosts[myfeedItemsString]) {
                            wallPosts[myfeedItemsString].sort((a, b) => {
                                const aTime = new Date(a.isoDate).getTime();
                                const bTime = new Date(b.isoDate).getTime();
                                return aTime > bTime ? -1 : bTime > aTime ? 1 : 0;
                            });
                        }
                        this.wallmyFeedItemsO.next(wallPosts[myfeedItemsString]);
                        //                            this.setwallFeed();
                    }
                });

            // ADN store content
            if (currentStore && currentStore.adnStoreId !== this.utilSvc.adnStoreId) {
                let adnstore: AdnStoreI;
                try {
                    adnstore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, this.utilSvc.adnStoreId)) as AdnStoreI;
                }
                catch (e) { }
                if (adnstore) {
                    if (adnstore.referrals) {
                        const adnreferrals = [];
                        for (const r of adnstore.referrals) {
                            if (r && r.publish) {
                                const temp = currentStore && currentStore.referrals &&
                                    currentStore.referrals.find(sr => sr && String(sr.referralId) === String(r.referralId));
                                if (!temp) {
                                    adnreferrals.push(r);
                                }
                            }
                        }
                        adnstore.referrals = adnreferrals;
                    }
                    await this.subscribeStoreFeedItems(socialnetworkInput, publishAdnOrders, adnstore, walltype, numberdays, true);
                    if (this.subscriptionStoreFeedItems[adnstore.adnStoreId]) {
                        this.subscriptionStoreFeedItems[adnstore.adnStoreId].unsubscribe();
                    }
                    this.subscriptionStoreFeedItems[adnstore.adnStoreId] =
                        this.getStoreFeedItems(adnstore.adnStoreId).subscribe(sns => {
                            if (sns) {
                                wallPosts[adnfeedItemsString] = [];
                                for (const sn of sns) {
                                    if (sn.feedItems) {
                                        wallPosts[adnfeedItemsString] = wallPosts[adnfeedItemsString].concat(sn.feedItems);
                                    }
                                }
                                if (wallPosts && wallPosts[adnfeedItemsString]) {
                                    wallPosts[adnfeedItemsString].sort((a, b) => {
                                        const aTime = new Date(a.isoDate).getTime();
                                        const bTime = new Date(b.isoDate).getTime();
                                        return aTime > bTime ? -1 : bTime > aTime ? 1 : 0;
                                    });
                                }
                                this.walladnFeedItemsO.next(wallPosts[adnfeedItemsString]);
                            }
                        });
                }
            }
            // my friends content
            for (const friend of friends) {
                let friendstore;
                if (friend.feedCategory === 'kamli') {
                    try {
                        friendstore = (await this.smbSvc.getStore(this.utilSvc.backendFBstoreId, friend.rssId));
                    }
                    catch (e) { }
                    if (friendstore) {
                        await this.subscribeStoreFeedItems(socialnetworkInput, undefined, friendstore, walltype, numberdays, false);
                        if (this.subscriptionStoreFeedItems[friendstore.adnStoreId]) {
                            this.subscriptionStoreFeedItems[friendstore.adnStoreId].unsubscribe();
                        }
                        this.subscriptionStoreFeedItems[friendstore.adnStoreId] =
                            this.getStoreFeedItems(friendstore.adnStoreId).subscribe(sns => {
                                if (sns) {
                                    for (const sn of sns) {
                                        if (sn && sn.feedItems && sn.feedItems.length > 0) {
                                            let temp = wallPosts[kamlifriendsfeedItemsString] &&
                                                wallPosts[kamlifriendsfeedItemsString].filter(posti =>
                                                    posti.creator !== sn.feedItems[0].creator);
                                            if (!temp) {
                                                temp = [];
                                            }
                                            wallPosts[kamlifriendsfeedItemsString] =
                                                temp.concat(sn.feedItems);
                                        }
                                    }
                                    if (wallPosts && wallPosts[kamlifriendsfeedItemsString]) {
                                        wallPosts[kamlifriendsfeedItemsString].sort((a, b) => {
                                            const aTime = new Date(a.isoDate).getTime();
                                            const bTime = new Date(b.isoDate).getTime();
                                            return aTime > bTime ? -1 : bTime > aTime ? 1 : 0;
                                        });
                                    }
                                }
                            });
                    }
                }
                else {
                    await this.subscribeSocialFeedItems(friend, numberdays);
                    if (this.subscriptionStoreFeedItems[friend.rssId]) {
                        this.subscriptionStoreFeedItems[friend.rssId].unsubscribe();
                    }
                    this.subscriptionStoreFeedItems[friend.rssId] =
                        this.getStoreFeedItems(friend.rssId).subscribe(sns => {
                            if (sns) {
                                for (const sn of sns) {
                                    if (sn && sn.feedItems && sn.feedItems.length > 0) {
                                        let temp = wallPosts[socialfriendsfeedItemsString] &&
                                            wallPosts[socialfriendsfeedItemsString].filter(posti =>
                                                posti.creator !== sn.feedItems[0].creator);
                                        if (!temp) {
                                            temp = [];
                                        }
                                        wallPosts[socialfriendsfeedItemsString] =
                                            temp.concat(sn.feedItems);
                                    }
                                }
                                if (wallPosts && wallPosts[socialfriendsfeedItemsString]) {
                                    wallPosts[socialfriendsfeedItemsString].sort((a, b) => {
                                        const aTime = new Date(a.isoDate).getTime();
                                        const bTime = new Date(b.isoDate).getTime();
                                        return aTime > bTime ? -1 : bTime > aTime ? 1 : 0;
                                    });
                                }
                            }
                        });
                }
            }
            if (!wallPosts[kamlifriendsfeedItemsString]) {
                wallPosts[kamlifriendsfeedItemsString] = [];
            }
            if (!wallPosts[socialfriendsfeedItemsString]) {
                wallPosts[socialfriendsfeedItemsString] = [];
            }
            this.wallkamlifriendsFeedItemsO.next(wallPosts[kamlifriendsfeedItemsString]);
            this.wallsocialfriendsFeedItemsO.next(wallPosts[socialfriendsfeedItemsString]);
        }
    }

    unsubscribePublishWallFeedItems(socialnetworkInput: RssFeedI[], currentStore: any) {
        this.unsubscribeFeedItems(this.utilSvc.adnStoreId);
        if (currentStore) {
            this.unsubscribeFeedItems(currentStore.adnStoreId);
            if (currentStore.referrals) {
                for (const r of currentStore.referrals) {
                    const temp = socialnetworkInput && socialnetworkInput.find(s => String(s.rssId) === String(r.referralId));
                    if (temp) {
                        this.unsubscribeFeedItems(temp.rssId);
                    }
                }
            }
        }
    }

    public getwallmyFeedItems(): Observable<RssfeeditemI[]> {
        return this.wallmyFeedItemsO.asObservable();
    }

    public getwalladnFeedItems(): Observable<RssfeeditemI[]> {
        return this.walladnFeedItemsO.asObservable();
    }

    public getkamliwallfriendsFeedItems(): Observable<RssfeeditemI[]> {
        return this.wallkamlifriendsFeedItemsO.asObservable();
    }

    public getsocialwallfriendsFeedItems(): Observable<RssfeeditemI[]> {
        return this.wallsocialfriendsFeedItemsO.asObservable();
    }

}
