import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LayoutService } from '../layout.service';
import { UtilsService, ServicesService } from 'godigital-lib';
import { TranslateService } from '@ngx-translate/core';
import { LocalUtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public loaded = 0;
  public serviceBSSdata: Subscription;
  public subscriptions = new Subscription();

  public componentName = 'footer.component';
  constructor(
    public router: Router,
    public utilSvc: UtilsService,
    public layoutSvc: LayoutService,
    public translateSvc: TranslateService,
    public mainSvc: ServicesService,
    public localUtilsSvc: LocalUtilsService,
  ) { }

  ngOnInit() {
    this.localUtilsSvc.error = {};
    this.ngOnProceed();
  }

  ngOnProceed() {
    this.subscriptions.add(
      // tslint:disable-next-line: deprecation
      this.mainSvc.getLanguage().subscribe(language => {
        this.translateSvc.use(language);
      }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToADN() {
    this.router.navigate(['/externalRedirect', { externalUrl: 'https://www.alldigitalnetwork.com' }]);
  }

  goToConfidentiality() {
    if (this.utilSvc.language === 'fr') {
      this.router.navigate(['/confidentiality.fr']);
    }
    else {
      this.router.navigate(['/confidentiality']);
    }
    return false;
  }

  goToLegal() {
    this.router.navigate(['/mentionslegales']);
    return false;
  }

}
