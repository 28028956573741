import { Injectable, ElementRef, } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs';
import { DatePipe } from '@angular/common';
import { StoreDbService, OBJECTNAME, AdnUser, AdnUserAddress } from '../godigital-lib';
import { UsersService, } from '../godigital-lib';
import { UtilsService, } from '../godigital-lib';
import { SmbService, AdnStoreI } from '../godigital-lib';

export enum DELIVERYMETHOD {
    PayDine = 'payDine',
    PayDeliver = 'payDeliver',
    PayCollect = 'payCollect',
    inHouse = 'inHouse',
}

export enum dateTimeValidationError {
    passedDate,
    closedBusiness,
    OK
}

@Injectable({
    providedIn: 'root'
})

export class DeliveryService {
    public payDineDetails;
    public payCollectDetails;
    public userInfo: AdnUser;
    public payDeliverDetails;
    public selectedAddress;

    constructor(
        public storeDbSvc: StoreDbService,
        public utilSvc: UtilsService,
        public usersSvc: UsersService,
        public smbSvc: SmbService,
        public datepipe: DatePipe,
    ) { }

    getPayDineDetails(storeId) {
        return new Promise((resolve) => {
            const objectName = OBJECTNAME.payDineDetails;
            this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(data => {
                this.payDineDetails = data;
                resolve(data);
            });
        });
    }

    getPayCollectDetails(storeId) {
        return new Promise((resolve) => {
            const objectName = OBJECTNAME.payCollectDetails;
            this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(data => {
                this.payCollectDetails = data;
                resolve(data);
            });
        });
    }

    getPayDeliverDetails(storeId) {
        return new Promise((resolve) => {
            const objectName = OBJECTNAME.payDeliverDetails;
            this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(data => {
                this.payDeliverDetails = data;
                resolve(data);
            });
        });
    }

    getUserDetails(storeId, adnUserId) {
        return new Promise((resolve) => {
            const objectName = OBJECTNAME.adnUsers;
            this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName, adnUserId).then(data => {
                this.userInfo = data as AdnUser;
                resolve(data);
            });
        });
    }

    getAdnStoreInfo(storeId) {
        return new Promise((resolve) => {
            const objectName = OBJECTNAME.adnStores;
            this.storeDbSvc.getObject(storeId, this.utilSvc.sdb[storeId], objectName).then(data => {
                resolve(data);
            });
        });
    }

    payDineFormInit() {
        const dateDelivery = new Date();
        dateDelivery.setHours(dateDelivery.getHours() + 1, dateDelivery.getMinutes() + 30);
        const today = this.datepipe.transform(dateDelivery, 'yyyy-MM-dd');
        const now = this.datepipe.transform(dateDelivery, 'HH:MM');
        return [today, now];
    }

    payCollectFormInit() {
        const dateDelivery = new Date();
        dateDelivery.setHours(dateDelivery.getHours() + 1, dateDelivery.getMinutes() + 30);
        const today = this.datepipe.transform(dateDelivery, 'yyyy-MM-dd');
        const now = this.datepipe.transform(dateDelivery, 'HH:MM');
        return [today, now];
    }

    payDeliverFormInit(storeId: string, adnUserId: string, addressField: ElementRef) {
    }

    public validateDateTime(store: AdnStoreI, requestedDay, requestedTime?) {
        const todayDate = new Date().getTime() + 2 * 3600000;
        let requestedDayn = new Date(requestedDay).getTime();
        const pday = new Date(requestedDay).getDay();
        if (requestedTime ) {
            requestedDayn = requestedDayn + Number(requestedTime) * 3600000;
        }
        let result;

        if (todayDate > requestedDayn) {
            result = dateTimeValidationError.passedDate;
        } else {
            if (this.smbSvc.isStoreOpen(store, pday, requestedTime, 0)) {
                result = dateTimeValidationError.OK;
            } else {
                result = dateTimeValidationError.closedBusiness;
            }
        }
        return result;
    }


    public validateAddress(selectedAddress: AdnUserAddress, smbDeliveryRadius: number, errorMessage) {
        const distanceString = 'distance';
        let deliveryAddress = null;
        if (selectedAddress != null) {
            if (selectedAddress[distanceString] > smbDeliveryRadius * 1000) {
                errorMessage = {
                    title: 'Delivery not possible',
                    description: 'Delivery not possible, distance of ' + Math.round(selectedAddress[distanceString] / 1000) + ' Kms is above radius of ' + smbDeliveryRadius + ' Kms',
                    details: ''
                };
            } else {
                deliveryAddress = selectedAddress;
            }
        } else {
            errorMessage = {
                title: 'Delivery Address unknown',
                description: 'Delivery Address unknown. Please select an address with a maximum distance of ' + smbDeliveryRadius + ' Kms from our store',
                details: ''
            };
        }
        return deliveryAddress;
    }



}

